import { School } from 'models/School';
import { PaymentStatus } from 'searchality-data';

export const getVacancyType = (pathname: string) => {
  if (pathname.includes('drafted')) return 'drafted';
  if (pathname.includes('archived')) return 'archived';
  return 'active';
};

export const checkIfSomeSchoolPayedMatching = (
  isMultipleSchools: boolean,
  schoolIds: string[],
  schoolId: string,
  schools: School[],
) =>
  isMultipleSchools
    ? schools?.some(
        (el) =>
          schoolIds?.includes(el._id) &&
          el?.matchingInformation?.paymentStatus === PaymentStatus.PAID,
      )
    : schools?.find((el) => el?._id === schoolId)?.matchingInformation
        ?.paymentStatus === PaymentStatus.PAID;

export const checkIfSomeVacancySchoolHasPaidMatching = (schools: School[]) =>
  schools?.some(
    (el) => el.matchingInformation?.paymentStatus === PaymentStatus.PAID,
  );
