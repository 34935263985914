import { createContext } from 'react';

type MatchingSchoolsContextProps = {
  schoolsForMatching: string[];
  manageSchoolsForMatching: (id: string) => void;
  setSchoolForMatching: (id: string) => void;
  clearSchools: () => void;
};

export default createContext<Partial<MatchingSchoolsContextProps>>({});
