import { useTranslation } from 'react-i18next';
import { NonAcademicContractType } from 'searchality-data';

const useContractDaysOptions = () => {
  const { t } = useTranslation();
  return Object.entries(NonAcademicContractType).map(([key, value]) => ({
    label: t(`ContractDaysType.${key}`),
    value,
  }));
};

export default useContractDaysOptions;
