import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import Pricing from './pages/Pricing';
import Review from './pages/Review';
import SchoolInformation, {
  schoolInformationRoutes,
} from './subrouters/SchoolInformation';
import SchoolTypeRouter, {
  schoolTypeRoutes,
} from './subrouters/SchoolTypeRouter';

export default [
  {
    path: 'school-type',
    element: SchoolTypeRouter,
    routes: schoolTypeRoutes,
  },
  {
    path: `:schoolType/residential-type/:residentialType/school-information`,
    element: SchoolInformation,
    routes: schoolInformationRoutes,
    validate: (_, { schoolType }) => {
      return schoolTypeRoutes.map((r) => r.path).includes(schoolType)
        ? ''
        : '/create-school';
    },
  },
  {
    path: 'pricing-information',
    element: Pricing,
  },
  {
    path: 'school-review',
    element: Review,
  },
  {
    index: true,
    element: <Navigate to="school-type" replace />,
  },
] as Array<ComplexRoute>;
