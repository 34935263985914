import { ComplexRoute } from 'router/components/Routes/Routes';
import SchoolLogoGroupWrapper from 'router/subrouters/Dashboard/components/SchoolLogoGroupWrapper';
import AssociationContactDetails from '../../pages/AssociationContactDetails';
import SchoolAssociations from '../../pages/SchoolAssociations';
import SchoolPartOfAssociation from '../../pages/SchoolPartOfAssociation';
import SchoolInformationStandAlone from './pages/SchoolInformationStandAlone';

const routes: Readonly<Array<ComplexRoute>> = [
  {
    path: 'part-of-association',
    element: SchoolPartOfAssociation,
  },
  {
    path: 'choose-associations',
    element: SchoolAssociations,
  },
  {
    path: 'association-contact-details',
    element: AssociationContactDetails,
  },
  {
    path: 'school-logo',
    element: SchoolLogoGroupWrapper,
  },
  {
    index: true,
    element: SchoolInformationStandAlone,
  },
] as const;

export default routes;
