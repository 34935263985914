import React from 'react';
import Tippy from '@tippyjs/react';
import { ReactComponent as InformationIcon } from 'icons/Information.icon.svg';

import './ComponentWithTooltip.styles.scss';

type ComponentWithTooltipProps = {
  children: React.ReactNode;
  tooltipContent: string;
};

const ComponentWithTooltip: React.FC<ComponentWithTooltipProps> = ({
  children,
  tooltipContent,
}) => (
  <div className="component-with-tooltip">
    {children}
    <Tippy
      content={tooltipContent}
      placement="top"
      className="tooltip-information-top"
    >
      <InformationIcon />
    </Tippy>
  </div>
);

export default ComponentWithTooltip;
