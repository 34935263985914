import { useLazyPreviewMatchingPriceQuery } from 'api/payment.api';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import MatchingSchoolsContext from 'providers/MatchingSchools/MatchingSchools.context';
import { useCallback, useContext, useEffect } from 'react';
import authSelectors from 'store/selectors/auth.selectors';
import { updateCouponValue } from 'store/slices/payment.slice';
import { popServerError } from 'store/slices/popNotifications.slice';

const usePreviewMatchingPrice = () => {
  const [previewMatchingPrice, { data, isLoading }] =
    useLazyPreviewMatchingPriceQuery();

  const dispatch = useAppDispatch();
  const promoCode = useAppSelector(({ payment }) => payment.coupon);

  const { schoolsForMatching } = useContext(MatchingSchoolsContext);

  const { schoolGroupId } = useAppSelector(authSelectors.selectUser);

  const handlePreviewSubscriptionPrice = useCallback(async () => {
    previewMatchingPrice(
      { schoolIds: schoolsForMatching, promoCode, schoolGroupId },
      true,
    )
      .unwrap()
      .then((res) => {
        if (res?.isPromoCodeValid === false) {
          dispatch(updateCouponValue(undefined));
        }
      })
      .catch((e) => {
        dispatch(popServerError(e));
        dispatch(updateCouponValue(undefined));
      });
  }, [
    previewMatchingPrice,
    schoolsForMatching,
    promoCode,
    schoolGroupId,
    dispatch,
  ]);

  useEffect(() => {
    if (schoolsForMatching?.length) {
      handlePreviewSubscriptionPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlePreviewSubscriptionPrice]);

  return { data, isLoading };
};

export default usePreviewMatchingPrice;
