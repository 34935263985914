import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as PlusIcon } from 'icons/Plus.icon.svg';
import { useTranslation } from 'react-i18next';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { Button, Modal } from 'ncoded-component-library';
import { Field, Form, useFormState } from 'react-final-form';
import {
  checkForInvalidValue,
  composeValidators,
  emailValidation,
  required,
  websiteValidation,
} from 'validations';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import useRelationshipOptions from './hooks/useRelationShipOptions';
import ErrorMessage from 'components/ErrorMessage';
import { ReactComponent as MailIcon } from 'icons/Mail.icon.svg';
import { ReactComponent as PhoneIcon } from 'icons/Contact.icon.svg';
import PhoneNumberField from 'components/PhoneNumberField';
import { Reference } from 'models/Candidate';
import ReferenceCard from '../ReferenceCard';
import MapboxAutocompleteField from 'components/MapboxAutocomplete';
import { YearsOfExperience } from 'searchality-data';

import './References.styles.scss';
import './References.styles.responsive.scss';

type ReferencesProps = {
  className?: string;
  value: Reference[];
  touched?: boolean;
  onChange: (value: Reference[]) => void;
  onBlur: (event?: React.FocusEvent<HTMLElement, Element>) => void;
  maxReferences?: number;
};

const References: React.FC<ReferencesProps> = (props) => {
  const { className, value, onChange } = props;

  const [initialValues, setInitialValues] = useState<Reference>(
    {} as Reference,
  );

  const [editIndex, setEditIndex] = useState<number>();

  const { t } = useTranslation();

  const relationshipOptions = useRelationshipOptions();

  const classes = classNames('references', className);

  const collaborationYearsOptions = Object.keys(YearsOfExperience).map(
    (key) => ({
      label:
        key !== 'NONE'
          ? t(`yearsOfExperience.`, {
              years: YearsOfExperience[key as keyof typeof YearsOfExperience],
            })
          : t('yearsOfExperience.LESS_THEN_ONE_YEAR'),
      value: YearsOfExperience[key as keyof typeof YearsOfExperience],
    }),
  );

  const {
    values: { personalInformation },
  } = useFormState();

  const addModalRef = useRef<ModalRef>();

  const referenceCards = useMemo(
    () =>
      value?.map((values, index) => {
        const {
          firstName,
          lastName,
          contactEmail,
          contactPhone,
          jobTitle,
          schoolName,
          schoolCity,
        } = values;

        return (
          <ReferenceCard
            key={index}
            name={`${firstName} ${lastName}`}
            email={contactEmail}
            phone={
              contactPhone?.phoneNumber
                ? `${contactPhone?.countryCode} ${contactPhone?.phoneNumber}`
                : undefined
            }
            positionTitle={jobTitle}
            schoolName={`${schoolName}, ${schoolCity ? schoolCity : ''}`}
            handleDelete={() => {
              onChange(value?.filter((_, ind) => ind !== index));
            }}
            handleEdit={() => {
              setInitialValues(values);
              setEditIndex(index);
              addModalRef.current.open();
            }}
          />
        );
      }),
    [onChange, value],
  );

  const handleSubmit = useCallback(
    (values: Reference) => {
      if (editIndex === undefined) {
        onChange([...value, values]);
      } else {
        const valueCopy = JSON.parse(JSON.stringify(value));
        valueCopy[editIndex] = values;
        onChange(valueCopy);
      }
      addModalRef.current.close();
    },
    [editIndex, onChange, value],
  );

  const validate = useCallback(
    (values: Reference) => {
      const errors: Record<string, any> = {};

      if (!values?.contactEmail && !values?.contactPhone?.phoneNumber) {
        errors.contactEmail = t('referenceModal.atLeastOneContactType');
        errors.contactPhone = {
          phoneNumber: t('referenceModal.atLeastOneContactType'),
        };
      }

      return errors;
    },
    [t],
  );

  return (
    <div className={classes}>
      <Button
        variant="outline"
        icon={<PlusIcon />}
        onClick={() => addModalRef.current.open()}
        className="references__button"
      >
        {!value?.length ? t('addNewRefree') : t('addAnotherRefree')}
      </Button>
      {value.length > 0 && (
        <div className="references__cards">{referenceCards}</div>
      )}
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        render={({
          handleSubmit,
          form: { reset },
          submitError,
          hasSubmitErrors,
          modifiedSinceLastSubmit,
        }) => {
          return (
            <Modal
              className="references-modal"
              ref={addModalRef}
              title={t(
                editIndex !== undefined
                  ? 'referenceModal.editRefree'
                  : 'referenceModal.addNewRefree',
              )}
              onClose={() => {
                reset();
                setEditIndex(undefined);
                setInitialValues({} as Reference);
              }}
              footer={
                <Button onClick={handleSubmit}>
                  {t(
                    editIndex !== undefined
                      ? 'referenceModal.editRefree'
                      : 'referenceModal.saveReferee',
                  )}
                </Button>
              }
            >
              <form onSubmit={handleSubmit}>
                <div className="references-modal__basic-info">
                  <Field
                    name="firstName"
                    component={InputField}
                    label={t('firstName')}
                    validate={required()}
                    className={'references-modal__basic-info__input'}
                    required
                  />
                  <Field
                    name="lastName"
                    component={InputField}
                    label={t('lastName')}
                    validate={required()}
                    className={'references-modal__basic-info__input'}
                    required
                  />
                  <Field
                    name="jobTitle"
                    component={InputField}
                    label={t('referenceModal.jobTitleLabel')}
                    validate={required()}
                    className={'references-modal__basic-info__input'}
                    required
                  />
                  <Field
                    name="relationship"
                    component={SelectField}
                    label={t('relationshipToYou')}
                    validate={required()}
                    options={relationshipOptions}
                    required
                  />
                  <Field
                    name="schoolName"
                    component={InputField}
                    label={t('employerName')}
                    validate={required()}
                    className={'references-modal__basic-info__input'}
                    required
                  />

                  <Field
                    name="schoolCity"
                    component={MapboxAutocompleteField}
                    label={t('educationModal.county')}
                    mapFeaturesToOptions={(feature: any) => {
                      const { place_name } = feature;
                      return {
                        label: place_name,
                        value: place_name,
                      };
                    }}
                    mapboxParams={{
                      types: ['place'],
                    }}
                    validate={required()}
                    required
                  />
                  <Field
                    name="schoolWebsite"
                    component={InputField}
                    label={t('employerWebsite')}
                    validate={websiteValidation()}
                    className={'references-modal__basic-info__input'}
                  />
                  <Field
                    name="collaborationYears"
                    component={SelectField}
                    options={collaborationYearsOptions}
                    required
                    validate={required()}
                    label={t('referenceModal.referenceCovers')}
                  />
                </div>
                <div className="references-modal__get-in-touch">
                  <p className="references-modal__get-in-touch__title">
                    {t('referenceModal.roleDescriptionParagraph')}
                  </p>
                  <p className="references-modal__get-in-touch__description">
                    {t('referenceModal.getInTouchSub')}
                  </p>
                  <div className="references-modal__get-in-touch__email">
                    <MailIcon />
                    <Field
                      name="contactEmail"
                      component={InputField}
                      label={t('referenceModal.email')}
                      className={'references-modal__get-in-touch__input'}
                      validate={composeValidators(
                        emailValidation(),
                        checkForInvalidValue(
                          t('cantUseYourEmail'),
                          personalInformation?.email,
                        ),
                      )}
                    />
                  </div>
                  <div className="references-modal__get-in-touch__phone">
                    <PhoneIcon />
                    <PhoneNumberField
                      name="contactPhone"
                      label={t('phoneNumber')}
                      className={'references-modal__get-in-touch__input'}
                      renderAsPortal
                    />
                  </div>
                  {hasSubmitErrors && !modifiedSinceLastSubmit && (
                    <ErrorMessage message={submitError} />
                  )}
                </div>
              </form>
            </Modal>
          );
        }}
      />
    </div>
  );
};

export default References;
