import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useForm, useFormState } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import Tag from 'components/Tag';
import { ReactComponent as AddIcon } from 'icons/Add.icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/CloseDialog.icon.svg';
import GoBackButton from 'components/GoBackButton';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';
import { CREATE_SCHOOL_WIZARD } from 'enums';
import useSchoolInformationRoute from 'router/subrouters/Dashboard/subrouters/CreateSchool/hooks/useSchoolInformationRoute';
import { SchoolGroupStructureType } from 'searchality-data';

import './DisctrictSchoolsPreview.styles.scss';

type DisctrictSchoolsPreviewProps = {
  className?: string;
};

const DisctrictSchoolsPreview: React.FC<DisctrictSchoolsPreviewProps> = (
  props,
) => {
  const { className } = props;

  const navigate = useNavigate();

  const { completeStep } = useContext(CreateSchoolWizardContext);

  const { t } = useTranslation();

  const {
    values: { schools, schoolCount, type, structureType },
  } = useFormState();

  const { change } = useForm();

  const navigateRoute = useSchoolInformationRoute(false);

  const shouldDisabledAddSchool = useMemo(
    () =>
      !!(
        structureType === SchoolGroupStructureType.GROUP &&
        schoolCount <= schools?.length
      ),
    [schoolCount, schools, structureType],
  );

  const classes = classNames('disctrict-schools-preview', className);

  const handleRemoveSchool = useCallback(
    (school: any) => {
      change(
        'schools',
        schools.filter((el: any) => el !== school),
      );
    },
    [change, schools],
  );

  useEffect(() => {
    if (!schools?.length) {
      navigate('../schools');
    }
  }, [navigate, schools?.length]);

  return (
    <form
      className={classes}
      onSubmit={async (ev) => {
        ev.preventDefault();
        completeStep(CREATE_SCHOOL_WIZARD.SCHOOL_INFORMATION);
        navigate('/create-school/pricing-information');
      }}
    >
      <GoBackButton to={`/create-school/${navigateRoute}`} />
      <h1>
        {t('District.SchoolsPreview.title', {
          type:
            type === 'public-school' &&
            structureType === SchoolGroupStructureType.GROUP
              ? t('districtSmall')
              : t('groupSmall'),
        })}
      </h1>

      <div className="disctrict-schools-preview__school-tags">
        {schools?.map((school: any) => (
          <Tag key={school.name} text={school.name} className="school-tag">
            <button
              onClick={() => handleRemoveSchool(school)}
              className="svg-button-wrapper"
            >
              <DeleteIcon />
            </button>
          </Tag>
        ))}
      </div>
      <Button
        variant="outline"
        icon={<AddIcon />}
        iconPosition="right"
        onClick={() => navigate('../schools')}
        disabled={shouldDisabledAddSchool}
      >
        {t('District.SchoolsPreview.addAnotherSchool')}
      </Button>
      <p className="description">
        {t('District.SchoolsPreview.description', {
          type:
            type === 'public-school' &&
            structureType === SchoolGroupStructureType.GROUP
              ? t('districtSmall')
              : t('groupSmall'),
        })}
      </p>
      {shouldDisabledAddSchool && (
        <p className="description error">{t('maxNumberOfSchoolsError')}</p>
      )}
      <Button type="submit" className="disctrict-schools-preview__submit">
        {t('continue')}
      </Button>
    </form>
  );
};

export default DisctrictSchoolsPreview;
