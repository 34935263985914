import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import GoBackButton from 'components/GoBackButton';
import { useTranslation } from 'react-i18next';
import authSelectors from 'store/selectors/auth.selectors';
import useAppSelector from 'hooks/useAppSelector';
import DiscountCodeWrapper from '../../../../components/DiscountCodeWrapper';
import { SchoolGroupStructureType } from 'searchality-data';
import MatchingSchoolsContext from 'providers/MatchingSchools/MatchingSchools.context';
import CheckoutMatchingWrapper from '../../../../components/CheckoutMatchingWrapper';
import { useNavigate } from 'react-router-dom';

import './MatchingCheckout.styles.scss';

type MatchingCheckoutProps = {
  className?: string;
};

const MatchingCheckout: React.FC<MatchingCheckoutProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const classes = classNames('matching-checkout', className);

  const {
    schoolGroup: { structureType },
  } = useAppSelector(authSelectors.selectUser);

  const { schoolsForMatching } = useContext(MatchingSchoolsContext);

  const isStandalone = structureType === SchoolGroupStructureType.STANDALONE;

  const goTo =
    isStandalone || !schoolsForMatching.length
      ? '../matching-plan'
      : '../select-schools';

  useEffect(() => {
    if (!schoolsForMatching?.length) {
      navigate('/my-account/manage-subscription');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes}>
      <GoBackButton to={goTo} />
      <h1>{t('CheckoutPage.title')}</h1>
      <p>{t('CheckoutPage.description')}</p>
      <DiscountCodeWrapper>
        <CheckoutMatchingWrapper />
      </DiscountCodeWrapper>
    </div>
  );
};

export default MatchingCheckout;
