import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { STORAGE_KEYS_CONSTANT } from 'constants/general';
import { t } from 'i18next';
import { Teacher } from 'models/Teacher';
import { User } from 'models/User';
import credentialsService from 'services/credentialsService';
import GtmService from 'services/GTM.service';
import refreshTokenService from 'services/refreshToken.service';
import storageService from 'services/storageService';
import { LoginData, RegisterData } from 'types';
import utils from 'utils';
import { initializeVacancyWizard } from './createVacancyWizard.slice';
import { popError, popServerError } from './popNotifications.slice';

type AuthState = {
  loading: boolean;
  user: User;
  error?: string;
  verifying: boolean;
  submittiing: boolean;
};

const initialState: AuthState = {
  loading: false,
  user: null as User,
  verifying: true,
  submittiing: false,
};

export const loginUser = createAsyncThunk<void, LoginData>(
  'auth/login',
  async (loginData, { rejectWithValue }) => {
    try {
      const { data } = await api.auth.login(loginData);
      credentialsService.saveAuthBody(data);
      storageService.setItem(
        STORAGE_KEYS_CONSTANT.dontShowUntilNextLogin,
        'false',
      );
    } catch (err) {
      const stringError = utils.getStringError(err as any);
      return rejectWithValue(stringError);
    }
  },
);

export const registerUser = createAsyncThunk<void, RegisterData>(
  'auth/register',
  async (registerData, { dispatch }) => {
    try {
      const { data } = await api.auth.register(registerData);
      credentialsService.saveAuthBody(data);
      await dispatch(loadCurrentUser());
    } catch (err) {
      dispatch(popServerError(err));
    }
  },
);

export const registerTeacherUser = createAsyncThunk<void, RegisterData>(
  'teachers-auth/register',
  async (registerData, { dispatch }) => {
    try {
      const { data } = await api.teachersAuth.register(registerData);
      GtmService.teacherRegisters();
      credentialsService.saveAuthBody(data);
      await dispatch(loadCurrentUser());
    } catch (err) {
      dispatch(popServerError(err));
    }
  },
);

export const verifyEmail = createAsyncThunk<void, any>(
  'auth/verifyEmail',
  async (token, { dispatch }) => {
    try {
      const data = await api.auth.verifyEmail(token);
      credentialsService.saveAuthBody(data);
    } catch (err) {
      dispatch(popServerError(err));
    }
  },
);

export const loadCurrentUser = createAsyncThunk<any, undefined>(
  'auth/loadCurrentUser',
  async (_, { dispatch }) => {
    try {
      const user = await api.user.getCurrentUser();
      if (!user) throw Error('User not found');

      if (user.schoolGroup?.structureType) {
        await dispatch(
          initializeVacancyWizard({
            structureType: user.schoolGroup.structureType,
          }),
        );
      }

      return user;
    } catch (err) {
      dispatch(popServerError(err));
      dispatch(logout());
      return;
    }
  },
);

export const updateUser = createAction<User>('auth/updateUser');

export const expireSession = createAsyncThunk(
  'auth/sessionExpired',
  (_, { dispatch }) => {
    dispatch(popError(t('sessionExpired')));
    refreshTokenService.reinit();
    credentialsService.removeAuthBody();
    storageService.removeItem(STORAGE_KEYS_CONSTANT.dontShowUntilNextLogin);
    dispatch(logout());
  },
);

export const updateTeacherUser = createAsyncThunk<Teacher, Teacher>(
  'auth/updateTeacher',
  async (userData, { rejectWithValue }) => {
    try {
      const { data } = await api.teachersAuth.updateTacher(userData);
      return data;
    } catch (err) {
      const stringError = utils.getStringError(err as any);
      return rejectWithValue(stringError);
    }
  },
);

export const logout = createAction('auth/logout');

export const confirmSchoolInvitation = createAsyncThunk<
  void,
  RegisterData & { token: string }
>('auth/schoolInvitation', async (registerData, { dispatch }) => {
  try {
    const { data } = await api.auth.confirmSchoolInvitation(registerData);
    credentialsService.saveAuthBody(data);
    await dispatch(loadCurrentUser());
  } catch (err) {
    dispatch(popServerError(err));
  }
});

export const confirmAssociationInvitation = createAsyncThunk<
  void,
  RegisterData & { token: string }
>('auth/associationInvitation', async (registerData, { dispatch }) => {
  try {
    const { data } = await api.auth.confirmAssociationInvitation(registerData);
    credentialsService.saveAuthBody(data);
    await dispatch(loadCurrentUser());
  } catch (err) {
    dispatch(popServerError(err));
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadCurrentUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadCurrentUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(loadCurrentUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUser, (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.verifying = false;
      state.user = { ...state.user, emailConfirmed: true };
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.loading = false;
      state.verifying = false;
    });
    builder.addCase(logout, () => {
      return initialState;
    });
    builder.addCase(updateTeacherUser.fulfilled, (state, action) => {
      state.user = { ...state.user, ...action.payload };
    });
    builder.addDefaultCase((state) => state);
  },
});

export default authSlice.reducer;
