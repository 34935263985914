import api from 'api';
import { AxiosError } from 'axios';
import { getFileFromURL } from 'components/ImageCropper/utils';
import { DEFAULT_COUNTRY_CODE } from 'constants/general';
import { t } from 'i18next';
import { ExperienceType } from 'models/Candidate';
import { Publication, Teacher, TeacherWorkAvailability } from 'models/Teacher';
import { ImageFile, User } from 'models/User';
import { RightToWorkType } from 'searchality-data';
import ModelsMappingService from 'services/ModelsMapping.service';
import utils from 'utils';
import {
  PublicationFormValue,
  TeacherFormValues,
  TeacherFormWorkAvailabilty,
} from '../models/TeacherFormValues';

async function getPersonalInformation(values: TeacherFormValues) {
  const {
    gender,
    birthDate,
    birthDateAnswerRejected,
    website,
    videoUrl,
    genderDescription,
  } = values;

  return {
    gender,
    genderDescription,
    birthDate,
    birthDateAnswerRejected,
    website,
    videoUrl,
  };
}
async function getContactInformation(values: TeacherFormValues) {
  const { streetAddress, city, postcode, state, mobilePhone } = values;
  return {
    streetAddress,
    city,
    postcode,
    state,
    ...(mobilePhone?.phoneNumber && { mobilePhone }),
  };
}
async function getWorkAvailability(values: TeacherFormValues) {
  const {
    rightToWorkDescription,
    rightToWork,
    hasRightToWork,
    dateUntilVisaIsValid,
    startDate,
  } = values;

  const submitObject: Partial<Teacher> = {
    hasRightToWork,
    rightToWorkDescription,
  };

  if (rightToWork?.includes(RightToWorkType.VISA)) {
    submitObject.dateUntilVisaIsValid = dateUntilVisaIsValid;
  }
  if (hasRightToWork) {
    submitObject.rightToWork = [rightToWork];
  } else {
    if (hasRightToWork === false) {
      submitObject.rightToWork = [];
    }
  }

  if (values.immediately) {
    submitObject.startDate = null;
  } else if (startDate) {
    submitObject.startDate = startDate;
  }

  return submitObject;
}
async function getCredentialsEducation({
  education,
  certificates,
}: TeacherFormValues) {
  return {
    certificates,
    education: education?.length > 0 ? education : undefined,
  };
}
async function getCarrerExperience({
  experience,
  curricula,
}: TeacherFormValues) {
  const parsedExperiences: ExperienceType[] = experience?.map((exp) =>
    ModelsMappingService.parseExperience(exp),
  );

  return {
    experience: parsedExperiences,
    curricula,
  };
}
async function getSportsActivities(values: TeacherFormValues) {
  const { sports, activities } = values;

  return {
    sports,
    activities,
  };
}
async function getLanguages(values: TeacherFormValues) {
  const { englishLevel, languages } = values;
  return {
    englishLevel,
    languages: languages?.length > 0 ? languages : undefined,
  };
}

async function getReferences({ references }: TeacherFormValues) {
  return {
    references:
      references?.length > 0
        ? references?.map((reference) => ({
            ...reference,
            contactPhone: reference.contactPhone?.phoneNumber
              ? reference.contactPhone
              : undefined,
          }))
        : undefined,
  };
}

async function getTargetPositions({
  targetPositions,
  preferredResidentialTypes,
  preferredResidentialRole,
}: TeacherFormValues) {
  return {
    targetPositions,
    preferredResidentialTypes,
    preferredResidentialRole,
  };
}

async function getTargetLocations({ targetLocations }: TeacherFormValues) {
  return {
    targetLocations,
  };
}

async function formatPublications(publications: Publication[]) {
  if (!publications?.length) return [];

  return await Promise.all(
    publications?.map(async ({ name, key, url }) => ({
      name,
      ...(!key && {
        url,
      }),
      ...(key && {
        file: [await getFileFromURL(url, t('publication'), 'application/pdf')],
      }),
    })),
  );
}

function formatPublicationFormIntoPublications(
  publications: PublicationFormValue[],
) {
  return (publications as PublicationFormValue[])?.map(
    ({ url, name, file }, index) => ({
      _id: index.toString(),
      url: url ?? URL.createObjectURL(file?.[0]),
      name,
      key: url ? undefined : 'true',
    }),
  );
}

async function formatTeacherValues(user: User) {
  const {
    genderDescription,
    gender,
    birthDate,
    birthDateAnswerRejected,
    website,
    videoUrl,
    streetAddress,
    city,
    postcode,
    state,
    mobilePhone,
    hasRightToWork,
    rightToWork,
    dateUntilVisaIsValid,
    rightToWorkDescription,
    startDate,
    education,
    sports,
    activities,
    curricula,
    englishLevel,
    languages,
    references,
    imageFile,
    educationPhilosophyUrl,
    publications,
    certificates,
    experience,
    targetLocations,
    targetPositions,
    preferredResidentialRole,
    preferredResidentialTypes,
  } = user;

  const teacherFormValues: any = {
    genderDescription,
    gender,
    birthDate,
    birthDateAnswerRejected,
    website,
    videoUrl,
    streetAddress,
    city,
    preferredResidentialRole,
    preferredResidentialTypes,
    postcode,
    state,
    mobilePhone,
    ...formatWorkAvailability({
      hasRightToWork,
      rightToWork,
      dateUntilVisaIsValid,
      rightToWorkDescription,
      startDate,
    }),
    education,
    certificates,
    experience: experience?.map((exp) =>
      ModelsMappingService.formatExperience(exp),
    ),
    sports,
    activities,
    curricula,
    englishLevel,
    languages,
    references,
    targetLocations,
    targetPositions,
  };

  if (!mobilePhone) {
    teacherFormValues.mobilePhone = { countryCode: DEFAULT_COUNTRY_CODE };
  }

  if (imageFile) {
    try {
      const file = await getFileFromURL(imageFile.url, 'photo', 'image/jpeg');
      teacherFormValues.imageFile = [file];
    } catch (err) {
      teacherFormValues.imageFile = undefined;
    }
  }

  if (publications) {
    teacherFormValues.publications = await formatPublications(publications);
  }

  if (educationPhilosophyUrl)
    try {
      const file = await getFileFromURL(
        educationPhilosophyUrl,
        t('educationPhilosophy'),
        'application/pdf',
      );
      teacherFormValues.educationPhilosophy = [file];
    } catch (err) {
      teacherFormValues.educationPhilosophy = undefined;
    }

  return teacherFormValues;
}
function formatWorkAvailability(workAvailability: TeacherWorkAvailability) {
  const {
    hasRightToWork,
    rightToWork,
    dateUntilVisaIsValid,
    rightToWorkDescription,
    startDate,
  } = workAvailability;
  return {
    hasRightToWork,
    rightToWork: hasRightToWork ? rightToWork?.[0] : undefined,
    dateUntilVisaIsValid,
    rightToWorkDescription,
    immediately: startDate === null ? true : false,
    startDate: startDate ?? undefined,
  } as TeacherFormWorkAvailabilty;
}

async function submitTeacherProfileImage(
  imageFile: File,
  onSuccess: (userImageFile: ImageFile) => void,
  onError: (errorMessage: string) => void,
) {
  try {
    const {
      data: { imageFile: userImageFile },
    } = await api.user.uploadUserPhoto(imageFile);
    onSuccess?.(userImageFile as ImageFile);
  } catch (err) {
    onError?.(utils.getStringError(err as AxiosError));
  }
}
async function submitTeacherEducationPhilosophy(
  file: File[],
  onSuccess: (educationPhilosophyUrl: string) => void,
  onError: (errorMessage: string) => void,
) {
  try {
    const formData = new FormData();

    formData.append('file', file[0]);
    const {
      data: { educationPhilosophyUrl },
    } = await api.user.uploadEducationalPhilosophy(formData);
    onSuccess?.(educationPhilosophyUrl);
  } catch (err) {
    onError?.(utils.getStringError(err as AxiosError));
  }
}

async function submitPublications(
  publicationsFormValue: PublicationFormValue[],
  onSuccess: (publications: Publication[]) => void,
  onError: (errorMessage: string) => void,
) {
  try {
    const formData = new FormData();

    if (publicationsFormValue?.length) {
      publicationsFormValue.forEach(({ url, file, name }) => {
        if (file?.[0]) {
          formData.append('files', file[0]);
          formData.append('fileNames', name);
          return;
        }
        formData.append('urls', url);
        formData.append('urlNames', name);
      });
    }

    const {
      data: { publications },
    } = await api.user.uploadPublications(formData);
    onSuccess?.(publications);
  } catch (err) {
    onError?.(utils.getStringError(err as AxiosError));
  }
}

async function getTeacherSubmitObject(formValues: TeacherFormValues) {
  if (JSON.stringify(formValues) === '{}') {
    return {};
  }

  const data = await Promise.all([
    getPersonalInformation(formValues),
    getContactInformation(formValues),
    getWorkAvailability(formValues),
    getCredentialsEducation(formValues),
    getCarrerExperience(formValues),
    getSportsActivities(formValues),
    getLanguages(formValues),
    getReferences(formValues),
    getTargetLocations(formValues),
    getTargetPositions(formValues),
  ]);

  const submitObject: Teacher = data.reduce((acc: Teacher, curr: any) => {
    return { ...acc, ...curr };
  }, {} as Teacher);

  return submitObject;
}

export default {
  getCarrerExperience,
  getContactInformation,
  getCredentialsEducation,
  getPersonalInformation,
  getLanguages,
  getWorkAvailability,
  getSportsActivities,
  getReferences,
  getTargetPositions,
  getTargetLocations,
  getTeacherSubmitObject,
  formatWorkAvailability,
  formatTeacherValues,
  submitTeacherProfileImage,
  submitTeacherEducationPhilosophy,
  submitPublications,
  formatPublications,
  formatPublicationFormIntoPublications,
};
