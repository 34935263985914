import FormFieldLabel from 'components/FormFieldLabel';
import InputField from 'components/InputField';
import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { maxNumberValidation } from 'validations';

type AcademicContractDaysFieldsProps = {
  baseClass: string;
  isDisabled: boolean;
};

const AcademicContractDaysFields: React.FC<AcademicContractDaysFieldsProps> = (
  props,
) => {
  const { baseClass, isDisabled } = props;

  const { t } = useTranslation();

  return (
    <div className={`${baseClass}__contact-tearms`}>
      <FormFieldLabel
        text={t('contractTerms')}
        hint={t('vacancyWizard.step4.contractTippyContent')}
      />
      <p className="descriptive-paragraph">
        {t('vacancyWizard.step4.contractTermsParagraph')}
      </p>
      <Field
        name="studentContactDays"
        component={InputField}
        label={t('studentContactDays')}
        validate={maxNumberValidation(365)}
        onlyNumbers
        maxLength={3}
        disabled={isDisabled}
      />
      <Field
        name="studentNonContactDays"
        component={InputField}
        label={t('nonContactDays')}
        validate={maxNumberValidation(365)}
        onlyNumbers
        maxLength={3}
        disabled={isDisabled}
      />
    </div>
  );
};

export default AcademicContractDaysFields;
