import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { numberPriceToCurrencyString } from 'utils';
import { SchoolGroupStructureType } from 'searchality-data';
import { School } from 'models/School';
import useSearchalityPricing from 'hooks/useSearchalityPricing';

export const useTotalCardContent = (
  structureType: SchoolGroupStructureType,
  schools: School[],
  hasOldPricingSystem: boolean,
) => {
  const { t } = useTranslation();

  const {
    WEBSITE_INTEGRATION_PRICE,
    STANDALONE_PRICE,
    GROUP_INTITIAL_PRICE,
    GROUP_ADDITIONAL_SCHOOL_PRICE,
    WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
  } = useSearchalityPricing(hasOldPricingSystem);

  const isStandalone = useMemo(
    () => structureType === SchoolGroupStructureType.STANDALONE,
    [structureType],
  );

  const initialPrice = useMemo(
    () => (isStandalone ? STANDALONE_PRICE : GROUP_INTITIAL_PRICE),
    [GROUP_INTITIAL_PRICE, STANDALONE_PRICE, isStandalone],
  );

  const schoolIncludedInInitialPrice = useMemo(
    () =>
      schools
        ?.slice(0, 2)
        ?.map((school: School) => school?.name)
        .join(', '),

    [schools],
  );

  const additionalSchoolsPrice = useMemo(
    () => schools?.slice(2)?.length * GROUP_ADDITIONAL_SCHOOL_PRICE,
    [GROUP_ADDITIONAL_SCHOOL_PRICE, schools],
  );

  const additionalSchoolsIntegrationPrice = useMemo(
    () => schools?.slice(2)?.length * WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
    [WEBSITE_ADDITIONAL_INTEGRATION_PRICE, schools],
  );

  const additionalSchoolNames = useMemo(
    () =>
      schools
        ?.slice(2)
        ?.map((school: School) => school.name)
        ?.join(', '),
    [schools],
  );

  const totalPrice = useMemo(
    () =>
      initialPrice +
      additionalSchoolsPrice +
      additionalSchoolsIntegrationPrice +
      WEBSITE_INTEGRATION_PRICE,
    [
      WEBSITE_INTEGRATION_PRICE,
      additionalSchoolsIntegrationPrice,
      additionalSchoolsPrice,
      initialPrice,
    ],
  );

  return {
    subtitle: numberPriceToCurrencyString(totalPrice),
    content: (
      <ul>
        <li className="card__li-price">
          {t('Pricing.Total.initialPrice', {
            schoolNames: schoolIncludedInInitialPrice,
            price: numberPriceToCurrencyString(initialPrice),
          })}
        </li>
        <li className="card__li-price">
          {t('Pricing.Total.oneTimeSetupFee', {
            price: numberPriceToCurrencyString(WEBSITE_INTEGRATION_PRICE),
          })}
        </li>
        {schools?.length > 2 && (
          <li className="card__li-price">
            {t('Pricing.Total.additionalSchoolsPrice', {
              price: numberPriceToCurrencyString(additionalSchoolsPrice),
              schoolNames: additionalSchoolNames,
            })}
          </li>
        )}
        {schools?.length > 2 && (
          <li className="card__li-price">
            {t('Pricing.Total.additionalIntegrationsPrice', {
              price: numberPriceToCurrencyString(
                additionalSchoolsIntegrationPrice,
              ),
              schoolNames: additionalSchoolNames,
            })}
          </li>
        )}
      </ul>
    ),
  };
};
