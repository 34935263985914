import React, { useCallback, useMemo } from 'react';
import { SubscriptionLinkProps } from '../components/SubscriptionLink/SubscriptionLink.component';
import { ReactComponent as EducationIcon } from 'icons/Education.icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import { ReactComponent as MembersIcon } from 'icons/Members.icon.svg';
import { ReactComponent as LanguageIcon } from 'icons/Language.icon.svg';
import { useTranslation } from 'react-i18next';
import { numberPriceToCurrencyString } from 'utils';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { SchoolGroupStructureType, PaymentStatus } from 'searchality-data';
import useSearchalityPricing from 'hooks/useSearchalityPricing';
import DateService from 'services/Date.service';
import confirm from 'modules/confirm';
import { useCancelMatchingSubMutation } from 'api/payment.api';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { loadCurrentUser } from 'store/slices/auth.slice';
import useAppDispatch from 'hooks/useAppDispatch';

const useSubscriptionLinksList = () => {
  const { t } = useTranslation();
  const user = useAppSelector(authSelectors.selectUser);

  const {
    schoolGroup: {
      structureType,
      schoolCount,
      schools,
      hasOldPricingSystem,
      paymentStatus,
      subscriptionEndDate,
    },
  } = user;

  const dispatch = useAppDispatch();

  const [unsubscribe, { isLoading }] = useCancelMatchingSubMutation();

  const {
    GROUP_ADDITIONAL_SCHOOL_PRICE,
    WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
    MATCHING_STANDALONE_PRICE,
    MATCHING_SCHOOL_GROUP_PRICE,
  } = useSearchalityPricing(hasOldPricingSystem);

  const isStandalone = structureType === SchoolGroupStructureType.STANDALONE;

  const isSubscriptionCanceled =
    isStandalone && schools?.[0]?.matchingInformation?.isSubscriptionCanceled;

  const isMatchingPaid = useMemo(
    () =>
      schools.every(
        (school) =>
          school?.matchingInformation?.paymentStatus === PaymentStatus.PAID,
      ),
    [schools],
  );

  const disableMatching = useMemo(
    () => paymentStatus !== PaymentStatus.PAID || isMatchingPaid,
    [paymentStatus, isMatchingPaid],
  );

  const matchingPriceText = useMemo(
    () =>
      `${t('UpgradeSubscription.price', {
        price: numberPriceToCurrencyString(
          isStandalone
            ? MATCHING_STANDALONE_PRICE
            : MATCHING_SCHOOL_GROUP_PRICE,
        ),
      })}${!isStandalone ? t('UpgradeSubscription.perSchool') : ''}`,
    [MATCHING_SCHOOL_GROUP_PRICE, MATCHING_STANDALONE_PRICE, isStandalone, t],
  );

  const handleCancelMatching = useCallback(async () => {
    try {
      await unsubscribe();

      dispatch(loadCurrentUser());
      dispatch(popSuccess(t('successUnsubscribed')));
    } catch (error) {
      dispatch(popServerError(error));
    }
  }, [dispatch, t, unsubscribe]);

  return [
    ...(structureType === SchoolGroupStructureType.GROUP
      ? [
          {
            priceText: t('UpgradeSubscription.addSchoolPrice', {
              time: t('year'),
              schoolPrice: numberPriceToCurrencyString(
                GROUP_ADDITIONAL_SCHOOL_PRICE,
              ),
              integrationPrice: numberPriceToCurrencyString(
                WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
              ),
            }),
            description: t('UpgradeSubscription.addSchoolDescription'),
            to: '/my-account/manage-subscription/add-new-school',
            iconLeft: <EducationIcon />,
            disabled: schoolCount === schools?.length,
            ...(schoolCount === schools?.length && {
              errorMessage: t('UpgradeSubscription.addSchoolError'),
            }),
          },
          {
            description: t('UpgradeSubscription.removeSchoolDescription'),
            priceText: t('UpgradeSubscription.removeSchoolDescriptionSub'),
            to: '/my-account/school-settings',
            iconLeft: <DeleteIcon />,
            disabled: schools?.length < 1,
            ...(schools?.length < 1 && {
              errorMessage: t('UpgradeSubscription.removeSchoolError'),
            }),
          },
        ]
      : []),
    ...(isMatchingPaid && isStandalone && !isSubscriptionCanceled
      ? []
      : [
          {
            priceText: isSubscriptionCanceled
              ? t('UpgradeSubscription.matchingTitleCancelled')
              : matchingPriceText,
            description: isSubscriptionCanceled
              ? t('UpgradeSubscription.matchingDescriptionCancelled', {
                  date: DateService.getUSADateFormatLong(subscriptionEndDate),
                })
              : t('UpgradeSubscription.matchingDescription'),
            to: '/my-account/manage-subscription/matching-subscription',
            iconLeft: <MembersIcon />,
            disabled: disableMatching,
          },
        ]),
    ...(isMatchingPaid && isStandalone && !isSubscriptionCanceled
      ? [
          {
            priceText: t('SubscriptionActions.until', {
              date: DateService.getUSADateFormatLong(subscriptionEndDate),
            }),
            description: t('UpgradeSubscription.cancelMatchingSubscription'),
            className: 'cancel-matching-sub',
            iconLeft: <MembersIcon />,
            onClick: async () =>
              await confirm({
                title: t('CancelMatchingModal.title'),
                cancelBtnText: t('CancelMatchingModal.cancel'),
                confirmBtnText: t('CancelMatchingModal.confirm'),
                className: 'cancel-matching-sub-modal',
                cancelButtonVariant: 'solid',
                confirmButtonVariant: 'outline',
                content: t('CancelMatchingModal.content', {
                  date: DateService.getUSADateFormatLong(subscriptionEndDate),
                }),
                disabledSubmit: isLoading,
                onSubmit: handleCancelMatching,
              }),
          },
        ]
      : []),
    {
      // priceText: t('UpgradeSubscription.price', {
      //   time: t('month'),
      //   price: numberPriceToCurrencyString(50),
      // }),
      priceText: t('comingSoon'),
      description: t('UpgradeSubscription.marketingDescription'),
      to: '/',
      iconLeft: <LanguageIcon />,
      disabled: true,
    },
  ] as SubscriptionLinkProps[];
};

export default useSubscriptionLinksList;
