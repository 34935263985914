import React, { useCallback, useEffect, useState } from 'react';
import useAppSelector from 'hooks/useAppSelector';
import { Form } from 'react-final-form';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AddNewSchoolValues } from 'types';
import authSelectors from 'store/selectors/auth.selectors';
import { School } from 'models/School';
import useAppDispatch from 'hooks/useAppDispatch';
import api from 'api';
import { updateUser } from 'store/slices/auth.slice';
import { popServerError } from 'store/slices/popNotifications.slice';
import { getFileFromURL } from 'components/ImageCropper/utils';

import './AddNewSchool.styles.scss';
import classNames from 'classnames';

const AddNewSchool: React.FC = () => {
  const user = useAppSelector(authSelectors.selectUser);
  const [schoolsForCreate, setSchoolsForCreate] = useState<
    (School & { schoolLogo?: File[] })[]
  >([]);

  const {
    schoolGroupId,
    schoolGroup: { subscriptionId },
  } = user;

  const schools = useAppSelector(({ addNewSchool }) => addNewSchool.schools);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    async (values: AddNewSchoolValues) => {
      if (subscriptionId) {
        return navigate('matching-plan');
      }

      const { schools } = values;
      const schoolsWithoutLogo = schools?.map((school: any) => {
        const { schoolLogoUrl, schoolLogoType, ...rest } = school;
        return rest;
      });

      try {
        const {
          data: { schoolGroup },
        } = await api.schools.addSchools(schoolGroupId, {
          schools: schoolsWithoutLogo,
        });

        let updateSchools: School[] = schoolGroup?.schools;

        for (const school of schoolGroup?.schools as School[]) {
          try {
            const schoolLogo = (schoolsForCreate as any)?.find(
              (el: any) => el.name === school.name,
            )?.schoolLogo;

            if (schoolLogo) {
              const { data } = await api.user.uploadSchoolLogo(
                schoolLogo[0],
                school._id,
              );
              updateSchools = updateSchools?.map((el) =>
                el._id === data._id ? data : el,
              );
            }
          } catch (e) {
            dispatch(popServerError(e));
          }
        }

        dispatch(
          updateUser({
            ...user,
            schoolGroup: {
              ...schoolGroup,
              schools: updateSchools,
            },
            schools: updateSchools,
            schoolIds: updateSchools?.map((el: School) => el._id),
          }),
        );

        navigate('./finish');
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, navigate, schoolGroupId, schoolsForCreate, subscriptionId, user],
  );

  const fetchIcons = useCallback(async () => {
    let schoolsToUpdate = structuredClone(schools);
    for (const school of schoolsToUpdate) {
      try {
        const schoolForFile = (schools as any)?.find(
          (el: any) => el.name === school.name,
        );

        if (schoolForFile?.schoolLogoUrl) {
          const file = await getFileFromURL(
            schoolForFile.schoolLogoUrl,
            'School Logo',
            schoolForFile?.schoolLogoType,
          );

          schoolsToUpdate = schoolsToUpdate?.map((el) =>
            el.name === schoolForFile?.name
              ? { ...el, schoolLogo: [file] }
              : el,
          );
        }
      } catch (e) {
        dispatch(popServerError(e));
      }
    }
    setSchoolsForCreate(schoolsToUpdate);
  }, [dispatch, schools]);

  useEffect(() => {
    fetchIcons();
  }, [fetchIcons]);

  useEffect(() => {
    if (schools.length) {
      navigate('preview', { replace: true });
      return;
    }

    navigate('school', { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames('add-new-school', {
        'add-new-school--checkout': pathname?.includes('checkout'),
      })}
    >
      {pathname?.includes('checkout') ? (
        <Outlet />
      ) : (
        <Form
          onSubmit={handleSubmit}
          initialValues={{ schools }}
          render={() => <Outlet />}
        />
      )}
    </div>
  );
};

export default AddNewSchool;
