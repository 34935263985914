import { SchoolGroupStructureType, SchoolGroupType } from 'searchality-data';

export const schoolTypeOptions: Record<
  SchoolGroupType,
  { label: string; description: string }
> = {
  'public school': {
    label: 'publicSchool',
    description: 'publicSchoolDescription',
  },
  independent: {
    label: 'independentSchool',
    description: 'independentSchoolDescription',
  },
  charter: {
    label: 'charterSchool',
    description: 'charterSchoolDescription',
  },
  religious: {
    label: 'religiousSchool',
    description: 'religiousSchoolDescription',
  },
};

export const schoolSubTypePublicOptions: Record<
  SchoolGroupStructureType,
  { label: string; description: string }
> = {
  standalone: {
    label: 'publicStandaloneTitle',
    description: 'publicStandaloneDescription',
  },
  group: {
    label: 'publicGroupTitle',
    description: 'publicGroupDescription',
  },
};

export const schoolSubTypeIndependentOptions: Record<
  SchoolGroupStructureType,
  { label: string; description: string }
> = {
  standalone: {
    label: 'independentStandaloneTitle',
    description: 'independentStandaloneDescription',
  },
  group: {
    label: 'independentGroupTitle',
    description: 'independentGroupDescription',
  },
};
