import React, { useMemo } from 'react';
import SelectField from 'components/SelectField';
import ToggleField from 'components/ToggleField';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { required } from 'validations';
import TwoOptionsSelectField from 'components/TwoOptionsSelectField';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import useAnotherLanguage from '../../../../../../../../../hooks/selectOptions/useAnotherLanguageOptions';
import useAnotherLanguageLevel from '../../hooks/useAnotherLanguageLevel';
import useEducationLevelOptions from '../../hooks/useEducationLevelOptions';
import useNumberOfReferences from '../../hooks/useNumberOfReferences';
import FormFieldLabel from 'components/FormFieldLabel';
import {
  SchoolResidentialType,
  VacancyStatus,
  VacancyType,
  YearsOfExperience,
} from 'searchality-data';
import { TagsField } from 'components/Tags/Tags.component';
import CurriculumExperienceFields from './components/CurriculumExperienceFields';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import BoardingSchoolPositionRequirements from '../../components/BoardingSchoolPositionRequirements';

import './PositionRequirementsFormFields.styles.scss';

const PositionRequirementsFormFields: React.FC = () => {
  const { t } = useTranslation();

  const { schoolGroup } = useAppSelector(authSelectors.selectUser);

  const { residentialType } = schoolGroup || {};

  const anotherLanguage = useAnotherLanguage();

  const anotherLanguageLevel = useAnotherLanguageLevel();

  const numberOfReferencesOptions = useNumberOfReferences();

  const educationLevelOptions = useEducationLevelOptions();

  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );

  const isAcademicVacancy = type === VacancyType.ACADEMIC;

  const {
    values: { isSecondLanguageRequired, yearsOfExperienceRequired, status },
  } = useFormState();

  const isOngoingVacancyEdit = status === VacancyStatus.ONGOING;

  const { change } = useForm();

  useEffectSkipFirst(() => {
    if (!isSecondLanguageRequired) {
      change('requiredLanguages', []);
    }
  }, [isSecondLanguageRequired]);

  const baseClass = 'position-requirements-fields';

  const yearsOfExperience = useMemo(() => {
    return Object.keys(YearsOfExperience).map((key, index) => ({
      label:
        key === 'NONE'
          ? t('yearsOfExperience.None')
          : t(`yearsOfExperience.`, {
              years: YearsOfExperience[key as keyof typeof YearsOfExperience],
            }),
      value: index.toString(),
    }));
  }, [t]);

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__position`}>
        <FormFieldLabel
          text={t('QualificationDescription.title.educationLevel')}
        />
        <p className={`${baseClass}__position__sub`}>
          {t('vacancyWizard.step3.educationLevel')}
        </p>
        <Field
          name="educationLevel"
          component={SelectField}
          label={t('educationLevel')}
          options={educationLevelOptions}
          required
          validate={required()}
          disabled={isOngoingVacancyEdit}
        />
        <Field
          name="certificates"
          component={TagsField}
          placeholder={t('vacancyWizard.step3.certificatesPlaceholder')}
          disabled={isOngoingVacancyEdit}
        />
      </div>
      <div className={`${baseClass}__cover-letter`}>
        <FormFieldLabel text={t('vacancyWizard.step3.coverLetterParagraph')} />
        <Field
          name="isCoverLetterRequired"
          component={ToggleField}
          validate={required()}
          disabled={isOngoingVacancyEdit}
        />
      </div>
      {type === VacancyType.ACADEMIC && (
        <div className={`${baseClass}__resume`}>
          <div>
            <FormFieldLabel text={t('vacancyWizard.step3.resumeParagraph')} />
            <p className={`${baseClass}__resume__sub`}>
              {t('vacancyWizard.step3.resumeSubParagraph')}
            </p>
          </div>
          <Field
            name="isResumeRequired"
            component={ToggleField}
            validate={required()}
            disabled={isOngoingVacancyEdit}
          />
        </div>
      )}
      {type === VacancyType.ACADEMIC && (
        <div className={`${baseClass}__resume`}>
          <div>
            <FormFieldLabel
              text={t('vacancyWizard.step3.eduPhilosophyTitle')}
            />
            <p className={`${baseClass}__resume__sub`}>
              {t('vacancyWizard.step3.eduPhilosophyDesc')}
            </p>
          </div>
          <Field
            name="isEducationPhilosophyRequired"
            component={ToggleField}
            validate={required()}
            disabled={isOngoingVacancyEdit}
          />
        </div>
      )}
      <div className={`${baseClass}__reference`}>
        <div>
          <FormFieldLabel text={t('vacancyWizard.step3.referenceParagraph')} />
          <p className={`${baseClass}__reference__sub`}>
            {t('vacancyWizard.step3.referenceSubParagraph')}
          </p>
        </div>
        <Field
          name="numberOfReferences"
          label={t('numberOfReferences')}
          component={SelectField}
          options={numberOfReferencesOptions}
          validate={required()}
          required
          disabled={isOngoingVacancyEdit}
        />
      </div>
      <div className={`${baseClass}__experience`}>
        <FormFieldLabel text={t('vacancyWizard.step3.experienceParagraph1')} />
        <p className={`${baseClass}__experience__sub`}>
          {t('vacancyWizard.step3.experienceParagraph2')}
        </p>
        <Field
          name="yearsOfExperienceRequired"
          label={t('yearsExperience')}
          component={SelectField}
          options={yearsOfExperience}
          validate={required()}
          key={yearsOfExperienceRequired}
          required
          disabled={isOngoingVacancyEdit}
        />
        {isAcademicVacancy && (
          <CurriculumExperienceFields baseClass={baseClass} />
        )}
      </div>
      <div className={`${baseClass}__required-language`}>
        <FormFieldLabel
          text={t('vacancyWizard.step3.requiredLanguageParagraph1')}
        />
        <p className={`${baseClass}__required-language__sub`}>
          {t('vacancyWizard.step3.requiredLanguageQualification')}
        </p>
        <Field
          name="isSecondLanguageRequired"
          component={ToggleField}
          validate={required()}
          disabled={isOngoingVacancyEdit}
        />
        {isSecondLanguageRequired && (
          <Field
            name="requiredLanguages"
            component={TwoOptionsSelectField}
            firstSelectOptions={anotherLanguage}
            secondSelectOptions={anotherLanguageLevel}
            firstSelectLabel={t('vacancyWizard.step3.anotherLanguage')}
            secondSelectLabel={t('vacancyWizard.step3.levelRequiredByTeacher')}
            paragraphText={t('vacancyWizard.step3.requiredLanguageParagraph2')}
            validate={required()}
            firstOptionName="language"
            secondOptionName="level"
            otherOptionLabel={t('vacancyWizard.step3.other')}
            disabled={isOngoingVacancyEdit}
          />
        )}
      </div>
      <div className={`${baseClass}__work-in-usa`}>
        <FormFieldLabel text={t('vacancyWizard.step3.workInUsaParagraph')} />
        <p className={`${baseClass}__work-in-usa__sub`}>
          {t('vacancyWizard.step3.workInUsaParagraph2')}
        </p>
        <Field
          name="isRightToWorkRequired"
          component={ToggleField}
          validate={required()}
          disabled={isOngoingVacancyEdit}
        />
      </div>
      {residentialType !== SchoolResidentialType.DAY_SCHOOL && (
        <BoardingSchoolPositionRequirements />
      )}
    </div>
  );
};

export default PositionRequirementsFormFields;
