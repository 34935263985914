import MatchingSchoolsContext from 'providers/MatchingSchools/MatchingSchools.context';
import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MatchingPricingPlan from '../../../../components/MatchingPricingPlan';

const MatchingSub: React.FC = () => {
  const navigate = useNavigate();

  const { clearSchools } = useContext(MatchingSchoolsContext);

  const onSkip = useCallback(() => {
    clearSchools();
    navigate('../checkout');
  }, [navigate, clearSchools]);

  const onContinue = useCallback(() => {
    navigate('../select-schools');
  }, [navigate]);

  return (
    <MatchingPricingPlan
      goBackTo="../pricing"
      onContinue={onContinue}
      onSkip={onSkip}
    />
  );
};

export default MatchingSub;
