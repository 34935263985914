import React from 'react';
import classNames from 'classnames';
import ProfileWorkAvailabilitySection from '../ProfileWorkAvailabilitySection';
import { ProfileCardType } from 'models/ProfileCard.type';
import ProfileLanguagesSection from '../ProfileLanguagesSection';
import { ReactComponent as WorkIcon } from 'icons/Work.icon.svg';
import { useTranslation } from 'react-i18next';

type ProfileNonAcademicRequirementsProps = {
  className?: string;
} & Pick<
  ProfileCardType,
  | 'startDate'
  | 'hasRightToWork'
  | 'rightToWork'
  | 'rightToWorkDescription'
  | 'languages'
  | 'englishLevel'
  | 'doesHaveYearsOfExperience'
  | 'yearsOfExperienceRequired'
  | 'educationLevel'
  | 'doesMeetMinimumEducationLevel'
>;

const ProfileNonAcademicRequirements: React.FC<
  ProfileNonAcademicRequirementsProps
> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const {
    startDate,
    hasRightToWork,
    rightToWork,
    rightToWorkDescription,
    languages,
    englishLevel,
    doesHaveYearsOfExperience,
    yearsOfExperienceRequired,
    educationLevel,
    doesMeetMinimumEducationLevel,
  } = props;

  const baseClass = 'profile-non-academic-requirements';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <ProfileWorkAvailabilitySection
        startDate={startDate}
        hasRightToWork={hasRightToWork}
        rightToWork={rightToWork}
        rightToWorkDescription={rightToWorkDescription}
      />
      <hr />
      <ProfileLanguagesSection
        baseClass={baseClass}
        languages={languages}
        englishLevel={englishLevel}
      />
      <hr />
      {educationLevel && (
        <div className="profile-section">
          <div className="profile-section__title">
            <WorkIcon />
            <p>
              {t('Profile.Requirements.educationLevel', { educationLevel })}
            </p>
          </div>
          <p className="subtitle">
            {t(
              `Profile.Requirements.${
                doesMeetMinimumEducationLevel
                  ? 'haveEducationLevelRequired'
                  : 'dontHaveEducationLevelRequired'
              }`,
            )}
          </p>
        </div>
      )}
      <hr />
      {yearsOfExperienceRequired && (
        <div className="profile-section">
          <div className="profile-section__title">
            <WorkIcon />
            <p>
              {t('Profile.Requirements.experience', {
                count: yearsOfExperienceRequired,
              })}
            </p>
          </div>
          <p className="subtitle">
            {t(
              `Profile.Requirements.${
                doesHaveYearsOfExperience
                  ? 'haveYearsRequired'
                  : 'dontHaveYearsRequired'
              }`,
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default ProfileNonAcademicRequirements;
