import GoBackButton from 'components/GoBackButton';
import { Button } from 'ncoded-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MatchingPricingCard from '../MatchingPricingCard';

import './MatchingPricingPlan.styles.scss';

type MatchingPricingPlanProps = {
  onSkip?: () => void;
  onContinue?: () => void;
  goBackTo: string;
};

const MatchingPricingPlan: React.FC<MatchingPricingPlanProps> = (props) => {
  const { onSkip, onContinue, goBackTo } = props;
  const { t } = useTranslation();

  return (
    <div className="matching-pricing-plan">
      <GoBackButton to={goBackTo} />
      <h1>{t('MatchingPricingPlan.title')}</h1>
      <p>{t('MatchingPricingPlan.description')}</p>
      <MatchingPricingCard onContinue={onContinue} />
      {!!onSkip && (
        <Button variant="outline" onClick={onSkip}>
          {t('skipForNow')}
        </Button>
      )}
    </div>
  );
};
export default MatchingPricingPlan;
