import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { School } from 'models/School';
import { Checkbox } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import {
  StripeProductType,
  WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
} from 'searchality-data';
import { numberPriceToCurrencyString } from 'utils';
import ValueContext from 'providers/ValueContext/Value.context';
import OrderItem from '../OrderItem';
import CheckoutContext from 'providers/Checkout/Checkout.context';
import { CheckoutCategory } from '../../types';

import './OrderSchoolItem.styles.scss';

type OrderSchoolItemProps = {
  className?: string;
  withoutCheckbox?: boolean;
  includedInGroupPrice?: boolean;
  onChange: (_id: string) => void;
} & School;

const OrderSchoolItem: React.FC<OrderSchoolItemProps> = (props) => {
  const {
    className,
    _id,
    name,
    onChange,
    withoutCheckbox = false,
    includedInGroupPrice = false,
  } = props;

  const { t } = useTranslation();

  const { selectedIds } = useContext(ValueContext);
  const { stripePreviewObject, checkoutCategory } = useContext(CheckoutContext);

  const isSubscriptionCheckout = checkoutCategory === CheckoutCategory.BASIC;

  const classes = classNames('order-school-item', className);

  const schoolLineObject = useMemo(
    () =>
      stripePreviewObject?.lines?.data?.find((element) =>
        isSubscriptionCheckout
          ? element?.price?.product?.name ===
            StripeProductType.ANNUAL_ADDITIONAL_SCHOOL_SUBSCRIPTION
          : element?.price?.product?.name ===
            StripeProductType.MONTHLY_SCHOOL_SUBSCRIPTION,
      ),
    [isSubscriptionCheckout, stripePreviewObject?.lines?.data],
  );

  const schoolBasePrice = isSubscriptionCheckout
    ? schoolLineObject?.amount / schoolLineObject?.quantity
    : schoolLineObject?.amount;

  const schoolPrice = useMemo(() => {
    const amount = schoolLineObject?.discount_amounts?.[0]?.amount;
    if (amount > 0) {
      return isSubscriptionCheckout
        ? schoolBasePrice - amount / schoolLineObject?.quantity
        : schoolBasePrice - amount;
    }
    return schoolBasePrice;
  }, [
    isSubscriptionCheckout,
    schoolLineObject?.discount_amounts,
    schoolLineObject?.quantity,
    schoolBasePrice,
  ]);

  const schoolPriceComponent = useMemo(
    () =>
      !includedInGroupPrice ? (
        <>
          {schoolLineObject?.discount_amounts?.[0]?.amount > 0 && (
            <span>{numberPriceToCurrencyString(schoolBasePrice)}</span>
          )}{' '}
          {numberPriceToCurrencyString(schoolPrice)}
        </>
      ) : (
        t('OrderSummary.includedInGroupPrice')
      ),
    [
      includedInGroupPrice,
      schoolLineObject?.discount_amounts,
      schoolPrice,
      t,
      schoolBasePrice,
    ],
  );

  const widgetLineObject = useMemo(
    () =>
      stripePreviewObject?.lines?.data?.find(
        (element) =>
          element?.price?.product?.name ===
          StripeProductType.ADDITIONAL_SCHOOL_WIDGET_INTEGRATION,
      ),
    [stripePreviewObject?.lines?.data],
  );

  const widgetIntegrationBasePrice = widgetLineObject?.amount
    ? widgetLineObject?.amount / widgetLineObject?.quantity
    : WEBSITE_ADDITIONAL_INTEGRATION_PRICE;

  const widgetIntegrationPrice = useMemo(() => {
    const amount = widgetLineObject?.discount_amounts?.[0]?.amount;

    if (amount > 0) {
      return widgetIntegrationBasePrice - amount / widgetLineObject?.quantity;
    }
    return widgetIntegrationBasePrice;
  }, [
    widgetLineObject?.discount_amounts,
    widgetLineObject?.quantity,
    widgetIntegrationBasePrice,
  ]);

  const widgetIntegrationLabel = useMemo(() => {
    return includedInGroupPrice ? (
      t('OrderSummary.includedInGroupPrice')
    ) : (
      <>
        {widgetLineObject?.discount_amounts?.[0]?.amount > 0 && (
          <span>{numberPriceToCurrencyString(widgetIntegrationBasePrice)}</span>
        )}{' '}
        {numberPriceToCurrencyString(widgetIntegrationPrice)}
      </>
    );
  }, [
    includedInGroupPrice,
    t,
    widgetLineObject?.discount_amounts,
    widgetIntegrationBasePrice,
    widgetIntegrationPrice,
  ]);

  return (
    <>
      <OrderItem
        className={classes}
        name={t('OrderSummary.OrderItem.SchoolItem.schoolFeeName', { name })}
        price={schoolPriceComponent}
      />
      <OrderItem
        className={classes}
        name={t('OrderSummary.OrderItem.SchoolItem.widgetIntegrationName', {
          name,
        })}
        price={widgetIntegrationLabel}
      >
        {!withoutCheckbox && (
          <Checkbox
            className="order-school-item__widget-price"
            onChange={() =>
              !includedInGroupPrice &&
              onChange(isSubscriptionCheckout ? _id : name)
            }
            checked={
              includedInGroupPrice ||
              selectedIds?.includes(isSubscriptionCheckout ? _id : name)
            }
            disabled={includedInGroupPrice}
          />
        )}
      </OrderItem>
    </>
  );
};

export default OrderSchoolItem;
