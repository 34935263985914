import React, { useMemo } from 'react';
import classNames from 'classnames';
import ApplicationInformationCard from '../../components/ApplicationInformationCard';
import GoBackButton from 'components/GoBackButton';
import {
  ApplicantStage,
  BoardingPositionRequirementStatus,
  EducationLevel,
  SchoolResidentialType,
} from 'searchality-data';
import { useTranslation } from 'react-i18next';
import { useGetMatchingOpportunityQuery } from 'api/matching.api';
import { useNavigate, useParams } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import DateService from 'services/Date.service';
import { DateTime } from 'luxon';
import utils, { numberPriceToCurrencyString } from 'utils';
import OpportunityFeedback from '../../components/OpportunityFeedback';
import { Button } from 'ncoded-component-library';
import OpportunityMetadata from '../../components/OpportunityMetadata';
import pdfImage from 'assets/images/pdf-image.webp';
import JobPreviewBoardingSection from 'components/JobPreviewComponent/components/JobPreviewBoardingSection';

import './Opportunity.styles.scss';
import './Opportunity.styles.responsive.scss';

type OpportunityProps = {
  className?: string;
};

const Opportunity: React.FC<OpportunityProps> = (props) => {
  const { className } = props;

  const classes = classNames('opportunity', className);

  const { t } = useTranslation();
  const { opportunityId } = useParams();
  const navigate = useNavigate();

  const { data: opportunity, isLoading } =
    useGetMatchingOpportunityQuery(opportunityId);

  const { vacancy } = opportunity || {};

  const startDateJobApplication = useMemo(() => {
    if (!vacancy) return;

    if (
      DateService.toDateString(new Date(vacancy?.startDate)) ===
      DateService.toDateString(new Date(0))
    ) {
      return t('applicationPreview.startDateFullTime', {
        startDate: t('immediately'),
      });
    }

    const { monthLong, day, year } = DateTime.fromJSDate(
      new Date(vacancy?.startDate),
    );

    const startDate = `${monthLong} ${day}, ${year}`;

    return t('applicationPreview.startDateFullTime', { startDate });
  }, [t, vacancy]);

  const salaryInfo = useMemo(() => {
    const salaryInfo = vacancy?.salaryInfo;
    const { from, to, applicantStage } = salaryInfo || {};

    if (applicantStage !== ApplicantStage.IN_THE_JOB_POSTING) {
      return `${t(applicantStage)}`;
    }

    return `${numberPriceToCurrencyString(
      from,
    )} - ${numberPriceToCurrencyString(to)}`;
  }, [t, vacancy?.salaryInfo]);

  const hasBenefits = useMemo(() => {
    if (!vacancy) return false;

    const {
      isPrivateHealthInsuranceOffered,
      isPensionContributionOffered,
      isProfessionalDevelopmentOffered,
      isFinancialSupportForRelocationOffered,
      isAdditionalBenefitOffered,
      isTuitionForDependentsOffered,
    } = vacancy || {};

    return [
      isPrivateHealthInsuranceOffered,
      isPensionContributionOffered,
      isProfessionalDevelopmentOffered,
      isFinancialSupportForRelocationOffered,
      isAdditionalBenefitOffered,
      isTuitionForDependentsOffered,
    ].some((el) => el);
  }, [vacancy]);

  const benefits = useMemo(() => {
    if (!hasBenefits) return null;

    const {
      isPrivateHealthInsuranceOffered,
      isPensionContributionOffered,
      isProfessionalDevelopmentOffered,
      isFinancialSupportForRelocationOffered,
      isAdditionalBenefitOffered,
      isTuitionForDependentsOffered,
      privateHealthInsuranceOffered,
      pensionContributionOffered,
      financialSupportForRelocationOffered,
      professionalDevelopmentOffered,
      additionalBenefitOffered,
      dependentsDevelopmentOffered,
    } = vacancy;

    return (
      <li>
        {t('jobPreview.benefits')}
        <ul>
          {isPrivateHealthInsuranceOffered && (
            <li>
              {t('jobPreview.healthcareInsurance', {
                option: privateHealthInsuranceOffered ?? t('yes'),
              })}
            </li>
          )}
          {isPensionContributionOffered && (
            <li>
              {t('jobPreview.pensionContribution', {
                option: pensionContributionOffered ?? t('yes'),
              })}
            </li>
          )}
          {isFinancialSupportForRelocationOffered && (
            <li>
              {t('jobPreview.relocationStipend', {
                option: financialSupportForRelocationOffered ?? t('yes'),
              })}
            </li>
          )}
          {isProfessionalDevelopmentOffered && (
            <li>
              {t('jobPreview.professionalDevelopment', {
                developmentOptions: professionalDevelopmentOffered ?? t('yes'),
              })}
            </li>
          )}
          {isAdditionalBenefitOffered && (
            <li>
              {t('jobPreview.aditionalBenefits', {
                additionalBenefits: additionalBenefitOffered,
              })}
            </li>
          )}

          {isTuitionForDependentsOffered && (
            <li>
              {t('jobPreview.dependents', {
                options: dependentsDevelopmentOffered,
              })}
            </li>
          )}
        </ul>
      </li>
    );
  }, [vacancy, hasBenefits, t]);

  const languages = useMemo(() => {
    if (vacancy?.requiredLanguages?.length < 2) {
      return vacancy?.requiredLanguages?.map(({ language, level }) => (
        <li key={language}>
          <b>{t('jobPreview.language')}</b>
          {`${language} / ${level}`}
        </li>
      ));
    }

    return (
      <li>
        <b>{t('jobPreview.languages')}</b>
        <ul>
          {vacancy?.requiredLanguages?.map(({ language, level }) => (
            <li key={language}>{`${language} / ${level}`}</li>
          ))}
        </ul>
      </li>
    );
  }, [t, vacancy?.requiredLanguages]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className={classes}>
      <GoBackButton to="../" />
      {opportunity && (
        <>
          <OpportunityMetadata />
          <Button onClick={() => navigate(`/job-application/${vacancy?._id}`)}>
            {t('applyNow')}
          </Button>
          {!opportunity?.isTeacherInterested && <OpportunityFeedback />}
          <ApplicationInformationCard>
            <h4>{t('aboutOurPosition')}</h4>
            <div
              className="role-description"
              dangerouslySetInnerHTML={utils.createMarkup(
                vacancy?.roleDescription,
              )}
            />
            <h4 className="title-margin-top">{t('vacancyInformation')}</h4>
            <ul>
              <li>{startDateJobApplication}</li>
              <li>
                {t('jobPreview.employmentType')}
                {vacancy?.isFullTimeEmployment
                  ? t('fullTime')
                  : t('jobPreview.partTime', {
                      details: `, ${vacancy?.amountOfTimeRequired}`,
                    })}
              </li>
              <li>
                {t('applicationPreview.contractType', {
                  type: vacancy?.isPermanentContract
                    ? t('permanent')
                    : t('jobPreview.temporary', {
                        details: `, ${vacancy?.lengthOfContract}`,
                      }),
                })}
              </li>
            </ul>
            {vacancy?.positionDescriptionUrl && (
              <div className={`opportunity__detailed-container`}>
                <p>{t('jobPreview.viewFullDescription')}</p>
                <a
                  href={vacancy.positionDescriptionUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={pdfImage} alt="pdf" />
                  {t('detailedPositionDescription')}
                </a>
              </div>
            )}
            <h4 className="title-margin-top">{t('requirements')}</h4>
            <ul>
              <li>
                {t('applicationPreview.minimumDiplomaRequired', {
                  diploma: vacancy?.educationLevel,
                })}
              </li>
              <li>
                {vacancy?.yearsOfExperienceRequired > 0
                  ? t('applicationPreview.minimumExperienceRequired', {
                      count: vacancy?.yearsOfExperienceRequired,
                    })
                  : t('noExperienceRequired')}
              </li>
              <li>
                {t('applicationPreview.teachingSystem', {
                  system:
                    vacancy?.curriculumExperience !== 'None'
                      ? vacancy?.curriculumExperience
                      : t('noExperienceNeeded'),
                })}
              </li>
              {vacancy?.curriculumExperience !== 'None' && (
                <li>
                  {t('applicationPreview.minimumCurriculumExperience', {
                    curriculumExperience: vacancy?.curriculumExperience,
                    years: vacancy?.yearsOfCurriculumExperienceRequired,
                  })}
                </li>
              )}
              {languages}
              <li>
                {t('applicationPreview.workingRights', {
                  isRequired: vacancy?.isRightToWorkRequired
                    ? t('required')
                    : t('notRequired'),
                })}
              </li>
              {!!vacancy?.certificates?.length &&
                vacancy?.educationLevel === EducationLevel.TEACHING_DIPLOMA && (
                  <li>
                    {t('educationCertificates')}
                    <ul>
                      {vacancy.certificates?.map((certificate, index) => (
                        <li key={index}>{certificate}</li>
                      ))}
                    </ul>
                  </li>
                )}
            </ul>
          </ApplicationInformationCard>
          <ApplicationInformationCard>
            <h4>{t('whatWeOffer')}</h4>
            <ul>
              <li>
                {t('salary')}
                {salaryInfo}
              </li>
              {(vacancy?.studentContactDays ||
                vacancy?.studentNonContactDays) && (
                <li>
                  {t('applicationPreview.ContractTerms')}
                  <ul>
                    {vacancy?.studentContactDays && (
                      <li>
                        {t('applicationPreview.contractDays', {
                          days: vacancy?.studentContactDays,
                        })}
                      </li>
                    )}
                    {vacancy?.studentNonContactDays && (
                      <li>
                        {t('applicationPreview.nonContractDays', {
                          days: vacancy?.studentNonContactDays,
                        })}
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {benefits}
            </ul>
          </ApplicationInformationCard>
          {vacancy?.residentialType !== SchoolResidentialType.DAY_SCHOOL &&
            vacancy?.boardingPositionRequirementStatus !==
              BoardingPositionRequirementStatus.NOT_REQUIRED && (
              <ApplicationInformationCard>
                <h4>{t('boardingSupervisionAndBenefits')}</h4>
                <JobPreviewBoardingSection
                  additionalBenefits={
                    vacancy?.boardingPositionBenefits?.additionalBenefits
                  }
                  salary={vacancy?.boardingPositionBenefits?.salary}
                  isRequired={
                    vacancy?.boardingPositionRequirementStatus ===
                    BoardingPositionRequirementStatus.REQUIRED
                  }
                  roleDescription={
                    vacancy?.boardingPositionRequirements?.roleDescription
                  }
                  roleDescriptionUrl={vacancy?.boardingPositionRequirementsUrl}
                  noSubTitles
                />
              </ApplicationInformationCard>
            )}
        </>
      )}
    </div>
  );
};

export default Opportunity;
