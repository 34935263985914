import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DoneIcon } from 'assets/images/Done.icon.svg';
import { numberPriceToCurrencyString } from 'utils';
import { SchoolGroupStructureType } from 'searchality-data';
import useSearchalityPricing from 'hooks/useSearchalityPricing';

const STANDALONE_ITEMS = ['Pricing.Standalone.li1', 'Pricing.Standalone.li2'];

const GROUP_ITEMS = [
  'Pricing.Group.li1',
  'Pricing.Group.li2',
  'Pricing.Group.li3',
];

export const useLeftCardContent = (
  structureType: SchoolGroupStructureType,
  hasOldPricingSystem: boolean,
) => {
  const { t } = useTranslation();

  const {
    STANDALONE_PRICE,
    GROUP_INTITIAL_PRICE,
    GROUP_ADDITIONAL_SCHOOL_PRICE,
  } = useSearchalityPricing(hasOldPricingSystem);

  const standaloneItems = useMemo(
    () => (
      <ul>
        {STANDALONE_ITEMS.map((el, index) => (
          <li key={index}>
            <p className="card__description">
              <DoneIcon />
              {t(el)}
            </p>
          </li>
        ))}
      </ul>
    ),
    [t],
  );

  const groupItems = useMemo(
    () => (
      <ul>
        {GROUP_ITEMS.map((el, index) => (
          <li key={index}>
            <p className="card__description">
              <DoneIcon />
              {t(el)}
            </p>
          </li>
        ))}
      </ul>
    ),
    [t],
  );

  if (structureType === SchoolGroupStructureType.STANDALONE) {
    return {
      title: t('Pricing.Standalone.title'),
      subtitle: t('Pricing.Standalone.price', {
        price: numberPriceToCurrencyString(STANDALONE_PRICE),
      }),
      description: t('Pricing.Standalone.billed'),
      content: standaloneItems,
    };
  }

  return {
    title: t('Pricing.Group.title'),
    subtitle: t('Pricing.Group.price', {
      price: numberPriceToCurrencyString(GROUP_INTITIAL_PRICE),
    }),
    description: t('Pricing.Group.billed'),
    content: (
      <>
        {groupItems}
        <p className="card__description">
          <span>➕</span>
          {t('Pricing.Group.fee', {
            price: numberPriceToCurrencyString(GROUP_ADDITIONAL_SCHOOL_PRICE),
          })}
        </p>
        <p className={'pricing-plan__note'}>
          {t('Pricing.Group.note', {
            price: numberPriceToCurrencyString(GROUP_ADDITIONAL_SCHOOL_PRICE),
          })}
        </p>
      </>
    ),
  };
};
