import { ComplexRoute } from 'router/components/Routes/Routes';
import { Role } from 'searchality-data';
import VacancyBeforePublishPreview from '../../../VacancyProfile/pages/VacancyBeforePublishPreview';
import CreateVacancyWizard from '../../../../pages/VacancyWizard/CreateVacancyWizard.page';

export default [
  {
    path: ':stepName',
    element: CreateVacancyWizard,
    validate: ({ createVacancyWizard }, { stepName }) => {
      const { currentStepIndex, steps } = createVacancyWizard;
      const index = steps.findIndex((s) => s === stepName);
      return index > currentStepIndex ? `../${steps[0]}` : '';
    },
    authorizedRoles: [
      Role.AccountManager,
      Role.AccountOwner,
      Role.HiringManager,
    ],
  },
  {
    path: 'preview',
    element: VacancyBeforePublishPreview,
    validate: (state) => {
      if (!state.vacancyBuilder) {
        return '..';
      }
      return '';
    },
    authorizedRoles: [
      Role.AccountManager,
      Role.AccountOwner,
      Role.HiringManager,
    ],
  },
  {
    index: true,
    element: CreateVacancyWizard,
    validate: ({ createVacancyWizard }) => {
      const { steps, currentStepIndex } = createVacancyWizard;
      return `${steps[currentStepIndex]}`;
    },
    authorizedRoles: [
      Role.AccountManager,
      Role.AccountOwner,
      Role.HiringManager,
    ],
  },
] as Array<ComplexRoute>;
