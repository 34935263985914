import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import LoginInformation, {
  LoginInformationRoutes,
} from '../MyAccount/subrouters/PersonalInformation/subrouters/LoginInformation';
import AssociationInformation from './pages/AssociationInformation';
import AssociationPersonalDetails from './pages/AssociationPersonalDetails';
import AssociationPersonalInformation from './pages/AssociationPersonalInformation';
import EditAssociationInformation from './pages/EditAssociationInformation';

export default [
  {
    path: 'personal-information',
    element: AssociationPersonalInformation,
  },
  {
    path: 'personal-information/personal-details',
    element: AssociationPersonalDetails,
  },
  {
    path: 'personal-information/login-information',
    routes: LoginInformationRoutes,
    element: LoginInformation,
  },
  {
    path: 'association-settings',
    element: AssociationInformation,
  },
  {
    path: 'edit-association-information',
    element: EditAssociationInformation,
  },
  {
    index: true,
    element: <Navigate to="personal-information" replace />,
  },
] as Array<ComplexRoute>;
