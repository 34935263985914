import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { ExtractPropTypes } from 'types';
import { RangeSlider } from 'ncoded-component-library';
import { FieldRenderProps } from 'react-final-form';
import { RangeValueType } from 'ncoded-component-library/build/components/molecules/RangeSlider/RangeSlider.component';
import useDebouncedState from 'hooks/useDebouncedState';

export const getUnitValue = (value: string | number) => {
  if (typeof value === 'string') return parseInt(value).toFixed();
  if (typeof value === 'number') return value.toFixed().toString();
};

export type RangeHelperProps = {
  minValue: number;
  maxValue: number;
  showMax?: boolean;
};

export type NumberRange = {
  from: number | string;
  to: number | string;
};

type RangeSliderProps = ExtractPropTypes<typeof RangeSlider>;

type RangeSliderFieldProps = RangeSliderProps &
  FieldRenderProps<NumberRange, HTMLElement> & {
    className?: string;
    label?: string;
    tooltip?: React.FC;
  };

const RangeSliderField: React.FC<RangeSliderFieldProps> = (props) => {
  const {
    input,
    meta: { touched, error },
    className,
    min,
    max,
    label,
    tooltip,
    variant = 'double',
    ...rest
  } = props;

  const { value: pValue, onChange, ...restInput } = input;
  const [rangeValue, setRangeValue] = useDebouncedState({
    init: undefined,
    debounceTime: 500,
  });

  const hasError = useMemo(() => touched && error, [error, touched]);
  const changeRef = useRef<boolean>(false);

  const classes = classNames(
    'range-slider-field',
    { 'range-slider-field--error': hasError },
    className,
  );

  const value = useMemo(
    () =>
      ({
        min: pValue.from ? getUnitValue(pValue.from) : min,
        max: pValue.to ? getUnitValue(pValue.to) : max,
      } as RangeValueType),
    [max, min, pValue.from, pValue.to],
  );

  const getRound = (dir: 'left' | 'right', value: number, round = 500) => {
    const lower = Math.floor(value / round) * round;
    const upper = Math.ceil(value / round) * round;
    if (dir === 'left') {
      return lower;
    }
    if (dir === 'right') {
      return upper;
    }
  };

  const getRoundedValue = useCallback(
    (rangeValue: RangeValueType, fieldValue: NumberRange) => {
      const { min, max } = rangeValue;
      const { from: fromString, to: toString } = fieldValue;

      const from = +fromString;
      const to = +toString;

      if (min !== from) {
        if (min < from) {
          return { from: getRound('left', min), to };
        }
        return { from: getRound('right', min), to };
      }

      if (max !== to) {
        if (max < to) {
          return { from, to: getRound('left', max) };
        }
        return { from, to: getRound('right', max) };
      }

      return { from, to };
    },
    [],
  );

  const handleOnChange = useCallback(
    (values: RangeValueType) => {
      const rangeValue = getRoundedValue(values, pValue);
      changeRef.current = true;
      setRangeValue(rangeValue);
    },
    [getRoundedValue, pValue, setRangeValue],
  );

  useEffect(() => {
    if (changeRef.current) {
      if (variant === 'double') {
        onChange(rangeValue);
      } else if (variant === 'single') onChange(getUnitValue(rangeValue?.from));
      changeRef.current = false;
    }
  }, [onChange, rangeValue, variant]);

  return (
    <div className={classes}>
      {label && <label className="range-slider-field__label">{label}</label>}
      <RangeSlider
        {...rest}
        {...restInput}
        min={min}
        max={max}
        variant={variant}
        value={value}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default RangeSliderField;
