import { createApi } from '@reduxjs/toolkit/query/react';
import {
  SchoolUserEmailSettings,
  TeacherUserEmailSettings,
  User,
} from 'models/User';
import axiosBaseQuery from './axiosBaseQuery';
import { School } from 'models/School';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    updateSchoolUserEmailSettings: builder.mutation<
      User,
      SchoolUserEmailSettings
    >({
      query: (data) => {
        return {
          url: `school-users/email-settings`,
          method: 'PATCH',
          data,
          params: {
            $populate: [
              'schoolGroup',
              'schoolGroup.schools',
              'schools',
              'schools.associations',
              'schoolGroup.schools.associations',
            ],
          },
        };
      },
    }),
    updateTeacherUserEmailSettings: builder.mutation<
      User,
      TeacherUserEmailSettings
    >({
      query: (data) => {
        return {
          url: `teacher-users/email-settings`,
          method: 'PATCH',
          data,
          params: {
            $populate: [
              'schoolGroup',
              'schoolGroup.schools',
              'schools',
              'schools.associations',
              'schoolGroup.schools.associations',
            ],
          },
        };
      },
    }),
    requestAdditionalVacancyInformation: builder.mutation<
      School,
      {
        schoolId: string;
        data: {
          followUpQuestions: Array<string>;
          videoDescription: string;
        };
      }
    >({
      query: ({ schoolId, data }) => {
        return {
          url: `schools/${schoolId}/requested-additional-information`,
          method: 'PATCH',
          data,
          params: {
            $populate: ['associations'],
          },
        };
      },
    }),
  }),
});

export const {
  useUpdateSchoolUserEmailSettingsMutation,
  useUpdateTeacherUserEmailSettingsMutation,
  useRequestAdditionalVacancyInformationMutation,
} = usersApi;
