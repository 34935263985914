import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EducationIcon } from 'icons/Education.icon.svg';
import { ReactComponent as Referrals } from 'icons/Referrals.icon.svg';
import { ReactComponent as WorkIcon } from 'icons/Work.icon.svg';
import EmptyScreen from 'components/EmptyScreen';
import workExperienceImage from 'assets/images/work-experience.webp';
import { ProfileCardType } from 'models/ProfileCard.type';
import { DateTime } from 'luxon';
import DateService from 'services/Date.service';

import './ProfileEducation.styles.scss';

type ProfileEducationProps = {
  className?: string;
} & Pick<
  ProfileCardType,
  | 'education'
  | 'experience'
  | 'curricula'
  | 'yearsOfExperienceWithFaithBasedSchools'
  | 'doesHaveExperinceWithFaithBasedSchools'
  | 'certificates'
>;

const ProfileEducation: React.FC<ProfileEducationProps> = (props) => {
  const {
    className,
    education,
    experience,
    curricula,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveExperinceWithFaithBasedSchools,
    certificates,
  } = props;

  const { t } = useTranslation();

  const formatDate = useCallback((date: string) => {
    const { monthLong, year } = DateTime.fromJSDate(new Date(date));
    return `${monthLong} ${year}`;
  }, []);

  const baseClass = 'searchality-profile-education';

  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <div className="profile-section">
        <div className="profile-section__title">
          <WorkIcon />
          <p>{t('Profile.Education.experience')}</p>
        </div>
        {experience?.length ? (
          experience.map((el: any) => (
            <div
              key={el.positionTitle + el.positionDivision}
              className={`${baseClass}__section`}
            >
              <p className="title">{el.schoolName}</p>
              <p className="subtitle">{`${el.positionDivision} • ${el.positionTitle}`}</p>
              <p className="description">
                {el.endDate
                  ? `${formatDate(el.startDate)} - ${formatDate(el.endDate)}`
                  : `${formatDate(el.startDate)}`}
              </p>
            </div>
          ))
        ) : (
          <div className={`${baseClass}__empty-state`}>
            <EmptyScreen
              title={t('Profile.Education.experienceEmptyState')}
              description=""
              img={<img src={workExperienceImage} alt="Empty screen" />}
              headingLevel={4}
            />
          </div>
        )}
      </div>
      <hr />
      <div className="profile-section">
        <div className="profile-section__title">
          <EducationIcon />
          <p>{t('Profile.Education.education')}</p>
        </div>
        {education?.length ? (
          education.map((el: any) => (
            <div
              key={el.startDate + el.endDate}
              className={`${baseClass}__section`}
            >
              <p className="title">{el.schoolName}</p>
              <p className="subtitle">
                {`${el.degreeLevel} • ${el.fieldOfStudy}`}
              </p>
              <p className="description">
                {el.endDate
                  ? `${formatDate(el.startDate)} - ${formatDate(el.endDate)}`
                  : `${formatDate(el.startDate)}`}
              </p>
            </div>
          ))
        ) : (
          <div className={`${baseClass}__empty-state`}>
            <EmptyScreen
              title={t('Profile.Education.educationEmptyState')}
              description=""
              headingLevel={4}
              img={<img src={workExperienceImage} alt="Empty screen" />}
            />
          </div>
        )}
      </div>
      {!!yearsOfExperienceWithFaithBasedSchools &&
        doesHaveExperinceWithFaithBasedSchools && (
          <>
            <hr />
            <div className={`${baseClass}__availability profile-section`}>
              <div className="profile-section__title">
                <Referrals className="grey-icon" />
                <p>{t('Profile.Education.faith')}</p>
              </div>
              <div className={`${baseClass}__section`}>
                <p className="title">
                  {yearsOfExperienceWithFaithBasedSchools}
                </p>
              </div>
            </div>
          </>
        )}
      {!!curricula?.length && (
        <>
          <hr />
          <div className={`${baseClass}__availability profile-section`}>
            <div className="profile-section__title">
              <Referrals className="grey-icon" />
              <p>{t('Profile.Education.curricula')}</p>
            </div>
            <div className={`${baseClass}__section`}>
              {curricula.map(({ curriculumExperience, yearsOfExperience }) => (
                <p key={curriculumExperience} className="title">
                  {curriculumExperience} - {yearsOfExperience}
                </p>
              ))}
            </div>
          </div>
        </>
      )}
      {!!certificates?.length && (
        <>
          <hr />
          <div className={`${baseClass}__availability profile-section`}>
            <div className="profile-section__title">
              <Referrals className="grey-icon" />
              <p>{t('Profile.Education.certificates')}</p>
            </div>
            {certificates.map(
              (
                {
                  certificationDate,
                  certificateName,
                  grantingInstitution,
                  isCurrentlyCompleting,
                },
                index,
              ) => (
                <div key={index} className={`${baseClass}__section`}>
                  <p className="title">{certificateName}</p>
                  <p className="subtitle">{grantingInstitution}</p>
                  {certificationDate && (
                    <p className="description">
                      {DateService.formatDate(certificationDate)}
                    </p>
                  )}
                  {isCurrentlyCompleting && (
                    <p className="description">{t('currentlyCompleting')}</p>
                  )}
                </div>
              ),
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileEducation;
