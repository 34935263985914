import React, { useCallback } from 'react';
import SignupForm from './components/SignupForm';
import MainLayout from 'components/MainLayout';
import { useTranslation } from 'react-i18next';
import useAppDispatch from 'hooks/useAppDispatch';
import { RegisterData } from 'types';
import { confirmSchoolInvitation, registerUser } from 'store/slices/auth.slice';
import useQParamsActions from 'hooks/useQueryParams';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import SelectSearchalityVersion from 'components/SelectSearchalityVersion';
import env from 'env';

import './Signup.styles.scss';
import './Signup.styles.responsive.scss';

const Signup: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { params: queryParams } = useQParamsActions();

  const { token, email } = queryParams || {};

  const handleOnSubmit = useCallback(
    async (values: RegisterData) => {
      if (token && email) {
        await dispatch(confirmSchoolInvitation({ ...values, token }));
      } else {
        await dispatch(registerUser(values));
      }
    },

    [dispatch, email, token],
  );

  return (
    <MainLayout className="auth" headerShadow>
      <div className="auth-signup">
        <div className="auth-signup__titles">
          <h1 className="school">{t('InformationSection.title')}</h1>
          <p>{t('InformationSection.description')}</p>
        </div>
        <div className="auth-signup__description">
          <p className="subtitle">{t('InformationSection.listTitle')}</p>
          <ul>
            <li>{t('InformationSection.list1')}</li>
            <li>{t('InformationSection.list2')}</li>
          </ul>
          <p className="subtitle">{t('InformationSection.areYouATeacher')}</p>
          <p>{t('InformationSection.areYouATeacherDescription')}</p>
          <Button
            variant="outline"
            onClick={() => navigate('../teacher-signup')}
          >
            {t('InformationSection.signInAsTeacher')}
          </Button>
        </div>
        <div className="auth-signup__form">
          <SelectSearchalityVersion
            description={t('selectVersionSchool')}
            link={`${env.SEARCHALITY_INT_VERSION}/auth/school-signup`}
          />
          <SignupForm onSubmit={handleOnSubmit} />
        </div>
      </div>
    </MainLayout>
  );
};

export default Signup;
