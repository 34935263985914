import React from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import { useTranslation } from 'react-i18next';
import { numberPriceToCurrencyString } from 'utils';
import { HOW_IT_WORKS_LINK, WEBSITE_INTEGRATION_MORE } from 'constants/links';
import { ReactComponent as ArrowIcon } from 'icons/Arrow.icon.svg';

import './AddSchoolsPricing.styles.scss';
import useSearchalityPricing from 'hooks/useSearchalityPricing';

type AddSchoolsPricingProps = {
  className?: string;
  hasOldPricingSystem: boolean;
};

const AddSchoolsPricing: React.FC<AddSchoolsPricingProps> = (props) => {
  const { className, hasOldPricingSystem } = props;

  const {
    GROUP_ADDITIONAL_SCHOOL_PRICE,
    WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
  } = useSearchalityPricing(hasOldPricingSystem);

  const classes = classNames('add-schools-pricing__cards', className);

  const { t } = useTranslation();

  return (
    <div className={classes}>
      <Card
        className="teal add-schools-pricing__cards__left"
        title={t('Pricing.Group.title')}
        subtitle={t('AddNewSchool.Pricing.LeftCard.subtitle', {
          price: numberPriceToCurrencyString(GROUP_ADDITIONAL_SCHOOL_PRICE),
        })}
        description={t('AddNewSchool.Pricing.LeftCard.description')}
      >
        <p className="pricing-plan__note">
          {t('AddNewSchool.Pricing.LeftCard.note')}
        </p>
        <a href={HOW_IT_WORKS_LINK} target="_blank" rel="noreferrer">
          <p className="pricing__links">
            {t('Pricing.seeAll')}
            <ArrowIcon />
          </p>
        </a>
      </Card>
      <Card
        className="orange add-schools-pricing__cards__right"
        title={t('Pricing.Website.title')}
        subtitle={t('Pricing.Website.price', {
          price: numberPriceToCurrencyString(
            WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
          ),
        })}
      >
        <p className="card__description">{t('Pricing.Website.description')}</p>
        <a href={WEBSITE_INTEGRATION_MORE} target="_blank" rel="noreferrer">
          <p className="pricing__links">
            {t('Pricing.Website.learnMore')}
            <ArrowIcon />
          </p>
        </a>
      </Card>
    </div>
  );
};

export default AddSchoolsPricing;
