import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import InputField from 'components/InputField';
import {
  composeValidators,
  positiveNumber,
  required,
  websiteValidation,
} from 'validations';
import { Button } from 'ncoded-component-library';
import SelectField from 'components/SelectField';
import { useNavigate } from 'react-router-dom';
import api from 'api';
import GoBackButton from 'components/GoBackButton';
import { states, statesOptions } from 'constants/states';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError, popServerError } from 'store/slices/popNotifications.slice';
import MapboxAutocompleteField from 'components/MapboxAutocomplete';

import './DistrictInfo.styles.scss';

type DistrictInfoProps = {
  className?: string;
};

const DistrictInfo: React.FC<DistrictInfoProps> = (props) => {
  const { className } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const classes = classNames('district-info', className);

  const navigate = useNavigate();

  const { submit, getState } = useForm();

  const {
    values: { schools, name, website, state, residentialType },
  } = useFormState();

  const checkIfSchoolGroupExists = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        data: { exists },
      } = await api.schools.checkIfSchoolGroupExists({
        name,
        website,
      });

      if (!exists) {
        if (schools?.length) {
          return navigate('schools-preview');
        }
        return navigate('schools');
      }
      dispatch(popError(t('District.Info.alreadyExistsError')));
    } catch (error) {
      dispatch(popServerError(error));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, name, navigate, schools?.length, t, website]);

  const selectedStateName = states[state as keyof typeof states];

  return (
    <form
      className={classes}
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid } = getState();
        if (!valid) return submit();
        checkIfSchoolGroupExists();
      }}
    >
      <GoBackButton
        to={`/create-school/school-type/public-school/residential-type/${residentialType}`}
      />
      <h1>{t('District.Info.title')}</h1>
      <Field
        name="name"
        component={InputField}
        label={t('District.Info.districtPlaceholder')}
        required
        validate={required()}
      />
      <Field
        name="state"
        options={statesOptions}
        component={SelectField}
        searchable
        label={t('District.Info.state')}
        required
        validate={required()}
      />
      <Field
        name="county"
        component={MapboxAutocompleteField}
        filterResultString={selectedStateName}
        label={t('District.Info.county')}
        key={state}
        mapFeaturesToOptions={(feature: any) => {
          const { place_name } = feature;
          return {
            label: place_name,
            value: place_name,
          };
        }}
        mapboxParams={{
          types: ['district', 'region'],
        }}
      />
      <Field
        name="website"
        component={InputField}
        label={t('District.Info.websitePlaceholder')}
        validate={websiteValidation()}
      />
      <Field
        onlyNumbers
        maxLength={4}
        name="schoolCount"
        component={InputField}
        hint={t('District.Info.schoolCountHint')}
        label={t('District.Info.schoolCountPlaceholder')}
        required
        validate={composeValidators(required(), positiveNumber())}
      />
      <Button type="submit" disabled={isLoading}>
        {t('continue')}
      </Button>
    </form>
  );
};

export default DistrictInfo;
