import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Anim from 'components/animations';
import { useTranslation } from 'react-i18next';
import Tag from 'components/Tag';
import { ReactComponent as CloseDialog } from 'icons/CloseDialog.icon.svg';
import { Select } from 'ncoded-component-library';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import Input from 'components/Input';
import FormFieldLabel from 'components/FormFieldLabel';

import './TwoOptionsSelect.styles.scss';

type TwoOptionsSelectValue = Record<string, string>;

type TwoOptionsSelectProps = {
  className?: string;
  paragraphText?: string;
  firstSelectLabel?: string;
  secondSelectLabel?: string;
  firstSelectOptions?: OptionValue[];
  secondSelectOptions?: OptionValue[];
  value: TwoOptionsSelectValue[];
  error?: string;
  touched?: boolean;
  firstOptionName: string;
  secondOptionName: string;
  otherOptionLabel?: string;
  otherOptionInputPlaceholder?: string;
  hideOnValue?: string;
  onChange: (value: TwoOptionsSelectValue[]) => void;
  onBlur: (event?: React.FocusEvent<HTMLElement, Element>) => void;
  disabled?: boolean;
};

const TwoOptionsSelect: React.FC<TwoOptionsSelectProps> = (props) => {
  const {
    className,
    paragraphText,
    value,
    firstOptionName,
    secondOptionName,
    firstSelectLabel,
    secondSelectLabel,
    secondSelectOptions,
    firstSelectOptions,
    error,
    touched,
    otherOptionLabel,
    otherOptionInputPlaceholder,
    hideOnValue,
    onChange,
    onBlur,
    disabled,
  } = props;

  const [firstOption, setFirstOption] = useState<string>('');
  const [otherOption, setOtherOption] = useState('');

  const isFocused = useRef(false);

  const { t } = useTranslation();

  const tags = useMemo(
    () =>
      value?.map((option) => (
        <Tag
          key={`${t(option[firstOptionName])}`}
          text={`${t(option[firstOptionName])} / ${t(
            option[secondOptionName],
          )}`}
          className="small position-requirements-fields__tag"
        >
          <button
            onClick={() => {
              onChange(
                value.filter(
                  (el) => el[firstOptionName] !== option[firstOptionName],
                ),
              );
            }}
            disabled={disabled}
            className="svg-button-wrapper"
          >
            <CloseDialog />
          </button>
        </Tag>
      )),
    [disabled, firstOptionName, onChange, secondOptionName, t, value],
  );

  const firstSelectOptionsMapped = useMemo(
    () =>
      firstSelectOptions.filter(
        (el) => !value.some((option) => el.value === option[firstOptionName]),
      ),
    [firstOptionName, firstSelectOptions, value],
  );

  const hasError = error && touched;

  const classes = classNames('two-options-select', className);

  const isOtherOptionSelected = firstOption === 'Other';

  const shouldShowSecondSelect =
    !!firstOption &&
    (firstOption !== 'Other' || otherOption) &&
    firstOption !== hideOnValue;

  useEffect(() => {
    const body = document.querySelector('#root');

    const onBlurEvent = () => {
      if (isFocused.current) {
        onBlur();
      }
    };

    body.addEventListener('click', onBlurEvent);

    return () => {
      body.removeEventListener('click', onBlurEvent);
    };
  }, [onBlur]);

  return (
    <div
      className={classes}
      onClick={(ev) => {
        ev.stopPropagation();
        ev.nativeEvent.stopImmediatePropagation();

        isFocused.current = true;
      }}
    >
      {paragraphText && <p>{paragraphText}</p>}
      <Anim.Collapse active>
        {firstSelectOptionsMapped.length > 0 && (
          <Select
            options={firstSelectOptionsMapped || []}
            innerLabel={firstSelectLabel}
            multiple={false}
            value={firstOption}
            onChange={(option) => setFirstOption(option.value)}
            hasError={hasError && !firstOption}
            disabled={disabled}
          />
        )}
      </Anim.Collapse>
      {isOtherOptionSelected && (
        <>
          {otherOptionLabel && <FormFieldLabel text={otherOptionLabel} />}
          <Input
            value={otherOption}
            placeholder={otherOptionInputPlaceholder}
            onChange={({ target: { value } }) => setOtherOption(value)}
            disabled={disabled}
            error={
              value?.some((el) => el?.[firstOptionName] === otherOption)
                ? t('cantUseSameNameTwice')
                : undefined
            }
          />
        </>
      )}
      {shouldShowSecondSelect && (
        <Anim.Collapse active>
          <Select
            options={secondSelectOptions}
            innerLabel={secondSelectLabel}
            multiple={false}
            onChange={(option) => {
              if (value?.some((el) => el?.[firstOptionName] === otherOption)) {
                return;
              }
              onChange([
                ...value,
                {
                  [firstOptionName]: isOtherOptionSelected
                    ? otherOption
                    : firstOption,
                  [secondOptionName]: option.value,
                },
              ]);
              setFirstOption(undefined);
              setOtherOption('');
            }}
            hasError={hasError}
            disabled={disabled}
          />
        </Anim.Collapse>
      )}
      {tags}
    </div>
  );
};

export default TwoOptionsSelect;
