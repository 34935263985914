import { ComplexRoute } from 'router/components/Routes/Routes';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { SchoolGroupStructureType } from 'searchality-data';
import DefaultMatchingSub from './pages/DefaultMatchingSub ';
import MatchingSelectSchools from '../../pages/MatchingSelectSchools';
import MatchingCheckout from './pages/MatchingCheckout';

export default [
  {
    path: 'matching-plan',
    element: DefaultMatchingSub,
  },
  {
    path: 'checkout',
    element: MatchingCheckout,
  },
  {
    path: 'select-schools',
    element: MatchingSelectSchools,
    validate: ({ auth: { user } }) =>
      user?.schoolGroup?.structureType === SchoolGroupStructureType.STANDALONE
        ? '/my-account/manage-subscription/matching-plan'
        : '',
  },
  {
    index: true,
    element: <Navigate to="matching-plan" />,
  },
] as Array<ComplexRoute>;
