import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useDebouncedState from 'hooks/useDebouncedState';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import api from 'api';
import { FieldRenderProps } from 'react-final-form';
import Select from 'components/Select';

import './MapboxAutocomplete.styles.scss';
import './MapboxAutocomplete.styles.responsive.scss';

type MapboxAutocompleteProps = FieldRenderProps<string, HTMLElement> & {
  className?: string;
  mapboxParams?: any;
  filterString?: string;
  placeholder?: string;
  callback?: (value: string, feature: any) => void;
  mapFeaturesToOptions: (feature: any) => OptionValue;
  searchable?: boolean;
};

const MapboxAutocompleteField: React.FC<MapboxAutocompleteProps> = (props) => {
  const {
    mapboxParams,
    filterString,
    placeholder,
    searchable = true,
    meta: { error, touched },
    input: { value, onBlur, onChange, ...restInput },
    mapFeaturesToOptions = (feature: any) => {
      const { place_name } = feature;
      return { label: place_name, value: place_name };
    },
    callback = () => {},
    ...rest
  } = props;

  const [features, setFeatures] = useState([]);

  const [debouncedValue, setDebouncedValue] = useDebouncedState({
    init: value,
    debounceTime: 500,
  });

  const getLocationSuggestions = useCallback(async () => {
    try {
      const { data } = await api.mapbox.getSuggestions(
        debouncedValue,
        mapboxParams,
      );

      setFeatures(data?.features);
    } catch (e) {
      console.error(e);
    }
  }, [debouncedValue, mapboxParams]);

  const suggestedOptions = useMemo(() => {
    const suggestions = features.map(mapFeaturesToOptions);
    if (filterString) {
      suggestions.filter((feat) =>
        feat.label.toString().includes(filterString),
      );
    }
    return suggestions;
  }, [features, mapFeaturesToOptions, filterString]);

  useEffect(() => {
    if (!debouncedValue) return;

    if (value === debouncedValue) {
      setFeatures([{ place_name: value }]);
      return;
    }

    getLocationSuggestions();
  }, [debouncedValue, getLocationSuggestions, value]);

  return (
    <Select
      searchable={searchable}
      value={value}
      options={suggestedOptions}
      placeholder={placeholder}
      error={error && touched ? error : ''}
      async
      onSearchChange={({
        target: { value },
      }: React.ChangeEvent<HTMLInputElement>) => {
        setDebouncedValue(value);
        if (!value) {
          onChange(value);
        }
      }}
      onChange={({ value }) => {
        const optionIndex = suggestedOptions.findIndex(
          (opt) => opt.value === value,
        );
        callback(value.toString(), features[optionIndex]);
        onChange(value);
      }}
      onClose={onBlur}
      {...restInput}
      {...rest}
      multiple={false}
    />
  );
};

export default MapboxAutocompleteField;
