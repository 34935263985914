import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterData } from 'types';
import useAppDispatch from 'hooks/useAppDispatch';
import { registerTeacherUser } from 'store/slices/auth.slice';
import MainLayout from 'components/MainLayout';
import SignupForm from '../Signup/components/SignupForm';
import { LANDING_VIDEO_SRC } from 'constants/general';

import './JoinUsSignup.styles.scss';
import './JoinUsSignup.styles.responsive.scss';

const JoinUsSignup: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    async (values: RegisterData) => await dispatch(registerTeacherUser(values)),
    [dispatch],
  );

  return (
    <MainLayout className="auth" headerShadow>
      <div className="join-us-signup">
        <div className="join-us-signup__titles">
          <h1>{t('JoinUsSingup.title')}</h1>
          <p className="subtitle">{t('JoinUsSingup.subtitle')}</p>
        </div>
        <div className="join-us-signup__description">
          <p className="subtitle">{t('JoinUsSingup.introductionTitle')}</p>
          <p>{t('JoinUsSingup.introductionDescription')}</p>
        </div>
        <SignupForm onSubmit={handleSubmit} />
        <div className="join-us-signup__why-join-us">
          <p className="subtitle">{t('JoinUsSingup.benefitsTitle')}</p>
          <ul>
            <li>{t('JoinUsSingup.benefitsLi1')}</li>
            <li>{t('JoinUsSingup.benefitsLi2')}</li>
            <li>{t('JoinUsSingup.benefitsLi3')}</li>
            <li>{t('JoinUsSingup.benefitsLi4')}</li>
          </ul>
        </div>
        <div className="iframe-video-wrapper">
          <video src={LANDING_VIDEO_SRC} autoPlay={false} controls />
        </div>
      </div>
    </MainLayout>
  );
};

export default JoinUsSignup;
