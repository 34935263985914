import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Field, useForm, useFormState } from 'react-final-form';
import RadioGroupField from 'components/RadioGroupField';
import { SchoolResidentialType } from 'searchality-data';
import RadioButtonLabel from 'components/RadioButtonLabel';
import { useNavigate } from 'react-router-dom';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';
import { useTranslation } from 'react-i18next';
import { required } from 'validations';
import { Button } from 'ncoded-component-library';
import utils from 'utils';
import GoBackButton from 'components/GoBackButton';

import './SchoolGroupResidentialType.styles.scss';

type SchoolGroupResidentialTypeProps = {
  className?: string;
};

const SchoolGroupResidentialType: React.FC<SchoolGroupResidentialTypeProps> = (
  props,
) => {
  const { className } = props;

  const { t } = useTranslation();

  const classes = classNames('school-group-residential-type', className);

  const { submit, reset, getState } = useForm();

  const {
    values: { type, residentialType },
  } = useFormState();

  const { resetCompletedSteps } = useContext(CreateSchoolWizardContext);

  const navigate = useNavigate();

  const options = useMemo(
    () =>
      Object.entries(SchoolResidentialType).map(([key, value]) => ({
        label: (
          <RadioButtonLabel label={t(`ResidentialType.${key}`) as string} />
        ),
        value,
      })),
    [t],
  );

  const resetForm = useCallback(() => {
    reset({ type, residentialType });
    resetCompletedSteps([]);
  }, [reset, resetCompletedSteps, residentialType, type]);

  return (
    <form
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid, dirtyFields } = getState();
        if (!valid) return submit();
        if (dirtyFields.residentialType) resetForm();
        navigate(`${utils.mapResidentialTypeToRoute(residentialType)}`);
      }}
      className={classes}
    >
      <GoBackButton to={`/create-school/school-type`} />
      <h1>{t('ResidentialType.title')}</h1>
      <p className="description">{t('ResidentialType.description')}</p>
      <Field
        name="residentialType"
        component={RadioGroupField}
        options={options}
        validate={required(t('mustSelectOne'))}
        wrapperClassName="school-type__radio-group"
      />
      <Button type="submit">{t('continue')}</Button>
    </form>
  );
};

export default SchoolGroupResidentialType;
