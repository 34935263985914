import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import InputField from 'components/InputField';
import { required, websiteValidation, composeValidators } from 'validations';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import api from 'api';
import GoBackButton from 'components/GoBackButton';
import useSchoolInformationRoute from 'router/subrouters/Dashboard/subrouters/CreateSchool/hooks/useSchoolInformationRoute';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError, popServerError } from 'store/slices/popNotifications.slice';
import MapboxAutocompleteField from 'components/MapboxAutocomplete';
import { SchoolGroupStructureType } from 'searchality-data';
import useReverseGeocodingFeature from 'hooks/useReverseGeocodingFeature';

import './DisctrictSchools.styles.scss';

type DisctrictSchoolsProps = {
  className?: string;
};

const DisctrictSchools: React.FC<DisctrictSchoolsProps> = (props) => {
  const { className } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { submit, change, getState } = useForm();

  const {
    values: { school, schools, type, structureType },
    values,
  } = useFormState();

  const navigateRoute = useSchoolInformationRoute(false);

  const checkIfSchoolExists = useCallback(async () => {
    if (schools?.some((el: any) => el.name === school.name)) {
      return dispatch(popError(t('District.Schools.sameNameError')));
    }

    setIsLoading(true);
    try {
      const { name, postcode, website } = school;

      const {
        data: { exists },
      } = await api.schools.checkIfSchoolExists({
        name,
        postcode,
        website,
      });

      if (!exists) {
        navigate('../school-logo');
      } else {
        dispatch(popError(t('schoolAlreadyExists')));
      }
    } catch (error) {
      dispatch(popServerError(error));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, navigate, school, schools, t]);

  const classes = classNames('disctrict-schools', className);

  const { getAddressObject } = useReverseGeocodingFeature();

  useEffect(() => {
    const { type, name, state, website, schoolCount } = values;

    if (
      !name ||
      (type === 'public-school' && !state && !schoolCount) ||
      (type !== 'public-school' && !website)
    ) {
      navigate('../');
    }
  }, [navigate, school, schools, structureType, type, values]);

  return (
    <form
      className={classes}
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid } = getState();
        if (!valid) return submit();
        checkIfSchoolExists();
      }}
    >
      <GoBackButton to={`/create-school/${navigateRoute}`} />
      <div>
        <h1>
          {t('District.Schools.title', {
            type:
              type === 'public-school' &&
              structureType === SchoolGroupStructureType.GROUP
                ? t('District.Schools.district')
                : t('group'),
          })}
        </h1>
        <p className="description">{t('District.Schools.description')}</p>
      </div>
      <Field
        name="school.name"
        component={InputField}
        label={t('schoolName')}
        required
        validate={required()}
      />
      <Field
        name="school.city"
        label={t('city')}
        validate={required()}
        component={MapboxAutocompleteField}
        required
        callback={async (selectedOption: any, feature: any) => {
          const address = await getAddressObject(feature);
          if (address?.state) {
            change('school.state', address.state);
          }
        }}
        mapFeaturesToOptions={(feature: any) => {
          const { place_name } = feature;
          return {
            label: place_name,
            value: place_name,
          };
        }}
        mapboxParams={{
          types: ['place'],
        }}
      />
      <div className="state-postcode">
        <Field
          name="school.state"
          searchable
          label={t('District.Schools.statePlaceholder')}
          required
          validate={required()}
          component={MapboxAutocompleteField}
          mapboxParams={{
            types: ['district', 'region'],
          }}
          key={school?.state}
          mapFeaturesToOptions={(feature: any) => {
            const { place_name } = feature;
            return {
              label: place_name,
              value: place_name,
            };
          }}
        />
        <Field
          name="school.postcode"
          component={InputField}
          label={t('zipcode')}
          required
          validate={required()}
        />
      </div>
      <Field
        name="school.website"
        component={InputField}
        label={t('schoolWebsite')}
        validate={composeValidators(required(), websiteValidation())}
        required
      />
      <Button type="submit" disabled={isLoading}>
        {t('addSchool')}
      </Button>
    </form>
  );
};

export default DisctrictSchools;
