import MainLayout from 'components/MainLayout';
import React, { useCallback } from 'react';
import { loadCurrentUser, loginUser } from 'store/slices/auth.slice';
import { LoginData } from 'types';
import LoginForm from 'router/subrouters/Auth/components/LoginForm';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError } from 'store/slices/popNotifications.slice';
import { useTranslation } from 'react-i18next';
import searchalityLogo from 'assets/images/searchality-logo.webp';
import { LEARN_MORE_LINK } from 'constants/links';
import { useLocation, useNavigate } from 'react-router-dom';
import env from 'env';
import SelectSearchalityVersion from 'components/SelectSearchalityVersion';

import './Login.styles.scss';
import './Login.responsive.styles.scss';

type LoginProps = Record<string, any>;

const Login: React.FC<LoginProps> = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { state } = useLocation();

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (values: LoginData) => {
      const queryParams = new URLSearchParams(window.location.search);

      const authToken = queryParams.get('authToken');
      const utmContent = queryParams.get('utm_content');

      const actionResult = await dispatch(loginUser(values));

      if (loginUser.fulfilled.match(actionResult)) {
        await dispatch(loadCurrentUser());

        if (state?.from && (authToken || utmContent)) {
          navigate({
            pathname: state.from,
            search: queryParams.toString(),
          });
        }
      } else {
        dispatch(popError(t(actionResult.payload as string)));
      }
    },
    [dispatch, navigate, state?.from, t],
  );

  return (
    <MainLayout className="auth" headerShadow>
      <div className="login">
        <div className="login__titles">
          <img src={searchalityLogo} alt="Logo" />
          <h1>{t('LoginPage.title')}</h1>
        </div>
        <div className="login__description">
          <p>{t('LoginPage.paragraph1')}</p>
          <p>{t('LoginPage.paragraph2')}</p>
          <a href={LEARN_MORE_LINK} target="_blank" rel="noreferrer">
            {t('LoginPage.link')}
          </a>
        </div>
        <div className="login__form">
          <SelectSearchalityVersion
            description={t('selectVersionDefault')}
            link={`${env.SEARCHALITY_INT_VERSION}/auth/signin`}
          />
          <LoginForm onSubmit={handleSubmit} />
        </div>
      </div>
    </MainLayout>
  );
};

export default Login;
