import React from 'react';
import classNames from 'classnames';
import PhotoPreview from 'components/PhotoPreview';
import { useTranslation } from 'react-i18next';
import DateService from 'services/Date.service';

import './MemberInfo.styles.scss';

type MemberInfoProps = {
  className?: string;
  src?: string;
  backgroundColor?: string;
  placeholderText?: string;
  name?: string;
  email: string;
  onClick?: () => void;
  displayRemaingTime: boolean;
  invitedAt: string;
};

const MemberInfo: React.FC<MemberInfoProps> = (props) => {
  const {
    className,
    placeholderText,
    src,
    backgroundColor,
    name,
    email,
    onClick = () => {},
    invitedAt,
    displayRemaingTime,
  } = props;

  const baseClass = 'member-info';
  const classes = classNames(baseClass, className);

  const { t } = useTranslation();

  return (
    <div className={classes} onClick={onClick}>
      <PhotoPreview
        placeHolderText={placeholderText}
        size="m"
        src={src}
        backgroundColor={backgroundColor}
      />
      <div className={`${baseClass}__text`}>
        {name && <p className={`${baseClass}__text__name`}>{name}</p>}
        <p className={classNames(`${baseClass}__text__email`)}>{email}</p>
      </div>
      {displayRemaingTime && invitedAt && (
        <p className={classNames(`${baseClass}__text__expire`)}>
          {t('inviteDaysRemaining', {
            days: DateService.substractDatesForInviteExpire(invitedAt),
          })}
        </p>
      )}
    </div>
  );
};

export default MemberInfo;
