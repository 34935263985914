import React, { useEffect } from 'react';
import { useGetVacancyQuery } from 'api/vacancies.api';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { Outlet, useParams } from 'react-router-dom';
import {
  changeVacancyType,
  resetVacancyStep,
  resetVacancyType,
} from 'store/slices/createVacancyWizard.slice';

const EditVacancy: React.FC = () => {
  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );
  const dispatch = useAppDispatch();
  const { vacancyId } = useParams();

  const { data: vacancy } = useGetVacancyQuery(vacancyId);

  useEffect(() => {
    if (vacancy && !type) dispatch(changeVacancyType(vacancy?.type));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy]);

  useEffect(() => {
    return () => {
      dispatch(resetVacancyType());
      dispatch(resetVacancyStep());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet />;
};

export default EditVacancy;
