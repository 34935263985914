import {
  useAttachBoardingPositionDescriptionMutation,
  useAttachPositionDescriptionMutation,
  useDeleteBoardingPositionDescriptionMutation,
  useDeletePositionDescriptionMutation,
  useGetVacancyQuery,
  useUpdateOngoingVacancyMutation,
  useUpdateVacancyMutation,
} from 'api/vacancies.api';
import PageLoader from 'components/PageLoader';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { Vacancy, VacancyFormValues } from 'models/Vacancy';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SchoolGroupStructureType, VacancyStatus } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';
import { updateVacancyWizardLastGroupStep } from 'store/slices/createVacancyWizard.slice';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { vacancyBuilderActions } from 'store/slices/vacancyBuilder.slice';
import VacancyWizardService from '../../services/VacancyWizard.service';
import { checkIfSomeSchoolPayedMatching } from '../../utils';
import useStandaloneSchoolId from './hooks/useStandaloneSchoolId';
import VacancyWizard from './VacancyWizard.component';

const EditVacancyWizard: React.FC = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { pathname } = useLocation();

  const [isLoadingInitValues, setIsLoadingInitValues] = useState(false);
  const [initialValues, setInitialValues] = useState<VacancyFormValues>(
    {} as VacancyFormValues,
  );
  const vacancyBuilderValues = useAppSelector((state) => state.vacancyBuilder);

  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );

  const { schoolGroup, marketingInformation } = useAppSelector(
    authSelectors.selectUser,
  );

  const { vacancyId } = useParams();
  const { data: vacancy, isLoading } = useGetVacancyQuery(vacancyId);
  const standaloneSchoolId = useStandaloneSchoolId();

  const [updateVacancy] = useUpdateVacancyMutation();
  const [uploadPositionDescription] = useAttachPositionDescriptionMutation();
  const [deletePositionDescription] = useDeletePositionDescriptionMutation();
  const [updateOngoingVacancy] = useUpdateOngoingVacancyMutation();
  const [uploadBoardingPositionDescription] =
    useAttachBoardingPositionDescriptionMutation();
  const [deleteBoardingPositionDescription] =
    useDeleteBoardingPositionDescriptionMutation();

  const onEditVacancy = useCallback(
    async (values: VacancyFormValues) => {
      try {
        if (vacancy?.status === VacancyStatus.ONGOING) {
          const {
            positionDescription,
            roleDescription,
            boardingPositionRequirements,
            boardingRoleDescription,
            positionTitle,
          } = values;
          const { _id } = vacancy;

          await updateOngoingVacancy({
            _id,
            roleDescription,
            boardingPositionRequirements,
            positionTitle,
          })
            .unwrap()
            .then(async (data) => {
              if (positionDescription?.length) {
                await uploadPositionDescription({
                  vacancyId: _id,
                  positionDescriptionFile: positionDescription[0],
                });
              } else if (
                !positionDescription?.length &&
                data.positionDescriptionUrl
              ) {
                await deletePositionDescription({
                  vacancyId: _id,
                });
              }
              if (boardingRoleDescription?.length) {
                await uploadBoardingPositionDescription({
                  vacancyId: _id,
                  boardingPositionDescriptionFile: boardingRoleDescription[0],
                });
              } else if (
                !boardingRoleDescription?.length &&
                data.boardingPositionRequirementsUrl
              ) {
                await deleteBoardingPositionDescription({
                  vacancyId: vacancy._id,
                });
              }
              dispatch(popSuccess(t('successPublishedVacancy')));
              dispatch(vacancyBuilderActions.clearValues());
              navigate('/vacancies/active');
            })
            .catch((e) => dispatch(popServerError(e)));
        } else if (vacancy?.status === VacancyStatus.DRAFT) {
          const {
            positionDescription,
            boardingRoleDescription,
            positionDescriptionLocalUrl,
            boardingRoleDescriptionLocalUrl,
            ...restValues
          } = values as any;
          dispatch(
            vacancyBuilderActions['setValues']({
              ...restValues,
              ...(positionDescription?.length && {
                positionDescriptionLocalUrl: URL.createObjectURL(
                  positionDescription[0],
                ),
              }),
              ...(boardingRoleDescription?.length && {
                boardingRoleDescriptionLocalUrl: URL.createObjectURL(
                  boardingRoleDescription[0],
                ),
              }),
              _id: vacancyId,
            }),
          );
          navigate('../preview');
        } else {
          const {
            status,
            schools,
            positionDescriptionUrl,
            boardingPositionRequirementsUrl,
            ...submitObject
          } = VacancyWizardService.convertFormIntoVacancy(
            values,
            type,
            standaloneSchoolId,
          ) as Vacancy;

          await updateVacancy({ ...submitObject, _id: vacancyId })
            .unwrap()
            .then(async (vacancy) => {
              if (values?.positionDescription?.length) {
                await uploadPositionDescription({
                  vacancyId: vacancy._id,
                  positionDescriptionFile: values.positionDescription[0],
                });
              } else if (
                !values?.positionDescription?.length &&
                vacancy.positionDescriptionUrl
              ) {
                await deletePositionDescription({
                  vacancyId: vacancy._id,
                });
              }
              if (values?.boardingRoleDescription?.length) {
                await uploadBoardingPositionDescription({
                  vacancyId: vacancy._id,
                  boardingPositionDescriptionFile:
                    values?.boardingRoleDescription[0],
                });
              } else if (
                !values?.boardingRoleDescription?.length &&
                vacancy?.boardingPositionRequirementsUrl
              ) {
                await deleteBoardingPositionDescription({
                  vacancyId: vacancy._id,
                });
              }
              dispatch(popSuccess(t('successPublishedVacancy')));
              dispatch(vacancyBuilderActions.clearValues());
              navigate('/vacancies/active');
            })
            .catch((e) => dispatch(popServerError(e)));
        }
      } catch (e) {
        console.error(e);
      }
    },
    [
      deleteBoardingPositionDescription,
      deletePositionDescription,
      dispatch,
      navigate,
      standaloneSchoolId,
      t,
      type,
      updateOngoingVacancy,
      updateVacancy,
      uploadBoardingPositionDescription,
      uploadPositionDescription,
      vacancy,
      vacancyId,
    ],
  );

  const convertVacancyAsync = useCallback(async () => {
    setIsLoadingInitValues(true);
    if (vacancy) {
      const formValues: VacancyFormValues =
        await VacancyWizardService.convertVacancyIntoForm(vacancy);

      const { isMultipleSchools, schoolId, schoolIds } = formValues;

      if (
        !pathname.includes('non-academic') &&
        schoolGroup?.structureType !== SchoolGroupStructureType.STANDALONE
      ) {
        const isMatchingPaidForSelectedSchools = checkIfSomeSchoolPayedMatching(
          isMultipleSchools,
          schoolIds,
          schoolId,
          schoolGroup?.schools,
        );
        dispatch(
          updateVacancyWizardLastGroupStep({
            isMatchingPaidForSelectedSchools,
            isMatchingAdDisabled: marketingInformation?.isMatchingAdDisabled,
          }),
        );
      }

      if (vacancy.status === VacancyStatus.DRAFT) {
        setInitialValues({ ...formValues, ...vacancyBuilderValues });
      } else {
        setInitialValues(formValues);
      }
    }

    setIsLoadingInitValues(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    marketingInformation?.isMatchingAdDisabled,
    schoolGroup?.schools,
    vacancy,
    vacancyBuilderValues,
  ]);

  useEffect(() => {
    convertVacancyAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || isLoadingInitValues) {
    return <PageLoader />;
  }

  return (
    <VacancyWizard initialValues={initialValues} onSubmit={onEditVacancy} />
  );
};

export default EditVacancyWizard;
