import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileTab } from '../../ProfileTabs/ProfileTabs.component';
import ProfileEducation from '../../ProfileEducation';
import ProfileWorkProfile from '../../ProfileWorkProfile';
import ProfileLanguages from '../../ProfileLanguages';
import ProfileReference from '../../ProfileReference';
import { ProfileCardType } from 'models/ProfileCard.type';
import { ApplicationType } from 'searchality-data';
import ProfileNonAcademicWork from '../../ProfileNonAcademicWork';
import ProfileNonAcademicRequirements from '../../ProfileNonAcademicRequirements';

export default function useProfileTabs(cardInfo: ProfileCardType) {
  const { t } = useTranslation();
  const { type } = cardInfo;

  const [activeTab, setActiveTab] = useState(
    type === ApplicationType.NON_ACADEMIC ? 'workNonAcademic' : 'work',
  );

  const tabs: ProfileTab[] = useMemo(
    () =>
      type === ApplicationType.NON_ACADEMIC
        ? [
            {
              label: t('Profile.Tabs.work'),
              onClick: () => setActiveTab('workNonAcademic'),
              key: 'workNonAcademic',
            },
            {
              label: t('Profile.Tabs.requirements'),
              onClick: () => setActiveTab('requirements'),
              key: 'requirements',
            },
            {
              label: t('Profile.Tabs.reference'),
              onClick: () => setActiveTab('reference'),
              key: 'reference',
            },
          ]
        : [
            {
              label: t('Profile.Tabs.work'),
              onClick: () => setActiveTab('work'),
              key: 'work',
            },
            {
              label: t('Profile.Tabs.education'),
              onClick: () => setActiveTab('education'),
              key: 'education',
            },
            {
              label: t('Profile.Tabs.languages'),
              onClick: () => setActiveTab('languages'),
              key: 'languages',
            },
            {
              label: t('Profile.Tabs.reference'),
              onClick: () => setActiveTab('reference'),
              key: 'reference',
            },
          ],
    [t, type],
  );

  const currentSection = useMemo(() => {
    if (activeTab === 'work') {
      const {
        educationPhilosophyUrl,
        videoUrl,
        rightToWork,
        startDate,
        hasRightToWork,
        coverLetterUrl,
        resumeUrl,
        rightToWorkDescription,
      } = cardInfo;
      return (
        <ProfileWorkProfile
          educationPhilosophyUrl={educationPhilosophyUrl}
          videoUrl={videoUrl}
          rightToWork={rightToWork}
          hasRightToWork={hasRightToWork}
          startDate={startDate}
          coverLetterUrl={coverLetterUrl}
          resumeUrl={resumeUrl}
          rightToWorkDescription={rightToWorkDescription}
        />
      );
    }

    if (activeTab === 'workNonAcademic') {
      const { resumeUrl, coverLetterUrl } = cardInfo;
      return (
        <ProfileNonAcademicWork
          resumeUrl={resumeUrl}
          coverLetterUrl={coverLetterUrl}
        />
      );
    }

    if (activeTab === 'education') {
      const {
        education,
        experience,
        curricula,
        yearsOfExperienceWithFaithBasedSchools,
        doesHaveExperinceWithFaithBasedSchools,
        certificates,
      } = cardInfo;
      return (
        <ProfileEducation
          education={education}
          experience={experience}
          curricula={curricula}
          yearsOfExperienceWithFaithBasedSchools={
            yearsOfExperienceWithFaithBasedSchools
          }
          doesHaveExperinceWithFaithBasedSchools={
            doesHaveExperinceWithFaithBasedSchools
          }
          certificates={certificates}
        />
      );
    }

    if (activeTab === 'languages') {
      const { languages, englishLevel, sports, activities, publications } =
        cardInfo;
      return (
        <ProfileLanguages
          languages={languages}
          englishLevel={englishLevel}
          sports={sports}
          activities={activities}
          publications={publications}
        />
      );
    }

    if (activeTab === 'requirements') {
      const {
        rightToWork,
        startDate,
        hasRightToWork,
        rightToWorkDescription,
        languages,
        englishLevel,
        doesHaveYearsOfExperience,
        yearsOfExperienceRequired,
        educationLevel,
        doesMeetMinimumEducationLevel,
      } = cardInfo;
      return (
        <ProfileNonAcademicRequirements
          startDate={startDate}
          hasRightToWork={hasRightToWork}
          rightToWork={rightToWork}
          rightToWorkDescription={rightToWorkDescription}
          englishLevel={englishLevel}
          languages={languages}
          doesHaveYearsOfExperience={doesHaveYearsOfExperience}
          yearsOfExperienceRequired={yearsOfExperienceRequired}
          educationLevel={educationLevel}
          doesMeetMinimumEducationLevel={doesMeetMinimumEducationLevel}
        />
      );
    }

    const { references } = cardInfo;

    return <ProfileReference references={references} />;
  }, [activeTab, cardInfo]);

  return { activeTab, tabs, currentSection };
}
