import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './LinkedAssociationEmptyState.styles.scss';

type LinkedAssociationEmptyStateProps = {
  className?: string;
};

const LinkedAssociationEmptyState: React.FC<
  LinkedAssociationEmptyStateProps
> = (props) => {
  const { className } = props;

  const classes = classNames('linked-association-empty-state', className);

  const { t } = useTranslation();

  return (
    <div className={classes}>
      <p className="linked-association-empty-state__title">
        {t('associationsLinkedToSchools')}
      </p>
      <p className="linked-association-empty-state__description">
        {t('CreateSchoolAssociations.PartOfAssociation.description2')}
        <a href="https://searchality.com/associations">{t('here')}</a>
        {t('CreateSchoolAssociations.PartOfAssociation.description3')}
      </p>
    </div>
  );
};

export default LinkedAssociationEmptyState;
