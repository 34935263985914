import { createContext } from 'react';

type BannersContextProps = {
  showRenew: boolean;
  showLink: boolean;
  showSettlePayment: boolean;
  updateRenewBanner: (show: boolean) => void;
  updateLinkBanner: (show: boolean) => void;
  updateSettlePaymentBanner: (show: boolean) => void;
};

export default createContext<Partial<BannersContextProps>>({});
