import { getFileFromURL } from 'components/ImageCropper/utils';
import { DEFAULT_COUNTRY_CODE } from 'constants/general';
import { t } from 'i18next';
import { Application, ExperienceType, Reference } from 'models/Candidate';
import { User } from 'models/User';
import TeacherProfileDataService from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/services/TeacherProfileData.service';
import { ApplicationType, CurriculumExperience } from 'searchality-data';
import ModelsMappingService from 'services/ModelsMapping.service';
import {
  JobApplicationFormPersonalInformation,
  JobApplicationFormPositionRequirements,
  JobApplicationFormValues,
  JobApplicationFormYourProfile,
} from '../types/JobApplicationFormValues';

const convertPersonalInformationIntoApplication = (
  personalInformation: JobApplicationFormPersonalInformation,
) => {
  const {
    firstName,
    lastName,
    address,
    city,
    state,
    zipcode,
    email,
    phoneNumber,
    videoUrl,
  } = personalInformation;

  return {
    firstName,
    lastName,
    address,
    city,
    state,
    zipcode,
    email,
    ...(phoneNumber?.phoneNumber && { phoneNumber }),
    videoUrl,
  };
};

const convertYourProfileIntoApplication = (
  yourProfile: JobApplicationFormYourProfile,
) => {
  const { experience, education } = yourProfile;

  const parsedExperiences: ExperienceType[] = experience?.map((exp) =>
    ModelsMappingService.parseExperience(exp),
  );
  return {
    experience: parsedExperiences?.length > 0 ? parsedExperiences : undefined,
    education: education
      ? education?.map(({ endDate, ...rest }) => ({
          ...rest,
          endDate: rest.isCurrentlyStudying ? undefined : endDate,
        }))
      : undefined,
  };
};

const convertReferencesIntoApplicaiton = (references: Reference[]) => {
  return references?.length > 0
    ? references?.map((reference) => ({
        ...reference,
        contactPhone: reference.contactPhone?.phoneNumber
          ? reference.contactPhone
          : undefined,
      }))
    : undefined;
};

const calculateDoesHaveExperienceInTheField = (
  doesHaveExperienceInTheField: boolean,
  curriculumExperience: string,
) =>
  curriculumExperience === CurriculumExperience.NO_EXPERIENCE
    ? false
    : doesHaveExperienceInTheField;

const convertPositionRequirementIntoApplication = (
  values: JobApplicationFormValues,
  curriculumExperience: string,
) => {
  const {
    doesHaveExperienceInTheField,
    doesHaveYearsOfExperience,
    doesMeetMinimumEducationLevel,
    yearsOfExperienceInTheField,
    earliestAvailableStartingDate,
    isAbleToStartOnRequiredDate,
    rightToWork,
    hasRightToWork,
    visaType,
    rightToWorkDescription,
    dateUntilVisaIsValid,
    languageRequirement,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveExperinceWithFaithBasedSchools,
    englishLevel,
  } = values.positionRequirements;

  const mappedLanguageRequirement =
    ModelsMappingService.formatLanguageReuirementType(languageRequirement);

  const doesHaveExpInField = calculateDoesHaveExperienceInTheField(
    doesHaveExperienceInTheField,
    curriculumExperience,
  );

  return {
    hasRightToWork,
    yearsOfExperienceWithFaithBasedSchools,
    doesMeetMinimumEducationLevel,
    doesHaveExperinceWithFaithBasedSchools,
    englishLevel,
    rightToWork: rightToWork ? [rightToWork] : undefined,
    dateUntilVisaIsValid,
    rightToWorkDescription,
    visaType,
    doesHaveExperienceInTheField: doesHaveExpInField,
    earliestAvailableStartingDate: isAbleToStartOnRequiredDate
      ? undefined
      : earliestAvailableStartingDate,

    isAbleToStartOnRequiredDate,
    doesHaveYearsOfExperience,
    yearsOfExperienceInTheField: doesHaveExpInField
      ? yearsOfExperienceInTheField
      : undefined,
    languageRequirement:
      mappedLanguageRequirement?.length === 0
        ? undefined
        : mappedLanguageRequirement,
  };
};

/**
 * When user submits/save-as-draft application
 * @param values
 * @returns
 */
const convertFormIntoApplication = (
  values: JobApplicationFormValues,
  curriculumExperience: string,
  type: ApplicationType,
) => {
  const submitObject: Partial<Application> = {
    ...convertPersonalInformationIntoApplication(values.personalInformation),
    ...convertYourProfileIntoApplication(values.yourProfile),
    ...convertPositionRequirementIntoApplication(values, curriculumExperience),
    references: convertReferencesIntoApplicaiton(values.references),
    ...(values?.isAbleToWorkAsBoardingParent !== undefined && {
      isAbleToWorkAsBoardingParent:
        values?.isAbleToWorkAsBoardingParent === 'true' ? true : false,
    }),
    type,
  };

  return submitObject;
};
/**
 * It's used in case when you want to finish your application (finish the draft)
 * Application -> ApplicationProfile ...
 * @param application
 * @returns
 */

const convertApplicationToJobApplicationForm = async (
  application: Application,
) => {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    city,
    state,
    zipcode,
    videoUrl,
    education,
    references,
    experience,
    educationPhilosophyUrl,
    languageRequirement,
    rightToWork,
    yearsOfExperienceInTheField,
    doesHaveYearsOfExperience,
    visaType,
    rightToWorkDescription,
    dateUntilVisaIsValid,
    imageFile,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveExperinceWithFaithBasedSchools,
    isAbleToStartOnRequiredDate,
    doesMeetMinimumEducationLevel,
    doesHaveExperienceInTheField,
    earliestAvailableStartingDate,
    hasRightToWork,
    coverLetterUrl,
    resumeUrl,
    publications,
    englishLevel,
    isAbleToWorkAsBoardingParent,
  } = application;

  const positionRequirements: JobApplicationFormPositionRequirements = {
    languageRequirement:
      ModelsMappingService.parseLanguageReuirementType(languageRequirement),
    rightToWork: rightToWork?.[0],
    visaType,
    dateUntilVisaIsValid,
    rightToWorkDescription,
    isAbleToStartOnRequiredDate,
    doesHaveExperienceInTheField,
    hasRightToWork,
    yearsOfExperienceInTheField,
    doesMeetMinimumEducationLevel,
    doesHaveYearsOfExperience,
    earliestAvailableStartingDate,
    yearsOfExperienceWithFaithBasedSchools,
    doesHaveExperinceWithFaithBasedSchools,
    englishLevel,
  };

  const formValues: JobApplicationFormValues = {
    personalInformation: {
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      city,
      state,
      zipcode,
      videoUrl,
      applicantPhoto: undefined,
    },
    yourProfile: {
      education,
      experience: experience?.map((exp) =>
        ModelsMappingService.formatExperience(exp),
      ),
      educationPhilosophy: undefined,
      coverLetter: null,
      resume: undefined,
    },
    positionRequirements,
    references,
    publications: await TeacherProfileDataService.formatPublications(
      publications,
    ),
    ...(isAbleToWorkAsBoardingParent !== undefined && {
      isAbleToWorkAsBoardingParent: isAbleToWorkAsBoardingParent
        ? 'true'
        : 'false',
    }),
  };

  if (imageFile) {
    const file = await getFileFromURL(imageFile.url, 'photo', 'image/jpeg');
    formValues.personalInformation = {
      ...formValues.personalInformation,
      applicantPhoto: [file],
    };
  }

  if (educationPhilosophyUrl) {
    const file = await getFileFromURL(
      educationPhilosophyUrl,
      t('educationPhilosophy'),
      'application/pdf',
    );
    formValues.yourProfile = {
      ...formValues.yourProfile,
      educationPhilosophy: [file],
    };
  }
  if (coverLetterUrl) {
    const file = await getFileFromURL(
      coverLetterUrl,
      t('coverLetter'),
      'application/pdf',
    );
    formValues.yourProfile = {
      ...formValues.yourProfile,
      coverLetter: [file],
    };
  }

  if (resumeUrl) {
    const file = await getFileFromURL(
      resumeUrl,
      t('resume'),
      'application/pdf',
    );
    formValues.yourProfile = {
      ...formValues.yourProfile,
      resume: [file],
    };
  }

  return formValues;
};

const populateFormWithTeacher = async (
  user: User,
  isEducationPhilosophyRequired: boolean,
  requiredLanguages?: {
    language: string;
    level: string;
  }[],
  curriculumExperience?: string,
) => {
  const {
    firstName,
    lastName,
    email,
    mobilePhone,
    streetAddress,
    city,
    state,
    postcode,
    videoUrl,
    education,
    references,
    curricula,
    experience,
    educationPhilosophyUrl,
    languages,
    hasRightToWork,
    rightToWork,
    visaType,
    rightToWorkDescription,
    dateUntilVisaIsValid,
    imageFile,
    publications,
    englishLevel,
    isAbleToWorkAsBoardingParent,
  } = user;

  const curriculaTemp = curricula.filter(
    (el) => el.curriculumExperience === curriculumExperience,
  );

  const formValues: JobApplicationFormValues = {
    personalInformation: {
      firstName,
      lastName,
      email,
      phoneNumber: mobilePhone,
      address: streetAddress,
      city,
      state,
      zipcode: postcode,
      videoUrl,
      applicantPhoto: undefined,
    },
    yourProfile: {
      education,
      experience: experience.map((exp) =>
        ModelsMappingService.formatExperience(exp),
      ),
      educationPhilosophy: undefined,
      coverLetter: undefined,
      resume: undefined,
    },
    positionRequirements: {
      languageRequirement: ModelsMappingService.parseLanguageReuirementType(
        languages,
        requiredLanguages,
      ),
      rightToWork: rightToWork[0],
      visaType,
      dateUntilVisaIsValid,
      rightToWorkDescription,
      doesHaveExperienceInTheField: curriculaTemp?.length > 0,
      yearsOfExperienceInTheField: curriculaTemp?.[0]?.yearsOfExperience,
      hasRightToWork,
      englishLevel,
    },
    references,
    publications: await TeacherProfileDataService.formatPublications(
      publications,
    ),
    ...(isAbleToWorkAsBoardingParent !== undefined && {
      isAbleToWorkAsBoardingParent: isAbleToWorkAsBoardingParent
        ? 'true'
        : 'false',
    }),
  };

  if (!mobilePhone) {
    formValues.personalInformation.phoneNumber = {
      countryCode: DEFAULT_COUNTRY_CODE,
    };
  }

  if (imageFile) {
    const file = await getFileFromURL(imageFile.url, 'photo', 'image/jpeg');
    formValues.personalInformation = {
      ...formValues.personalInformation,
      applicantPhoto: [file],
    };
  }

  if (educationPhilosophyUrl && isEducationPhilosophyRequired) {
    const file = await getFileFromURL(
      educationPhilosophyUrl,
      t('educationPhilosophy'),
      'application/pdf',
    );
    formValues.yourProfile = {
      ...formValues.yourProfile,
      educationPhilosophy: [file],
    };
  }

  return formValues;
};

export default {
  convertFormIntoApplication,
  populateFormWithTeacher,
  convertApplicationToJobApplicationForm,
};
