import { states } from 'constants/states';
import useAppSelector from 'hooks/useAppSelector';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SchoolGroupStructureType } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';
import { ReviewCardItemList } from 'types';

export const schoolTypeLabelMapping: { [name: string]: string } = {
  'public school': 'publicSchool',
  charter: 'charterSchool',
  independent: 'independentSchool',
};

export default function () {
  const { schoolGroup } = useAppSelector(authSelectors.selectUser);

  const {
    type: schoolType,
    structureType,
    name,
    website,
    county,
    state,
  } = schoolGroup;

  const { t } = useTranslation();

  const list: ReviewCardItemList = useMemo(() => {
    if (structureType === SchoolGroupStructureType.STANDALONE) {
      return [
        {
          label: t(schoolTypeLabelMapping[schoolType]),
          value: t('standalone'),
        },
      ];
    }

    if (schoolType === 'independent' || schoolType === 'charter') {
      return [
        {
          label: t('name'),
          value: name,
        },
        {
          label: t('website'),
          value: website,
        },
        {
          label: t('state'),
          value: states[state as keyof typeof states],
        },
      ];
    }

    return [
      {
        label: t('districtGroupName'),
        value: name,
      },
      {
        label: t('districtGroupWebsite'),
        value: website,
      },
      {
        label: t('state'),
        value: states[state as keyof typeof states],
      },
      {
        label: t('county'),
        value: county?.split(',')?.[0],
      },
    ];
  }, [county, name, schoolType, state, structureType, t, website]);

  return list;
}
