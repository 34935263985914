import useAppSelector from 'hooks/useAppSelector';
import MatchingSchoolsContext from 'providers/MatchingSchools/MatchingSchools.context';
import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SchoolGroupStructureType } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';
import MatchingPricingPlan from '../../../../components/MatchingPricingPlan';

const DefaultMatchingSub: React.FC = () => {
  const navigate = useNavigate();

  const {
    schoolGroup: { structureType, schools },
  } = useAppSelector(authSelectors.selectUser);

  const { setSchoolForMatching } = useContext(MatchingSchoolsContext);

  const isStandalone = structureType === SchoolGroupStructureType.STANDALONE;

  const onContinue = useCallback(() => {
    if (isStandalone) {
      setSchoolForMatching(schools[0]._id);
      navigate('../checkout');
    } else {
      navigate('../select-schools');
    }
  }, [isStandalone, setSchoolForMatching, navigate, schools]);

  return <MatchingPricingPlan goBackTo="../../" onContinue={onContinue} />;
};

export default DefaultMatchingSub;
