import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import JobPreviewComponent from 'components/JobPreviewComponent';
import { useTranslation } from 'react-i18next';
import { Role, VacancyStatus } from 'searchality-data';
import {
  useAddVacancyMutation,
  useAttachBoardingPositionDescriptionMutation,
  useAttachPositionDescriptionMutation,
  useDeleteBoardingPositionDescriptionMutation,
  useDeletePositionDescriptionMutation,
  usePublishVacancyMutation,
  useUpdateVacancyMutation,
} from 'api/vacancies.api';
import { useNavigate } from 'react-router-dom';
import VacancyPreviewCard from '../../../../components/VacancyPreviewCard';
import useAppSelector from 'hooks/useAppSelector';
import VacancyWizardService from '../../../../services/VacancyWizard.service';
import { Vacancy } from 'models/Vacancy';
import useAppDispatch from 'hooks/useAppDispatch';
import useStandaloneSchoolId from '../../../../pages/VacancyWizard/hooks/useStandaloneSchoolId';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { resetVacancyStep } from 'store/slices/createVacancyWizard.slice';
import { vacancyBuilderActions } from 'store/slices/vacancyBuilder.slice';
import authSelectors from 'store/selectors/auth.selectors';
import vacancyConstants from 'constants/vacancy';
import ShareVacancyModal from '../../../../components/ShareVacancyModal';
import { ShareVacancyModalRef } from '../../../../components/ShareVacancyModal/ShareVacancyModal.component';
import { getFileFromURL } from 'components/ImageCropper/utils';

const VacancyBeforePublishPreview: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const values = useAppSelector(({ vacancyBuilder }) => vacancyBuilder);
  const user = useAppSelector(authSelectors.selectUser);
  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );

  const [positionDescriptionFormValue, setPositionDescriptionFormValue] =
    useState<File[]>([]);
  const [boardingDescriptionFile, setBoardingDescriptionFile] = useState<
    File[]
  >([]);

  const shareVacancyRef = useRef<ShareVacancyModalRef>();

  const standaloneSchoolId = useStandaloneSchoolId();

  const [updateVacancy] = useUpdateVacancyMutation();
  const [publishVacancy] = usePublishVacancyMutation();
  const [addVacancy] = useAddVacancyMutation();
  const [uploadPositionDescription] = useAttachPositionDescriptionMutation();
  const [deletePositionDescription] = useDeletePositionDescriptionMutation();
  const [uploadBoardingPositionDescription] =
    useAttachBoardingPositionDescriptionMutation();
  const [deleteBoardingPositionDescription] =
    useDeleteBoardingPositionDescriptionMutation();

  const fetchFiles = useCallback(async () => {
    const { positionDescriptionLocalUrl, boardingRoleDescriptionLocalUrl } =
      values;
    if (positionDescriptionLocalUrl) {
      const file = await getFileFromURL(
        positionDescriptionLocalUrl,
        'Position Description',
        'application/pdf',
      );
      setPositionDescriptionFormValue([file]);
    }

    if (boardingRoleDescriptionLocalUrl) {
      const file = await getFileFromURL(
        boardingRoleDescriptionLocalUrl,
        'Boarding position description',
        'application/pdf',
      );
      setBoardingDescriptionFile([file]);
    }
  }, [values]);

  const vacancy = useMemo(() => {
    const {
      positionDescriptionLocalUrl,
      boardingRoleDescriptionLocalUrl,
      ...restValues
    } = values;

    return VacancyWizardService.convertFormIntoVacancy(
      {
        ...restValues,
        positionDescription: positionDescriptionFormValue,
        boardingRoleDescription: boardingDescriptionFile,
      },
      type,
      standaloneSchoolId,
      user,
    ) as Vacancy;
  }, [
    boardingDescriptionFile,
    positionDescriptionFormValue,
    standaloneSchoolId,
    type,
    user,
    values,
  ]);

  const onPublish = useCallback(async () => {
    const {
      positionDescriptionUrl,
      boardingPositionRequirementsUrl,
      ...forAddVacancy
    } = vacancy;
    const { status, ...restOfVacancy } = forAddVacancy;
    if (status === VacancyStatus.DRAFT) {
      updateVacancy(restOfVacancy)
        .unwrap()
        .then(async () => {
          await publishVacancy(values._id)
            .unwrap()
            .then(
              async ({
                _id,
                positionDescriptionUrl,
                boardingPositionRequirementsUrl,
              }) => {
                if (positionDescriptionFormValue.length) {
                  await uploadPositionDescription({
                    vacancyId: _id,
                    positionDescriptionFile: positionDescriptionFormValue[0],
                  });
                } else if (
                  !positionDescriptionFormValue.length &&
                  positionDescriptionUrl
                ) {
                  await deletePositionDescription({
                    vacancyId: _id,
                  });
                }
                if (boardingDescriptionFile.length) {
                  await uploadBoardingPositionDescription({
                    vacancyId: _id,
                    boardingPositionDescriptionFile: boardingDescriptionFile[0],
                  });
                } else if (
                  !boardingDescriptionFile.length &&
                  boardingPositionRequirementsUrl
                ) {
                  await deleteBoardingPositionDescription({
                    vacancyId: _id,
                  });
                }
                dispatch(popSuccess(t('successPublishedVacancy')));
                dispatch(vacancyBuilderActions.clearValues());
                navigate('/vacancies/active');
              },
            )
            .catch((e) => dispatch(popServerError(e)));
        })
        .catch((e) => dispatch(popServerError(e)));
    } else {
      await addVacancy({
        ...forAddVacancy,
      })
        .unwrap()
        .then(
          async ({
            _id,
            positionDescriptionUrl,
            boardingPositionRequirementsUrl,
          }) => {
            if (positionDescriptionFormValue.length) {
              await uploadPositionDescription({
                vacancyId: _id,
                positionDescriptionFile: positionDescriptionFormValue[0],
              });
            } else if (
              !positionDescriptionFormValue.length &&
              positionDescriptionUrl
            ) {
              await deletePositionDescription({
                vacancyId: _id,
              });
            }
            if (boardingDescriptionFile.length) {
              await uploadBoardingPositionDescription({
                vacancyId: _id,
                boardingPositionDescriptionFile: boardingDescriptionFile[0],
              });
            } else if (
              !boardingDescriptionFile.length &&
              boardingPositionRequirementsUrl
            ) {
              await deleteBoardingPositionDescription({
                vacancyId: _id,
              });
            }
            dispatch(popSuccess(t('successPublishedVacancy')));
            shareVacancyRef?.current?.openShareVacancy(_id);
          },
        )
        .catch((e) => {
          dispatch(popServerError(e));
        });
    }
  }, [
    addVacancy,
    boardingDescriptionFile,
    deleteBoardingPositionDescription,
    deletePositionDescription,
    dispatch,
    navigate,
    positionDescriptionFormValue,
    publishVacancy,
    t,
    updateVacancy,
    uploadBoardingPositionDescription,
    uploadPositionDescription,
    vacancy,
    values._id,
  ]);

  const onEdit = useCallback(async () => {
    const { status, _id, type } = vacancy;
    navigate(
      status === VacancyStatus.DRAFT
        ? `/vacancies/${_id}/edit/${vacancyConstants.vacancyTypeToRouteMapping[type]}`
        : `/vacancies/create-vacancy/${vacancyConstants.vacancyTypeToRouteMapping[type]}`,
    );
  }, [navigate, vacancy]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  return (
    <>
      <VacancyPreviewCard
        title={t('VacancyPreviewCard.beforePublishTitle')}
        subtitle={t('VacancyPreviewCard.beforePublishSubtitle')}
        onPublish={onPublish}
        onEdit={onEdit}
      />
      <JobPreviewComponent
        vacancy={vacancy}
        noActions
        noPreviousButton={user.role === Role.Teacher}
        previousPage={() => navigate('..')}
        previousPageButtonText={t('goBack')}
      />
      <ShareVacancyModal
        ref={shareVacancyRef}
        title={t('ShareVacancyModal.publishTitle')}
        description={t('ShareVacancyModal.publishDescription')}
        onClose={() => {
          dispatch(resetVacancyStep());
          dispatch(vacancyBuilderActions.clearValues());
          navigate('/vacancies/active');
        }}
      />
    </>
  );
};

export default VacancyBeforePublishPreview;
