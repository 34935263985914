import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import CheckoutProvider from 'providers/Checkout';
import CheckoutPayment from '../CheckoutPayment/CheckoutPayment.component';
import PaymentMethodsContext from 'providers/PaymentMethods/PaymentMethods.context';
import ValueContext from 'providers/ValueContext/Value.context';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { useFormState } from 'react-final-form';
import api from 'api';
import confirm from 'modules/confirm';
import { useTranslation } from 'react-i18next';
import usePreviewNewSchoolsPrice from '../../hooks/usePreviewNewSchoolsPrice';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { updateUser } from 'store/slices/auth.slice';
import {
  updateClientSecretValue,
  updateCouponValue,
} from 'store/slices/payment.slice';
import { useNavigate } from 'react-router-dom';
import { resetState } from 'store/slices/addNewSchool.slice';
import { batch } from 'react-redux';
import { getFileFromURL } from 'components/ImageCropper/utils';
import { School } from 'models/School';
import MatchingSchoolsContext from 'providers/MatchingSchools/MatchingSchools.context';
import { CheckoutCategory } from '../../types';

const CheckoutNewSchoolWrapper: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState('');
  const [checked, setChecked] = useState('');
  const [schoolsForCreate, setSchoolsForCreate] = useState<
    (School & { schoolLogo?: File[] })[]
  >([]);

  const { schoolsForMatching } = useContext(MatchingSchoolsContext);

  const { t } = useTranslation();

  const { paymentInfo } = useContext(PaymentMethodsContext);
  const { paymentMethods } = paymentInfo || {};
  const dispatch = useAppDispatch();

  const { selectedIds } = useContext(ValueContext);

  const user = useAppSelector(authSelectors.selectUser);
  const schools = useAppSelector(({ addNewSchool }) => addNewSchool?.schools);
  const promoCode = useAppSelector(({ payment }) => payment?.coupon);
  const navigate = useNavigate();

  const { data, isLoading: previewLoading } = usePreviewNewSchoolsPrice();

  const { schoolGroupId } = user;
  const { values } = useFormState();

  const onSubscribe = useCallback(
    async (skipPayment: boolean) => {
      const schoolsForPricingPreview = schools?.map((school) => ({
        ...school,
        optOutIntegration: !selectedIds?.includes(school?.name),
        hasMatchingSubscription: schoolsForMatching?.includes(school?.name),
      }));

      const subscribeRequest = async () => {
        try {
          setIsLoading(true);
          if (purchaseOrder) {
            await api.schools.addPaymentInfo({ purchaseOrder });
          }
          const {
            data: { schoolGroup, clientSecret },
          } = await api.schools.addSchools(schoolGroupId, {
            schools: schoolsForPricingPreview,
            promoCode: promoCode,
            paymentMethodId: skipPayment ? undefined : checked,
          });

          let updateSchools: School[] = schoolGroup?.schools;

          for (const school of schoolGroup?.schools as School[]) {
            try {
              const schoolLogo = (schoolsForCreate as any)?.find(
                (el: any) => el.name === school.name,
              )?.schoolLogo;

              if (schoolLogo) {
                const { data } = await api.user.uploadSchoolLogo(
                  schoolLogo[0],
                  school._id,
                );
                updateSchools = updateSchools?.map((el) =>
                  el._id === data._id ? data : el,
                );
              }
            } catch (e) {
              dispatch(popServerError(e));
            }
          }

          dispatch(
            popSuccess(
              t('addNewSchoolsSuccess', {
                count: schoolsForPricingPreview?.length,
              }),
            ),
          );
          batch(() => {
            dispatch(resetState());
            dispatch(
              updateUser({
                ...user,
                schoolGroup: {
                  ...schoolGroup,
                  schools: updateSchools,
                },
                schools: updateSchools,
                schoolIds: updateSchools?.map((el) => el._id),
              }),
            );

            dispatch(updateCouponValue(undefined));
          });

          if (clientSecret && !skipPayment) {
            dispatch(updateClientSecretValue(clientSecret));
          } else {
            navigate('/my-account/subscription');
            dispatch(updateCouponValue(undefined));
          }
        } catch (error) {
          console.error(error);
          dispatch(updateClientSecretValue(''));
        } finally {
          setIsLoading(false);
        }
      };

      if (!data?.isPromoCodeValid && values?.discountCode?.length) {
        return await confirm({
          title: t('ConfirmationModalPromoCode.title'),
          content: <p>{t('ConfirmationModalPromoCode.description')}</p>,
          onSubmit: subscribeRequest,
        });
      } else {
        await subscribeRequest();
      }
    },
    [
      checked,
      data?.isPromoCodeValid,
      dispatch,
      navigate,
      promoCode,
      purchaseOrder,
      schoolGroupId,
      schools,
      schoolsForCreate,
      selectedIds,
      t,
      user,
      values?.discountCode?.length,
      schoolsForMatching,
    ],
  );

  const checkedItem = useMemo(
    () => paymentMethods?.find(({ id }) => id === checked),
    [checked, paymentMethods],
  );

  const fetchIcons = useCallback(async () => {
    let schoolsToUpdate = structuredClone(schools);
    for (const school of schoolsToUpdate) {
      try {
        const schoolForFile = (schools as any)?.find(
          (el: any) => el.name === school.name,
        );

        if (schoolForFile?.schoolLogoUrl) {
          const file = await getFileFromURL(
            schoolForFile.schoolLogoUrl,
            'Position Description',
            schoolForFile.schoolLogoType,
          );

          schoolsToUpdate = schoolsToUpdate?.map((el) =>
            el.name === schoolForFile?.name
              ? { ...el, schoolLogo: [file] }
              : el,
          );
        }
      } catch (e) {
        dispatch(popServerError(e));
      }
    }
    setSchoolsForCreate(schoolsToUpdate);
  }, [dispatch, schools]);

  useEffect(() => {
    fetchIcons();
  }, [fetchIcons]);

  useEffect(() => {
    if (checked) return;
    setChecked(
      () => paymentMethods?.find(({ isDefault }) => isDefault)?.id || '',
    );
  }, [checked, paymentMethods]);

  return (
    <CheckoutProvider
      data={{
        subscribe: onSubscribe,
        purchaseOrder,
        setPurchaseOrder,
        checked,
        setChecked,
        checkedItem,
        isLoading,
        stripePreviewObject: data,
        previewLoading,
        checkoutCategory: CheckoutCategory.NEW_SCHOOL,
        setIsLoading,
      }}
    >
      <CheckoutPayment />
    </CheckoutProvider>
  );
};

export default CheckoutNewSchoolWrapper;
