import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { AdditionalDocument } from 'models/Candidate';
import { ReactComponent as DocumentIcon } from 'icons/Documents.icon.svg';
import { ReactComponent as PhotoIcon } from 'icons/Photo.icon.svg';
import { ReactComponent as InformationIcon } from 'icons/Information.icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import { ReactComponent as UploadIcon } from 'icons/Upload.icon.svg';
import { Dropdown } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import FileInformationModal from '../FileInformationModal';
import { getFileFromURL } from 'components/ImageCropper/utils';

import './UploadedRequestedDocument.styles.scss';
import { RequestedDocumentTitle } from 'searchality-data';
import utils from 'utils';

type UploadedRequestedDocumentProps = {
  className?: string;
  handleDeleteFile: (key: string, documentId?: string) => void;
  isDeleted?: boolean;
  noClick?: boolean;
  noActions?: boolean;
  deleteKey: string;
  documentId?: string;
  description?: string;
  title?: string;
} & AdditionalDocument;

const UploadedRequestedDocument: React.FC<UploadedRequestedDocumentProps> = (
  props,
) => {
  const {
    className,
    fileType,
    fileName,
    fileSize,
    documentId,
    noClick = false,
    url,
    owner,
    noActions,
    deleteKey,
    createdAt,
    title,
    description,
    handleDeleteFile,
    isDeleted,
  } = props;

  const [file, setFile] = useState<File>();

  const fileInformationRef = useRef<ModalRef>();
  const dropdownRef = useRef<DropdownRef>();

  const { t } = useTranslation();
  const classes = classNames('uploaded-requested-document', className, {
    'uploaded-requested-document--deleted': isDeleted,
  });

  const icon = useMemo(
    () => (fileType.includes('pdf') ? <DocumentIcon /> : <PhotoIcon />),
    [fileType],
  );

  const fetchFile = useCallback(async () => {
    const file = await getFileFromURL(url, fileName, fileType);
    setFile(file);
  }, [fileName, fileType, url]);

  useEffect(() => {
    fetchFile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={classes}
        onClick={(e) => {
          if (isDeleted || noClick) return;
          const classListToCheck = [
            'overlay',
            'uploaded-requested-document__dots',
            'uploaded-requested-document__dots__dot',
          ];

          const classList = (e as any).target.classList;

          for (const className of classListToCheck) {
            if (classList.contains(className)) {
              e.stopPropagation();
              return;
            }
          }
          window.open(url, '_blank');
        }}
      >
        {icon}
        <div className="uploaded-requested-document__info">
          <p className="uploaded-requested-document__info__name">
            {title && description
              ? `${t(
                  `RequestDocumentOptions.Title.${utils.getKeyByValue(
                    RequestedDocumentTitle,
                    title,
                  )}`,
                )} - ${description}`
              : fileName}
          </p>
          <p className="uploaded-requested-document__info__size">
            {title && description
              ? fileName
              : (fileSize / 1024).toFixed(1) + ' kB'}
          </p>
        </div>
        {!noActions ? (
          isDeleted ? (
            <DeleteIcon />
          ) : (
            <Dropdown
              trigger={
                <div
                  className="uploaded-requested-document__dots"
                  onClick={(e) => e.stopPropagation()}
                >
                  <span
                    className="uploaded-requested-document__dots__dot"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <span className="uploaded-requested-document__dots__dot" />
                  <span className="uploaded-requested-document__dots__dot" />
                </div>
              }
              ref={dropdownRef}
            >
              <div
                className="uploaded-requested-document__actions"
                onClick={(e) => e.stopPropagation()}
              >
                <span
                  className="uploaded-requested-document__actions__action"
                  onClick={(e) => {
                    e.stopPropagation();
                    dropdownRef.current.setIsOpen(false);
                    fileInformationRef?.current?.open();
                  }}
                >
                  <InformationIcon />
                  <p>{t('fileInformation')}</p>
                </span>
                <span
                  className="uploaded-requested-document__actions__action"
                  onClick={() => {
                    dropdownRef.current.setIsOpen(false);
                    handleDeleteFile(deleteKey, documentId);
                  }}
                >
                  <DeleteIcon />
                  <p>{t('deleteFile')}</p>
                </span>
                <span
                  className="uploaded-requested-document__actions__action"
                  onClick={() => {
                    dropdownRef.current.setIsOpen(false);

                    const a = document.createElement('a');
                    a.href = URL.createObjectURL(file);
                    a.download = fileName;
                    a.click();
                  }}
                >
                  <UploadIcon />
                  <p>{t('downloadFile')}</p>
                </span>
              </div>
            </Dropdown>
          )
        ) : (
          <div />
        )}
      </div>
      {!noActions && (
        <FileInformationModal
          ref={fileInformationRef}
          fileName={fileName}
          fileSize={fileSize}
          createdAt={createdAt}
          owner={owner}
        />
      )}
    </>
  );
};

export default UploadedRequestedDocument;
