import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { required } from 'validations';
import GoBackButton from 'components/GoBackButton';
import { Field, useForm, useFormState } from 'react-final-form';
import RadioGroupField from 'components/RadioGroupField';
import RadioButtonLabel from 'components/RadioButtonLabel';
import { useLocation, useNavigate } from 'react-router-dom';
import { batch } from 'react-redux';
import { setSchools } from 'store/slices/addNewSchool.slice';
import useAppDispatch from 'hooks/useAppDispatch';

import './SchoolPartOfAssociation.styles.scss';

type SchoolPartOfAssociationProps = {
  className?: string;
};

const SchoolPartOfAssociation: React.FC<SchoolPartOfAssociationProps> = (
  props,
) => {
  const { className } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const {
    values: { isPartOfAssociation, school, schools },
  } = useFormState();
  const { getState, submit, change } = useForm();

  const options = useMemo(
    () => [
      {
        label: <RadioButtonLabel label={t('yes')} />,
        value: 'true',
      },
      {
        label: <RadioButtonLabel label={t('no')} />,
        value: 'false',
      },
    ],
    [t],
  );

  const classes = classNames('school-part-of-association', className);

  const handleContinue = useCallback(() => {
    if (isPartOfAssociation === 'false') {
      batch(() => {
        change(
          'schools',
          !schools || pathname?.includes('standalone')
            ? [school]
            : [...schools, school],
        );
        if (!pathname?.includes('standalone')) {
          change('school', undefined);
          change('isPartOfAssociation', undefined);
        }

        const { schoolLogo, ...rest } = school;

        if (pathname?.includes('add-new-school')) {
          dispatch(
            setSchools([
              ...schools,
              {
                ...rest,
                ...(schoolLogo?.length && {
                  schoolLogoUrl: URL.createObjectURL(schoolLogo?.[0]),
                  schoolLogoType: schoolLogo?.[0].type,
                }),
              },
            ]),
          );
        }

        change('nationalAssociations', undefined);
        change('regionalAssociations', undefined);
        change('stateAssociations', undefined);
        change('associationContactDetails', undefined);
      });

      if (pathname?.includes('standalone')) {
        return navigate('/create-school/pricing-information');
      }

      return navigate(
        pathname?.includes('add-new-school')
          ? '../preview'
          : '../schools-preview',
      );
    }

    navigate('../choose-associations');
  }, [
    change,
    dispatch,
    isPartOfAssociation,
    navigate,
    pathname,
    school,
    schools,
  ]);

  return (
    <form
      className={classes}
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid } = getState();
        if (!valid) return submit();
        handleContinue();
      }}
    >
      <GoBackButton
        to={
          pathname?.includes('add-new-school') ||
          pathname?.includes('standalone')
            ? '..'
            : `../school-logo`
        }
      />
      <h1>{t('CreateSchoolAssociations.PartOfAssociation.title')}</h1>
      <p className="description">
        {t('CreateSchoolAssociations.PartOfAssociation.description')}
      </p>
      <Field
        name="isPartOfAssociation"
        component={RadioGroupField}
        options={options}
        required
        validate={required()}
      />
      <p className="description">
        {t('CreateSchoolAssociations.PartOfAssociation.description2')}
        <a href="https://searchality.com/associations">{t('here')}</a>
        {t('CreateSchoolAssociations.PartOfAssociation.description3')}
      </p>
      <Button type="submit" disabled={!getState()?.valid}>
        {t('continue')}
      </Button>
    </form>
  );
};

export default SchoolPartOfAssociation;
