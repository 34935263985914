import { ComplexRoute } from 'router/components/Routes/Routes';
import SchoolTypePage from './pages/SchoolType/SchoolType.page';
import CharterRouter from './subrouters/CharterRouter';
import CharterRoutes from './subrouters/CharterRouter/routes';
import IndependentRouter from './subrouters/IndependentRouter';
import IndependentRoutes from './subrouters/IndependentRouter/routes';
import PublicRouter from './subrouters/PublicRouter';
import PublicRoutes from './subrouters/PublicRouter/routes';
import ReligiousRouter from './subrouters/ReligiousRouter';
import ReligiousRoutes from './subrouters/ReligiousRouter/routes';

export default [
  {
    path: 'public-school',
    element: PublicRouter,
    routes: PublicRoutes,
  },
  {
    path: 'independent',
    element: IndependentRouter,
    routes: IndependentRoutes,
  },
  {
    path: 'charter',
    element: CharterRouter,
    routes: CharterRoutes,
  },
  {
    path: 'religious',
    element: ReligiousRouter,
    routes: ReligiousRoutes,
  },
  {
    index: true,
    element: SchoolTypePage,
  },
] as Array<ComplexRoute>;
