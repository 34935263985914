import { useCallback, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { SchoolGroupStructureType } from 'searchality-data';

export default function () {
  const [currentSchooldIndex, setCurrentSchooldIndex] = useState(0);

  const { t } = useTranslation();

  const {
    values: { schools, type, structureType, county: schoolGroupCounty },
  } = useFormState();

  const length = schools?.length;

  const previousStep = useCallback(() => {
    setCurrentSchooldIndex((prev) => (prev === 0 ? length - 1 : prev - 1));
  }, [length]);

  const nextStep = useCallback(() => {
    setCurrentSchooldIndex((prev) => (prev === length - 1 ? 0 : prev + 1));
  }, [length]);

  const list = useMemo(() => {
    if (!schools?.length) return [];
    const { city, name, postcode, state, website, county } =
      schools[currentSchooldIndex];

    const result = [
      {
        label: t('schoolName'),
        value: name,
      },
      {
        label: t('schoolWebsite'),
        value: website,
      },
      {
        label: t('cityAndState'),
        value: `${city} - ${state}`,
      },
      {
        label: t('zipcode'),
        value: postcode,
      },
    ];

    return type !== 'public-school' &&
      structureType === SchoolGroupStructureType.GROUP
      ? result
      : [
          ...result,
          {
            label: t('county'),
            value: (county || schoolGroupCounty)?.split(',')?.[0],
          },
        ];
  }, [currentSchooldIndex, schoolGroupCounty, schools, structureType, t, type]);

  return { list, currentSchooldIndex, nextStep, previousStep };
}
