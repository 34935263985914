import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddNewSchoolProps } from 'types';
import { Button } from 'ncoded-component-library';
import { useForm, useFormState } from 'react-final-form';
import GoBackButton from 'components/GoBackButton';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import PricingPlan from 'router/subrouters/Dashboard/components/PricingPlan';
import AddSchoolsPricing from 'router/subrouters/Dashboard/components/AddSchoolsPricing';
import AddSchoolsTotalPrice from 'router/subrouters/Dashboard/components/AddSchoolsTotalPrice';

import './Pricing.styles.scss';

const Pricing: React.FC<AddNewSchoolProps> = () => {
  const { t } = useTranslation();

  const { schools: userSchools, schoolGroup } = useAppSelector(
    authSelectors.selectUser,
  );

  const { structureType } = schoolGroup || {};

  const {
    submitting,
    values: { schools },
  } = useFormState();

  const { submit } = useForm();

  return (
    <form
      className="add-schools-pricing"
      onSubmit={(ev) => {
        ev.preventDefault();
        submit();
      }}
    >
      <GoBackButton to="../preview" />
      <div>
        <h1>{t('Pricing.title')}</h1>
        <p className="description">{t('Pricing.description')}</p>
      </div>
      {userSchools?.length < 2 ? (
        <PricingPlan
          structureType={structureType}
          hasOldPricingSystem={schoolGroup?.hasOldPricingSystem}
        />
      ) : (
        <AddSchoolsPricing
          hasOldPricingSystem={schoolGroup?.hasOldPricingSystem}
        />
      )}
      <AddSchoolsTotalPrice
        newSchools={schools}
        numberOfAlreadyCreatedSchools={userSchools?.length}
      />
      <p className="note">{t('AddNewSchool.Pricing.note')}</p>
      <Button type="submit" disabled={submitting}>
        {t('Pricing.submit')}
      </Button>
    </form>
  );
};

export default Pricing;
