import { getFileFromURL } from 'components/ImageCropper/utils';
import {
  MAX_ACADEMIC_SALARY,
  MAX_NON_ACADEMIC_SALARY,
} from 'constants/general';
import { User } from 'models/User';
import { Vacancy, VacancyFormValues } from 'models/Vacancy';
import {
  ApplicantStage,
  CurriculumExperience,
  Language,
  NonAcademicContractType,
  NonAcademicVacancyCategory,
  SchoolGroupStructureType,
  SubjectGradeLevel,
  VacancyType,
} from 'searchality-data';

/**
 * Covert Curriculum experience from form values to vacancy object
 * @param curriculumExperience d
 * @param curriculumExperienceOther
 * @returns
 */
const mapCurriculumExperienceIntoVacancy = (
  curriculumExperience: string,
  curriculumExperienceOther: string,
) => {
  return curriculumExperience === 'Other'
    ? curriculumExperienceOther
    : curriculumExperience;
};

/**
 * Map vacancy curriculumExperience value into form
 * @param curriculumExperience
 * @returns
 */
const mapCurriculumExperienceIntoForm = (curriculumExperience: string) => {
  const isValueFromEnum = Object.values(CurriculumExperience).find(
    (ce) => ce === curriculumExperience,
  );

  if (!isValueFromEnum) {
    return {
      curriculumExperience: 'Other',
      curriculumExperienceOther: curriculumExperience,
    };
  }
  return {
    curriculumExperience,
    curriculumExperienceOther: '',
  };
};

const teacherLanguageParseValue = (
  gradeLevel: string,
  teacherLanguage: string,
) => {
  if (
    gradeLevel === SubjectGradeLevel.TEACHER_ELEMENTARY.FOREIGN_LANGUAGE ||
    gradeLevel === SubjectGradeLevel.TEACHER_MIDDLE_SCHOOL.FOREIGN_LANGUAGE
  ) {
    if (
      Object.keys(Language).some(
        (key) => Language[key as keyof typeof Language] === teacherLanguage,
      )
    ) {
      return teacherLanguage;
    }
    return 'Other';
  }
  return undefined;
};

/**
 *
 */
const convertFormIntoVacancy = (
  formValues: VacancyFormValues,
  type: VacancyType,
  standaloneSchoolId?: string,
  user?: User,
) => {
  const { disableAdd, ...rest } = formValues || {};
  const {
    isMultipleSchools,
    schoolId,
    schoolIds,
    immediately,
    teacherLanguage,
    teacherLanguageOther,
    curriculumExperience,
    curriculumExperienceOther,
    yearsOfExperienceRequired,
    yearsOfCurriculumExperienceRequired,
    numberOfReferences,
    salaryInfo,
    applicantStageOther,
    studentContactDays,
    studentNonContactDays,
    certificates,
    schools,
    category,
    categoryOther,
    contractDaysDetails,
    contractDays,
    positionDescription,
    subjectGradeLevel,
    boardingRoleDescription,
    boardingPositionBenefits,
    ...restOfValues
  } = (rest as VacancyFormValues) || {};

  const vacancy: Partial<Vacancy> = {
    ...restOfValues,
    subjectGradeLevel,
  };

  if (
    boardingPositionBenefits &&
    JSON.stringify(boardingPositionBenefits) !== '{}'
  ) {
    vacancy.boardingPositionBenefits = {
      ...(boardingPositionBenefits.salary && {
        salary: parseInt(boardingPositionBenefits.salary),
      }),
      additionalBenefits: boardingPositionBenefits.additionalBenefits,
    };
  }

  vacancy.type = type;

  if (boardingRoleDescription?.length) {
    vacancy.boardingPositionRequirementsUrl = URL.createObjectURL(
      boardingRoleDescription[0],
    );
  }

  if (positionDescription?.length) {
    vacancy.positionDescriptionUrl = URL.createObjectURL(
      positionDescription[0],
    );
  }

  if (standaloneSchoolId) {
    vacancy.schoolIds = [standaloneSchoolId];
  } else {
    vacancy.schoolIds = isMultipleSchools ? schoolIds : [schoolId];
  }

  //POSITION_INFORMATION

  if (immediately) {
    vacancy.startDate = null;
  }

  if (
    subjectGradeLevel ===
      SubjectGradeLevel.TEACHER_ELEMENTARY.FOREIGN_LANGUAGE ||
    subjectGradeLevel ===
      SubjectGradeLevel.PARAPROFESSIONAL_ELEMENTARY.FOREIGN_LANGUAGE ||
    subjectGradeLevel ===
      SubjectGradeLevel.PARAPROFESSIONAL_MIDDLE_SCHOOL.FOREIGN_LANGUAGE ||
    subjectGradeLevel ===
      SubjectGradeLevel.TEACHER_MIDDLE_SCHOOL.FOREIGN_LANGUAGE
  ) {
    if (teacherLanguage === 'Other') {
      vacancy.teacherLanguage = teacherLanguageOther;
    } else {
      vacancy.teacherLanguage = teacherLanguage;
    }
  }

  //POSITION-REQUIREMENTS

  if (yearsOfExperienceRequired) {
    vacancy.yearsOfExperienceRequired = parseInt(yearsOfExperienceRequired);
  }

  if (category) {
    if (category !== NonAcademicVacancyCategory.OTHER) {
      vacancy.category = category;
    } else {
      vacancy.category = categoryOther;
    }
  }

  if (curriculumExperience) {
    vacancy.curriculumExperience = mapCurriculumExperienceIntoVacancy(
      curriculumExperience,
      curriculumExperienceOther,
    );

    if (vacancy.curriculumExperience !== CurriculumExperience.NO_EXPERIENCE) {
      if (yearsOfCurriculumExperienceRequired) {
        vacancy.yearsOfCurriculumExperienceRequired = parseInt(
          yearsOfCurriculumExperienceRequired,
        );
      }
    }
  }

  if (numberOfReferences) {
    vacancy.numberOfReferences = parseInt(numberOfReferences);
  }

  vacancy.certificates = certificates;

  if (user?.schoolGroup?.residentialType) {
    vacancy.residentialType = user?.schoolGroup?.residentialType;
  }

  //TEAACHER OFFERS

  if (studentContactDays) {
    vacancy.studentContactDays = parseInt(studentContactDays);
  }
  if (studentNonContactDays) {
    vacancy.studentNonContactDays = parseInt(studentNonContactDays);
  }

  if (contractDays) {
    vacancy.contractDays = contractDays;
  }

  if (contractDays === NonAcademicContractType.OTHER && contractDaysDetails) {
    vacancy.contractDaysDetails = contractDaysDetails;
  }

  if (salaryInfo?.from && salaryInfo?.to) {
    vacancy.salaryInfo = {
      from: parseInt(salaryInfo.from),
      to: parseInt(salaryInfo.to),
      applicantStage:
        salaryInfo.applicantStage === 'Other'
          ? applicantStageOther
          : salaryInfo.applicantStage,
    };
  }

  //SCHOOL-INFORMATION

  if (user?.schoolGroup.structureType === SchoolGroupStructureType.STANDALONE) {
    vacancy.schools = user?.schools;
  } else {
    vacancy.schools = user?.schools?.filter((el) => {
      if (isMultipleSchools) {
        return schoolIds?.some((id) => id === el._id);
      }
      return schoolId === el._id;
    });
  }

  return vacancy;
};

const isCategoryInEnum = (category: string) => {
  return Object.values(NonAcademicVacancyCategory).some(
    (value) => value === category,
  );
};

async function convertVacancyIntoForm(
  vacancy: Partial<Vacancy>,
  includeStatus = true,
) {
  const {
    _id,
    schoolIds,
    schools,
    status,
    startDate,
    positionDivision,
    positionTitle,
    positionType,
    isFullTimeEmployment,
    educationLevel,
    isCoverLetterRequired,
    isResumeRequired,
    numberOfReferences,
    yearsOfExperienceRequired,
    yearsOfCurriculumExperienceRequired,
    curriculumExperience,
    studentContactDays,
    studentNonContactDays,
    isPrivateHealthInsuranceOffered,
    privateHealthInsuranceOffered,
    isPensionContributionOffered,
    type,
    pensionContributionOffered,
    isFinancialSupportForRelocationOffered,
    financialSupportForRelocationOffered,
    isProfessionalDevelopmentOffered,
    professionalDevelopmentOffered,
    isTuitionForDependentsOffered,
    dependentsDevelopmentOffered,
    isAdditionalBenefitOffered,
    additionalBenefitOffered,
    isRightToWorkRequired,
    isSecondLanguageRequired,
    isPermanentContract,
    amountOfTimeRequired,
    lengthOfContract,
    roleDescription,
    subjectGradeLevel,
    teacherLanguage,
    requiredLanguages,
    salaryInfo,
    category,
    certificates,
    boardingPositionBenefits,
    boardingPositionRequirementStatus,
    boardingPositionRequirements,
    boardingPositionRequirementsUrl,
    contractDays,
    contractDaysDetails,
    matchingInformation,
    positionDescriptionUrl,
    isEducationPhilosophyRequired,
  } = vacancy;

  const isMultipleSchools = schoolIds?.length > 0;

  const salaryInfoTo =
    type === VacancyType.ACADEMIC
      ? MAX_ACADEMIC_SALARY
      : MAX_NON_ACADEMIC_SALARY;

  const formValues: VacancyFormValues = {
    _id,
    schools,
    isMultipleSchools,
    subjectGradeLevel,
    positionTitle,
    boardingPositionRequirementStatus,
    boardingPositionBenefits: {
      salary: boardingPositionBenefits?.salary.toString(),
      additionalBenefits: boardingPositionBenefits?.additionalBenefits,
    },
    positionType,
    positionDivision,
    startDate: startDate ?? undefined,
    immediately: startDate === null ? true : false,
    isFullTimeEmployment,
    amountOfTimeRequired,
    isPermanentContract,
    lengthOfContract,
    roleDescription,
    educationLevel,
    isCoverLetterRequired,
    isResumeRequired,
    numberOfReferences: numberOfReferences?.toString(),
    yearsOfExperienceRequired: yearsOfExperienceRequired?.toString(),
    yearsOfCurriculumExperienceRequired:
      yearsOfCurriculumExperienceRequired?.toString(),
    ...(curriculumExperience &&
      mapCurriculumExperienceIntoForm(curriculumExperience)),
    isSecondLanguageRequired,
    requiredLanguages,
    isRightToWorkRequired,
    studentContactDays: studentContactDays?.toString(),
    studentNonContactDays: studentNonContactDays?.toString(),
    isPrivateHealthInsuranceOffered,
    privateHealthInsuranceOffered,
    isPensionContributionOffered,
    pensionContributionOffered,
    isFinancialSupportForRelocationOffered,
    financialSupportForRelocationOffered,
    isProfessionalDevelopmentOffered,
    professionalDevelopmentOffered,
    isTuitionForDependentsOffered,
    dependentsDevelopmentOffered,
    isAdditionalBenefitOffered,
    additionalBenefitOffered,
    isEducationPhilosophyRequired,
    contractDays,
    matchingInformation: {
      isMatchingActive: matchingInformation?.isMatchingActive,
    },
    contractDaysDetails,
    certificates,
    teacherLanguage: teacherLanguageParseValue(
      subjectGradeLevel,
      teacherLanguage,
    ),
    teacherLanguageOther:
      teacherLanguageParseValue(subjectGradeLevel, teacherLanguage) === 'Other'
        ? teacherLanguage
        : undefined,
    salaryInfo: {
      from: salaryInfo?.from?.toString() ?? '0',
      to: salaryInfo?.to?.toString() ?? salaryInfoTo.toString(),
      ...(salaryInfo?.applicantStage && {
        applicantStage: Object.keys(ApplicantStage).some(
          (el) =>
            ApplicantStage[el as keyof typeof ApplicantStage] ===
            salaryInfo?.applicantStage,
        )
          ? salaryInfo?.applicantStage
          : 'Other',
      }),
    },
    applicantStageOther: Object.keys(ApplicantStage).some(
      (el) =>
        ApplicantStage[el as keyof typeof ApplicantStage] ===
        salaryInfo?.applicantStage,
    )
      ? undefined
      : salaryInfo?.applicantStage,
  };

  if (category) {
    formValues.category = isCategoryInEnum(category)
      ? (category as NonAcademicVacancyCategory)
      : NonAcademicVacancyCategory.OTHER;

    formValues.categoryOther = isCategoryInEnum(category)
      ? undefined
      : category;
  }

  if (boardingPositionRequirements) {
    formValues.boardingPositionRequirements = {
      roleDescription: boardingPositionRequirements.roleDescription,
    };

    if (boardingPositionRequirementsUrl) {
      const file = await getFileFromURL(
        boardingPositionRequirementsUrl,
        'Boarding position Description',
        'application/pdf',
      );

      formValues.boardingRoleDescription = [file];
    }
  }

  if (positionDescriptionUrl) {
    const file = await getFileFromURL(
      positionDescriptionUrl,
      'Position Description',
      'application/pdf',
    );
    formValues.positionDescription = [file];
  }

  if (isMultipleSchools) {
    formValues.schoolIds = schoolIds;
  } else {
    formValues.schoolId = schoolIds[0];
  }

  if (includeStatus) {
    formValues.status = status;
  }

  return formValues;
}

export default {
  convertFormIntoVacancy,
  convertVacancyIntoForm,
};
