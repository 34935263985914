import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { useFormState } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAttachEducationPhilosophyMutation,
  useAttachPublicationsMutation,
  useAttachTeacherCoverLetterMutation,
  useAttachTeacherPhotoMutation,
  useAttachTeacherResumeMutation,
  useLazyCreateTeacherApplicationQuery,
  usePatchTeacherApplicationMutation,
} from 'api/teacherApplications.api';
import { JobApplicationFormValues } from '../../types/JobApplicationFormValues';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import useAppDispatch from 'hooks/useAppDispatch';
import { ApplicationStatus, Role } from 'searchality-data';
import JobApplicationService from '../../services/JobApplication.service';
import { useGetOpenJobQuery } from 'api/jobs.api';
import { updateSubmitting } from 'store/slices/jobs.slice';
import utils from 'utils';

import './JobApplicationFormActionButtons.styles.scss';
import './JobApplicationFormActionButtons.styles.responsive.scss';

type JobApplicationFormActionButtonsProps = {
  className?: string;
  onPreview: () => void;
};

const JobApplicationFormActionButtons: React.FC<
  JobApplicationFormActionButtonsProps
> = (props) => {
  const { className, onPreview } = props;

  const navigate = useNavigate();
  const { applicationId, jobId } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { data: job } = useGetOpenJobQuery(jobId);

  const { curriculumExperience, type } = job;

  const user = useAppSelector(authSelectors.selectUser);

  const { _id: teacherId, role: teacherRole } = user || {};

  const classes = classNames('job-application-form-action-buttons', className);

  const { submitting, values } = useFormState();

  const [patchApplication] = usePatchTeacherApplicationMutation();
  const [attachCoverLetter] = useAttachTeacherCoverLetterMutation();
  const [attachPhoto] = useAttachTeacherPhotoMutation();
  const [attachEducationPhilosophy] = useAttachEducationPhilosophyMutation();
  const [createTeacherApplication] = useLazyCreateTeacherApplicationQuery();
  const [attachPublications] = useAttachPublicationsMutation();
  const [attachResume] = useAttachTeacherResumeMutation();

  const handleSaveAsDraft = useCallback(async () => {
    dispatch(updateSubmitting(true));
    if (applicationId) {
      const data = JobApplicationService.convertFormIntoApplication(
        values as JobApplicationFormValues,
        curriculumExperience,
        utils.mapVacancyTypeToApplicationType(type),
      );

      const {
        personalInformation: { applicantPhoto },
        yourProfile: { coverLetter, educationPhilosophy, resume },
        publications,
      } = values;

      patchApplication({ data, teacherId, applicationId })
        .unwrap()
        .then(async (patchResult) => {
          if (applicantPhoto?.length) {
            await attachPhoto({
              teacherId: teacherId,
              applicationId: patchResult._id,
              imageFile: applicantPhoto[0],
            });
          }
          if (coverLetter?.length) {
            await attachCoverLetter({
              teacherId: teacherId,
              applicationId: patchResult._id,
              coverLetterFile: coverLetter[0],
            });
          }
          if (educationPhilosophy?.length) {
            await attachEducationPhilosophy({
              teacherId: teacherId,
              applicationId: patchResult._id,
              educationPhilosophyFile: educationPhilosophy[0],
            });

            if (resume?.length) {
              await attachResume({
                teacherId: teacherId,
                applicationId: patchResult._id,
                resumeFile: resume[0],
              });
            }

            if (publications?.length) {
              await attachPublications({
                teacherId: user?._id,
                applicationId,
                publications,
              });
            }

            dispatch(popSuccess(t('applicationDraftSuccess')));
          }
          navigate(`/applications/${patchResult._id}`);
        })
        .catch((error) => dispatch(popError(error.message)))
        .finally(() => {
          dispatch(updateSubmitting(false));
        });
    } else {
      const data = JobApplicationService.convertFormIntoApplication(
        values as JobApplicationFormValues,
        curriculumExperience,
        utils.mapVacancyTypeToApplicationType(type),
      );

      const {
        personalInformation: { applicantPhoto },
        yourProfile: { coverLetter, educationPhilosophy, resume },
        publications,
      } = values;
      createTeacherApplication({
        data: { ...data, vacancyId: jobId, status: ApplicationStatus.DRAFT },
        teacherId,
      })
        .unwrap()
        .then(async (patchResult) => {
          if (applicantPhoto?.length) {
            await attachPhoto({
              teacherId: teacherId,
              applicationId: patchResult._id,
              imageFile: applicantPhoto[0],
            });
          }
          if (coverLetter?.length) {
            await attachCoverLetter({
              teacherId,
              applicationId: patchResult._id,
              coverLetterFile: coverLetter[0],
            });
          }
          if (educationPhilosophy?.length) {
            await attachEducationPhilosophy({
              teacherId: teacherId,
              applicationId: patchResult._id,
              educationPhilosophyFile: educationPhilosophy[0],
            });

            if (resume?.length) {
              await attachResume({
                teacherId: teacherId,
                applicationId: patchResult._id,
                resumeFile: resume[0],
              });
            }

            if (publications?.length) {
              await attachPublications({
                teacherId: user?._id,
                applicationId,
                publications,
              });
            }

            dispatch(popSuccess(t('applicationDraftSuccess')));
          }
          navigate(`/applications/${patchResult._id}`);
        })
        .catch((error) => dispatch(popError(error.message)))
        .finally(() => {
          dispatch(updateSubmitting(false));
        });
    }
  }, [
    dispatch,
    applicationId,
    values,
    curriculumExperience,
    type,
    patchApplication,
    teacherId,
    navigate,
    attachPhoto,
    attachCoverLetter,
    attachEducationPhilosophy,
    t,
    attachResume,
    attachPublications,
    user?._id,
    createTeacherApplication,
    jobId,
  ]);

  return (
    <div className={classes}>
      {teacherId && teacherRole === Role.Teacher && (
        <Button variant="outline" onClick={handleSaveAsDraft}>
          {t('saveDraft')}
        </Button>
      )}
      <Button onClick={onPreview} disabled={submitting}>
        {t('previewApplication')}
      </Button>
    </div>
  );
};

export default JobApplicationFormActionButtons;
