import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NonAcademicContractType } from 'searchality-data';

export type NonAcademicContractTermsProps = {
  contractDays: NonAcademicContractType;
  contractDaysDetails: string;
};

const NonAcademicContractTerms: React.FC<NonAcademicContractTermsProps> = (
  props,
) => {
  const { t } = useTranslation();

  const { contractDays, contractDaysDetails } = props;

  const shouldRender = useMemo(
    () => contractDays || contractDaysDetails,
    [contractDays, contractDaysDetails],
  );

  const shouldRenderContractDays = useMemo(
    () =>
      contractDays !== NonAcademicContractType.OTHER || !contractDaysDetails,
    [contractDays, contractDaysDetails],
  );

  if (!shouldRender) {
    return null;
  }

  return (
    <>
      {
        <p>
          <b>{t('jobPreview.ContractTerms')}</b>
          {shouldRenderContractDays ? contractDays : contractDaysDetails}
        </p>
      }
    </>
  );
};

export default NonAcademicContractTerms;
