import { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { ReviewCardItemList } from 'types';
import { numberPriceToCurrencyString } from 'utils';
import { useSchoolPrices } from '../../Pricing/hooks/useSchoolPrices';
import { SchoolGroupStructureType } from 'searchality-data';
import useSearchalityPricing from 'hooks/useSearchalityPricing';

export default function () {
  const {
    values: { structureType, schools },
  } = useFormState();

  const { WEBSITE_INTEGRATION_PRICE } = useSearchalityPricing(false);

  const { schoolPrice, websiteAdditionalPrice } = useSchoolPrices(
    structureType,
    schools,
    false,
  );

  const { t } = useTranslation();

  const list: ReviewCardItemList = useMemo(() => {
    if (structureType === SchoolGroupStructureType.STANDALONE) {
      return [
        {
          label: t('standaloneSet'),
          value: t('standaloneSchoolReviewPrice', {
            price: numberPriceToCurrencyString(schoolPrice),
          }),
          description: t('standaloneSchoolReviewPriceDescription'),
        },
        {
          label: t('Pricing.Preview.setupFee'),
          value: numberPriceToCurrencyString(WEBSITE_INTEGRATION_PRICE),
          description: t('standaloneSchoolWebsitePriceDescription'),
        },
      ];
    }

    return [
      {
        label: t('groupDisctrictSet'),
        subtitle: t('Pricing.Preview.xSchools', { count: schools?.length }),
        value: t('publicSchoolGroupPrice', {
          price: numberPriceToCurrencyString(schoolPrice),
        }),
        description: t('publicSchoolGroupPriceDescription'),
      },
      {
        label: t('Pricing.Preview.setupFee'),
        value: numberPriceToCurrencyString(WEBSITE_INTEGRATION_PRICE),
        description: t('standaloneSchoolWebsitePriceDescription'),
      },
      ...(schools?.length > 2
        ? [
            {
              label: t('websiteIntegration'),
              value: numberPriceToCurrencyString(websiteAdditionalPrice),
              description: t('publicSchoolGroupWebsitePriceDescription'),
            },
          ]
        : []),
    ];
  }, [
    WEBSITE_INTEGRATION_PRICE,
    schoolPrice,
    schools?.length,
    structureType,
    t,
    websiteAdditionalPrice,
  ]);

  return list;
}
