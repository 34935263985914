import { createApi } from '@reduxjs/toolkit/query/react';
import { User } from 'models/User';
import { Vacancy, VacancyCandidatesFilterInfo } from 'models/Vacancy';
import { FillVacancyMethod, VacancyStatus } from 'searchality-data';
import { PaginatedResponse, Params } from 'types';
import axiosBaseQuery from './axiosBaseQuery';

export const vacanciesApi = createApi({
  reducerPath: 'vacanciesApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Vacancies', 'PotentialMembers', 'Vacancy', 'CandidatesInfo'],
  endpoints: (builder) => ({
    getVacancies: builder.query<PaginatedResponse<Vacancy>, unknown>({
      query: (params?: Params) => ({
        url: 'vacancies',
        method: 'get',
        params: {
          ...params,
          $populate: ['schools', 'schoolGroup'],
        },
      }),
      keepUnusedDataFor: 10,
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(
                ({ _id }) => ({ type: 'Vacancies', id: _id } as const),
              ),
              { type: 'Vacancies', id: 'LIST' },
            ]
          : [{ type: 'Vacancies', id: 'LIST' }],
    }),
    addVacancy: builder.mutation<Vacancy, unknown>({
      query: (data) => {
        return {
          url: `vacancies`,
          method: 'post',
          data,
        };
      },
      invalidatesTags: ['Vacancies'],
    }),
    draftVacancy: builder.mutation<Vacancy, unknown>({
      query: (data) => {
        return {
          url: `vacancies/draft`,
          method: 'post',
          data,
        };
      },
      invalidatesTags: ['Vacancies'],
    }),
    deleteVacancy: builder.mutation<
      { deleted: boolean },
      {
        id: string;
        reason: string;
      }
    >({
      query: ({ id, reason }) => {
        return {
          url: `/vacancies/${id}`,
          method: 'delete',
          data: {
            reason,
          },
        };
      },
      invalidatesTags: ['Vacancies'],
    }),
    candidateFiltersInfo: builder.query<VacancyCandidatesFilterInfo[], string>({
      query: (vacancyId) => {
        return {
          url: `vacancies/${vacancyId}/candidates/info`,
          method: 'get',
        };
      },
      providesTags: ['CandidatesInfo'],
    }),
    fillVacancy: builder.mutation<
      Vacancy,
      {
        fillMethod: FillVacancyMethod;
        vacancyId: string;
        applicantIds?: string[];
        shouldRemainOpen?: boolean;
      }
    >({
      query: ({
        vacancyId,
        fillMethod,
        applicantIds,
        shouldRemainOpen = false,
      }) => {
        return {
          url: `vacancies/${vacancyId}/fill`,
          method: 'post',
          data: {
            fillMethod,
            applicantIds,
            shouldRemainOpen,
          },
        };
      },
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { vacancyId } = requestInfo;
          dispatch(
            vacanciesApi.util.updateQueryData('getVacancy', vacancyId, () => {
              return data;
            }),
          );
        });
      },
      invalidatesTags: ['Vacancies', 'CandidatesInfo'],
    }),
    updateVacancy: builder.mutation<Vacancy, Omit<Vacancy, 'status'>>({
      query: (data) => {
        return {
          url: `vacancies/${data._id}`,
          method: 'patch',
          data,
        };
      },
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { _id: vacancyId } = requestInfo;
          dispatch(
            vacanciesApi.util.updateQueryData('getVacancy', vacancyId, () => {
              return data;
            }),
          );
        });
      },
      invalidatesTags: ['Vacancies'],
    }),
    updateOngoingVacancy: builder.mutation<
      Vacancy,
      Pick<
        Vacancy,
        | 'roleDescription'
        | '_id'
        | 'boardingPositionRequirements'
        | 'positionTitle'
      >
    >({
      query: (data) => {
        return {
          url: `vacancies/${data._id}/ongoing`,
          method: 'patch',
          data,
        };
      },
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { _id: vacancyId } = requestInfo;
          dispatch(
            vacanciesApi.util.updateQueryData('getVacancy', vacancyId, () => {
              return data;
            }),
          );
        });
      },
      invalidatesTags: ['Vacancies'],
    }),
    publishVacancy: builder.mutation<Vacancy, string>({
      query: (vacancyId) => {
        return {
          url: `vacancies/${vacancyId}/publish`,
          method: 'post',
        };
      },
      async onQueryStarted(vacancyId, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          dispatch(
            vacanciesApi.util.updateQueryData('getVacancy', vacancyId, () => {
              return data;
            }),
          );
        });
      },
      invalidatesTags: ['Vacancies'],
    }),
    archiveVacancy: builder.mutation<Vacancy, string>({
      query: (vacancyId) => {
        return {
          url: `/vacancies/${vacancyId}/archive`,
          method: 'post',
          data: {
            vacancyId,
            status: VacancyStatus.ARCHIVED,
          },
        };
      },
      async onQueryStarted(vacancyId, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          dispatch(
            vacanciesApi.util.updateQueryData('getVacancy', vacancyId, () => {
              return data;
            }),
          );
        });
      },
      invalidatesTags: ['Vacancies'],
    }),
    getVacancy: builder.query<Vacancy, string>({
      query: (id) => {
        return {
          url: `vacancies/${id}`,
          method: 'get',
          params: {
            $populate: [
              'schools',
              'schoolGroup',
              'creator',
              'updater',
              'publisher',
            ],
          },
        };
      },
      providesTags: ['Vacancy'],
    }),
    updateExpiredVacancy: builder.mutation<
      Vacancy,
      { _id: string; startDate: string }
    >({
      query: ({ _id, startDate }) => {
        return {
          url: `vacancies/${_id}/expired`,
          method: 'patch',
          data: { startDate },
        };
      },
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { _id: vacancyId } = requestInfo;
          dispatch(
            vacanciesApi.util.updateQueryData('getVacancy', vacancyId, () => {
              return data;
            }),
          );
        });
      },
      invalidatesTags: ['Vacancies'],
    }),

    getVacanciesFilters: builder.query<any, void>({
      query: () => {
        return {
          url: 'vacancies/filters',
          method: 'get',
        };
      },
      providesTags: ['Vacancies'],
    }),

    shareVacancy: builder.mutation<
      void,
      { vacancyId: string; memberIds: string[] }
    >({
      query: (data) => {
        return {
          url: 'school-users/share-vacancy',
          method: 'post',
          data,
        };
      },
      invalidatesTags: ['PotentialMembers'],
    }),
    closeVacancy: builder.mutation<
      void,
      { vacancyId: string; shouldNotifyApplicants?: boolean; message?: string }
    >({
      query: ({ vacancyId, ...data }) => {
        return {
          url: `vacancies/${vacancyId}/close`,
          method: 'post',
          data,
        };
      },
      invalidatesTags: ['Vacancies', 'Vacancy'],
    }),
    reopenVacancy: builder.mutation<void, { vacancyId: string }>({
      query: ({ vacancyId }) => {
        return {
          url: `vacancies/${vacancyId}/reopen`,
          method: 'post',
        };
      },
      invalidatesTags: ['Vacancies', 'Vacancy'],
    }),
    shareVacancyFromCandidate: builder.mutation<
      void,
      { vacancyId: string; memberIds: string[]; candidateId: string }
    >({
      query: (data) => {
        return {
          url: 'school-users/candidate',
          method: 'post',
          data,
        };
      },
      invalidatesTags: ['PotentialMembers'],
    }),

    potentialInvitees: builder.query<User[], string>({
      query: (vacancyId) => {
        return {
          url: `school-users/vacancies/${vacancyId}/potential-invitees`,
          method: 'get',
          params: {
            $populate: ['schools'],
          },
        };
      },
      providesTags: ['PotentialMembers'],
    }),

    vacancyMembers: builder.query<
      User[],
      {
        vacancyId: string;
        params?: Partial<Params>;
      }
    >({
      query: ({ vacancyId, params }) => {
        return {
          url: `school-users/vacancies/${vacancyId}/current-members`,
          method: 'get',
          params,
        };
      },
      providesTags: ['PotentialMembers'],
    }),

    generateMagicLink: builder.mutation<
      { url: string },
      { vacancyId: string; candidateId?: string }
    >({
      query: ({ vacancyId, candidateId }) => {
        return {
          url: 'school-users/vacancies/generate-magic-link',
          method: 'post',
          data: {
            vacancyId,
            candidateId,
          },
        };
      },
    }),
    confirmMagicLink: builder.query<
      User,
      { token: string; vacancyId?: string; candidateId?: string }
    >({
      query: (data) => {
        return {
          url: 'school-users/confirm-magic-link',
          method: 'post',
          data,
          params: {
            $populate: ['schoolGroup', 'schoolGroup.schools', 'schools'],
          },
        };
      },
    }),
    switchMatching: builder.mutation<unknown, string>({
      query: (vacancyId) => {
        return {
          url: `vacancies/${vacancyId}/switch-matching`,
          method: 'patch',
        };
      },
      invalidatesTags: ['Vacancy'],
    }),
    attachPositionDescription: builder.mutation<
      Vacancy,
      { vacancyId: string; positionDescriptionFile: File }
    >({
      query: (requestInfo) => {
        const { vacancyId, positionDescriptionFile } = requestInfo;
        const formData = new FormData();
        formData.append('file', positionDescriptionFile);

        return {
          url: `vacancies/${vacancyId}/position-description`,
          method: 'post',
          data: formData,
          params: {
            $populate: ['schools', 'schoolGroup'],
          },
        };
      },
    }),
    deletePositionDescription: builder.mutation<Vacancy, { vacancyId: string }>(
      {
        query: (requestInfo) => {
          const { vacancyId } = requestInfo;
          return {
            url: `vacancies/${vacancyId}/position-description`,
            method: 'delete',
            params: {
              $populate: ['schools', 'schoolGroup'],
            },
          };
        },
      },
    ),
    attachBoardingPositionDescription: builder.mutation<
      Vacancy,
      { vacancyId: string; boardingPositionDescriptionFile: File }
    >({
      query: (requestInfo) => {
        const { vacancyId, boardingPositionDescriptionFile } = requestInfo;
        const formData = new FormData();
        formData.append('file', boardingPositionDescriptionFile);

        return {
          url: `vacancies/${vacancyId}/boarding-role-description`,
          method: 'post',
          data: formData,
          params: {
            $populate: ['schools', 'schoolGroup'],
          },
        };
      },
    }),
    deleteBoardingPositionDescription: builder.mutation<
      Vacancy,
      { vacancyId: string }
    >({
      query: (requestInfo) => {
        const { vacancyId } = requestInfo;
        return {
          url: `vacancies/${vacancyId}/boarding-role-description`,
          method: 'delete',
          params: {
            $populate: ['schools', 'schoolGroup'],
          },
        };
      },
    }),
  }),
});

export const {
  useGetVacanciesQuery,
  useAddVacancyMutation,
  usePublishVacancyMutation,
  useArchiveVacancyMutation,
  useDeleteVacancyMutation,
  useFillVacancyMutation,
  useUpdateVacancyMutation,
  useGetVacancyQuery,
  useUpdateExpiredVacancyMutation,
  useDraftVacancyMutation,
  useGetVacanciesFiltersQuery,
  useShareVacancyMutation,
  usePotentialInviteesQuery,
  useVacancyMembersQuery,
  useGenerateMagicLinkMutation,
  useLazyVacancyMembersQuery,
  useLazyPotentialInviteesQuery,
  useLazyConfirmMagicLinkQuery,
  useLazyGetVacancyQuery,
  useShareVacancyFromCandidateMutation,
  useSwitchMatchingMutation,
  useDeletePositionDescriptionMutation,
  useAttachPositionDescriptionMutation,
  useAttachBoardingPositionDescriptionMutation,
  useDeleteBoardingPositionDescriptionMutation,
  useCloseVacancyMutation,
  useReopenVacancyMutation,
  useUpdateOngoingVacancyMutation,
  useCandidateFiltersInfoQuery,
} = vacanciesApi;
