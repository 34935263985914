import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SchoolGroupStructureType, SchoolGroupType } from 'searchality-data';
import RadioButtonLabel from 'components/RadioButtonLabel';
import { schoolSubTypePublicOptions } from 'constants/schoolTypeOptions';
import { Field, useForm, useFormState } from 'react-final-form';
import RadioGroupField from 'components/RadioGroupField';
import { required } from 'validations';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import { CreateSchoolValues } from 'types';
import { CREATE_SCHOOL_WIZARD } from 'enums';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';
import GoBackButton from 'components/GoBackButton';
import useSchoolInformationRoute from 'router/subrouters/Dashboard/subrouters/CreateSchool/hooks/useSchoolInformationRoute';

import './PublicSchoolSubtypeForm.styles.scss';

type PublicSchoolSubtypeFormProps = {
  className?: string;
};

const PublicSchoolSubtypeForm: React.FC<PublicSchoolSubtypeFormProps> = (
  props,
) => {
  const { className } = props;

  const { completeStep, resetCompletedSteps } = useContext(
    CreateSchoolWizardContext,
  );

  const { t } = useTranslation();

  const { submit, reset, getState } = useForm();

  const navigate = useNavigate();

  const { values } = useFormState();

  const { type, structureType, residentialType } = values as CreateSchoolValues;

  const options = useMemo(
    () =>
      Object.values(SchoolGroupStructureType).map((el) => ({
        label: (
          <RadioButtonLabel label={t(schoolSubTypePublicOptions[el].label)} />
        ),
        value: el,
      })),
    [t],
  );

  const classes = classNames('public-school-subtype-form', className);

  const navigateRoute = useSchoolInformationRoute(false);

  const resetForm = useCallback(() => {
    reset({ type, structureType, residentialType });
    resetCompletedSteps([CREATE_SCHOOL_WIZARD.SCHOOL_TYPE]);
  }, [reset, resetCompletedSteps, residentialType, structureType, type]);

  return (
    <form
      className={classes}
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid, dirtyFields } = getState();
        if (!valid) return submit();
        if (dirtyFields.structureType) {
          resetForm();
        }
        type !== SchoolGroupType.RELIGIOUS &&
          completeStep(CREATE_SCHOOL_WIZARD.SCHOOL_TYPE);
        navigate(`/create-school/${navigateRoute}`);
      }}
    >
      <GoBackButton to="../" />
      <h1>{t('PublicSchoolSubtypeForm.title')}</h1>
      <p className="description">{t('answerForSpecificSchool')}</p>
      <Field
        name="structureType"
        component={RadioGroupField}
        options={options}
        validate={required()}
        wrapperClassName="public-school-subtype-form__radio-group"
      />
      <Button type="submit">{t('continue')}</Button>
    </form>
  );
};

export default PublicSchoolSubtypeForm;
