import React, { useCallback, useState } from 'react';
import MatchingSchoolsContext from './MatchingSchools.context';

type MatchingSchoolsProviderProps = {
  children: JSX.Element;
};

const MatchingSchoolsProvider: React.FC<MatchingSchoolsProviderProps> = (
  props,
) => {
  const { children } = props;

  const [schoolsForMatching, setSchoolsForMatching] = useState<string[]>([]);

  const manageSchoolsForMatching = useCallback(
    (id: string) =>
      setSchoolsForMatching((prev) =>
        prev.includes(id)
          ? prev.filter((schoolId) => schoolId !== id)
          : [...prev, id],
      ),
    [],
  );

  const setSchoolForMatching = useCallback(
    (id: string) => setSchoolsForMatching([id]),
    [],
  );

  const clearSchools = useCallback(() => setSchoolsForMatching([]), []);

  return (
    <MatchingSchoolsContext.Provider
      value={{
        schoolsForMatching,
        manageSchoolsForMatching,
        clearSchools,
        setSchoolForMatching,
      }}
    >
      {children}
    </MatchingSchoolsContext.Provider>
  );
};

export default MatchingSchoolsProvider;
