import React, { useMemo } from 'react';
import classNames from 'classnames';
import { RequestedDocument } from 'models/Candidate';
import UploadedDocument from 'router/subrouters/Dashboard/components/UploadedDocument';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import { RequestedDocumentTitle } from 'searchality-data';

import './CandidateUploadedDocuments.styles.scss';

type CandidateUploadedDocumentsProps = {
  className?: string;
  documentRequests: RequestedDocument[];
};

const CandidateUploadedDocuments: React.FC<CandidateUploadedDocumentsProps> = (
  props,
) => {
  const { className, documentRequests } = props;

  const { t } = useTranslation();

  const baseClass = 'candidate-uploaded-documents';
  const classes = classNames(baseClass, className);

  const uploadedDocuments = useMemo(
    () =>
      documentRequests?.flatMap(({ title, files, description }) =>
        files.map((file) => (
          <UploadedDocument
            name={t(
              `RequestDocumentOptions.Title.${utils.getKeyByValue(
                RequestedDocumentTitle,
                title,
              )}`,
            )}
            description={description}
            key={file._id}
            document={file}
          />
        )),
      ),
    [documentRequests, t],
  );

  return (
    <div className={classes}>
      <p className={`${baseClass}__title`}>{t('uploadedDocuments')}</p>
      {!!uploadedDocuments?.length && (
        <div className={`${baseClass}__links`}>{uploadedDocuments}</div>
      )}
    </div>
  );
};

export default CandidateUploadedDocuments;
