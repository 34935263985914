import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import RadioButtonLabel from 'components/RadioButtonLabel';
import { SchoolGroupStructureType, SchoolGroupType } from 'searchality-data';
import { schoolSubTypeIndependentOptions } from 'constants/schoolTypeOptions';
import { Field, useForm, useFormState } from 'react-final-form';
import RadioGroupField from 'components/RadioGroupField';
import { required } from 'validations';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import { CREATE_SCHOOL_WIZARD } from 'enums';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';
import GoBackButton from 'components/GoBackButton';
import utils from 'utils';

import './IndependentCharterSubtypeForm.styles.scss';

type IndependentCharterSubtypeFormProps = {
  className?: string;
};

const IndependentCharterSubtypeForm: React.FC<
  IndependentCharterSubtypeFormProps
> = (props) => {
  const { className } = props;

  const { completeStep, resetCompletedSteps } = useContext(
    CreateSchoolWizardContext,
  );

  const { t } = useTranslation();

  const { submit, getState, reset } = useForm();

  const {
    values: { type, structureType, residentialType },
  } = useFormState();

  const navigate = useNavigate();

  const options = useMemo(
    () =>
      Object.values(SchoolGroupStructureType).map((el) => ({
        label: (
          <RadioButtonLabel
            label={t(schoolSubTypeIndependentOptions[el].label)}
          />
        ),
        value: el,
      })),
    [t],
  );

  const resetForm = useCallback(() => {
    reset({ type, structureType, residentialType });
    resetCompletedSteps([CREATE_SCHOOL_WIZARD.SCHOOL_TYPE]);
  }, [reset, resetCompletedSteps, residentialType, structureType, type]);

  const classes = classNames('independent-charter-subtype-form', className);

  return (
    <form
      className={classes}
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid, dirtyFields } = getState();
        if (!valid) return submit();
        if (type === SchoolGroupType.RELIGIOUS) {
          return navigate(
            `/create-school/school-type/${type}/residential-type/${utils.mapResidentialTypeToRoute(
              residentialType,
            )}/religion-type`,
          );
        }
        if (dirtyFields.structureType) {
          resetForm();
        }
        type !== SchoolGroupType.RELIGIOUS &&
          completeStep(CREATE_SCHOOL_WIZARD.SCHOOL_TYPE);
        navigate(
          `/create-school/${type}/residential-type/${utils.mapResidentialTypeToRoute(
            residentialType,
          )}/school-information/${structureType}`,
        );
      }}
    >
      <GoBackButton to="../" />
      <h1>
        {t('IndependentCharterSubtypeForm.title', {
          type: t(`${type}Small`),
        })}
      </h1>
      <p className="description">{t('independentCharterSchoolNote')}</p>
      <Field
        name="structureType"
        component={RadioGroupField}
        options={options}
        validate={required()}
        wrapperClassName="independent-charter-subtype-form__radio-group"
      />
      <Button type="submit">{t('continue')}</Button>
    </form>
  );
};

export default IndependentCharterSubtypeForm;
