import React from 'react';
import ContentLoader from 'react-content-loader';

export const FilterSelectPlaceholder = (props: any) => {
  return (
    <ContentLoader
      {...props}
      backgroundColor="#fcfcfc"
      foregroundColor="#f6f6f6"
      height="48px"
      className="placeholder-filter-border"
      viewBox={`0 0 ${props?.width.slice(0, -2)} 46`}
    >
      <rect x="0" y="0" rx="8" ry="8" width="100%" height="100%" />
    </ContentLoader>
  );
};
