import React from 'react';
import ReviewCardWrapper from 'router/subrouters/Dashboard/subrouters/CreateSchool/pages/Review/components/ReviewCardWrapper';
import useSchoolGroupInformation from '../../hooks/useSchoolGroupInformation';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SchoolGroupInformation: React.FC = () => {
  const list = useSchoolGroupInformation();

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <ReviewCardWrapper
      list={list}
      title={t('myAccount.GroupSettings.schoolGroupInformation')}
      onEdit={() => navigate('school-group')}
    />
  );
};

export default SchoolGroupInformation;
