import React, { useCallback, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from 'icons/Add.icon.svg';
import { ReactComponent as DeleteIcon } from 'icons/CloseDialog.icon.svg';
import Tag from 'components/Tag';
import { Button } from 'ncoded-component-library';
import usePublicSchoolType from 'hooks/usePublicSchoolType';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import GoBackButton from 'components/GoBackButton';
import { School } from 'models/School';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { setSchools } from 'store/slices/addNewSchool.slice';

import './SchoolsPreview.styles.scss';

const SchoolsPreview: React.FC = () => {
  const {
    schoolGroup: { schools: oldSchools, schoolCount },
  } = useAppSelector(authSelectors.selectUser);

  const {
    values: { schools },
  } = useFormState();

  const { change } = useForm();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const disabledAddNewSchool = useMemo(
    () => schools?.length + oldSchools?.length === schoolCount,
    [oldSchools?.length, schoolCount, schools?.length],
  );

  const handleRemoveSchool = useCallback(
    (school: School) => {
      const newSchools = schools.filter((el: School) => el !== school);
      change('schools', newSchools);
      dispatch(setSchools(newSchools));
    },
    [change, dispatch, schools],
  );

  const publichSchoolType = usePublicSchoolType();

  return (
    <form className="schools-preview">
      <GoBackButton to="/my-account/manage-subscription" />
      <div>
        <h1>
          {t('District.SchoolsPreview.title', { type: publichSchoolType })}
        </h1>
        <p className="description">
          {t('District.SchoolsPreview.description', {
            type: publichSchoolType,
          })}
        </p>
      </div>
      {schools.length > 0 && (
        <div className="schools-preview__school-tags">
          {schools.map((school: School) => (
            <Tag key={school.name} text={school.name} className="school-tag">
              <button
                onClick={() => handleRemoveSchool(school)}
                className="svg-button-wrapper"
              >
                <DeleteIcon />
              </button>
            </Tag>
          ))}
        </div>
      )}

      <Button
        variant="outline"
        icon={<AddIcon />}
        iconPosition="right"
        onClick={() => navigate('../school')}
        disabled={disabledAddNewSchool}
      >
        {t('District.SchoolsPreview.addAnotherSchool')}
      </Button>
      {disabledAddNewSchool && (
        <p className="description error">{t('maxNumberOfSchoolsError')}</p>
      )}

      <Button onClick={() => navigate('../pricing')}>{t('continue')}</Button>
    </form>
  );
};

export default SchoolsPreview;
