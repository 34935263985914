import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import CheckoutProvider from 'providers/Checkout';
import CheckoutPayment from '../CheckoutPayment/CheckoutPayment.component';
import { useLazySubscribeQuery } from 'api/payment.api';
import useAppDispatch from 'hooks/useAppDispatch';
import PaymentMethodsContext from 'providers/PaymentMethods/PaymentMethods.context';
import ValueContext from 'providers/ValueContext/Value.context';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import usePreviewSubscriptionPrice from '../../hooks/usePreviewSubscriptionPrice';
import { useFormState } from 'react-final-form';
import api from 'api';
import {
  updateClientSecretValue,
  updateCouponValue,
} from 'store/slices/payment.slice';
import { popServerError } from 'store/slices/popNotifications.slice';
import confirm from 'modules/confirm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MatchingSchoolsContext from 'providers/MatchingSchools/MatchingSchools.context';
import { CheckoutCategory } from '../../types';

const CheckoutPaymentSubscribtionWrapper: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState('');
  const [checked, setChecked] = useState('');

  const { schoolsForMatching } = useContext(MatchingSchoolsContext);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [subscribe] = useLazySubscribeQuery();
  const dispatch = useAppDispatch();

  const { paymentInfo } = useContext(PaymentMethodsContext);
  const { paymentMethods } = paymentInfo || {};

  const promoCode = useAppSelector(({ payment }) => payment.coupon);

  const { selectedIds } = useContext(ValueContext);
  const {
    schoolGroup: { schools },
  } = useAppSelector(authSelectors.selectUser);

  const { data, isLoading: previewLoading } = usePreviewSubscriptionPrice();
  const { values } = useFormState();

  const onSubscribe = useCallback(async () => {
    const schoolsForPricingPreview = schools?.map(({ _id }) => ({
      id: _id,
      optOutIntegration: !selectedIds?.includes(_id),
      hasMatchingSubscription: schoolsForMatching?.includes(_id),
    }));

    const subscribeRequest = async () => {
      setIsLoading(true);
      if (purchaseOrder) {
        try {
          await api.schools.addPaymentInfo({ purchaseOrder });
        } catch (error) {
          console.error(error);
        }
      }

      subscribe({
        schools: schoolsForPricingPreview,
        paymentMethodId: checked,
        promoCode,
      })
        .unwrap()
        .then((res) => {
          if (res?.clientSecret) {
            dispatch(updateClientSecretValue(res?.clientSecret));
          } else {
            dispatch(updateCouponValue(undefined));
            navigate('/my-account/subscription');
          }
        })
        .catch((e) => {
          dispatch(popServerError(e));
        })
        .finally(() => setIsLoading(false));
    };

    const handleSubscribe = async () => {
      if (!data?.isPromoCodeValid && values?.discountCode?.length) {
        return await confirm({
          title: t('ConfirmationModalPromoCode.title'),
          content: <p>{t('ConfirmationModalPromoCode.description')}</p>,
          onSubmit: subscribeRequest,
        });
      } else {
        await subscribeRequest();
      }
    };

    if (
      (schools?.length > 2 && selectedIds?.length < 2) ||
      (schools?.length <= 2 && schools?.length !== selectedIds?.length)
    ) {
      return await confirm({
        title: t('OrderSummary.subscribeConfirmationTitle'),
        content: <p>{t('OrderSummary.subscribeConfirmationDescription')}</p>,
        onSubmit: handleSubscribe,
      });
    } else {
      await handleSubscribe();
    }
  }, [
    checked,
    data?.isPromoCodeValid,
    dispatch,
    navigate,
    promoCode,
    purchaseOrder,
    schools,
    selectedIds,
    subscribe,
    t,
    values?.discountCode?.length,
    schoolsForMatching,
  ]);

  const checkedItem = useMemo(
    () => paymentMethods?.find(({ id }) => id === checked),
    [checked, paymentMethods],
  );

  useEffect(() => {
    if (checked) return;

    const defaultId =
      paymentMethods?.find(({ isDefault }) => isDefault)?.id || '';

    if (defaultId) {
      setChecked(defaultId);
      return;
    }

    if (paymentMethods?.length) {
      setChecked(paymentMethods[0].id);
    }
  }, [checked, paymentMethods]);

  return (
    <CheckoutProvider
      data={{
        subscribe: onSubscribe,
        purchaseOrder,
        setPurchaseOrder,
        checked,
        setChecked,
        checkedItem,
        isLoading,
        stripePreviewObject: data,
        previewLoading,
        checkoutCategory: CheckoutCategory.BASIC,
        setIsLoading,
      }}
    >
      <CheckoutPayment />
    </CheckoutProvider>
  );
};

export default CheckoutPaymentSubscribtionWrapper;
