import useAppSelector from 'hooks/useAppSelector';
import React from 'react';
import MatchingSelectSchools from '../../../../pages/MatchingSelectSchools';

const MatchingSelectSchoolsWrapper: React.FC = () => {
  const schools = useAppSelector(({ addNewSchool }) => addNewSchool?.schools);

  return <MatchingSelectSchools schools={schools} />;
};

export default MatchingSelectSchoolsWrapper;
