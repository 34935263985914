import React, { useCallback, useMemo } from 'react';
import { User } from 'models/User';
import MemberNameTableData from '../../Members/components/MemberInfo';
import { getNameInitials } from 'utils';
import { Role } from 'searchality-data';
import { School } from 'models/School';
import { useTranslation } from 'react-i18next';

export default (potentialMembers: User[], itemClassName?: string) => {
  const { t } = useTranslation();

  const schoolTableValue = useCallback(
    (role: Role, schools?: School[]) => {
      if (role === Role.Reviewer) {
        return '';
      }

      if (role === Role.AccountOwner || role === Role.AccountManager) {
        return t('all');
      }

      return schools?.[0]?.name;
    },
    [t],
  );

  const potentialMembersOptions = useMemo(
    () =>
      potentialMembers?.map(
        ({
          _id,
          firstName,
          lastName,
          title,
          systemColor,
          schools,
          imageFile,
          invitedAt,
          role,
        }) => ({
          value: _id,
          label: {
            icon: (
              <div className={itemClassName}>
                <MemberNameTableData
                  placeholderText={getNameInitials(firstName, lastName)}
                  email={title}
                  src={imageFile?.url}
                  backgroundColor={systemColor}
                  name={`${firstName} ${lastName}`}
                  onClick={undefined}
                  invitedAt={invitedAt}
                  displayRemaingTime={false}
                  key={_id}
                />
                <p>{schoolTableValue(role, schools)}</p>
              </div>
            ),
            text: '',
          },
          searchValue: _id,
        }),
      ),
    [itemClassName, schoolTableValue, potentialMembers],
  );

  return potentialMembersOptions;
};
