import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { ReactComponent as DeleteIcon } from 'icons/Delete.icon.svg';
import { ReactComponent as EditIcon } from 'icons/Edit.icon.svg';
import { ReactComponent as ArchiveIcon } from 'icons/Archive.icon.svg';
import { ReactComponent as SchoolIcon } from 'icons/Education.icon.svg';
import { ReactComponent as NonAcademicIcon } from 'icons/Non-academic.icon.svg';
import { Vacancy } from 'models/Vacancy';
import BasicTag from 'components/BasicTag';
import { BasicTagVariants } from 'components/BasicTag/BasicTag.component';
import DateService from 'services/Date.service';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { useTranslation } from 'react-i18next';
import VacancyDeleteConfirmationModal from 'router/subrouters/Dashboard/subrouters/Vacancies/components/VacancyDeleteConfirmationModal';
import Tippy from '@tippyjs/react';
import VacancyArchiveConfirmationModal from 'router/subrouters/Dashboard/subrouters/Vacancies/components/VacancyArchiveConfirmationModal';
import { VacancyStatus, VacancyType } from 'searchality-data';
import VacancyExpiredUpdateModal from 'router/subrouters/Dashboard/subrouters/Vacancies/components/VacancyExpiredUpdateModal';
import { Params } from 'types';

import './VacancyCard.styles.scss';
import './VacancyCard.styles.responsive.scss';

export type VacancyCardProps = Vacancy & {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onEdit?: () => void;
  noActions?: boolean;
  noDelete?: boolean;
  noArchive?: boolean;
  noEdit?: boolean;
  className?: string;
  isSelected?: boolean;
  initialParams?: Partial<Params>;
};

const basicTagColorMapping: { [name in VacancyStatus]: BasicTagVariants } = {
  Published: 'blue',
  Draft: 'orange',
  Archived: 'lightGrey',
  Ongoing: 'blue',
  Filled: 'blue',
  Expired: 'lightGrey',
  Closed: 'lightBlue',
};

const VacancyCard: React.FC<VacancyCardProps> = (props) => {
  const {
    className,
    onClick,
    onEdit,
    noActions,
    noArchive,
    noDelete,
    noEdit,
    isSelected = false,
    initialParams,
    ...vacancy
  } = props;

  const {
    schools,
    publishedAt,
    createdAt,
    _id,
    positionDivision,
    positionTitle,
    subjectGradeLevel,
    status,
    type,
    category,
  } = vacancy;

  const { t } = useTranslation();
  const classes = classNames('searchality-vacancy-card', className, {
    'searchality-vacancy-card--active': isSelected,
  });

  const deleteModalRef = useRef<ModalRef>(null);
  const archiveModalRef = useRef<ModalRef>(null);
  const editExpiredModalRef = useRef<ModalRef>(null);

  const closeDeleteModal = useCallback(() => {
    deleteModalRef.current.close();
  }, []);

  const closeArchiveModal = useCallback(() => {
    archiveModalRef.current.close();
  }, []);

  const closeEditExpiredModalRef = useCallback(() => {
    editExpiredModalRef.current?.close();
  }, []);

  const schoolNamesList = useMemo(() => {
    if (!schools.length) return;

    if (schools.length === 1) {
      return <p className="">{schools[0].name}</p>;
    }

    return <p>{t('vacancyForNumberOfSchools', { length: schools.length })}</p>;
  }, [schools, t]);

  const isVacancyPublished = useMemo(() => status === 'Published', [status]);

  return (
    <li
      className={classes}
      onClick={(e) => {
        if ((e as any).target?.className === 'overlay__content') return;

        onClick?.(e);
      }}
    >
      <div className="searchality-vacancy-card__left">
        <p className="searchality-vacancy-card__left__school-type">
          {type === VacancyType.ACADEMIC ? (
            <>
              <SchoolIcon />
              {subjectGradeLevel}
            </>
          ) : (
            <>
              <NonAcademicIcon className="searchality-vacancy-card__left__school-type__non-academic-icon" />
              {category}
            </>
          )}
        </p>
        <p className="searchality-vacancy-card__left__position">
          {positionTitle}
        </p>
        <span className="searchality-vacancy-card__left__type">
          {type === VacancyType.ACADEMIC ? positionDivision : ''}
        </span>
        <span className="searchality-vacancy-card__left__school-name">
          {schoolNamesList}
        </span>
      </div>
      <div className="searchality-vacancy-card__right">
        <div>
          <label>{t('status')}</label>
          <BasicTag
            text={t(`VacancyStatus.${status}`)}
            variant={basicTagColorMapping[status as VacancyStatus]}
          />
        </div>
        <span className="searchality-vacancy-card__right__status-date">{`${
          isVacancyPublished ? 'Published' : 'Created'
        } on ${DateService.fullShorterDate(
          isVacancyPublished ? publishedAt : createdAt,
        )}`}</span>
        {!noActions && (
          <div className="actions-container">
            <VacancyDeleteConfirmationModal
              ref={deleteModalRef}
              vacancyId={_id}
              closeModal={closeDeleteModal}
              initialParams={initialParams}
            />
            {!noEdit && (
              <>
                <Tippy
                  className="tooltip-information actions-container__tooltip"
                  content={t('edit')}
                  placement="bottom"
                  arrow={true}
                  zIndex={5}
                >
                  <button
                    className="svg-button-wrapper"
                    onClick={(e) => {
                      e.stopPropagation();
                      vacancy.status !== VacancyStatus.EXPIRED
                        ? onEdit()
                        : editExpiredModalRef.current.open();
                    }}
                  >
                    <EditIcon />
                  </button>
                </Tippy>
                <VacancyExpiredUpdateModal
                  ref={editExpiredModalRef}
                  vacancyId={_id}
                  closeModal={closeEditExpiredModalRef}
                  initialParams={initialParams}
                />
              </>
            )}

            {!noArchive && (
              <>
                <Tippy
                  className="tooltip-information actions-container__tooltip"
                  content={t('archive')}
                  placement="bottom"
                  arrow={true}
                  zIndex={5}
                >
                  <button
                    className="svg-button-wrapper"
                    onClick={(e) => {
                      e.stopPropagation();
                      archiveModalRef.current?.open();
                    }}
                  >
                    <ArchiveIcon />
                  </button>
                </Tippy>
                <VacancyArchiveConfirmationModal
                  ref={archiveModalRef}
                  vacancyId={_id}
                  closeModal={closeArchiveModal}
                  initialParams={initialParams}
                />
              </>
            )}

            {!noDelete && (
              <Tippy
                className="tooltip-information actions-container__tooltip"
                content={t('delete')}
                placement="bottom"
                arrow={true}
                zIndex={5}
              >
                <button
                  className="svg-button-wrapper"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteModalRef.current?.open();
                  }}
                >
                  <DeleteIcon />
                </button>
              </Tippy>
            )}
          </div>
        )}
      </div>
    </li>
  );
};

export default VacancyCard;
