import { createAction, createSlice } from '@reduxjs/toolkit';
import { SchoolGroupStructureType, VacancyType } from 'searchality-data';

type createVacancyWizard = {
  currentStepIndex: number;
  steps: any[];
  type?: VacancyType;
};

const initialState: createVacancyWizard = {
  currentStepIndex: 0,
  steps: [],
};

export const initializeVacancyWizard = createAction<{
  structureType: SchoolGroupStructureType;
  isAcademic?: boolean;
  isMatchingPaidForStandaloneSchool?: boolean;
  isMatchingAddDisabled?: boolean;
}>('INIT_STEPS');
export const updateVacancyWizardLastGroupStep = createAction<{
  isMatchingPaidForSelectedSchools: boolean;
  isMatchingAdDisabled: boolean;
}>('UPDATE_LAST_STEP_GROUP');
export const nextVacancyStep = createAction('NEXT_STEP');
export const previuosVacancyStep = createAction('PREVIOUS_STEP');
export const resetVacancyStep = createAction('RESET_STEPPER');
export const changeVacancyType = createAction<VacancyType>(
  'CHANGE_VACANCY_TYPE',
);
export const resetVacancyType = createAction('RESET_VACANCY_TYPE');

const createVacancyWizardSlice = createSlice({
  name: 'createVacancyWizard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initializeVacancyWizard, (state, action) => {
      state.currentStepIndex = 0;

      let lastStep: string;

      if (action.payload.isAcademic) {
        if (action.payload.isMatchingPaidForStandaloneSchool) {
          lastStep = 'matching-feature';
        } else {
          if (action.payload.isMatchingAddDisabled) {
            lastStep = undefined;
          }
          lastStep = 'matching-advertisement';
        }
      } else {
        lastStep = undefined;
      }

      state.steps =
        action.payload.structureType === SchoolGroupStructureType.STANDALONE
          ? [
              'position-information',
              'position-requirements',
              'teacher-offers',
              ...(lastStep ? [lastStep] : []),
            ]
          : [
              'school-information',
              'position-information',
              'position-requirements',
              'teacher-offers',
              ...(action.payload.isAcademic ? ['matching-feature'] : []),
            ];
    });
    builder.addCase(updateVacancyWizardLastGroupStep, (state, action) => {
      const lastStep = action.payload.isMatchingPaidForSelectedSchools
        ? 'matching-feature'
        : !action.payload.isMatchingAdDisabled
        ? 'matching-advertisement'
        : undefined;

      let steps;

      if (!lastStep) {
        steps = state.steps.slice(0, 4);
      } else {
        steps = [...state.steps.slice(0, 4), lastStep];
      }

      state.steps = [...steps];
    });
    builder.addCase(nextVacancyStep, (state) => {
      state.currentStepIndex = Math.min(
        state.currentStepIndex + 1,
        state.steps.length,
      );
    });
    builder.addCase(previuosVacancyStep, (state) => {
      state.currentStepIndex = Math.max(state.currentStepIndex - 1, 0);
    });
    builder.addCase(resetVacancyStep, (state) => {
      state.currentStepIndex = 0;
    });
    builder.addCase(changeVacancyType, (state, action) => {
      state.type = action.payload;
    });
    builder.addCase(resetVacancyType, (state) => {
      state.type = undefined;
    });
  },
});

export default createVacancyWizardSlice.reducer;
