import React, { useMemo } from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import { useLeftCardContent } from '../../hooks/useLeftCardContent';
import { useTranslation } from 'react-i18next';
import { SchoolGroupStructureType } from 'searchality-data';
import { numberPriceToCurrencyString } from 'utils';
import { ReactComponent as ArrowIcon } from 'icons/Arrow.icon.svg';
import { HOW_IT_WORKS_LINK, WEBSITE_INTEGRATION_MORE } from 'constants/links';

import './PricingPlan.styles.scss';
import useSearchalityPricing from 'hooks/useSearchalityPricing';

type PricingPlanProps = {
  className?: string;
  structureType: SchoolGroupStructureType;
  hasOldPricingSystem: boolean;
};

const PricingPlan: React.FC<PricingPlanProps> = (props) => {
  const { className, structureType, hasOldPricingSystem } = props;

  const { t } = useTranslation();

  const { WEBSITE_ADDITIONAL_INTEGRATION_PRICE, WEBSITE_INTEGRATION_PRICE } =
    useSearchalityPricing(hasOldPricingSystem);

  const leftCardContent = useLeftCardContent(
    structureType,
    hasOldPricingSystem,
  );

  const classes = classNames('pricing-plan__cards', className);

  const seeAllLink = useMemo(
    () => (
      <a href={HOW_IT_WORKS_LINK} target="_blank" rel="noreferrer">
        <p className="pricing-plan__links">
          {t('Pricing.seeAll')}
          <ArrowIcon />
        </p>
      </a>
    ),
    [t],
  );

  return (
    <div
      className={classNames(classes, {
        'pricing-plan__cards--group':
          structureType !== SchoolGroupStructureType.STANDALONE,
      })}
    >
      <Card
        className="teal pricing-plan__cards__left"
        title={leftCardContent.title}
        subtitle={leftCardContent.subtitle}
        description={leftCardContent.description}
      >
        {leftCardContent.content}
        {seeAllLink}
      </Card>
      <Card
        className="orange pricing-plan__cards__right"
        title={t('Pricing.Website.title')}
        subtitle={t('Pricing.Website.price', {
          price: numberPriceToCurrencyString(WEBSITE_INTEGRATION_PRICE),
        })}
      >
        <p className="card__description">
          {t(
            `Pricing.Website.${
              structureType !== SchoolGroupStructureType.STANDALONE
                ? 'description'
                : 'descriptionStandalone'
            }`,
          )}
        </p>
        {structureType !== SchoolGroupStructureType.STANDALONE && (
          <p className="fee__description">
            <span>➕</span>
            {t('Pricing.Website.fee', {
              price: numberPriceToCurrencyString(
                WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
              ),
            })}
          </p>
        )}
        {structureType !== SchoolGroupStructureType.STANDALONE && (
          <p className="pricing-plan__note">{t('Pricing.Website.note')}</p>
        )}
        <a href={WEBSITE_INTEGRATION_MORE} target="_blank" rel="noreferrer">
          <p className="pricing-plan__links">
            {t('Pricing.Website.learnMore')}
            <ArrowIcon />
          </p>
        </a>
      </Card>
    </div>
  );
};

export default PricingPlan;
