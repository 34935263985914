import React, { useContext } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';
import { CREATE_SCHOOL_WIZARD } from 'enums';
import useNavigationCurrentStep from '../../hooks/useNavigationCurrentStep';
import GoBackButton from 'components/GoBackButton';
import useSchoolInformationRoute from '../../hooks/useSchoolInformationRoute';
import PricingPlan from 'router/subrouters/Dashboard/components/PricingPlan';
import TotalPricePreview from 'router/subrouters/Dashboard/components/TotalPricePreview';

import './Pricing.styles.scss';

type PricingProps = {
  className?: string;
};

const Pricing: React.FC<PricingProps> = (props) => {
  const { className } = props;

  const { completeStep } = useContext(CreateSchoolWizardContext);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const {
    values: { structureType, schools },
  } = useFormState();

  useNavigationCurrentStep(CREATE_SCHOOL_WIZARD.PRICING_INFORMATION);

  const navigateRoute = useSchoolInformationRoute();

  const classes = classNames('pricing', className);

  return (
    <form
      className={classes}
      onSubmit={async (ev) => {
        ev.preventDefault();
        completeStep(CREATE_SCHOOL_WIZARD.PRICING_INFORMATION);
        navigate('../school-review');
      }}
    >
      <GoBackButton to={`../${navigateRoute}`} />
      <h1>{t('Pricing.title')}</h1>
      <p className="description">{t('Pricing.description')}</p>
      <PricingPlan structureType={structureType} hasOldPricingSystem={false} />
      <TotalPricePreview
        structureType={structureType}
        schools={schools}
        hasOldPricingSystem={false}
      />
      <Button type="submit">{t('Pricing.submit')}</Button>
    </form>
  );
};

export default Pricing;
