import React from 'react';
import RadioButtonLabel from 'components/RadioButtonLabel';
import { useTranslation } from 'react-i18next';
import { RightToWorkType } from 'searchality-data';

const useWorkRightOptions = () => {
  const { t } = useTranslation();

  return Object.keys(RightToWorkType).map((key) => ({
    label: <RadioButtonLabel label={t(`workRequiredTypeOptions.${key}`)} />,
    value: RightToWorkType[key as keyof typeof RightToWorkType],
  }));
};

export default useWorkRightOptions;
