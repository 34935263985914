import React, { useCallback } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import JobApplicationSection from '../JobApplicationSection';
import YourProfile from './components/YourProfile';
import JobApplicationFormActionButtons from '../JobApplicationFormActionButtons';
import ReferencesField from './components/ReferencesField';
import PersonalInfo from './components/PersonalInfo';
import PositionRequirements from './components/PositionRequirements';
import { atLeastNumberOfEntries } from 'validations';
import { useNavigate, useParams } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError } from 'store/slices/popNotifications.slice';
import PageLoader from 'components/PageLoader';
import useAppSelector from 'hooks/useAppSelector';
import { useGetOpenJobQuery } from 'api/jobs.api';
import PublicationsField from 'router/subrouters/Dashboard/components/PublicationsField';
import {
  BoardingPositionRequirementStatus,
  SchoolResidentialType,
  VacancyType,
} from 'searchality-data';
import JobApplicationBoarding from './components/JobApplicationBoarding';

import './JobApplicationForm.styles.scss';
import './JobApplicationForm.styles.responsive.scss';

const JobApplicationForm: React.FC = () => {
  const { jobId } = useParams();

  const { data: job } = useGetOpenJobQuery(jobId);

  const { numberOfReferences } = job || {};

  const submitting = useAppSelector(({ jobs }) => jobs.submitting);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { submit, reset } = useForm();

  const { values, valid } = useFormState();

  const handleOnPreview = useCallback(() => {
    if (
      values?.personalInformation?.firstName &&
      values?.personalInformation?.lastName
    ) {
      navigate('preview', { state: { values, valid } });
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      dispatch(popError(t('enterAtLeastName')));
    }
  }, [dispatch, navigate, t, valid, values]);

  return (
    <>
      {submitting && (
        <div className="searchality-overlay">
          <PageLoader />
        </div>
      )}
      <form onSubmit={submit}>
        <JobApplicationSection
          title={t('personalInformation')}
          onFormClear={() => {
            reset({ ...values, personalInformation: undefined });
          }}
        >
          <PersonalInfo />
        </JobApplicationSection>

        <JobApplicationSection
          title={t('yourBackground')}
          onFormClear={() => {
            reset({ ...values, yourProfile: undefined });
          }}
        >
          <YourProfile />
        </JobApplicationSection>
        <JobApplicationSection
          title={t('positionRequirements')}
          onFormClear={() => {
            reset({ ...values, positionRequirements: undefined });
          }}
        >
          <PositionRequirements />
        </JobApplicationSection>
        <JobApplicationSection
          title={<>{t('references')}</>}
          onFormClear={() => {
            reset({ ...values, references: undefined });
          }}
          description={
            numberOfReferences > 0
              ? t('referencesDescription', {
                  count: numberOfReferences,
                })
              : t('referencesDescriptionNotReq')
          }
        >
          <Field
            name="references"
            component={ReferencesField}
            maxReferences={numberOfReferences}
            validate={atLeastNumberOfEntries(numberOfReferences)}
          />
        </JobApplicationSection>
        {job?.residentialType !== SchoolResidentialType.DAY_SCHOOL &&
          job?.boardingPositionRequirementStatus !==
            BoardingPositionRequirementStatus.NOT_REQUIRED && (
            <JobApplicationSection
              title={t('JobApplication.boardingSectionTitle')}
              onFormClear={() => {
                reset({ ...values, isAbleToWorkAsBoardingParent: undefined });
              }}
              description={t('JobApplication.boardingSectionDescription')}
            >
              <JobApplicationBoarding
                isRequired={
                  job?.boardingPositionRequirementStatus ===
                  BoardingPositionRequirementStatus.REQUIRED
                }
              />
            </JobApplicationSection>
          )}
        {job.type === VacancyType.ACADEMIC && (
          <JobApplicationSection
            title={t('publicationsOrExternal')}
            onFormClear={() => {
              reset({ ...values, publications: undefined });
            }}
            description={t('publicationsOrExternalDescription')}
          >
            <Field name="publications" component={PublicationsField} />
          </JobApplicationSection>
        )}
        <JobApplicationSection>
          <JobApplicationFormActionButtons onPreview={handleOnPreview} />
        </JobApplicationSection>
      </form>
    </>
  );
};

export default JobApplicationForm;
