import React from 'react';
import PaymentMethodsProvider from 'providers/PaymentMethods';
import { Outlet } from 'react-router-dom';
import MatchingSchoolsProvider from 'providers/MatchingSchools';

const ManageSubscription: React.FC = () => (
  <PaymentMethodsProvider>
    <MatchingSchoolsProvider>
      <Outlet />
    </MatchingSchoolsProvider>
  </PaymentMethodsProvider>
);

export default ManageSubscription;
