import useAppSelector from 'hooks/useAppSelector';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSchoolPrices } from 'router/subrouters/Dashboard/subrouters/CreateSchool/pages/Pricing/hooks/useSchoolPrices';
import { PaymentStatus, SchoolGroupStructureType } from 'searchality-data';
import useSubscriptionStatus from 'router/subrouters/Dashboard/hooks/useSubscriptionStatus';
import DateService from 'services/Date.service';
import authSelectors from 'store/selectors/auth.selectors';
import { numberPriceToCurrencyString } from 'utils';
import useSearchalityPricing from 'hooks/useSearchalityPricing';

const useCard = () => {
  const { schoolGroup } = useAppSelector(authSelectors.selectUser);

  const {
    structureType,
    schools,
    subscriptionEndDate,
    subscriptionStartDate,
    paymentStatus,
    hasOldPricingSystem,
  } = schoolGroup;

  const { GROUP_ADDITIONAL_SCHOOL_PRICE, GROUP_INTITIAL_PRICE } =
    useSearchalityPricing(hasOldPricingSystem);

  const { nextSubscriptionPrice } = useSchoolPrices(
    structureType,
    schools,
    hasOldPricingSystem,
  );

  const { t } = useTranslation();

  const subscriptionStatus = useSubscriptionStatus();

  const standalone = useMemo(
    () => [
      {
        label: t('SubscriptionCard.totalCostLabel'),
        value: t('SubscriptionCard.totalCostValue', {
          price: numberPriceToCurrencyString(nextSubscriptionPrice),
        }),
      },
    ],
    [nextSubscriptionPrice, t],
  );

  const group = useMemo(
    () => [
      {
        label: t('SubscriptionCard.totalCostLabel'),
        value: t('SubscriptionCard.totalCostValue', {
          price: numberPriceToCurrencyString(nextSubscriptionPrice),
        }),
        description: t('SubscriptionCard.totalCostDescription', {
          basePrice: numberPriceToCurrencyString(GROUP_INTITIAL_PRICE),
          additionalPrice: numberPriceToCurrencyString(
            GROUP_ADDITIONAL_SCHOOL_PRICE,
          ),
          numOfSchools: 2,
        }),
      },
      {
        label: t('SubscriptionCard.ammountOfSchoolTitle'),
        value: t('SubscriptionCard.ammountOfSchoolDescription', {
          count: schools?.length,
        }),
      },
      {
        label: t('SubscriptionCard.costPerAdditionalSchool'),
        value: numberPriceToCurrencyString(
          schools.length > 2
            ? (schools.length - 2) * GROUP_ADDITIONAL_SCHOOL_PRICE
            : 0,
        ),
      },
    ],
    [
      GROUP_ADDITIONAL_SCHOOL_PRICE,
      GROUP_INTITIAL_PRICE,
      nextSubscriptionPrice,
      schools.length,
      t,
    ],
  );

  const billing = useMemo(
    () => [
      {
        label: t('SubscriptionCard.subscriptionStatus'),
        value: t(subscriptionStatus),
        className: subscriptionStatus === 'active' ? 'blue' : 'orange',
      },
      {
        label: t('SubscriptionCard.paymentStatus'),
        value: t(paymentStatus),
        className: paymentStatus === PaymentStatus.PAID ? 'blue' : 'orange',
      },
      ...(subscriptionStartDate
        ? [
            {
              label: t('SubscriptionCard.billingPeriodStart'),
              value: DateService.getUSADateFormatLongWithTime(
                subscriptionStartDate,
              ),
            },
          ]
        : []),
      ...(subscriptionEndDate
        ? [
            {
              label: t('SubscriptionCard.nextPaymentOn'),
              value:
                DateService.getUSADateFormatLongWithTime(subscriptionEndDate),
            },
          ]
        : []),
    ],
    [
      paymentStatus,
      subscriptionEndDate,
      subscriptionStartDate,
      subscriptionStatus,
      t,
    ],
  );

  return structureType === SchoolGroupStructureType.GROUP
    ? [...group, ...billing]
    : [...standalone, ...billing];
};

export default useCard;
