import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import OrderItem from '../OrderItem';
import { numberPriceToCurrencyString } from 'utils';
import { SchoolGroupStructureType, StripeProductType } from 'searchality-data';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import CheckoutContext from 'providers/Checkout/Checkout.context';

import './OrderSchoolGroupItem.styles.scss';

type OrderSchoolGroupItemProps = {
  className?: string;
  schoolNamesIncluded: string;
};

const OrderSchoolGroupItem: React.FC<OrderSchoolGroupItemProps> = (props) => {
  const { className, schoolNamesIncluded } = props;

  const {
    schoolGroup: { structureType },
  } = useAppSelector(authSelectors.selectUser);

  const { t } = useTranslation();

  const { stripePreviewObject } = useContext(CheckoutContext);

  const schoolGroupLineObject = useMemo(
    () =>
      stripePreviewObject?.lines?.data?.find(
        (element) =>
          element?.price?.product?.name ===
            StripeProductType.ANNUAL_INITIAL_SCHOOL_GROUP_SUBSCRIPTION ||
          element?.price?.product?.name ===
            StripeProductType.ANNUAL_INITIAL_STANDALONE_SCHOOL_SUBSCRIPTION,
      ),
    [stripePreviewObject?.lines?.data],
  );

  const schoolGroupBasePrice = schoolGroupLineObject?.amount;

  const schoolGroupPrice = useMemo(() => {
    const amount = schoolGroupLineObject?.discount_amounts?.[0]?.amount;
    return amount > 0 ? schoolGroupBasePrice - amount : schoolGroupBasePrice;
  }, [schoolGroupLineObject?.discount_amounts, schoolGroupBasePrice]);

  const schoolGroupComponent = useMemo(
    () => (
      <>
        {schoolGroupLineObject?.discount_amounts?.[0]?.amount > 0 && (
          <span>{numberPriceToCurrencyString(schoolGroupBasePrice)}</span>
        )}{' '}
        {numberPriceToCurrencyString(schoolGroupPrice)}
      </>
    ),
    [
      schoolGroupLineObject?.discount_amounts,
      schoolGroupPrice,
      schoolGroupBasePrice,
    ],
  );

  const widgetIntegrationPriceObject = useMemo(
    () =>
      stripePreviewObject?.lines?.data?.find(
        (element) =>
          element?.price?.product?.name ===
            StripeProductType.SCHOOL_GROUP_WIDGET_INTEGRATION ||
          element?.price?.product?.name ===
            StripeProductType.WIDGET_INTEGRATION_STANDALONE,
      ),
    [stripePreviewObject?.lines?.data],
  );

  const widgetGroupIntegrationBasePrice = widgetIntegrationPriceObject?.amount;

  const widgetIntegrationPrice = useMemo(() => {
    const amount = widgetIntegrationPriceObject?.discount_amounts?.[0]?.amount;
    return amount > 0
      ? widgetGroupIntegrationBasePrice - amount
      : widgetGroupIntegrationBasePrice;
  }, [
    widgetIntegrationPriceObject?.discount_amounts,
    widgetGroupIntegrationBasePrice,
  ]);

  const widgetIntegrationLabel = useMemo(
    () => (
      <>
        {widgetIntegrationPriceObject?.discount_amounts?.[0]?.amount > 0 && (
          <span>
            {numberPriceToCurrencyString(widgetGroupIntegrationBasePrice)}
          </span>
        )}{' '}
        {numberPriceToCurrencyString(widgetIntegrationPrice)}
      </>
    ),
    [
      widgetIntegrationPrice,
      widgetIntegrationPriceObject?.discount_amounts,
      widgetGroupIntegrationBasePrice,
    ],
  );

  const classes = classNames('order-school-group-item', className);

  return (
    <>
      <OrderItem
        className={classes}
        name={
          structureType === SchoolGroupStructureType.GROUP
            ? t('OrderSummary.initialPrice', { name: schoolNamesIncluded })
            : t('OrderSummary.standaloneInitialPrice')
        }
        price={schoolGroupComponent}
      />
      <OrderItem
        className={classes}
        name={t('OrderSummary.setupFee')}
        price={widgetIntegrationLabel}
      />
    </>
  );
};

export default OrderSchoolGroupItem;
