import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { VacancyType } from 'searchality-data';
import {
  changeVacancyType,
  resetVacancyStep,
  resetVacancyType,
} from 'store/slices/createVacancyWizard.slice';
import vacancyConstants from 'constants/vacancy';

const CreateVacancy: React.FC = () => {
  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!type) {
      if (
        pathname.includes(
          vacancyConstants.vacancyTypeToRouteMapping[VacancyType.ACADEMIC],
        )
      ) {
        if (
          pathname.includes(
            vacancyConstants.vacancyTypeToRouteMapping[
              VacancyType.NON_ACADEMIC
            ],
          )
        ) {
          dispatch(changeVacancyType(VacancyType.NON_ACADEMIC));
        } else {
          dispatch(changeVacancyType(VacancyType.ACADEMIC));
        }
      }
    }
  }, [dispatch, pathname, type]);

  useEffect(() => {
    return () => {
      dispatch(resetVacancyType());
      dispatch(resetVacancyStep());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Outlet />;
};

export default CreateVacancy;
