import { useMemo } from 'react';
import useSchoolInformationRoute from 'router/subrouters/Dashboard/subrouters/CreateSchool/hooks/useSchoolInformationRoute';

export default function () {
  const schoolInformationRoute = useSchoolInformationRoute();

  return useMemo(
    () =>
      [
        {
          to: 'school-type',
          label: 'School type',
        },
        {
          to: schoolInformationRoute,
          label: 'School information',
        },
        {
          to: 'pricing-information',
          label: 'Pricing information',
        },
        {
          to: 'school-review',
          label: 'Review',
        },
      ] as const,
    [schoolInformationRoute],
  );
}
