import React, { useCallback, useContext, useMemo } from 'react';
import RadioButtonLabel from 'components/RadioButtonLabel';
import RadioGroupField from 'components/RadioGroupField';
import { schoolTypeOptions } from 'constants/schoolTypeOptions';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { SchoolGroupType } from 'searchality-data';
import { required } from 'validations';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';

import './SchoolType.styles.scss';

const SchoolType: React.FC = () => {
  const { t } = useTranslation();

  const { submit, reset, getState } = useForm();

  const {
    values: { type },
  } = useFormState();

  const { resetCompletedSteps } = useContext(CreateSchoolWizardContext);

  const navigate = useNavigate();

  const options = useMemo(
    () =>
      Object.values(SchoolGroupType).map((el) => ({
        label: (
          <RadioButtonLabel label={t(schoolTypeOptions[el].label) as string} />
        ),
        value: el.replace(/ /g, '-'),
      })),
    [t],
  );

  const resetForm = useCallback(() => {
    reset({ type });
    resetCompletedSteps([]);
  }, [reset, resetCompletedSteps, type]);

  return (
    <form
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid, dirtyFields } = getState();
        if (!valid) return submit();
        if (dirtyFields.type) resetForm();
        navigate(`${type}/residential-type`);
      }}
      className="school-type"
    >
      <h1>{t('schoolType.title')}</h1>
      <Field
        name="type"
        component={RadioGroupField}
        options={options}
        validate={required(t('mustSelectOne'))}
        wrapperClassName="school-type__radio-group"
      />
      <Button type="submit">{t('continue')}</Button>
    </form>
  );
};

export default SchoolType;
