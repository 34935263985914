import React, { useCallback, useEffect, useState } from 'react';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { VacancyFormValues } from 'models/Vacancy';
import { useNavigate } from 'react-router-dom';
import { vacancyBuilderActions } from 'store/slices/vacancyBuilder.slice';
import VacancyWizard from './VacancyWizard.component';
import { getFileFromURL } from 'components/ImageCropper/utils';

const CreateVacancyWizard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const initialValues = useAppSelector((state) => state.vacancyBuilder);
  const [positionDescriptionFormValue, setPositionDescriptionFormValue] =
    useState<File[]>([]);
  const [boardingDescriptionFile, setBoardingDescriptionFile] = useState<
    File[]
  >([]);

  const navigateToVacancyPreview = useCallback(
    (values: VacancyFormValues) => {
      const {
        positionDescription,
        boardingRoleDescription,
        positionDescriptionLocalUrl,
        boardingRoleDescriptionLocalUrl,
        ...restValues
      } = values as any;

      dispatch(
        vacancyBuilderActions['setValues']({
          ...restValues,
          ...(positionDescription?.length && {
            positionDescriptionLocalUrl: URL.createObjectURL(
              positionDescription[0],
            ),
          }),
          ...(boardingRoleDescription?.length && {
            boardingRoleDescriptionLocalUrl: URL.createObjectURL(
              boardingRoleDescription[0],
            ),
          }),
        }),
      );
      navigate('../preview');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [dispatch, navigate],
  );

  const fetchFiles = useCallback(async () => {
    const { positionDescriptionLocalUrl, boardingRoleDescriptionLocalUrl } =
      initialValues || {};
    if (positionDescriptionLocalUrl) {
      const file = await getFileFromURL(
        positionDescriptionLocalUrl,
        'Position Description',
        'application/pdf',
      );
      setPositionDescriptionFormValue([file]);
    }

    if (boardingRoleDescriptionLocalUrl) {
      const file = await getFileFromURL(
        boardingRoleDescriptionLocalUrl,
        'Boarding position description',
        'application/pdf',
      );
      setBoardingDescriptionFile([file]);
    }
  }, [initialValues]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  return (
    <VacancyWizard
      initialValues={
        initialValues
          ? {
              ...initialValues,
              positionDescription: positionDescriptionFormValue,
              boardingRoleDescription: boardingDescriptionFile,
            }
          : ({} as VacancyFormValues)
      }
      onSubmit={navigateToVacancyPreview}
    />
  );
};

export default CreateVacancyWizard;
