import GoBackButton from 'components/GoBackButton';
import RadioButtonLabel from 'components/RadioButtonLabel';
import RadioGroupWithOtherField from 'components/RadioGroupWithOtherField';
import { CREATE_SCHOOL_WIZARD } from 'enums';
import { Button } from 'ncoded-component-library';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';
import React, { useCallback, useContext, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { Religion } from 'searchality-data';
import utils from 'utils';
import { required } from 'validations';

import './ReligionType.styles.scss';

const ReligionType: React.FC = () => {
  const { submit, reset, getState } = useForm();

  const {
    values: { type, structureType, residentialType, religion },
  } = useFormState();

  const { resetCompletedSteps, completeStep } = useContext(
    CreateSchoolWizardContext,
  );

  const navigate = useNavigate();

  const { t } = useTranslation();

  const options = useMemo(
    () =>
      Object.keys(Religion).map((religion) => ({
        label: <RadioButtonLabel label={t(`ReligionTypes.${religion}`)} />,
        value: Religion[religion as keyof typeof Religion],
      })),
    [t],
  );

  const resetForm = useCallback(() => {
    reset({ type, residentialType, structureType, religion });
    resetCompletedSteps([]);
  }, [religion, reset, resetCompletedSteps, residentialType, structureType, type]);

  if (!structureType) {
    return <Navigate to={`/create-school/school-type/${type}`} />;
  }

  return (
    <form
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid, dirtyFields } = getState();
        if (!valid) return submit();
        if (dirtyFields.type) resetForm();
        completeStep(CREATE_SCHOOL_WIZARD.SCHOOL_TYPE);
        navigate(
          `/create-school/${type}/residential-type/${utils.mapResidentialTypeToRoute(
            residentialType,
          )}/school-information/${structureType}`,
        );
      }}
      className="religion-type"
    >
      <GoBackButton
        to={`/create-school/school-type/${type}/residential-type/${residentialType}`}
      />
      <h1>{t('ReligionTypes.title')}</h1>
      <p className="description">{t('ReligionTypes.description')}</p>
      <Field
        name="religion"
        component={RadioGroupWithOtherField}
        options={options}
        validate={required(t('mustSelectOne'))}
        innerLabel={t('ReligionTypes.specifyReligion')}
        required
      />
      <p className="religion-type__hint">{t('ReligionTypes.hint')}</p>
      <Button type="submit" disabled={!getState()?.valid}>
        {t('continue')}
      </Button>
    </form>
  );
};

export default ReligionType;
