import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Role, UserStatus } from 'searchality-data';
import MemberNameTableData from '../MemberInfo';
import { getNameInitials } from 'utils';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { useTranslation } from 'react-i18next';
import { School } from 'models/School';
import { User } from 'models/User';
import { useNavigate } from 'react-router-dom';
import RolesSelect from '../RolesSelect';
import useMembersTableHeader from 'router/subrouters/Dashboard/hooks/useMembersTableHeader';
import useMatchMedia from 'hooks/useMatchMedia';
import MemberCards from '../MemberCards';
import AssociationRolesSelect from '../AssociationRolesSelect';
import PendingInvite from '../PendingInvite';

import './MembersTable.styles.scss';

type MembersTableProps = {
  className?: string;
  members: User[];
  isReadonly?: boolean;
  schoolName?: string;
  isAssociation?: boolean;
};

const MembersTable: React.FC<MembersTableProps> = (props) => {
  const {
    className,
    members,
    schoolName,
    isReadonly = false,
    isAssociation = false,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isPablet = useMatchMedia('isPhablet');

  const tableHeader = useMembersTableHeader(isAssociation);

  const user = useAppSelector(authSelectors.selectUser);

  const { role: currentUserRole } = user;

  const classes = classNames('members-table', className);

  const isReadonlyOrAssociation = isReadonly || isAssociation;

  const schoolTableValue = useCallback(
    (status: UserStatus, role: Role, schools?: School[]) => {
      if (schoolName) return schoolName;

      if (status === UserStatus.PENDING || role === Role.Reviewer) return '';

      if (role === Role.AccountOwner || role === Role.AccountManager)
        return t('all');

      return schools?.[0]?.name;
    },
    [schoolName, t],
  );

  const headerMapped = useMemo(
    () =>
      tableHeader?.map(({ id, label, key }) => (
        <th key={id} className={`'members-table__column__${key}`}>
          {label}
        </th>
      )),
    [tableHeader],
  );

  if (isPablet) {
    return (
      <MemberCards
        members={members}
        isReadonly={isReadonly}
        schoolName={schoolName}
      />
    );
  }

  return (
    <>
      <table className={classes}>
        <thead>
          <tr>{headerMapped}</tr>
        </thead>
        <tbody>
          {members?.map(
            ({
              _id,
              firstName,
              lastName,
              imageFile,
              email,
              systemColor,
              status,
              role,
              invitedAt,
              schools,
            }) => {
              const isPending = status === UserStatus.PENDING;

              return (
                <tr key={_id}>
                  <td className={`members-table__column__name`}>
                    <MemberNameTableData
                      placeholderText={
                        !isPending ? getNameInitials(firstName, lastName) : 'NN'
                      }
                      email={email}
                      src={imageFile?.url}
                      backgroundColor={systemColor}
                      name={
                        !isPending &&
                        `${firstName} ${lastName} ${
                          user?._id === _id ? t('Members.you') : ''
                        }`
                      }
                      className={classNames({
                        'members-table__column__name--clickable': !(
                          isReadonlyOrAssociation || isPending
                        ),
                      })}
                      onClick={() =>
                        isReadonlyOrAssociation || isPending
                          ? undefined
                          : navigate(`/members/${_id}`)
                      }
                      invitedAt={invitedAt}
                      displayRemaingTime={isPending}
                    />
                  </td>
                  {!isAssociation && (
                    <td className={'members-table__column__school'}>
                      <p>{schoolTableValue(status, role, schools)}</p>
                    </td>
                  )}
                  <td className={'members-table__column__role'}>
                    {isPending ? (
                      <PendingInvite id={_id} />
                    ) : isAssociation ? (
                      <AssociationRolesSelect
                        currentRole={role}
                        isReadonly={
                          isReadonly ||
                          currentUserRole !== Role.AssociationOwner ||
                          role === Role.AssociationOwner
                        }
                        userId={_id}
                      />
                    ) : (
                      <RolesSelect
                        currentRole={role}
                        isReadonly={
                          isReadonly ||
                          currentUserRole !== Role.AccountOwner ||
                          role === Role.AccountOwner
                        }
                        userId={_id}
                      />
                    )}
                  </td>
                </tr>
              );
            },
          )}
        </tbody>
      </table>
    </>
  );
};

export default MembersTable;
