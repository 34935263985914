import React, { useCallback } from 'react';
import Anim from 'components/animations';
import CheckboxField from 'components/CheckboxField';
import InputField from 'components/InputField';
import ToggleField from 'components/ToggleField';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { required, requiredTextEditor } from 'validations';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import DatepickerField from 'components/DatepickerField';
import FormFieldLabel from 'components/FormFieldLabel';
import JobPositionTypeFields from './components/JobPositionTypeFields';
import DropzoneField from 'components/DropzoneField';
import TextEditorField from 'components/TextEditorField';

import './PositionInformationFormFields.styles.scss';
import { VacancyStatus } from 'searchality-data';

const PositionInformationFormFields = () => {
  const { t } = useTranslation();

  const { change } = useForm();

  const baseClass = 'position-information-fields';

  const {
    values: {
      immediately,
      isPermanentContract,
      isFullTimeEmployment,
      startDate,
      status,
    },
  } = useFormState();

  const isOngoingVacancyEdit = status === VacancyStatus.ONGOING;

  const startDateValidation = useCallback(
    (value: any, allValues: any) => {
      if (!allValues?.immediately) {
        if (typeof value === 'undefined' || value === null) {
          return t('requiredField');
        }
      }
    },
    [t],
  );

  useEffectSkipFirst(() => {
    if (startDate && immediately) {
      change('immediately', false);
    }
  }, [startDate]);

  useEffectSkipFirst(() => {
    if (startDate && immediately) {
      change('startDate', undefined);
    }
  }, [immediately]);

  return (
    <div className={baseClass}>
      <Field
        name="positionTitle"
        component={InputField}
        label={t('positionTitle')}
        validate={required()}
        required
      />
      <JobPositionTypeFields
        isDisabled={isOngoingVacancyEdit}
        className={`${baseClass}__position-variables`}
      />
      <div className={`${baseClass}__role-description`}>
        <FormFieldLabel text={t('vacancyWizard.step3.roleDescription')} />
        <Field
          name="roleDescription"
          component={TextEditorField}
          placeholder={t('roleDescription')}
          validate={requiredTextEditor()}
          required
        />
        <p className={`${baseClass}__position__description-pdf`}>
          {t('vacancyWizard.step3.pdfRoleDescription')}
        </p>
        <Field name="positionDescription" component={DropzoneField} />
      </div>
      <div className={`${baseClass}__start-date`}>
        <FormFieldLabel text={t('startingDate')} />
        <p>{t('vacancyWizard.step2.startingDateParagraph')}</p>
        <div className={`${baseClass}__start-date__inputs`}>
          <Field
            name="startDate"
            placeholder={t('startDate')}
            component={DatepickerField}
            validate={startDateValidation}
            onlyFuture
            disabled={isOngoingVacancyEdit}
          />
          <Field
            name="immediately"
            key={startDate}
            label={t('immediately')}
            component={CheckboxField}
            type="checkbox"
            disabled={isOngoingVacancyEdit}
          />
        </div>
      </div>
      <div className={`${baseClass}__employment-type`}>
        <FormFieldLabel text={t('employmentType')} />
        <Field
          name="isFullTimeEmployment"
          component={ToggleField}
          validate={required()}
          noLabel="partTime"
          yesLabel="fullTime"
          disabled={isOngoingVacancyEdit}
        />
        {isFullTimeEmployment === false && (
          <>
            <p className="optional-text">
              {t('vacancyWizard.step2.employmentTypeParagraph')}
            </p>
            <Anim.Collapse active>
              <Field
                name="amountOfTimeRequired"
                component={InputField}
                label={t('vacancyWizard.step2.amountOfTimePlaceholder')}
                validate={required()}
                required
                disabled={isOngoingVacancyEdit}
              />
            </Anim.Collapse>
          </>
        )}
      </div>
      <div className={`${baseClass}__contract-type`}>
        <FormFieldLabel text={t('contractType')} />
        <Field
          name="isPermanentContract"
          component={ToggleField}
          noLabel="temporary"
          yesLabel="permanent"
          validate={required()}
          disabled={isOngoingVacancyEdit}
        />
        {isPermanentContract === false && (
          <>
            <p className="optional-text">
              {t('vacancyWizard.step2.lengthOfContractParagraph')}
            </p>
            <Anim.Collapse active>
              <Field
                name="lengthOfContract"
                component={InputField}
                label={t('vacancyWizard.step2.lengthOfContractPlaceholder')}
                validate={required()}
                required
                disabled={isOngoingVacancyEdit}
              />
            </Anim.Collapse>
          </>
        )}
      </div>
    </div>
  );
};

export default PositionInformationFormFields;
