import React from 'react';
import HiringSchoolInformationFormFields from '../forms/HiringSchoolInformationFields/HiringSchoolInformationFields.component';
import MatchingAdvertisementWizardForm from '../forms/MatchingAdvertisementWizardForm';
import MatchingFeatureWizardForm from '../forms/MatchingFeatureWizardForm';
import PositionInformationFormFields from '../forms/PositionInformationFormFields';
import PositionRequirementsFormFields from '../forms/PositionRequirementsFormFields';
import TeacherOffersForm from '../forms/TeacherOffersForm';

export const RouteElementMapping: { [name: string]: JSX.Element } = {
  'school-information': <HiringSchoolInformationFormFields />,
  'position-information': <PositionInformationFormFields />,
  'position-requirements': <PositionRequirementsFormFields />,
  'teacher-offers': <TeacherOffersForm />,
  'matching-advertisement': <MatchingAdvertisementWizardForm />,
  'matching-feature': <MatchingFeatureWizardForm />,
};

export const VacancyWizardTitleMapping = {
  'school-information': 'vacancyWizard.step1.title',
  'position-information': 'vacancyWizard.step2.title',
  'position-requirements': 'vacancyWizard.step3.title',
  'teacher-offers': 'vacancyWizard.step4.title',
  'matching-advertisement': 'vacancyWizard.step5.advertisementTitle',
  'matching-feature': 'vacancyWizard.step5.featureTitle',
};
