import React from 'react';
import classNames from 'classnames';
import { ReactComponent as ResumeIcon } from 'icons/Resume.icon.svg';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import BasicTag from 'components/BasicTag';
import {
  ApplicationRequestedAdditionalInformation,
  RequestedDocuments,
} from 'models/Candidate';
import DateService from 'services/Date.service';
import utils from 'utils';
import {
  RequestedDocumentStatus,
  RequestedDocumentTitle,
} from 'searchality-data';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DoneIcon } from 'icons/Done.icon.svg';

import './TeacherApplicationUploadRequestedDocuments.styles.scss';
import './TeacherApplicationUploadRequestedDocuments.styles.responsive.scss';

type TeacherApplicationUploadRequestedDocumentsProps = {
  className?: string;
  isSubmitted?: boolean;
  requestedDocuments: RequestedDocuments;
  questionsAndVideo: ApplicationRequestedAdditionalInformation;
};

const TeacherApplicationUploadRequestedDocuments: React.FC<
  TeacherApplicationUploadRequestedDocumentsProps
> = (props) => {
  const {
    className,
    isSubmitted = false,
    requestedDocuments,
    questionsAndVideo,
  } = props;

  const { submitByDate, documents } = requestedDocuments || {};

  const { followUpQuestions, video } = questionsAndVideo;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const baseClass = 'teacher-application-upload-requested-documents';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <div className={`${baseClass}__title`}>
        <ResumeIcon />
        <p>{t('Teacher.Application.Documents.requested')}</p>
      </div>
      <p>{t('Teacher.Application.Documents.description')}</p>
      {!!documents?.length && (
        <>
          <p className={`${baseClass}__ul-paragraph`}>{t('documents')}</p>
          <ol>
            {documents?.map((document) => (
              <li key={document._id}>
                <p>
                  {`${t(
                    `RequestDocumentOptions.Title.${utils.getKeyByValue(
                      RequestedDocumentTitle,
                      document.title,
                    )}`,
                  )} - ${document.description}`}
                  {document.status === RequestedDocumentStatus.SUBMITTED && (
                    <DoneIcon />
                  )}
                </p>
              </li>
            ))}
          </ol>
        </>
      )}
      {!!followUpQuestions?.length && (
        <>
          <p className={`${baseClass}__ul-paragraph`}>
            {t('VacancySettings.Add.questions')}
          </p>
          <ol>
            {followUpQuestions?.map(({ question, answer }) => (
              <li key={question}>
                <p>
                  {question}
                  {!!answer && <DoneIcon />}
                </p>
              </li>
            ))}
          </ol>
        </>
      )}
      {!!video && (
        <>
          <p className={`${baseClass}__ul-paragraph`}>
            {t('videoRequestedBySchool')}
          </p>
          <ol>
            <li>
              <p>
                {video?.videoDescription}
                {!!video?.videoUrl && <DoneIcon />}
              </p>
            </li>
          </ol>
        </>
      )}
      <p
        className={classNames(`${baseClass}__date`, {
          [`${baseClass}__date--green`]: isSubmitted,
        })}
      >
        {t('Teacher.Application.Documents.submitByDate', {
          date: DateService.getUSADateFormatLong(submitByDate),
        })}
      </p>
      {isSubmitted ? (
        <BasicTag
          text={t('Teacher.Application.Documents.submitted')}
          variant="outline"
        />
      ) : (
        <Button onClick={() => navigate('upload-documents')}>
          {t('uploadDocuments')}
        </Button>
      )}
    </div>
  );
};

export default TeacherApplicationUploadRequestedDocuments;
