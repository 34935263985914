import { useMemo } from 'react';
import { PaymentStatus, SchoolGroupStructureType } from 'searchality-data';
import { School } from 'models/School';

import useSearchalityPricing from 'hooks/useSearchalityPricing';

export const useSchoolPrices = (
  structureType: SchoolGroupStructureType,
  schools: School[],
  hasOldPricingSystem: boolean,
  numberOfWebsiteOptionalIntegration?: number,
) => {
  const {
    GROUP_ADDITIONAL_SCHOOL_PRICE,
    GROUP_INTITIAL_PRICE,
    STANDALONE_PRICE,
    WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
    WEBSITE_INTEGRATION_PRICE,
    MATCHING_SCHOOL_GROUP_PRICE,
    MATCHING_STANDALONE_PRICE,
  } = useSearchalityPricing(hasOldPricingSystem);
  const isStandalone = structureType === SchoolGroupStructureType.STANDALONE;

  const schoolPrice = useMemo(() => {
    if (isStandalone) {
      return STANDALONE_PRICE;
    }

    let price = GROUP_INTITIAL_PRICE;

    if (schools?.length > 2) {
      return (price += (schools?.length - 2) * GROUP_ADDITIONAL_SCHOOL_PRICE);
    }

    return price;
  }, [
    GROUP_ADDITIONAL_SCHOOL_PRICE,
    GROUP_INTITIAL_PRICE,
    STANDALONE_PRICE,
    schools?.length,
    isStandalone,
  ]);

  const websiteAdditionalPrice = useMemo(() => {
    if (isStandalone || schools?.length <= 2) {
      return 0;
    }

    if (numberOfWebsiteOptionalIntegration !== undefined) {
      if (numberOfWebsiteOptionalIntegration <= 2) {
        return 0;
      }

      return (
        (numberOfWebsiteOptionalIntegration - 2) *
        WEBSITE_ADDITIONAL_INTEGRATION_PRICE
      );
    }

    return (schools?.length - 2) * WEBSITE_ADDITIONAL_INTEGRATION_PRICE;
  }, [
    WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
    isStandalone,
    numberOfWebsiteOptionalIntegration,
    schools?.length,
  ]);

  const currentSubscrtiptionPrice = useMemo(() => {
    if (isStandalone) {
      return schoolPrice;
    }
    return (
      schoolPrice +
      schools?.filter(
        (school) => school.matchingInformation?.hasMatchingSubscription,
      )?.length *
        MATCHING_SCHOOL_GROUP_PRICE
    );
  }, [MATCHING_SCHOOL_GROUP_PRICE, isStandalone, schoolPrice, schools]);

  const nextSubscriptionPrice = useMemo(() => {
    const schoolWithMatchingSubscription = schools?.filter(
      ({ matchingInformation }) =>
        !matchingInformation?.isSubscriptionCanceled &&
        matchingInformation?.paymentStatus === PaymentStatus.PAID,
    );

    if (!schoolWithMatchingSubscription?.length) return schoolPrice;

    if (isStandalone) return schoolPrice + MATCHING_STANDALONE_PRICE;

    return (
      schoolPrice +
      schoolWithMatchingSubscription?.length * MATCHING_SCHOOL_GROUP_PRICE
    );
  }, [
    MATCHING_SCHOOL_GROUP_PRICE,
    MATCHING_STANDALONE_PRICE,
    isStandalone,
    schoolPrice,
    schools,
  ]);

  return {
    schoolPrice,
    websiteAdditionalPrice,
    currentSubscrtiptionPrice,
    nextSubscriptionPrice,
    totalPrice:
      schoolPrice + websiteAdditionalPrice + WEBSITE_INTEGRATION_PRICE,
  };
};
