import React, { useMemo } from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import { numberPriceToCurrencyString } from 'utils';
import { useNewSchoolPrices } from '../../subrouters/MyAccount/subrouters/ManageSubscription/subrouters/AddNewSchool/pages/Pricing/hooks/useNewSchoolPrices';
import { useTranslation } from 'react-i18next';
import { School } from 'models/School';

import './AddSchoolsTotalPrice.styles.scss';

type AddSchoolsTotalPriceProps = {
  className?: string;
  newSchools: School[];
  numberOfAlreadyCreatedSchools: number;
};

const AddSchoolsTotalPrice: React.FC<AddSchoolsTotalPriceProps> = (props) => {
  const { className, newSchools, numberOfAlreadyCreatedSchools } = props;

  const classes = classNames('add-schools-total-price', className);

  const { t } = useTranslation();

  const { totalPrice, yearlyFee, oneTimeFee } = useNewSchoolPrices(newSchools);

  const additionalSchoolNames = useMemo(() => {
    const sliceFor =
      numberOfAlreadyCreatedSchools >= 2
        ? 0
        : Math.abs(numberOfAlreadyCreatedSchools - 2);
    return newSchools
      ?.slice(sliceFor)
      ?.map((school: School) => school.name)
      ?.join(', ');
  }, [newSchools, numberOfAlreadyCreatedSchools]);

  return (
    <Card
      title={t('AddNewSchool.Pricing.Total.total')}
      subtitle={numberPriceToCurrencyString(totalPrice)}
      className={classes}
    >
      {!!totalPrice && (
        <ul>
          {!!yearlyFee && (
            <li>
              {t('AddNewSchool.Pricing.Total.additionalSchoolsPrice', {
                price: numberPriceToCurrencyString(yearlyFee),
                schoolNames: additionalSchoolNames,
              })}
            </li>
          )}
          {!!oneTimeFee && (
            <li>
              {t('AddNewSchool.Pricing.Total.additionalIntegrationsPrice', {
                price: numberPriceToCurrencyString(oneTimeFee),
                schoolNames: additionalSchoolNames,
              })}
            </li>
          )}
        </ul>
      )}
    </Card>
  );
};

export default AddSchoolsTotalPrice;
