import { ComplexRoute } from 'router/components/Routes/Routes';
import EditVacancyWizard from '../../../../../../pages/VacancyWizard/EditVacancyWizard.page';
import VacancyBeforePublishPreview from '../../../../pages/VacancyBeforePublishPreview';

export default [
  {
    path: ':stepName',
    element: EditVacancyWizard,
    validate: ({ createVacancyWizard }, { stepName }) => {
      const { currentStepIndex, steps } = createVacancyWizard;
      const index = steps.findIndex((s) => s === stepName);
      return index > currentStepIndex ? `../${steps[0]}` : '';
    },
  },
  {
    path: 'preview',
    element: VacancyBeforePublishPreview,
    validate: (state) => {
      if (!state.vacancyBuilder) {
        return '..';
      }
      return '';
    },
  },
  {
    index: true,
    element: EditVacancyWizard,
    validate: ({ createVacancyWizard }) => {
      const { steps, currentStepIndex } = createVacancyWizard;
      return `${steps[currentStepIndex]}`;
    },
  },
] as Array<ComplexRoute>;
