import { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { SchoolGroupStructureType } from 'searchality-data';
import { CreateSchoolValues, ReviewCardItemList } from 'types';

export default function () {
  const { values } = useFormState();
  const {
    type: schoolType,
    structureType,
    name,
    website,
  } = values as CreateSchoolValues;

  const { t } = useTranslation();

  const list: ReviewCardItemList = useMemo(() => {
    if (structureType === SchoolGroupStructureType.STANDALONE) {
      return [
        {
          label: t('type'),
          value: t('standaloneSet'),
        },
      ];
    }

    if (schoolType === 'independent' || schoolType === 'charter') {
      return [
        {
          label: t('type'),
          value: t(`groupDisctrictSet`),
        },
        {
          label: t('name'),
          value: name,
        },
        {
          label: t('groupWebsite'),
          value: website,
        },
      ];
    }

    return [
      {
        label: t('type'),
        value: t('groupDisctrictSet'),
      },
      {
        label: t('name'),
        value: name,
      },
      {
        label: t('districtWebsite'),
        value: website,
      },
    ];
  }, [name, schoolType, structureType, t, website]);

  return list;
}
