import React from 'react';
import classNames from 'classnames';
import { ReactComponent as DashboardIcon } from 'icons/Dashboard.icon.svg';

import './VacancySettingsInformation.styles.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';

type VacancySettingsInformationProps = {
  className?: string;
  schoolId: string;
  questions: Array<string>;
  video: string;
};

const VacancySettingsInformation: React.FC<VacancySettingsInformationProps> = (
  props,
) => {
  const { className, questions, video, schoolId } = props;

  const { t } = useTranslation();

  const baseClass = 'vacancy-settings-information';
  const classes = classNames(baseClass, className);

  const navigate = useNavigate();

  return (
    <div className={classes}>
      <div className={`${baseClass}__title`}>
        <DashboardIcon />
        <p>{t('VacancySettings.followUp')}</p>
      </div>
      {!!questions?.length && (
        <>
          <p className={`${baseClass}__list-title`}>
            {t('VacancySettings.questions')}
          </p>
          <ol>
            {questions.map((question) => (
              <li key={question}>{question}</li>
            ))}
          </ol>
        </>
      )}
      {video && (
        <>
          <p className={`${baseClass}__list-title`}>
            {t('VacancySettings.video')}
          </p>
          <p>{video}</p>
        </>
      )}
      <Button onClick={() => navigate(schoolId)}>{t('edit')}</Button>
    </div>
  );
};

export default VacancySettingsInformation;
