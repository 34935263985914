import { ComplexRoute } from 'router/components/Routes/Routes';
import AddNewSchool from './AddNewSchool.router';
import School from './pages/School';
import SchoolsPreview from './pages/SchoolsPreview';
import Pricing from './pages/Pricing';
import EmptyStateAddSchools from './pages/EmptyStateAddSchools';
import SchoolPartOfAssociation from '../../../../../CreateSchool/subrouters/SchoolInformation/pages/SchoolPartOfAssociation';
import SchoolAssociations from '../../../../../CreateSchool/subrouters/SchoolInformation/pages/SchoolAssociations';
import AssociationContactDetails from '../../../../../CreateSchool/subrouters/SchoolInformation/pages/AssociationContactDetails';
import SchoolLogoGroupWrapper from 'router/subrouters/Dashboard/components/SchoolLogoGroupWrapper';
import CheckoutNewSchool from './pages/CheckoutNewSchool';
import MatchingSub from './pages/MatchingSub/MatchingSub.page';
import MatchingSelectSchoolsWrapper from './pages/MatchingSelectSchoolsWrapper';

export default [
  {
    path: 'school',
    element: School,
  },
  {
    path: 'preview',
    element: SchoolsPreview,
  },
  {
    path: 'pricing',
    element: Pricing,
  },
  {
    path: 'finish',
    element: EmptyStateAddSchools,
  },
  {
    path: 'part-of-association',
    element: SchoolPartOfAssociation,
  },
  {
    path: 'school-logo',
    element: SchoolLogoGroupWrapper,
  },
  {
    path: 'choose-associations',
    element: SchoolAssociations,
  },
  {
    path: 'association-contact-details',
    element: AssociationContactDetails,
  },
  {
    path: 'matching-plan',
    element: MatchingSub,
  },
  {
    path: 'select-schools',
    element: MatchingSelectSchoolsWrapper,
  },
  {
    path: 'checkout',
    element: CheckoutNewSchool,
    validate: ({ auth: { user }, addNewSchool: { schools } }) =>
      schools?.length === 0 || !user?.schoolGroup?.subscriptionId
        ? '/my-account/subscription'
        : '',
  },
  {
    index: true,
    element: AddNewSchool,
  },
] as Array<ComplexRoute>;
