import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { ReactComponent as ArrowIcon } from 'icons/Arrow.icon.svg';
import { useFormState } from 'react-final-form';

import './ProfilePreviewAction.styles.scss';
import './ProfilePreviewAction.styles.responsive.scss';

type ProfileHeaderProps = {
  className?: string;
  onReturn?: () => void;
  onSave?: () => void;
  onSaveButtonText?: string;
  disableButtons?: boolean;
};

const ProfilePreviewAction: React.FC<ProfileHeaderProps> = (props) => {
  const { className, onReturn, onSave, onSaveButtonText, disableButtons } =
    props;

  const { t } = useTranslation();

  const { invalid } = useFormState();

  const baseClass = 'searchality-profile-header';

  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <div className={`${baseClass}__main`}>
        <p>{t('Profile.Header.title')}</p>
        <div className={`${baseClass}__main__actions`}>
          <Button
            iconPosition="left"
            icon={<ArrowIcon />}
            variant="outline"
            onClick={onReturn}
            disabled={disableButtons}
          >
            {t('Profile.Header.return')}
          </Button>
          {onSave && (
            <Button onClick={onSave} disabled={disableButtons || invalid}>
              {onSaveButtonText || t('finishApplication')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfilePreviewAction;
