import React from 'react';
import classNames from 'classnames';
import GoBackButton from 'components/GoBackButton';
import { useTranslation } from 'react-i18next';
import ValueContextProvider from 'providers/ValueContext/ValueContext.provider';
import useAppSelector from 'hooks/useAppSelector';
import CheckoutNewSchoolWrapper from '../../../../components/CheckoutNewSchoolWrapper';
import DiscountCodeWrapper from '../../../../components/DiscountCodeWrapper';

import './CheckoutNewSchool.styles.scss';

type CheckoutNewSchoolProps = {
  className?: string;
};

const CheckoutNewSchool: React.FC<CheckoutNewSchoolProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const classes = classNames('checkout-new-school', className);

  const schools = useAppSelector(({ addNewSchool }) => addNewSchool?.schools);

  return (
    <div className={classes}>
      <GoBackButton to="../" />
      <h1>{t('CheckoutPage.title')}</h1>
      <p>{t('CheckoutPage.description')}</p>
      <ValueContextProvider
        isMultiple
        initialIds={schools?.map(({ name }) => name)}
      >
        <DiscountCodeWrapper>
          <CheckoutNewSchoolWrapper />
        </DiscountCodeWrapper>
      </ValueContextProvider>
    </div>
  );
};

export default CheckoutNewSchool;
