import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import Group, { groupRoutes } from './subrouters/Group';
import District, { districtRoutes } from './subrouters/District';
import Standalone, { standaloneRoutes } from './subrouters/Standalone';

export default [
  {
    path: 'standalone',
    element: Standalone,
    routes: standaloneRoutes,
  },
  {
    path: 'district',
    element: District,
    routes: districtRoutes,
    validate: (_, { schoolType }) =>
      schoolType === 'public-school' ? '' : '/create-school',
  },
  {
    path: 'group',
    element: Group,
    routes: groupRoutes,
    validate: (_, { schoolType }) =>
      schoolType !== 'public-school' ? '' : '../district',
  },
  {
    index: true,
    element: <Navigate to="/create-school" replace />,
  },
] as Array<ComplexRoute>;
