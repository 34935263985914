import React from 'react';
import classNames from 'classnames';
import { ReactComponent as InternationalIcon } from 'icons/Website.icon.svg';
import { ReactComponent as UsIcon } from 'icons/Us.icon.svg';
import { useTranslation } from 'react-i18next';

import './SelectSearchalityVersion.styles.scss';
import './SelectSearchalityVersion.styles.responsive.scss';

type SelectSearchalityVersionProps = {
  className?: string;
  description: string;
  link: string;
};

const SelectSearchalityVersion: React.FC<SelectSearchalityVersionProps> = (
  props,
) => {
  const { className, description, link } = props;

  const { t } = useTranslation();

  const classes = classNames('select-searchality-version', className);

  return (
    <div className={classes}>
      <p>{description}</p>
      <div className="select-searchality-version__actions">
        <p
          className={classNames(
            'select-searchality-version__actions__action',
            'select-searchality-version__actions__action--active',
          )}
        >
          {t('us')} <UsIcon />
        </p>
        <a href={link} className="select-searchality-version__actions__action">
          {t('international')} <InternationalIcon />
        </a>
      </div>
    </div>
  );
};

export default SelectSearchalityVersion;
