import api from 'api';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { popError } from 'store/slices/popNotifications.slice';
import { AddressObject } from 'types';
import useAppDispatch from './useAppDispatch';

export default () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const getAddressObject = useCallback(
    async (feature: any): Promise<AddressObject> => {
      try {
        const { center } = feature;

        if (!center) return;

        const { data } = await api.mapbox.reverseGeocoding(
          center[0],
          center[1],
        );

        const { context } = data.features[0];

        const object = context.reduce((acc: any, curr: any) => {
          const { id, text, short_code } = curr;
          const partialAddress: Record<string, string> = {};
          if (id?.includes('country')) {
            partialAddress.country = text;
            partialAddress.countryShortCode = short_code;
          }
          if (id?.includes('postcode')) {
            partialAddress.postcode = text;
          }
          if (id?.includes('place')) {
            partialAddress.city = text;
          }
          if (id?.includes('region')) {
            partialAddress.state = text;
          }
          return {
            ...acc,
            ...partialAddress,
          };
        }, {});
        return object;
      } catch (err) {
        console.error(err);

        dispatch(popError(t('reverseGeocodingError')));
      }
    },
    [dispatch, t],
  );
  return { getAddressObject };
};
