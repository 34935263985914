import useAppSelector from 'hooks/useAppSelector';
import MatchingSchoolsContext from 'providers/MatchingSchools/MatchingSchools.context';
import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SchoolGroupStructureType } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';
import MatchingPricingPlan from '../../../../components/MatchingPricingPlan';

const BasicMatchingSub: React.FC = () => {
  const navigate = useNavigate();

  const {
    schoolGroup: { structureType, schools },
  } = useAppSelector(authSelectors.selectUser);

  const { setSchoolForMatching, clearSchools } = useContext(
    MatchingSchoolsContext,
  );

  const isStandalone = structureType === SchoolGroupStructureType.STANDALONE;

  const onSkip = useCallback(() => {
    clearSchools();
    navigate('../checkout');
  }, [navigate, clearSchools]);

  const onContinue = useCallback(() => {
    if (isStandalone) {
      setSchoolForMatching(schools[0]._id);
      navigate('../checkout');
    } else {
      navigate('../select-schools');
    }
  }, [isStandalone, setSchoolForMatching, navigate, schools]);

  return (
    <MatchingPricingPlan
      goBackTo="../../"
      onSkip={onSkip}
      onContinue={onContinue}
    />
  );
};

export default BasicMatchingSub;
