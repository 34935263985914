import React from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from 'icons/Link.icon.svg';
import { PRICING } from 'constants/links';
import { Button } from 'ncoded-component-library';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import {
  MATCHING_SCHOOL_GROUP_PRICE,
  MATCHING_STANDALONE_PRICE,
  SchoolGroupStructureType,
} from 'searchality-data';
import { numberPriceToCurrencyString } from 'utils';

import './MatchingPricingCard.styles.scss';
import './MatchingPricingCard.styles.responsive.scss';

type MatchingPricingCardProps = {
  className?: string;
  onContinue: () => void;
};

const matchingFeatures = [
  'MatchingPricingCard.Left.li1',
  'MatchingPricingCard.Left.li2',
  'MatchingPricingCard.Left.li3',
];

const MatchingPricingCard: React.FC<MatchingPricingCardProps> = (props) => {
  const { className, onContinue } = props;

  const {
    schoolGroup: { structureType },
  } = useAppSelector(authSelectors.selectUser);

  const isStandalone = structureType === SchoolGroupStructureType.STANDALONE;

  const { t } = useTranslation();

  const classes = classNames('matching-pricing-card', className);

  return (
    <div className={classes}>
      <Card
        className="teal matching-pricing-card__left"
        title={t('MatchingPricingCard.Left.title')}
        subtitle={t('MatchingPricingCard.Left.subtitle')}
      >
        <ul>
          {matchingFeatures.map((feature) => (
            <li key={feature}>{t(feature)}</li>
          ))}
        </ul>
        <a href={PRICING} target="_blank" rel="noreferrer">
          {t('learnMore')}
          <ArrowIcon />
        </a>
      </Card>
      <Card
        className="teal matching-pricing-card__right"
        title={t('MatchingPricingCard.Right.title')}
        subtitle={t('MatchingPricingCard.Right.subtitle')}
      >
        <p className="matching-pricing-card__right__price">
          {t(
            `MatchingPricingCard.Right.${
              isStandalone ? 'standalonePrice' : 'groupPrice'
            }`,
            {
              price: numberPriceToCurrencyString(
                isStandalone
                  ? MATCHING_STANDALONE_PRICE
                  : MATCHING_SCHOOL_GROUP_PRICE,
              ),
            },
          )}
        </p>
        <Button onClick={onContinue}>
          {t(
            `MatchingPricingCard.Right.${
              isStandalone ? 'standaloneAction' : 'groupAction'
            }`,
          )}
        </Button>
      </Card>
    </div>
  );
};

export default MatchingPricingCard;
