import React from 'react';
import classNames from 'classnames';
import { RequestedDocument } from 'models/Candidate';
import { useTranslation } from 'react-i18next';
import utils from 'utils';
import {
  RequestedDocumentStatus,
  RequestedDocumentTitle,
} from 'searchality-data';
import { ReactComponent as DoneIcon } from 'icons/Done.icon.svg';
import UploadedRequestedDocument from 'router/subrouters/Dashboard/subrouters/Candidates/components/UploadedRequestedDocument';
import DropzoneField from 'components/DropzoneField';
import { Field } from 'react-final-form';

import './UploadRequestedDocument.styles.scss';

type UploadRequestedDocumentProps = {
  className?: string;
  document: RequestedDocument;
};

const UploadRequestedDocument: React.FC<UploadRequestedDocumentProps> = (
  props,
) => {
  const { className, document } = props;

  const { status, files, title, description, _id } = document || {};

  const isSubmitted = status === RequestedDocumentStatus.SUBMITTED;

  const { t } = useTranslation();

  const classes = classNames('upload-requested-document', className);

  return (
    <div className={classes}>
      <div className="upload-requested-document__title--wrapper">
        <p className="upload-requested-document__title">
          {`${t(
            `RequestDocumentOptions.Title.${utils.getKeyByValue(
              RequestedDocumentTitle,
              title,
            )}`,
          )} - ${description}`}
        </p>
        {isSubmitted ? <DoneIcon /> : <div className="searchality-pending" />}
      </div>
      <p>{t('UploadRequestedDocument.fileTypeAndSize', { size: 5 })}</p>
      <div className="upload-requested-document__files">
        {files?.map((file, index) => (
          <UploadedRequestedDocument
            {...file}
            key={file.key ? file.key : index.toString()}
            handleDeleteFile={utils.noop}
            deleteKey={file.key ? file.key : index.toString()}
            noActions
            isDeleted
            noClick
          />
        ))}
        {!isSubmitted && (
          <Field
            name={_id}
            component={DropzoneField}
            uploadFileText={t('uploadAFile')}
            maxSize={5242880}
            showLinks
            accept={{
              'image/jpeg': [],
              'image/png': [],
              'application/pdf': [],
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UploadRequestedDocument;
