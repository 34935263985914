import React, { useMemo } from 'react';
import { Button, Collapsible, Select } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import { Params } from 'types';
import useDivisionOptions from '../../../Vacancies/hooks/useDivisionOptions';
import useSubjectGrades from '../../../Vacancies/hooks/useSubjectGrades';
import useFetchApplicationsFilters from '../../hooks/useFetchApplicationsFilters';
import useStatusOptions from '../../hooks/useStatusOptions';
import { ReactComponent as ArrowIcon } from 'icons/dropdown-arrow.icon.svg';
import { FilterSelectPlaceholder } from '../../../Candidates/components/CandidatesFilters/placeholders';
import { ReactComponent as SelectArrowIcon } from 'icons/SelectArrow.icon.svg';

import './ApplicationsFilters.styles.scss';
import './ApplicationsFilters.styles.responsive.scss';

type ApplicationsFiltersProps = {
  filterParams: Partial<Params>;
  onFilter: (key: string, value: any) => void;
  filteredBy: {
    [name: string]: any;
  };
  onReset: () => void;
};

const ApplicationsFilters: React.FC<ApplicationsFiltersProps> = (props) => {
  const { filterParams, filteredBy, onReset, onFilter } = props;
  const { t } = useTranslation();

  const { status, subjectGradeLevel, positionDivision } = filteredBy;

  const [filters, isLoading] = useFetchApplicationsFilters(filterParams);

  const statusValue = useMemo(() => {
    if (!status) return;
    if (status['$in']?.length > 1) return;
    return status['$in'][0];
  }, [status]);

  const subjectsValue = useMemo(() => {
    if (!subjectGradeLevel) return;
    return subjectGradeLevel['$in'][0];
  }, [subjectGradeLevel]);

  const divisionsValue = useMemo(() => {
    if (!positionDivision) return;
    return positionDivision['$in'][0];
  }, [positionDivision]);

  const subjectGradeOptions = useSubjectGrades(filters?.subjects);

  const divisionOptions = useDivisionOptions(filters?.divisions);

  const statusOptions = useStatusOptions(filters?.statuses);

  const showResetFilters = Object.values(filters ?? {})?.some(
    (filter: any) => filter?.length > 1,
  );

  const className = 'applications-filters';

  const FilterComponent = useMemo(() => {
    return (
      <div className={`${className}__wrapper`}>
        <div className={`${className}`}>
          {isLoading ? (
            Array(3)
              .fill(void 0)
              .map((_, index) => (
                <FilterSelectPlaceholder width="148px" key={index} />
              ))
          ) : (
            <>
              {statusOptions?.length > 1 && (
                <Select
                  options={statusOptions}
                  placeholder={t('status')}
                  value={statusValue}
                  multiple={false}
                  onChange={({ value }) => onFilter('status', { $in: [value] })}
                  renderAsPortal
                  portalClassName={`${className}__filter-portal`}
                  icon={<SelectArrowIcon />}
                />
              )}
              {subjectGradeOptions?.length > 1 && (
                <Select
                  options={subjectGradeOptions}
                  placeholder={t('RoleSubject')}
                  value={subjectsValue}
                  multiple={false}
                  onChange={({ value }) =>
                    onFilter('subjectGradeLevel', { $in: [value] })
                  }
                  renderAsPortal
                  portalClassName={`${className}__filter-portal`}
                  icon={<SelectArrowIcon />}
                />
              )}
              {divisionOptions?.length > 1 && (
                <Select
                  options={divisionOptions}
                  placeholder={t('schoolLevel')}
                  value={divisionsValue}
                  multiple={false}
                  onChange={({ value }) =>
                    onFilter('positionDivision', { $in: [value] })
                  }
                  renderAsPortal
                  portalClassName={`${className}__filter-portal`}
                  icon={<SelectArrowIcon />}
                />
              )}
            </>
          )}
        </div>
        {showResetFilters && (
          <Button variant="link" onClick={onReset}>
            {t('resetFilters')}
          </Button>
        )}
      </div>
    );
  }, [
    isLoading,
    statusOptions,
    t,
    statusValue,
    subjectGradeOptions,
    subjectsValue,
    divisionOptions,
    divisionsValue,
    showResetFilters,
    onReset,
    onFilter,
  ]);

  return (
    <>
      <div className={`${className}__outside`}>{FilterComponent}</div>
      <Collapsible
        trigger={
          <div className="applications-filters__trigger">
            <p>{t('filters')}</p>
            <ArrowIcon />
          </div>
        }
      >
        {FilterComponent}
      </Collapsible>
    </>
  );
};

export default ApplicationsFilters;
