import { Button, Modal } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFillVacancyMutation } from 'api/vacancies.api';
import { FillVacancyMethod } from 'searchality-data';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import useAppDispatch from 'hooks/useAppDispatch';

import './FinishFillingVacancyModal.styles.scss';

const FinishFillingVacancyModal: React.ForwardRefRenderFunction<
  ModalRef,
  {
    vacancyId: string;
    closeModal: () => void;
  }
> = ({ vacancyId, closeModal }, ref) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [fillCandidates, { isLoading }] = useFillVacancyMutation();

  const handleConfirm = useCallback(() => {
    fillCandidates({
      fillMethod: FillVacancyMethod.INTERNALLY,
      vacancyId,
    })
      .unwrap()
      .then(() => {
        closeModal();
        dispatch(popSuccess(t('vacancySuccesfllyFilled')));
      })
      .catch((e) => {
        dispatch(popError(e));
      });
  }, [closeModal, dispatch, fillCandidates, t, vacancyId]);

  return (
    <Modal
      ref={ref}
      title={t('finishFillingVacancyModal.title')}
      className="finish-filling-vacancy-modal"
      footer={
        <>
          <Button variant="outline" onClick={closeModal}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleConfirm} disabled={isLoading}>
            {t('Confirm')}
          </Button>
        </>
      }
    >
      <p>{t('finishFillingVacancyModal.description')}</p>
    </Modal>
  );
};
export default React.forwardRef(FinishFillingVacancyModal);
