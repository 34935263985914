import { useTranslation } from 'react-i18next';

const useMembersTableHeader = (isAssociation: boolean) => {
  const { t } = useTranslation();

  return [
    {
      id: '1',
      label: t('name'),
      key: 'name',
    },
    ...(!isAssociation
      ? [
          {
            id: '2',
            label: t('school'),
            key: 'school',
          },
        ]
      : []),
    {
      id: '3',
      label: t('teamPermission'),
      key: 'permission',
    },
  ];
};

export default useMembersTableHeader;
