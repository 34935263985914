import React, { useState } from 'react';
import classNames from 'classnames';
import GoBackButton from 'components/GoBackButton';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { ImageFile, User } from 'models/User';
import { useFormState } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import authSelectors from 'store/selectors/auth.selectors';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import { updateTeacherUser, updateUser } from 'store/slices/auth.slice';
import { popError } from 'store/slices/popNotifications.slice';
import DraftedProfileActions from './DraftedProfileActions';
import PublishedProfileActions from './PublishedProfileActions';
import { teacherProfileBuilderActions } from 'store/slices/teacherProfileBuilder.slice';
import TeacherProfileDataService from '../../services/TeacherProfileData.service';
import { TeacherFormValues } from '../../models/TeacherFormValues';
import { useTranslation } from 'react-i18next';
import useMobileTbHeaderNavigate from '../MoblileTeacherBuilderHeader/hooks/useMobileTbHeaderNavigate';

import './TPBTopActions.styles.scss';
import './TPBTopActions.styles.responsive.scss';

type TPBTopActionsProps = {
  noStatus?: boolean;
  onlyGoBack?: boolean;
  disabledSaveButton?: boolean;
  noGoBack?: boolean;
};

const TPBTopActions: React.FC<TPBTopActionsProps> = ({
  noGoBack,
  noStatus = false,
  disabledSaveButton,
}) => {
  const [submitting, setSumbitting] = useState(false);

  const dispatch = useAppDispatch();

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );

  const tpbStatus = useAppSelector(teacherProfileBuilderSelectors.selectStatus);

  const { values, dirtyFields } = useFormState();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { profileStatus } = useAppSelector(authSelectors.selectUser);

  const goBackRoute = useMobileTbHeaderNavigate();

  const baseClass = 'tpb-top-actions';

  const navigateToProfilePreview = () => {
    navigate('../preview');
  };

  const onSave = async (successCallback?: () => void) => {
    const { imageFile, educationPhilosophy, ...restOfValues } = values;

    if (dirtyFields['imageFile'] && imageFile) {
      await TeacherProfileDataService.submitTeacherProfileImage(
        imageFile[0],
        (userImageFile: ImageFile) =>
          dispatch(updateUser({ imageFile: userImageFile } as User)),
        (errorMessage: string) => dispatch(popError(errorMessage)),
      );
    }
    if (dirtyFields['educationPhilosophy'] && educationPhilosophy) {
      await TeacherProfileDataService.submitTeacherEducationPhilosophy(
        educationPhilosophy,
        (educationPhilosophyUrl: string) =>
          dispatch(
            updateUser({
              educationPhilosophyUrl: educationPhilosophyUrl,
            } as User),
          ),
        (errorMessage: string) => dispatch(popError(errorMessage)),
      );
    }

    setSumbitting(true);

    const changes = Object.keys(restOfValues)
      .filter((key) => dirtyFields[key])
      .reduce((acc, curr) => ({ ...acc, [curr]: restOfValues[curr] }), {});

    const submitObject = await TeacherProfileDataService.getTeacherSubmitObject(
      changes as TeacherFormValues,
    );

    const updateResult = await dispatch(
      updateTeacherUser({
        ...JSON.parse(JSON.stringify(submitObject)),
        filledSteps: finishedRoutes,
      } as User),
    );
    if (updateTeacherUser.fulfilled.match(updateResult)) {
      successCallback?.();
    } else {
      dispatch(popError(t(updateResult.payload as string)));
    }
    dispatch(teacherProfileBuilderActions.statusUpdate('SUBMITTED'));
    setSumbitting(false);
  };

  const classes = classNames(baseClass, {
    [`${baseClass}--full-profile`]: profileStatus === 'Published',
    [`${baseClass}--no-go-back`]: noGoBack,
  });

  return (
    <div className={classes}>
      {!noGoBack ? <GoBackButton to={`../${goBackRoute}`} /> : undefined}
      <div className={baseClass + '__right'}>
        {profileStatus === 'Published' ? (
          <PublishedProfileActions
            onSave={onSave}
            saveButtonDsiabled={disabledSaveButton}
            onCancel={() => navigate('../preview')}
          />
        ) : (
          <DraftedProfileActions
            onPreview={navigateToProfilePreview}
            noStatus={tpbStatus !== 'SUBMITTED' || submitting || noStatus}
            onCancel={() => navigate('../preview')}
          />
        )}
      </div>
    </div>
  );
};

export default TPBTopActions;
