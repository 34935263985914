import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationMetadataCard from '../../components/ApplicationMetadataCard';
import {
  ApplicantStage,
  ApplicationStatus,
  BoardingPositionRequirementStatus,
  EducationLevel,
  SchoolGroupStructureType,
  SchoolGroupType,
  SchoolResidentialType,
} from 'searchality-data';
import GoBackButton from 'components/GoBackButton';
import ApplicationInformationCard from '../../components/ApplicationInformationCard';
import { useTranslation } from 'react-i18next';
import DateService from 'services/Date.service';
import { useGetApplicationQuery } from 'api/teacherApplications.api';
import { DateTime } from 'luxon';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import PageLoader from 'components/PageLoader';
import utils, { numberPriceToCurrencyString } from 'utils';
import pdfImage from 'assets/images/pdf-image.webp';
import TeacherApplicationUploadRequestedDocuments from '../../components/TeacherApplicationUploadRequestedDocuments';
import JobPreviewBoardingSection from 'components/JobPreviewComponent/components/JobPreviewBoardingSection';

import './ApplicationPreview.styles.scss';

type ApplicationPreviewProps = {
  className?: string;
};

const ApplicationPreview: React.FC<ApplicationPreviewProps> = (props) => {
  const { className } = props;

  const { id: applicationId } = useParams() || {};
  const { _id: teacherId } = useAppSelector(authSelectors.selectUser) || {};

  const { data: application, isLoading } = useGetApplicationQuery(
    {
      applicationId,
      teacherId,
    },
    { refetchOnMountOrArgChange: true },
  );

  const navigate = useNavigate();

  const { t } = useTranslation();

  const baseClass = 'application-preview';
  const classes = classNames(baseClass, className);

  const handleFinishApplication = useCallback(async () => {
    navigate(
      `/job-application/${application?.vacancy?._id}/${application?._id}`,
    );
  }, [application, navigate]);

  const { status } = application || {};

  const isActiveApplication = [
    ApplicationStatus.SUBMITTED,
    ApplicationStatus.IN_PROCESS,
    ApplicationStatus.DRAFT,
    ApplicationStatus.INFORMATION_REQUESTED,
    ApplicationStatus.INFORMATION_SUBMITTED,
  ].includes(status);

  const isPreviousApplication = [
    ApplicationStatus.REJECTED_BY_SCHOOL,
    ApplicationStatus.VACANCY_CLOSED,
    ApplicationStatus.EXPIRED,
    ApplicationStatus.EXPIRED_HIRED_EXTERNALLY,
    ApplicationStatus.EXPIRED_HIRED_INTERNALLY,
  ].includes(status);

  const isSuccessfulApplication = [ApplicationStatus.ACCEPTED].includes(status);

  const navigateTo = isActiveApplication
    ? '/applications/active'
    : isPreviousApplication
    ? '/applications/previous'
    : isSuccessfulApplication
    ? '/applications/successful'
    : undefined;

  const mapSchoolType = useMemo(() => {
    if (!application) return;
    if (application?.schoolGroup) {
      if (application?.schoolGroup?.type === SchoolGroupType.PUBLIC_SCHOOL) {
        return application?.schoolGroup?.structureType ===
          SchoolGroupStructureType.GROUP
          ? t('publicSchoolGroup')
          : t('publicSchool');
      }
      return application?.schoolGroup?.structureType ===
        SchoolGroupStructureType.GROUP
        ? t('privateSchoolGroup')
        : t('privateSchool');
    }
  }, [application, t]);

  const startDateJobApplication = useMemo(() => {
    if (!application) return;

    if (
      DateService.toDateString(new Date(application?.vacancy?.startDate)) ===
      DateService.toDateString(new Date(0))
    ) {
      return t('applicationPreview.startDateFullTime', {
        startDate: t('immediately'),
      });
    }

    const { monthLong, day, year } = DateTime.fromJSDate(
      new Date(application?.vacancy?.startDate),
    );

    const startDate = `${monthLong} ${day}, ${year}`;

    return t('applicationPreview.startDateFullTime', { startDate });
  }, [t, application]);

  const salaryInfo = useMemo(() => {
    const salaryInfo = application?.vacancy?.salaryInfo;
    const { from, to, applicantStage } = salaryInfo || {};

    if (applicantStage !== ApplicantStage.IN_THE_JOB_POSTING) {
      return `${t(applicantStage)}`;
    }

    return `${numberPriceToCurrencyString(
      from,
    )} - ${numberPriceToCurrencyString(to)}`;
  }, [application?.vacancy?.salaryInfo, t]);

  const hasBenefits = useMemo(() => {
    const {
      isPrivateHealthInsuranceOffered,
      isPensionContributionOffered,
      isProfessionalDevelopmentOffered,
      isFinancialSupportForRelocationOffered,
      isAdditionalBenefitOffered,
      isTuitionForDependentsOffered,
    } = application?.vacancy || {};

    return [
      isPrivateHealthInsuranceOffered,
      isPensionContributionOffered,
      isProfessionalDevelopmentOffered,
      isFinancialSupportForRelocationOffered,
      isAdditionalBenefitOffered,
      isTuitionForDependentsOffered,
    ].some((el) => el);
  }, [application?.vacancy]);

  const benefits = useMemo(() => {
    if (!hasBenefits) return null;

    const {
      isPrivateHealthInsuranceOffered,
      isPensionContributionOffered,
      isProfessionalDevelopmentOffered,
      isFinancialSupportForRelocationOffered,
      isAdditionalBenefitOffered,
      isTuitionForDependentsOffered,
      privateHealthInsuranceOffered,
      pensionContributionOffered,
      financialSupportForRelocationOffered,
      professionalDevelopmentOffered,
      additionalBenefitOffered,
      dependentsDevelopmentOffered,
    } = application.vacancy;

    return (
      <li>
        {t('jobPreview.benefits')}
        <ul>
          {isPrivateHealthInsuranceOffered && (
            <li>
              {t('jobPreview.healthcareInsurance', {
                option: privateHealthInsuranceOffered ?? t('yes'),
              })}
            </li>
          )}
          {isPensionContributionOffered && (
            <li>
              {t('jobPreview.pensionContribution', {
                option: pensionContributionOffered ?? t('yes'),
              })}
            </li>
          )}
          {isFinancialSupportForRelocationOffered && (
            <li>
              {t('jobPreview.relocationStipend', {
                option: financialSupportForRelocationOffered ?? t('yes'),
              })}
            </li>
          )}
          {isProfessionalDevelopmentOffered && (
            <li>
              {t('jobPreview.professionalDevelopment', {
                developmentOptions: professionalDevelopmentOffered ?? t('yes'),
              })}
            </li>
          )}
          {isAdditionalBenefitOffered && (
            <li>
              {t('jobPreview.aditionalBenefits', {
                additionalBenefits: additionalBenefitOffered,
              })}
            </li>
          )}

          {isTuitionForDependentsOffered && (
            <li>
              {t('jobPreview.dependents', {
                options: dependentsDevelopmentOffered,
              })}
            </li>
          )}
        </ul>
      </li>
    );
  }, [application?.vacancy, hasBenefits, t]);

  const languages = useMemo(() => {
    if (application?.vacancy?.requiredLanguages?.length < 2) {
      return application?.vacancy?.requiredLanguages?.map(
        ({ language, level }) => (
          <li key={language}>
            <b>{t('jobPreview.language')}</b>
            {`${language} / ${level}`}
          </li>
        ),
      );
    }

    return (
      <li>
        <b>{t('jobPreview.languages')}</b>
        <ul>
          {application?.vacancy?.requiredLanguages?.map(
            ({ language, level }) => (
              <li key={language}>{`${language} / ${level}`}</li>
            ),
          )}
        </ul>
      </li>
    );
  }, [t, application?.vacancy?.requiredLanguages]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className={classes}>
      <GoBackButton to={navigateTo} />
      {application && (
        <>
          <ApplicationMetadataCard
            handleFinish={handleFinishApplication}
            data={{
              title: application.vacancy?.positionTitle,
              schoolName: application.schools
                ?.map((school) => school.name)
                .join(', '),
              date:
                application.status === ApplicationStatus.DRAFT
                  ? application.createdAt
                  : application.appliedAt,
              status: application.status,
              positionDivision: application.vacancy?.positionDivision,
              positionType: application.vacancy?.isFullTimeEmployment
                ? t('fullTime')
                : t('partTime'),
              schoolType: mapSchoolType,
              website: application.schools?.[0]?.website,
              vacancyStatus: application?.vacancy?.status,
            }}
          />
          {[
            ApplicationStatus.INFORMATION_REQUESTED,
            ApplicationStatus.INFORMATION_SUBMITTED,
          ].includes(application?.status) && (
            <TeacherApplicationUploadRequestedDocuments
              requestedDocuments={application?.requestedDocuments}
              isSubmitted={
                application?.status === ApplicationStatus.INFORMATION_SUBMITTED
              }
              questionsAndVideo={application?.requestedAdditionalInformation}
            />
          )}
          <ApplicationInformationCard>
            <h4>{t('aboutOurPosition')}</h4>
            <div
              dangerouslySetInnerHTML={utils.createMarkup(
                application?.vacancy?.roleDescription,
              )}
            />
            <h4 className="title-margin-top">{t('vacancyInformation')}</h4>
            <ul>
              <li>{startDateJobApplication}</li>
              <li>
                {t('jobPreview.employmentType')}
                {application?.vacancy?.isFullTimeEmployment
                  ? t('fullTime')
                  : t('jobPreview.partTime', {
                      details: `, ${application?.vacancy?.amountOfTimeRequired}`,
                    })}
              </li>
              <li>
                {t('applicationPreview.contractType', {
                  type: application.vacancy?.isPermanentContract
                    ? t('permanent')
                    : t('jobPreview.temporary', {
                        details: `, ${application.vacancy?.lengthOfContract}`,
                      }),
                })}
              </li>
            </ul>
            {application?.vacancy?.positionDescriptionUrl && (
              <div className={`application-preview__detailed-container`}>
                <p>{t('jobPreview.viewFullDescription')}</p>
                <a
                  href={application?.vacancy.positionDescriptionUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={pdfImage} alt="pdf" />
                  {t('detailedPositionDescription')}
                </a>
              </div>
            )}
            <h4 className="title-margin-top">{t('requirements')}</h4>
            <ul>
              <li>
                {t('applicationPreview.minimumDiplomaRequired', {
                  diploma: application.vacancy?.educationLevel,
                })}
              </li>
              <li>
                {application.vacancy?.yearsOfExperienceRequired > 0
                  ? t('applicationPreview.minimumExperienceRequired', {
                      count: application.vacancy?.yearsOfExperienceRequired,
                    })
                  : t('noExperienceRequired')}
              </li>
              <li>
                {t('applicationPreview.teachingSystem', {
                  system:
                    application.vacancy?.curriculumExperience !== 'None'
                      ? application.vacancy?.curriculumExperience
                      : t('noExperienceNeeded'),
                })}
              </li>
              {application.vacancy?.curriculumExperience !== 'None' && (
                <li>
                  {t('applicationPreview.minimumCurriculumExperience', {
                    curriculumExperience:
                      application.vacancy?.curriculumExperience,
                    years:
                      application.vacancy?.yearsOfCurriculumExperienceRequired,
                  })}
                </li>
              )}
              {languages}
              <li>
                {t('applicationPreview.workingRights', {
                  isRequired: application.vacancy?.isRightToWorkRequired
                    ? t('required')
                    : t('notRequired'),
                })}
              </li>
              {!!application.vacancy?.certificates?.length &&
                application.vacancy?.educationLevel ===
                  EducationLevel.TEACHING_DIPLOMA && (
                  <li>
                    {t('educationCertificates')}
                    <ul>
                      {application.vacancy.certificates?.map(
                        (certificate, index) => (
                          <li key={index}>{certificate}</li>
                        ),
                      )}
                    </ul>
                  </li>
                )}
            </ul>
          </ApplicationInformationCard>
          <ApplicationInformationCard>
            <h4>{t('whatWeOffer')}</h4>
            <ul>
              <li>
                {t('salary')}
                {salaryInfo}
              </li>
              {(application?.vacancy?.studentContactDays ||
                application?.vacancy?.studentNonContactDays) && (
                <li>
                  {t('applicationPreview.ContractTerms')}
                  <ul>
                    {application?.vacancy?.studentContactDays && (
                      <li>
                        {t('applicationPreview.contractDays', {
                          days: application?.vacancy?.studentContactDays,
                        })}
                      </li>
                    )}
                    {application?.vacancy?.studentNonContactDays && (
                      <li>
                        {t('applicationPreview.nonContractDays', {
                          days: application?.vacancy?.studentNonContactDays,
                        })}
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {benefits}
            </ul>
          </ApplicationInformationCard>
          {application.vacancy?.residentialType !==
            SchoolResidentialType.DAY_SCHOOL &&
            application.vacancy?.boardingPositionRequirementStatus !==
              BoardingPositionRequirementStatus.NOT_REQUIRED && (
              <ApplicationInformationCard>
                <h4>{t('boardingSupervisionAndBenefits')}</h4>
                <JobPreviewBoardingSection
                  additionalBenefits={
                    application.vacancy?.boardingPositionBenefits
                      ?.additionalBenefits
                  }
                  salary={application.vacancy?.boardingPositionBenefits?.salary}
                  isRequired={
                    application.vacancy?.boardingPositionRequirementStatus ===
                    BoardingPositionRequirementStatus.REQUIRED
                  }
                  roleDescription={
                    application.vacancy?.boardingPositionRequirements
                      ?.roleDescription
                  }
                  roleDescriptionUrl={
                    application.vacancy?.boardingPositionRequirementsUrl
                  }
                  noSubTitles
                />
              </ApplicationInformationCard>
            )}
        </>
      )}
    </div>
  );
};

export default ApplicationPreview;
