import {
  STANDALONE_PRICE,
  OLD_STANDALONE_PRICE,
  GROUP_INTITIAL_PRICE,
  OLD_GROUP_INTITIAL_PRICE,
  GROUP_ADDITIONAL_SCHOOL_PRICE,
  OLD_GROUP_ADDITIONAL_SCHOOL_PRICE,
  WEBSITE_INTEGRATION_PRICE,
  WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
  MATCHING_STANDALONE_PRICE,
  MATCHING_SCHOOL_GROUP_PRICE,
} from 'searchality-data';

const useSearchalityPricing = (hasOldPricingSystem: boolean) => {
  const samePrices = {
    WEBSITE_INTEGRATION_PRICE,
    WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
    MATCHING_STANDALONE_PRICE,
    MATCHING_SCHOOL_GROUP_PRICE,
  };

  if (hasOldPricingSystem) {
    return {
      ...samePrices,
      STANDALONE_PRICE: OLD_STANDALONE_PRICE,
      GROUP_INTITIAL_PRICE: OLD_GROUP_INTITIAL_PRICE,
      GROUP_ADDITIONAL_SCHOOL_PRICE: OLD_GROUP_ADDITIONAL_SCHOOL_PRICE,
    };
  }

  return {
    ...samePrices,
    STANDALONE_PRICE,
    GROUP_INTITIAL_PRICE,
    GROUP_ADDITIONAL_SCHOOL_PRICE,
  };
};

export default useSearchalityPricing;
