import { useFormState } from 'react-final-form';
import { SchoolGroupStructureType } from 'searchality-data';
import utils from 'utils';

export default function useSchoolInformationRoute(withPreview = true) {
  const {
    values: { type, structureType, schools, residentialType },
  } = useFormState();

  const shouldBeDisctrict =
    type === 'public-school' &&
    structureType === SchoolGroupStructureType.GROUP;

  const route = `${type}/residential-type/${utils.mapResidentialTypeToRoute(
    residentialType,
  )}/school-information/${shouldBeDisctrict ? 'district' : structureType}`;

  if (!withPreview || structureType === SchoolGroupStructureType.STANDALONE) {
    return route;
  }

  return schools?.length > 0 ? `${route}/schools-preview` : route;
}
