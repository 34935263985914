import httpClient from 'api/httpClient';
import env from 'env';
import { USA_PROXIMITY } from 'constants/general';

const getSuggestions = (searchString: string, params: Record<string, any>) =>
  httpClient.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchString}.json?proximity=${USA_PROXIMITY}&access_token=${env.MAPBOX_TOKEN}&autocomplete=true`,
    { params: { ...params, limit: 10 } },
  );

const reverseGeocoding = (longitude: string, latitude: string) => {
  return httpClient.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${env.MAPBOX_TOKEN}&autocomplete=true`,
  );
};

export default {
  getSuggestions,
  reverseGeocoding,
};
