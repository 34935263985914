import FormFieldLabel from 'components/FormFieldLabel';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import useCurriculumExperienceOptions from 'hooks/selectOptions/useCurriculumExperienceOptions';
import React, { useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { VacancyStatus, YearsOfExperience } from 'searchality-data';
import { required } from 'validations';

type CurriculumExperienceFieldsProps = {
  baseClass: string;
};

const CurriculumExperienceFields: React.FC<CurriculumExperienceFieldsProps> = (
  props,
) => {
  const { baseClass } = props;

  const { t } = useTranslation();

  const curriculumExperienceOptions = useCurriculumExperienceOptions();

  const {
    values: { curriculumExperience, status },
  } = useFormState();

  const isOngoingVacancyEdit = status === VacancyStatus.ONGOING;

  const yearsOfCurriculumExperience = useMemo(
    () =>
      Object.keys(YearsOfExperience)
        .filter((key) => key !== 'NONE')
        .map((key, index) => ({
          label: t(`yearsOfExperience.`, {
            years: YearsOfExperience[key as keyof typeof YearsOfExperience],
          }),
          value: (index + 1).toString(),
        })),
    [t],
  );

  return (
    <>
      <div>
        <FormFieldLabel text={t('vacancyWizard.step3.curriculumExpLabel')} />
        <p className={`${baseClass}__experience__sub`}>
          {t('vacancyWizard.step3.curriculumExpParagraph')}
        </p>
      </div>
      <Field
        name="curriculumExperience"
        options={curriculumExperienceOptions}
        label={t('curriculumExperience')}
        component={SelectField}
        validate={required()}
        required
        disabled={isOngoingVacancyEdit}
      />
      {curriculumExperience === 'Other' && (
        <Field
          name="curriculumExperienceOther"
          component={InputField}
          label={t('pleaseSpecify')}
          validate={required()}
          required
          disabled={isOngoingVacancyEdit}
        />
      )}
      {curriculumExperience !== 'None' && (
        <Field
          name="yearsOfCurriculumExperienceRequired"
          label={t('yearsOfExperienceForCurricula', { curriculumExperience })}
          component={SelectField}
          options={yearsOfCurriculumExperience}
          validate={required()}
          key={curriculumExperience}
          required
          disabled={isOngoingVacancyEdit}
        />
      )}
    </>
  );
};

export default CurriculumExperienceFields;
