import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PaginationInfo from 'components/PaginationInfo';
import { PaginationProps } from 'types';
import SortSelect from 'components/SortSelect';
import { useMatchedOpportunitiesOptions } from '../../hooks/useMatchedOpportunitiesOptions';

import './ApplicationsPaginationInfo.styles.scss';

type ApplicationsPaginationInfoProps = {
  className?: string;
  limit: number;
  pagination: PaginationProps;
  onSort: (value: string) => void;
  sortedBy: string;
};

const ApplicationsPaginationInfo: React.FC<ApplicationsPaginationInfoProps> = (
  props,
) => {
  const { className, limit, pagination, onSort, sortedBy } = props;

  const sortByOptions = useMatchedOpportunitiesOptions();

  const classes = classNames('applications-pagination-info', className);

  const { t } = useTranslation();

  return (
    <div className={classes}>
      <PaginationInfo
        name={t('applicationsSmall')}
        {...pagination}
        limit={limit}
      />
      <SortSelect
        options={sortByOptions}
        value={sortedBy}
        placeholder={t('sortBy')}
        multiple={false}
        onChange={({ value }) => {
          onSort(value);
        }}
      />
    </div>
  );
};

export default ApplicationsPaginationInfo;
