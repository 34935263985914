import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import GoBackButton from 'components/GoBackButton';
import { Button } from 'ncoded-component-library';
import InputField from 'components/InputField';
import { required } from 'validations';
import confirm from 'modules/confirm';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  useGetAssociationInformationQuery,
  useUpdateAssociationInformationMutation,
} from 'api/associations.api';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { ReactComponent as IconClose } from 'icons/Close.icon.svg';

import './EditAssociationInformation.styles.scss';

type EditAssociationInformationProps = {
  className?: string;
};

const EditAssociationInformation: React.FC<EditAssociationInformationProps> = (
  props,
) => {
  const { className } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const classes = classNames('edit-association-information', className);

  const { data } = useGetAssociationInformationQuery();

  const dispatch = useAppDispatch();
  const [updateAssociationInformation] =
    useUpdateAssociationInformationMutation();

  const initialValues = useMemo(
    () => ({
      name: data?.name,
      website: data?.website,
    }),
    [data?.name, data?.website],
  );

  const handleClose = useCallback(async () => {
    await confirm({
      title: t('EditAssociationInformation.cancel'),
      onSubmit: () => {
        navigate(-1);
      },
    });
  }, [navigate, t]);

  const handleSubmit = useCallback(
    async (values: { name?: string; website?: string }) => {
      updateAssociationInformation(values)
        .unwrap()
        .then(() => {
          dispatch(popSuccess(t('successfullyEditSchoolInformation')));
          navigate(-1);
        })
        .catch((e) => {
          dispatch(popServerError(e));
        });
    },
    [dispatch, navigate, t, updateAssociationInformation],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, dirty, invalid, submitting }) => (
        <form onSubmit={handleSubmit} className={classes}>
          <GoBackButton className="edit-association-information__go-back" />
          <Button
            variant="link"
            iconPosition="left"
            icon={<IconClose />}
            className="edit-association-information__close"
            onClick={handleClose}
          >
            {t('close')}
          </Button>
          <header>
            <h1>{t('EditAssociationInformation.title')}</h1>
            <p>{t('EditAssociationInformation.description')}</p>
          </header>
          <Field
            name="name"
            component={InputField}
            label={t('associationName')}
            validate={required()}
            required
          />
          <Field
            name="website"
            component={InputField}
            label={t('associationWebsite')}
            validate={required()}
            required
          />
          <Button type="submit" disabled={invalid || submitting || !dirty}>
            {t('save')}
          </Button>
        </form>
      )}
    />
  );
};

export default EditAssociationInformation;
