import { ComplexRoute } from 'router/components/Routes/Routes';
import ManageSubscription from './pages/ManageSubscription';
import { PaymentStatus, SchoolGroupStructureType } from 'searchality-data';
import EditUpcomingInvoiceDetails from './pages/EditUpcomingInvoiceDetails';
import BasicSubscriptionRoutes from './subrouters/BasicSubscription/BasicSubscription.routes';
import BasicSubscriptionRouter from './subrouters/BasicSubscription/BasicSubscription.router';
import AddNewSchool from './subrouters/AddNewSchool/AddNewSchool.router';
import AddNewSchoolRoutes from './subrouters/AddNewSchool/AddNewSchool.routes';
import MatchingSubscriptionRouter from './subrouters/MatchingSubscription/MatchingSubscription.router';
import MatchingSubscriptionRoutes from './subrouters/MatchingSubscription/MatchingSubscription.routes';

export default [
  {
    path: 'basic-subscription',
    element: BasicSubscriptionRouter,
    routes: BasicSubscriptionRoutes,
    validate: ({ auth: { user } }) =>
      user?.schoolGroup?.subscriptionId ||
      user?.schoolGroup?.schools?.length === 0
        ? '/my-account'
        : '',
  },
  {
    path: 'matching-subscription',
    element: MatchingSubscriptionRouter,
    routes: MatchingSubscriptionRoutes,
    validate: ({ auth: { user } }) =>
      user?.schoolGroup?.schools.every(
        (school) =>
          school?.matchingInformation?.paymentStatus === PaymentStatus.PAID,
      ) || !user?.schoolGroup?.subscriptionId
        ? '/my-account'
        : '',
  },
  {
    path: 'add-new-school',
    element: AddNewSchool,
    routes: AddNewSchoolRoutes,
    validate: ({ auth: { user } }) => {
      const { pathname } = window.document.location;

      return user?.schoolGroup?.structureType ===
        SchoolGroupStructureType.STANDALONE ||
        (user?.schoolGroup?.schools?.length ===
          user?.schoolGroup?.schoolCount &&
          !pathname.includes('finish'))
        ? '/my-account/school-settings'
        : '';
    },
  },
  {
    path: 'edit',
    element: EditUpcomingInvoiceDetails,
    validate: ({ auth: { user } }) =>
      !user?.schoolGroup?.subscriptionId ? '/my-account' : '',
  },
  {
    index: true,
    element: ManageSubscription,
  },
] as Array<ComplexRoute>;
