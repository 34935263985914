import { useMemo } from 'react';
import { School } from 'models/School';
import {} from 'searchality-data';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import useSearchalityPricing from 'hooks/useSearchalityPricing';

export const useNewSchoolPrices = (newSchools: School[]) => {
  const {
    schoolGroup: { schools, hasOldPricingSystem },
  } = useAppSelector(authSelectors.selectUser);

  const {
    GROUP_ADDITIONAL_SCHOOL_PRICE,
    WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
  } = useSearchalityPricing(hasOldPricingSystem);

  const schoolLength =
    schools.length < 2
      ? newSchools.length + schools.length - 2 > 0
        ? newSchools.length + schools.length - 2
        : 0
      : newSchools.length;

  const schoolPrice = useMemo(
    () => schoolLength * GROUP_ADDITIONAL_SCHOOL_PRICE,
    [GROUP_ADDITIONAL_SCHOOL_PRICE, schoolLength],
  );

  const websiteIntegration = useMemo(
    () => schoolLength * WEBSITE_ADDITIONAL_INTEGRATION_PRICE,
    [WEBSITE_ADDITIONAL_INTEGRATION_PRICE, schoolLength],
  );

  return {
    yearlyFee: schoolPrice,
    oneTimeFee: websiteIntegration,
    totalPrice: schoolPrice + websiteIntegration,
  };
};
