import React from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import GoBackButton from 'components/GoBackButton';
import { Field, useFormState } from 'react-final-form';
import DropzoneProfilePhoto from 'components/DropzoneProfileImage';

import './SchoolLogo.styles.scss';

type SchoolLogoProps = {
  className?: string;
  goBackRoute: string;
  handleSubmit: () => void;
  handleSkip: () => void;
};

const SchoolLogo: React.FC<SchoolLogoProps> = (props) => {
  const { className, goBackRoute, handleSubmit, handleSkip } = props;

  const { t } = useTranslation();

  const classes = classNames('school-logo', className);

  const {
    values: { school },
  } = useFormState();

  const { schoolLogo } = school || {};

  return (
    <form className={classes}>
      <GoBackButton to={goBackRoute} />
      <div>
        <h1>{t('SchoolLogo.title')}</h1>
        <p className="description">{t('SchoolLogo.description')}</p>
      </div>
      <div className="school-logo__hint-container">
        <p className="school-logo__hint-container__add">
          {t('SchoolLogo.add')}
        </p>
        <p className="description">{t('schoolLogoHint')}</p>
      </div>
      <Field
        name="school.schoolLogo"
        render={(props) => {
          const {
            input: { value, onChange },
          } = props;

          return (
            <DropzoneProfilePhoto
              value={value?.length ? value[0] : null}
              setFileCallback={(file: File) => onChange(file ? [file] : [])}
              cropShape="rect"
              aspect={5 / 3}
              maxSize={1048576}
              accept={{
                'image/svg+xml': [],
                'image/png': [],
                'image/jpeg': [],
              }}
            />
          );
        }}
      />
      <div className="school-logo__button-container">
        <Button disabled={!schoolLogo?.length} onClick={handleSubmit}>
          {t('continue')}
        </Button>
        <Button
          variant="outline"
          onClick={handleSkip}
          disabled={schoolLogo?.length}
        >
          {t('skipForNow')}
        </Button>
      </div>
    </form>
  );
};

export default SchoolLogo;
