import GoBackButton from 'components/GoBackButton';
import useAppSelector from 'hooks/useAppSelector';
import { School } from 'models/School';
import { Button, Checkbox } from 'ncoded-component-library';
import MatchingSchoolsContext from 'providers/MatchingSchools/MatchingSchools.context';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MATCHING_SCHOOL_GROUP_PRICE, PaymentStatus } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';
import { numberPriceToCurrencyString } from 'utils';
import SchoolCard from '../../../../pages/SchoolSettings/components/SchoolCard';

import './MatchingSelectSchools.styles.scss';

type MatchingSelectSchoolsProps = {
  schools?: School[];
};

const MatchingSelectSchools: React.FC<MatchingSelectSchoolsProps> = (props) => {
  const { schools: newSchools } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { schools } = useAppSelector(authSelectors.selectUser);

  const { manageSchoolsForMatching, schoolsForMatching } = useContext(
    MatchingSchoolsContext,
  );

  const isNewSchoolFlow = newSchools?.length;

  const listOfSchools = useMemo(
    () =>
      isNewSchoolFlow
        ? newSchools
        : schools.filter(
            ({ matchingInformation }) =>
              matchingInformation?.paymentStatus !== PaymentStatus.PAID,
          ),
    [schools, isNewSchoolFlow, newSchools],
  );

  return (
    <div className="matching-select-schools">
      <GoBackButton to={'../'} />
      <h1>{t('MatchingSelectSchools.title')}</h1>
      <p>{t('MatchingSelectSchools.description')}</p>
      <div className="matching-select-schools__checkbox-field">
        {!!listOfSchools?.length &&
          listOfSchools.map(({ _id, name }) => {
            const value = isNewSchoolFlow ? name : _id;

            return (
              <div key={_id} className="school-card--wrapper">
                <SchoolCard
                  onClick={() => manageSchoolsForMatching(value)}
                  _id={_id}
                  name={name}
                />
                <Checkbox
                  onChange={() => manageSchoolsForMatching(value)}
                  checked={schoolsForMatching.includes(value)}
                />
              </div>
            );
          })}
        <p>
          {t('MatchingSelectSchools.hint', {
            price: numberPriceToCurrencyString(MATCHING_SCHOOL_GROUP_PRICE),
          })}
        </p>
      </div>
      <Button
        disabled={!schoolsForMatching?.length}
        onClick={() => navigate('../checkout')}
      >
        {t('MatchingPricingCard.Right.standaloneAction')}
      </Button>
    </div>
  );
};

export default MatchingSelectSchools;
