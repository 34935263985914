import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  ApplicationSchoolStatus,
  PaymentStatus,
  VacancyType,
} from 'searchality-data';
import { ApplicationSchoolStatusWithFavourites, MongoWhere } from 'types';
import { FAVOURITE, MATCHING } from 'constants/general';
import { ReactComponent as FireIcon } from 'icons/Fire.icon.svg';
import {
  useCandidateFiltersInfoQuery,
  useGetVacancyQuery,
} from 'api/vacancies.api';
import { useNavigate } from 'react-router-dom';

import './VacancyCandidatesBoxFilters.styles.scss';

type VacancyCandidatesBoxFiltersProps = {
  vacancyId: string;
  currentStatus: ApplicationSchoolStatusWithFavourites;
  resetFilters: () => void;
  filterBy: (key: string, value: MongoWhere | any) => void;
};

const VacancyCandidatesBoxFilters: React.FC<
  VacancyCandidatesBoxFiltersProps
> = ({ vacancyId, currentStatus, resetFilters, filterBy }) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useState<URLSearchParams>();

  const navigate = useNavigate();

  const { data: vacancy } = useGetVacancyQuery(vacancyId);

  const { data: boxFiltersItems, isLoading } =
    useCandidateFiltersInfoQuery(vacancyId);

  const { matchingInformation } = vacancy || {};
  const { paymentStatus } = matchingInformation || {};

  const isMatchingPaid = paymentStatus === PaymentStatus.PAID;

  const getFilterCount = useCallback(
    (status: string) =>
      boxFiltersItems?.find(({ statusInSchool }) => statusInSchool === status)
        ?.count || 0,
    [boxFiltersItems],
  );

  const totalCount = useMemo(
    () =>
      boxFiltersItems
        ?.filter(
          ({ statusInSchool }) =>
            statusInSchool !== FAVOURITE && statusInSchool !== MATCHING,
        )
        .reduce((acc, curr) => acc + curr.count, 0) || 0,
    [boxFiltersItems],
  );

  const rejectCandidatesCount = useMemo(
    () => getFilterCount(ApplicationSchoolStatus.REJECTED),
    [getFilterCount],
  );

  const newCandidatesCount = useMemo(
    () => getFilterCount(ApplicationSchoolStatus.NEW),
    [getFilterCount],
  );

  const selectedCandidatesCount = useMemo(
    () => getFilterCount(ApplicationSchoolStatus.IN_PROCESS),
    [getFilterCount],
  );

  const favouriteCandidatesCount = useMemo(
    () => getFilterCount(FAVOURITE),
    [getFilterCount],
  );

  const matchingCandidatesCount = useMemo(
    () => getFilterCount(MATCHING),
    [getFilterCount],
  );

  const hiredCandidatesCount = useMemo(
    () => getFilterCount(ApplicationSchoolStatus.ACCEPTED),
    [getFilterCount],
  );

  const updateQueryParam = useCallback(
    (key: string) => {
      const updatedParams = new URLSearchParams();
      updatedParams.set(key, 'true'); // Set the updated value for 'myParam';

      setSearchParams(updatedParams);
      navigate({ search: `?${updatedParams.toString()}` });
    },
    [navigate],
  );

  const handleVacancyCandiatesFilter = useCallback(
    (val: string | string[], count: number) => {
      if (count === 0) return;

      filterBy('statusInSchool', { $in: Array.isArray(val) ? val : [val] });
    },
    [filterBy],
  );

  const handleFavouriteCandidatesFilter = useCallback(
    (count: number) => {
      if (count === 0) return;
      filterBy('isFavourite', true);
    },
    [filterBy],
  );

  const handleMatchingCandidatesFilter = useCallback(() => {
    filterBy('wasMatched', true);
  }, [filterBy]);

  const classes = classNames('box-filters', {
    'box-filters--loading': isLoading,
  });

  const arrayOfBoxFilters = useMemo(
    () => [
      <div
        className={classNames('box', {
          'box--active': currentStatus === undefined,
          'box--clickable': totalCount > 0,
        })}
        key="allCanidates"
        onClick={() => {
          resetFilters();
          updateQueryParam('totalCandidates');
        }}
      >
        <h2>{totalCount}</h2>
        <p>{t('Candidates.total')}</p>
      </div>,
      <div
        className={classNames('box', {
          'box--active': currentStatus === ApplicationSchoolStatus.NEW,
          'box--clickable': newCandidatesCount > 0,
        })}
        key="newCandidates"
        onClick={() => updateQueryParam('newCandidates')}
      >
        <h2>{newCandidatesCount}</h2>
        <p>{t('Candidates.new')}</p>
      </div>,
      <div
        className={classNames('box', {
          'box--active': currentStatus === ApplicationSchoolStatus.REJECTED,
          'box--clickable': rejectCandidatesCount > 0,
        })}
        key="rejectedCandidates"
        onClick={() => updateQueryParam('rejectedCandidates')}
      >
        <h2>{rejectCandidatesCount}</h2>
        <p>{t('Candidates.rejected')}</p>
      </div>,
      <div
        className={classNames('box', {
          'box--active': currentStatus === ApplicationSchoolStatus.IN_PROCESS,
          'box--clickable': selectedCandidatesCount > 0,
        })}
        key="inProcessCandidates"
        onClick={() => updateQueryParam('selectedCandidates')}
      >
        <h2>{selectedCandidatesCount}</h2>
        <p>{t('Candidates.qualified')}</p>
      </div>,
      <div
        className={classNames('box', {
          'box--active': currentStatus === ApplicationSchoolStatus.ACCEPTED,
          'box--clickable': hiredCandidatesCount > 0,
        })}
        key="hiredCandidates"
        onClick={() => updateQueryParam('hiredCandidates')}
      >
        <h2>{hiredCandidatesCount}</h2>
        <p>{t('Candidates.hired')}</p>
      </div>,
      <div
        className={classNames('box', {
          'box--clickable': favouriteCandidatesCount > 0,
          'box--active': currentStatus === FAVOURITE,
        })}
        key="favouriteCandidates"
        onClick={() => {
          updateQueryParam('favouriteCandidates');
        }}
      >
        <h2>{favouriteCandidatesCount}</h2>
        <p>{t('Candidates.favorites')}</p>
      </div>,
      ...(vacancy?.type === VacancyType.ACADEMIC
        ? [
            <div
              className={classNames(
                'box',
                {
                  'box--clickable':
                    !isMatchingPaid || matchingCandidatesCount > 0,
                },
                {
                  'box--active': currentStatus === MATCHING,
                },
              )}
              key="matchingCandidates"
              onClick={() => {
                (!isMatchingPaid || matchingCandidatesCount > 0) &&
                  updateQueryParam('wasMatched');
              }}
            >
              <h2>
                {isMatchingPaid && matchingCandidatesCount}
                <FireIcon />
              </h2>
              <p>{t('matchedApplicants')}</p>
              {!isMatchingPaid && <span>{t('premiumFeature')}</span>}
            </div>,
          ]
        : []),
    ],
    [
      currentStatus,
      favouriteCandidatesCount,
      matchingCandidatesCount,
      newCandidatesCount,
      rejectCandidatesCount,
      resetFilters,
      selectedCandidatesCount,
      t,
      totalCount,
      updateQueryParam,
      isMatchingPaid,
      vacancy?.type,
      hiredCandidatesCount,
    ],
  );

  const arrayOfFiltersToShow = useMemo(() => {
    if (!isMatchingPaid || vacancy?.type === VacancyType.NON_ACADEMIC)
      return arrayOfBoxFilters;

    const tempArray = arrayOfBoxFilters.slice();
    const element = tempArray.splice(5, 1)[0];
    tempArray.splice(1, 0, element);

    return tempArray;
  }, [arrayOfBoxFilters, isMatchingPaid, vacancy?.type]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (!searchParams || searchParams.keys().next().done) {
      updateQueryParam('totalCandidates');
    }

    setSearchParams(searchParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchParams) return;
    if (searchParams.get('favouriteCandidates')) {
      handleFavouriteCandidatesFilter(favouriteCandidatesCount);
    }

    if (searchParams.get('wasMatched')) {
      handleMatchingCandidatesFilter();
    }

    if (searchParams.get('newCandidates')) {
      handleVacancyCandiatesFilter(
        ApplicationSchoolStatus.NEW,
        newCandidatesCount,
      );
    }

    if (searchParams.get('rejectedCandidates')) {
      handleVacancyCandiatesFilter(
        ApplicationSchoolStatus.REJECTED,
        rejectCandidatesCount,
      );
    }

    if (searchParams.get('selectedCandidates')) {
      handleVacancyCandiatesFilter(
        ApplicationSchoolStatus.IN_PROCESS,
        selectedCandidatesCount,
      );
    }

    if (searchParams.get('hiredCandidates')) {
      handleVacancyCandiatesFilter(
        ApplicationSchoolStatus.ACCEPTED,
        hiredCandidatesCount,
      );
    }
  }, [
    favouriteCandidatesCount,
    handleFavouriteCandidatesFilter,
    handleMatchingCandidatesFilter,
    handleVacancyCandiatesFilter,
    newCandidatesCount,
    rejectCandidatesCount,
    searchParams,
    selectedCandidatesCount,
    hiredCandidatesCount,
  ]);

  return <div className={classes}>{arrayOfFiltersToShow}</div>;
};

export default VacancyCandidatesBoxFilters;
