import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import MatchingSchoolsContext from 'providers/MatchingSchools/MatchingSchools.context';
import PaymentMethodsContext from 'providers/PaymentMethods/PaymentMethods.context';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import authSelectors from 'store/selectors/auth.selectors';
import { useFormState } from 'react-final-form';
import CheckoutProvider from 'providers/Checkout';
import CheckoutPayment from '../CheckoutPayment';
import confirm from 'modules/confirm';
import { useMatchingSubscribeMutation } from 'api/payment.api';
import api from 'api';
import {
  updateClientSecretValue,
  updateCouponValue,
} from 'store/slices/payment.slice';
import { popServerError } from 'store/slices/popNotifications.slice';
import usePreviewMatchingPrice from '../../hooks/usePreviewMatchingPrice';
import { CheckoutCategory } from '../../types';

const CheckoutMatchingWrapper: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState('');
  const [checked, setChecked] = useState('');

  const [subscribe] = useMatchingSubscribeMutation();

  const { schoolsForMatching } = useContext(MatchingSchoolsContext);

  const { t } = useTranslation();

  const { paymentInfo } = useContext(PaymentMethodsContext);
  const { paymentMethods } = paymentInfo || {};
  const dispatch = useAppDispatch();

  const user = useAppSelector(authSelectors.selectUser);
  const promoCode = useAppSelector(({ payment }) => payment?.coupon);
  const navigate = useNavigate();

  const { data, isLoading: previewLoading } = usePreviewMatchingPrice();

  const { schoolGroupId } = user;
  const { values } = useFormState();

  const onSubscribe = useCallback(async () => {
    const subscribeRequest = async () => {
      setIsLoading(true);
      if (purchaseOrder) {
        try {
          await api.schools.addPaymentInfo({ purchaseOrder });
        } catch (error) {
          console.error(error);
        }
      }

      subscribe({
        schoolIds: schoolsForMatching,
        paymentMethodId: checked,
        promoCode,
        schoolGroupId,
      })
        .unwrap()
        .then((res) => {
          if (res?.clientSecret) {
            dispatch(updateClientSecretValue(res?.clientSecret));
          } else {
            dispatch(updateCouponValue(undefined));
            navigate('/my-account/subscription');
          }
        })
        .catch((e) => {
          dispatch(popServerError(e));
        })
        .finally(() => setIsLoading(false));
    };

    const handleSubscribe = async () => {
      if (!data?.isPromoCodeValid && values?.discountCode?.length) {
        return await confirm({
          title: t('ConfirmationModalPromoCode.title'),
          content: <p>{t('ConfirmationModalPromoCode.description')}</p>,
          onSubmit: subscribeRequest,
        });
      } else {
        await subscribeRequest();
      }
    };

    await handleSubscribe();
  }, [
    purchaseOrder,
    subscribe,
    schoolsForMatching,
    checked,
    promoCode,
    schoolGroupId,
    dispatch,
    navigate,
    data?.isPromoCodeValid,
    values?.discountCode?.length,
    t,
  ]);

  const checkedItem = useMemo(
    () => paymentMethods?.find(({ id }) => id === checked),
    [checked, paymentMethods],
  );

  useEffect(() => {
    if (checked) return;
    setChecked(
      () => paymentMethods?.find(({ isDefault }) => isDefault)?.id || '',
    );
  }, [checked, paymentMethods]);

  return (
    <CheckoutProvider
      data={{
        subscribe: onSubscribe,
        purchaseOrder,
        setPurchaseOrder,
        checked,
        setChecked,
        checkedItem,
        isLoading,
        stripePreviewObject: data,
        previewLoading,
        checkoutCategory: CheckoutCategory.DEFAULT,
        setIsLoading,
      }}
    >
      <CheckoutPayment />
    </CheckoutProvider>
  );
};

export default CheckoutMatchingWrapper;
