import i18n from 'i18next';
import { DateTime } from 'luxon';
import { PositionDivision, PositionType } from 'searchality-data';

//Final Form use undefined value for successful validation
const VALID: any = undefined;

const NUMBERS_ONLY = /^\d+$/;
const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^\d\w]).{8,}$/;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const WEBSITE_REGEX = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z0-9]+\/?$/;

export const WEBSITE_REGEX_WITH_PATH = /^[^\s]+\.[^\s]{2,}$/;

const FIVEDIGITS_REGEX = /^\d{5}$/;
const YOUTUBE_REGEX =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;

export const composeValidators =
  (...validators: any) =>
  (value: any) =>
    validators.reduce(
      (error: any, validator: any) => error || validator(value),
      VALID,
    );

export const isNonEmptyString = (value: any) => {
  return value.trim().length > 0;
};

export const required = (errorMessage?: string) => (value: any) => {
  const returnMessage = errorMessage || i18n.t('requiredField');

  if (typeof value === 'undefined' || value === null) {
    // undefined or null values are invalid
    return returnMessage;
  }

  if (typeof value === 'string') {
    // string must be nonempty when trimmed
    return isNonEmptyString(value) ? VALID : returnMessage;
  }

  if (Array.isArray(value) && value?.length === 0) {
    return returnMessage;
  }

  return VALID;
};

export const requiredTextEditor = (errorMessage?: string) => (value: any) => {
  const returnMessage = errorMessage || i18n.t('requiredField');

  if (typeof value === 'undefined' || value === null) {
    // undefined or null values are invalid
    return returnMessage;
  }

  if (typeof value === 'string') {
    // string must be nonempty when trimmed
    if (value === '' || value === '<p></p>\n') {
      return returnMessage;
    }
    return isNonEmptyString(value) ? VALID : returnMessage;
  }

  if (Array.isArray(value) && value?.length === 0) {
    return returnMessage;
  }

  return VALID;
};

export const passwordValidation = (errorMessage?: string) => (value: any) => {
  if (value) {
    const returnMessage = errorMessage || i18n.t('passwordError');
    return PASSWORD_REGEX.test(value) ? VALID : returnMessage;
  }

  return VALID;
};

export const emailValidation = (errorMessage?: string) => (value: any) => {
  if (value) {
    const returnMessage = errorMessage || i18n.t('emailError');
    return EMAIL_REGEX.test(value) ? VALID : returnMessage;
  }

  return VALID;
};

export const websiteValidation = (errorMessage?: string) => (value: any) => {
  if (value) {
    const returnMessage = errorMessage || i18n.t('websiteError');
    return WEBSITE_REGEX.test(value) ? VALID : returnMessage;
  }

  return VALID;
};

export const websiteWithPathValidation =
  (errorMessage?: string) => (value: any) => {
    if (value) {
      const returnMessage = errorMessage || i18n.t('websiteError');
      return WEBSITE_REGEX_WITH_PATH.test(value) ? VALID : returnMessage;
    }

    return VALID;
  };

export const onlyNumbers = (errorMessage?: string) => (value: string) => {
  const returnMessage = errorMessage || i18n.t('onlyNumbersError');
  return NUMBERS_ONLY.test(value) ? VALID : returnMessage;
};

export const fiveDigits = (errorMessage?: string) => (value: string) => {
  const returnMessage = errorMessage || i18n.t('fiveDigitsError');
  return FIVEDIGITS_REGEX.test(value) ? VALID : returnMessage;
};

export const positiveNumber = (errorMessage?: string) => (value: string) => {
  const returnMessage = errorMessage || i18n.t('positiviNumberError');
  if (value) {
    return +value > 0 ? VALID : returnMessage;
  }

  return VALID;
};

export const youtubeLink = (errorMessage?: string) => (value: string) => {
  const returnMessage = errorMessage || i18n.t('youtubeLinkError');
  if (value) {
    return YOUTUBE_REGEX.test(value) ? VALID : returnMessage;
  }

  return VALID;
};

export const checkForInvalidValue =
  (errorMessage: string, invalidValue?: string) => (value: string) => {
    const returnMessage = errorMessage;
    if (value && invalidValue) {
      return invalidValue !== value ? VALID : returnMessage;
    }

    return VALID;
  };

export const validateDateInFuture =
  (errorMessage?: string) => (value: string | Date) => {
    const returnMessage = errorMessage || i18n.t('dateCannotBeInPast');

    if (!value) return returnMessage;

    const currentDate = DateTime.local().startOf('day');
    const givenDate = DateTime.fromJSDate(new Date(value)).startOf('day');

    return givenDate < currentDate ? returnMessage : VALID;
  };

export const exactNumber =
  (requiredNumber: number, errorMessage?: string) => (value: any[]) => {
    const returnMessage =
      errorMessage || i18n.t('exactNumberError', { requiredNumber });
    if (requiredNumber !== value?.length) {
      return returnMessage;
    }

    return VALID;
  };

export const atLeastNumberOfEntries =
  (requiredNumber: number, errorMessage?: string) => (value: any[]) => {
    const returnMessage =
      errorMessage || i18n.t('atLeastNumberError', { requiredNumber });
    if (requiredNumber > (value?.length || 0)) {
      return returnMessage;
    }

    return VALID;
  };

export const requiredRange =
  (max: number, min: number, errorMessage?: string) => (value: string) => {
    const returnMessage =
      errorMessage || i18n.t('requiredRangeMessage', { min, max });

    if (parseInt(value) > max || parseInt(value) < min) {
      return returnMessage;
    }

    return VALID;
  };

export const maxNumberValidation =
  (max: number, errorMessage?: string) => (value: string) => {
    const returnMessage =
      errorMessage || i18n.t('maxNumberValidationError', { max });

    if (value === undefined) {
      return VALID;
    }

    if (parseInt(value) > max) {
      return returnMessage;
    }

    return VALID;
  };

export const minNumberValidation =
  (min: number, errorMessage?: string) => (value: string) => {
    const returnMessage =
      errorMessage || i18n.t('minNumberValidation', { min });

    if (parseInt(value) < min) {
      return returnMessage;
    }

    return VALID;
  };

export const requiredSubjectGrade = (value: any, allValues: any) => {
  const returnMessage = i18n.t('requiredField');

  const { positionType, positionDivision } = allValues || {};

  if (!value && (!positionDivision || !positionType)) {
    return returnMessage;
  }

  if (
    positionType !== PositionType.LEADERSHIP_ADMINISTRATOR &&
    positionType !== PositionType.SPECIALIST &&
    (positionDivision === PositionDivision.PRE_K ||
      positionDivision === PositionDivision.SCHOOLWIDE_ADMINISTRATION)
  ) {
    return VALID;
  }

  return value ? VALID : returnMessage;
};

export const salaryInfoFormValidation = (salaryInfo: any) => {
  if (parseInt(salaryInfo?.from) > parseInt(salaryInfo?.to))
    return {
      salaryInfo: {
        from: i18n.t('fromBiggerThenTo'),
      },
    };
  if (parseInt(salaryInfo?.to) < parseInt(salaryInfo?.from))
    return {
      salaryInfo: {
        from: i18n.t('toLessThenFrom'),
      },
    };
  return {};
};
