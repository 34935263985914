import React, { useMemo } from 'react';
import classNames from 'classnames';
import { ReactComponent as WorkExperienceIcon } from 'icons/WorkExperience.icon.svg';
import { ReactComponent as EducationIcon } from 'icons/Education.icon.svg';
import { ReactComponent as CalendarIcon } from 'icons/Calendar.icon.svg';
import { useGetMatchingOpportunityQuery } from 'api/matching.api';
import { useParams } from 'react-router-dom';
import DateService from 'services/Date.service';
import { useTranslation } from 'react-i18next';
import QualificationsService from 'services/Qualifications.service';
import useInnerWidth from 'hooks/useInnerWIdth';

import './OpportunityMetadata.styles.scss';
import './OpportunityMetadata.styles.responsive.scss';

type OpportunityMetadataProps = {
  className?: string;
};

const OpportunityMetadata: React.FC<OpportunityMetadataProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const baseClass = 'opportunity-metadata';
  const classes = classNames(baseClass, className);

  const windowWidth = useInnerWidth();

  const { opportunityId } = useParams();
  const { data: oppotrunity } = useGetMatchingOpportunityQuery(opportunityId);

  const { vacancy, qualifications } = oppotrunity || {};
  const { positionTitle, schools, startDate, isFullTimeEmployment } =
    vacancy || {};

  const { numberOfMatchingFields, numberOfTotalFields } = qualifications || {};

  const [variant, message] = useMemo(
    () =>
      QualificationsService.getCandidatesQualificationVariantAndMessage(
        numberOfMatchingFields,
        numberOfTotalFields,
        true,
      ),
    [numberOfMatchingFields, numberOfTotalFields],
  );

  return (
    <div className={classes}>
      <div className={`${baseClass}__top`}>
        <div className={`${baseClass}__top__school-information`}>
          <p>{schools?.[0]?.name}</p>
          <span className={`${baseClass}__top__school-information__state`}>
            <p>{schools?.[0]?.state}</p>
            <p>{`${schools?.[0]?.city.split(', ')?.[0]}, ${
              schools?.[0]?.postcode
            }`}</p>
          </span>
        </div>
        <h3 className={`${baseClass}__top__title`}>{positionTitle}</h3>
      </div>
      <div className={`${baseClass}__bottom`}>
        <span>
          <WorkExperienceIcon />
          <label>{t('employmentType')}:</label>
          <p>{isFullTimeEmployment ? t('fullTime') : t('partTime')}</p>
        </span>
        <span>
          <EducationIcon />
          <label>{t('qualifyingRate')}:</label>
          <p>
            <span className={classNames(`${baseClass}--${variant}`)}>
              {' '}
              {`${numberOfMatchingFields} / ${numberOfTotalFields}`}
            </span>{' '}
            {windowWidth > 370 && `- ${message}`}
          </p>
        </span>
        <span>
          <CalendarIcon />
          <label>{t('startingDate')}:</label>
          <p>
            {startDate === null
              ? t('immediately')
              : DateService.getUSADateFormatLong(startDate)}
          </p>
        </span>
      </div>
    </div>
  );
};

export default OpportunityMetadata;
