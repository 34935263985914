import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import CheckoutContext from 'providers/Checkout/Checkout.context';
import OrderItem from '../OrderItem';
import { useTranslation } from 'react-i18next';
import { SchoolGroupStructureType, StripeProductType } from 'searchality-data';
import MatchingSchoolsContext from 'providers/MatchingSchools/MatchingSchools.context';
import { numberPriceToCurrencyString } from 'utils';
import { School } from 'models/School';
import { CheckoutCategory } from '../../types';

type OrderMatchingItemProps = {
  className?: string;
  schools: School[];
};

const OrderMatchingItem: React.FC<OrderMatchingItemProps> = (props) => {
  const { className, schools } = props;

  const { t } = useTranslation();

  const { schoolGroup } = useAppSelector(authSelectors.selectUser);
  const { structureType } = schoolGroup || {};
  const { schoolsForMatching } = useContext(MatchingSchoolsContext);
  const { stripePreviewObject, checkoutCategory } = useContext(CheckoutContext);

  const isStandalone = structureType === SchoolGroupStructureType.STANDALONE;
  const isSubscriptionCheckout = checkoutCategory === CheckoutCategory.BASIC;
  const isNewSchoolCheckout = checkoutCategory === CheckoutCategory.NEW_SCHOOL;

  const classes = classNames('order-matching-item', className);

  const matchingLineObject = useMemo(() => {
    const productType = isSubscriptionCheckout
      ? isStandalone
        ? StripeProductType.ANNUAL_MATCHING_STANDALONE_SUBSCRIPTION
        : StripeProductType.ANNUAL_MATCHING_SCHOOL_GROUP_SUBSCRIPTION
      : isStandalone
      ? StripeProductType.MONTHLY_MATCHING_STANDALONE_SUBSCRIPTION
      : StripeProductType.MONTHLY_MATCHING_SCHOOL_GROUP_SUBSCRIPTION;

    return stripePreviewObject?.lines?.data?.filter((element) => {
      return element?.price?.product?.name === productType;
    });
  }, [stripePreviewObject?.lines?.data, isStandalone, isSubscriptionCheckout]);

  const matchingBasePrice = matchingLineObject?.reduce(
    (prev, curr) => prev + curr.amount,
    0,
  );

  const discountAmmount = useMemo(() => {
    return matchingLineObject?.reduce(
      (prev, curr) => prev + curr.discount_amounts?.[0]?.amount,
      0,
    );
  }, [matchingLineObject]);

  const matchingServicePrice = useMemo(() => {
    if (discountAmmount > 0) {
      return matchingBasePrice - discountAmmount;
    }
    return matchingBasePrice;
  }, [discountAmmount, matchingBasePrice]);

  const matchingPriceComponent = useMemo(
    () => (
      <>
        {discountAmmount > 0 && (
          <span>{numberPriceToCurrencyString(matchingBasePrice)}</span>
        )}{' '}
        {numberPriceToCurrencyString(matchingServicePrice)}
      </>
    ),
    [discountAmmount, matchingBasePrice, matchingServicePrice],
  );

  const matchingItemName = useMemo(() => {
    return t(
      `OrderSummary.OrderItem.MatchingItem.${
        isStandalone ? 'standaloneName' : 'groupName'
      }`,
      {
        name: schools
          ?.filter(({ _id, name }) =>
            schoolsForMatching.includes(isNewSchoolCheckout ? name : _id),
          )
          .map((school) => school.name)
          .join(', '),
      },
    );
  }, [isStandalone, schools, schoolsForMatching, t, isNewSchoolCheckout]);

  if (!schoolsForMatching.length) {
    return null;
  }

  return (
    <OrderItem
      className={classes}
      name={matchingItemName}
      price={matchingPriceComponent}
    />
  );
};

export default OrderMatchingItem;
