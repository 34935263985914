import React, { useEffect } from 'react';
import classNames from 'classnames';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import { Field, useForm, useFormState } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import TPBTopActions from '../../components/TPBTopActions';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import { useTranslation } from 'react-i18next';
import TPBBottomActions from '../../components/TPBBottomActions';
import useTargetLocationOptions from '../../hooks/useTargetLocationOptions';
import MultipleSelectField from 'components/MultipleSelectField';
import { TargetLocations as TargetLocationsType } from 'searchality-data';

import './TargetLocations.styles.scss';
import './TargetLocations.styles.responsive.scss';

type TargetLocationsProps = {
  className?: string;
};

const TargetLocations: React.FC<TargetLocationsProps> = (props) => {
  const { className } = props;

  const classes = classNames('target-locations', className);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );
  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const { submit, change } = useForm();
  const {
    dirtyFields,
    submitting,
    valid,
    values: { targetLocations },
  } = useFormState();

  const targetLocationOptions = useTargetLocationOptions();

  const isSubmitable = dirtyFields['targetLocations'];

  const onSubmit = () => {
    if (isSubmitable || !valid || !finishedRoutes.includes(currentRoute)) {
      submit();
    } else {
      navigate('/profile');
    }
  };

  const onSkip = () => {
    navigate('/profile');
  };

  useEffect(() => {
    if (
      targetLocations?.length > 1 &&
      targetLocations?.includes(TargetLocationsType.RELOCATE_ANYWHERE)
    ) {
      change('targetLocations', [TargetLocationsType.RELOCATE_ANYWHERE]);
    }
  }, [change, targetLocations]);

  return (
    <div className={classes}>
      <TPBTopActions disabledSaveButton={submitting} />
      <TPBPageContent fullWidth>
        <TPBPageHeading
          text={t('teacherProfileBuilder.targetLocations.title')}
          description={t('teacherProfileBuilder.targetLocations.description')}
        />
        <Field
          name="targetLocations"
          component={MultipleSelectField}
          options={targetLocationOptions}
          key={targetLocations}
          placeholder={t('teacherProfileBuilder.targetLocations.title')}
          searchable
        />
        <TPBBottomActions
          canSkip
          onSkip={onSkip}
          onSubmit={onSubmit}
          submitBtnDisabled={submitting}
        />
      </TPBPageContent>
    </div>
  );
};

export default TargetLocations;
