import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterData } from 'types';
import useAppDispatch from 'hooks/useAppDispatch';
import { registerTeacherUser } from 'store/slices/auth.slice';
import MainLayout from 'components/MainLayout';
import SignupForm from '../Signup/components/SignupForm';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import searchalityLogo from 'assets/images/searchality-logo.webp';
import { LEARN_MORE_LINK } from 'constants/links';
import SelectSearchalityVersion from 'components/SelectSearchalityVersion';
import env from 'env';

type TeacherSignupProps = {
  className?: string;
};

const TeacherSignup: React.FC<TeacherSignupProps> = (props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (values: RegisterData) => await dispatch(registerTeacherUser(values)),
    [dispatch],
  );

  return (
    <MainLayout className="auth" headerShadow>
      <div className="auth-signup auth-signup--teacher">
        <div className="auth-signup__titles">
          <img src={searchalityLogo} alt="Logo" />
          <h1>{t('InformationSection.teacherTitle')}</h1>
          <p>{t('InformationSection.paragraph')}</p>
          <a href={LEARN_MORE_LINK} target="_blank" rel="noreferrer">
            {t('TeacherSignup.link')}
          </a>
        </div>
        <div className="auth-signup__description">
          <p className="subtitle">{t('InformationSection.areYouASchool')}</p>
          <p>{t('InformationSection.areYouASchoolDescription')}</p>
          <Button
            variant="outline"
            onClick={() => navigate('../school-signup')}
          >
            {t('InformationSection.signInAsSchool')}
          </Button>
        </div>
        <div className="auth-signup__form">
          <SelectSearchalityVersion
            description={t('selectVersionTeacher')}
            link={`${env.SEARCHALITY_INT_VERSION}/auth/teacher-signup`}
          />
          <SignupForm onSubmit={handleSubmit} />
        </div>
      </div>
    </MainLayout>
  );
};

export default TeacherSignup;
