import React, { InputHTMLAttributes, ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import { Input as LibInput } from 'ncoded-component-library';

import './Input.styles.scss';
import Anim from 'components/animations';

export type FormControl = {
  label?: string;
  error?: ReactNode;
  hint?: ReactNode;
  wrapperClassName?: string;
};

type InputProps = FormControl & InputHTMLAttributes<HTMLInputElement>;

const Input: React.FC<InputProps> = (props) => {
  const { className, wrapperClassName, label, error, hint, ...rest } = props;

  const classes = classNames(
    's-input',
    { 's-input--error': !!error },
    className,
  );

  useEffect(() => {
    // Function to set padding based on source element height
    const setPadding = () => {
      const elements = document.querySelectorAll('.s-input');

      elements.forEach((element) => {
        const inputElement = element.querySelector('input');
        const labelElement = element.querySelector('label');

        if (inputElement && labelElement) {
          // Measure the height of the label element
          const labelHeight = labelElement.clientHeight;
          // Set the padding of the input element
          inputElement.style.paddingTop = `${labelHeight + 13}px`;
        }
      });
    };

    // Ensure DOM is loaded
    const handleDOMContentLoaded = () => {
      setPadding();
    };

    // Check if the document is already loaded
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
    } else {
      handleDOMContentLoaded();
    }

    // Cleanup event listener
    return () => {
      document.removeEventListener('DOMContentLoaded', handleDOMContentLoaded);
    };
  }, []);

  return (
    <div className={classNames('s-input-wrapper', wrapperClassName)}>
      <LibInput className={classes} innerLabel={label} {...rest} />
      <Anim.Collapse active={!!error}>
        <p className="s-input__error">{error}</p>
      </Anim.Collapse>
      {hint && <p className="s-input__hint">{hint}</p>}
    </div>
  );
};

export default Input;
