import React, { useCallback } from 'react';
import MainLayout from 'components/MainLayout';
import { useTranslation } from 'react-i18next';
import useAppDispatch from 'hooks/useAppDispatch';
import { RegisterData } from 'types';
import { confirmAssociationInvitation } from 'store/slices/auth.slice';
import useQParamsActions from 'hooks/useQueryParams';
import SignupForm from '../Signup/components/SignupForm';

import './AssociationSignup.styles.scss';
import './AssociationSignup.styles.responsive.scss';

const AssociationSignup: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { params: queryParams } = useQParamsActions();

  const { token, email } = queryParams || {};

  const handleOnSubmit = useCallback(
    async (values: RegisterData) => {
      if (token && email) {
        await dispatch(confirmAssociationInvitation({ ...values, token }));
      }
    },

    [dispatch, email, token],
  );

  return (
    <MainLayout className="auth" headerShadow>
      <div className="auth-signup">
        <div className="auth-signup__titles">
          <h1 className="school">{t('AssociationSignup.title')}</h1>
          <p>{t('AssociationSignup.description')}</p>
        </div>
        <SignupForm onSubmit={handleOnSubmit} />
      </div>
    </MainLayout>
  );
};

export default AssociationSignup;
