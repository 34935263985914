import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import classNames from 'classnames';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { Button, Checkbox, Modal } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import useCallbackRef from 'hooks/useCallbackRef';
import VacancyFillFactory from '../VacancyFillFactory';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError, popSuccess } from 'store/slices/popNotifications.slice';
import { useFillVacancyMutation } from 'api/vacancies.api';
import { useParams } from 'react-router-dom';
import ValueContext from 'providers/ValueContext/Value.context';
import { FillVacancyMethod } from 'searchality-data';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import api from 'api';
import credentialsService from 'services/credentialsService';
import ComponentWithTooltip from 'components/ComponentWithTooltip';

import './VacancyFillCandidateModal.styles.scss';

type VacancyFillCandidateModalProps = {
  className?: string;
  vacancyId: string;
  closeModal: () => void;
};

const VacancyFillCandidateModal: React.ForwardRefRenderFunction<
  ModalRef,
  VacancyFillCandidateModalProps
> = (props, ref) => {
  const {
    schoolGroup: { isCandidateCsvExportEnabled },
  } = useAppSelector(authSelectors.selectUser);
  const { className, closeModal } = props;
  const [fillCandidates, { isLoading }] = useFillVacancyMutation();
  const dispatch = useAppDispatch();
  const { vacancyId } = useParams();

  const [exportCsv, setExportCsv] = useState(false);

  const { t } = useTranslation();

  const baseClass = 'vacancy-fill-candidate-modal';

  const classes = classNames(baseClass, className);

  const { selectedIds, clearIds } = useContext(ValueContext);
  const [modal, modalRef] = useCallbackRef<ModalRef>();

  useImperativeHandle(ref, () => modal, [modal]);

  const onFillCandidates = async (shouldRemainOpen: boolean) => {
    try {
      await fillCandidates({
        fillMethod: FillVacancyMethod.FROM_CANDIDATE_APPLICATIONS,
        vacancyId,
        applicantIds: selectedIds,
        shouldRemainOpen,
      }).unwrap();

      if (exportCsv) {
        for (const id of selectedIds) {
          await api.schools.downloadCandidateCsv(id, credentialsService.token);
        }
      }

      closeModal();
      dispatch(popSuccess(t('vacancySuccesfllyFilled')));
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : String(e);
      dispatch(popError(errorMessage));
    }
  };

  useEffect(() => {
    if (modal?.openState === false) {
      clearIds();
    }
  }, [modal?.openState, clearIds]);

  return (
    <Modal
      className={classes}
      ref={modalRef}
      title={t('vacancyFillCandidateModal.title')}
      footer={
        <>
          <ComponentWithTooltip tooltipContent={t('exportCsvHint')}>
            <Checkbox
              label={t('exportCsvCheckbox')}
              checked={exportCsv}
              disabled={!isCandidateCsvExportEnabled}
              onChange={(value) => setExportCsv(value as boolean)}
            />
          </ComponentWithTooltip>
          <div className="footer-actions">
            <Button variant="outline" onClick={props.closeModal}>
              {t('Cancel')}
            </Button>
            <ComponentWithTooltip
              tooltipContent={t(
                'vacancyFillCandidateModal.confirmAndRemainOpenTooltip',
              )}
            >
              <Button
                disabled={!selectedIds[0] || isLoading}
                onClick={() => onFillCandidates(true)}
              >
                {t('vacancyFillCandidateModal.confirmAndRemainOpenButton')}
              </Button>
            </ComponentWithTooltip>
            <ComponentWithTooltip
              tooltipContent={t(
                'vacancyFillCandidateModal.confirmButtonTooltip',
              )}
            >
              <Button
                disabled={!selectedIds[0] || isLoading}
                onClick={() => onFillCandidates(false)}
              >
                {t('vacancyFillCandidateModal.confirmButton')}
              </Button>
            </ComponentWithTooltip>
          </div>
        </>
      }
    >
      {modal?.openState && <VacancyFillFactory />}
    </Modal>
  );
};

export default forwardRef(VacancyFillCandidateModal);
