import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NonAcademicVacancyCategory } from 'searchality-data';

const useCategoryOptions = (categories?: NonAcademicVacancyCategory[]) => {
  const { t } = useTranslation();

  const entries = useMemo(() => Object.entries(NonAcademicVacancyCategory), []);

  if (categories) {
    return categories?.map((value) => ({
      label: t(
        `NonAcademicVacancyCategory.${
          entries.find((element) => element?.[1] === value)?.[0]
        }`,
      ),
      value,
    }));
  }

  return entries.map(([key]) => ({
    label: t(`NonAcademicVacancyCategory.${key}`),
    value:
      NonAcademicVacancyCategory[
        key as keyof typeof NonAcademicVacancyCategory
      ],
  }));
};

export default useCategoryOptions;
