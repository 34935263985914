import React from 'react';
import SchoolLogo from '../SchoolLogo/SchoolLogo.component';
import { useLocation, useNavigate } from 'react-router-dom';

const SchoolLogoGroupWrapper: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isAddNewSchool = pathname.includes('add-new-school');

  const isStandalone = pathname.includes('standalone');

  return (
    <SchoolLogo
      goBackRoute={isAddNewSchool || isStandalone ? '..' : '../schools'}
      handleSubmit={() => navigate('../part-of-association')}
      handleSkip={() => navigate('../part-of-association')}
    />
  );
};

export default SchoolLogoGroupWrapper;
