import { useTranslation } from 'react-i18next';
import { TargetLocations } from 'searchality-data';

export default () => {
  const { t } = useTranslation();

  return Object.entries(TargetLocations).map(([key, value]) => ({
    label: t(`TargetLocationsOptions.${key}`),
    value,
  }));
};
