import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

enum CurriculumExperience {
  NO_EXPERIENCE = 'None',
  ADVANCED_PLACEMENT = 'Advanced Placement',
  COMMON_CORE = 'Common Core',
  IB_DP = 'IB DP',
  IB_MVP = 'IB MYP',
  IB_PYP = 'IB PYP',
  MONTESSORI = 'Montessori',
  WALDORF = 'Waldorf',
  OTHER = 'Other',
}

type CurriculumExperienceKey = keyof typeof CurriculumExperience;

const useCurriculumExperienceOptions = (isWithoutNone?: boolean) => {
  const { t } = useTranslation();

  const curriculumExperienceOptions = useMemo(() => {
    const options = Object.keys(CurriculumExperience).map((key) => ({
      label: t(`curriculumExperienceOptions.${key}`),
      value: CurriculumExperience[key as CurriculumExperienceKey],
    }));

    if (isWithoutNone) {
      options.shift();
    }

    return options;
  }, [isWithoutNone, t]);

  return curriculumExperienceOptions;
};

export default useCurriculumExperienceOptions;
