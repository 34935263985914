import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PageLoader from 'components/PageLoader';
import GoBackButton from 'components/GoBackButton';
import ProfileMetadata from 'router/subrouters/Dashboard/components/Profile/components/ProfileMetadata';
import ProfileInvite from 'router/subrouters/Dashboard/components/Profile/components/ProfileInvite';
import ProfileComments from 'router/subrouters/Dashboard/components/Profile/components/ProfileComments';
import ProfileActions from 'router/subrouters/Dashboard/components/Profile/components/ProfileActions';
import Profile from 'router/subrouters/Dashboard/components/Profile';
import { extractProfileInfoFromCandidate } from '../../../TeacherProfileBuilder/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyGetCandidateQuery } from 'api/canidates.api';
import { ApplicationStatus, ApplicationType, Role } from 'searchality-data';
import { useTranslation } from 'react-i18next';
import CandidateProfileQualifications from '../../components/CandidateProfileQualifications';
import QualificationsService from 'services/Qualifications.service';
import ProfileContact from 'router/subrouters/Dashboard/components/Profile/components/ProfileContact';
import { useLazyConfirmMagicLinkQuery } from 'api/vacancies.api';
import useAppSelector from 'hooks/useAppSelector';
import { updateUser } from 'store/slices/auth.slice';
import authSelectors from 'store/selectors/auth.selectors';
import useAppDispatch from 'hooks/useAppDispatch';
import CandidateProfileDocuments from '../../components/CandidateProfileDocuments';

import './CandidateProfile.styles.scss';
import './CandidateProfile.styles.responsive.scss';

type CandidateProfileProps = {
  className?: string;
};

const CandidateProfile: React.FC<CandidateProfileProps> = (props) => {
  const { className } = props;

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { candidateId } = useParams();
  const navigate = useNavigate();

  const { role } = useAppSelector(authSelectors.selectUser);
  const dispatch = useAppDispatch();

  const [getCanidate, { data: candidate }] = useLazyGetCandidateQuery();

  const [confirmMagicLink] = useLazyConfirmMagicLinkQuery();

  const requirementsData = useMemo(() => {
    if (!candidate?.qualifications) return [];

    const { numberOfMatchingFields, numberOfTotalFields, ...rest } =
      candidate.qualifications;

    const languagesDontMatch =
      QualificationsService.getCandidateLanguagesMatch(candidate);

    const curriculumExperienceAdditionalDescription =
      QualificationsService.getCurriculumExperienceAdditionalDescription(
        candidate,
      );

    const languagesDontMatchText = languagesDontMatch
      .map(({ language, level }) => {
        const { vacancy } = candidate;
        const { requiredLanguages } = vacancy || {};

        const requiredProficiencyLevel = requiredLanguages.find(
          ({ language: l }) => language === l,
        )?.level;

        return t('QualificationDescription.languageExplanation', {
          language,
          requiredProficiencyLevel,
          level,
        });
      })
      .join('\n\n');

    return Object.keys(rest).map((qualKey) => ({
      fitCriteria: candidate.qualifications[qualKey as keyof typeof rest],
      title: t(`QualificationDescription.title.${qualKey}`),
      requirement: t(`QualificationDescription.requirement.${qualKey}`, {
        [qualKey]: QualificationsService.getVacancyValue(
          qualKey as keyof typeof rest,
          candidate?.vacancy,
        ),
        ...(qualKey === 'yearsOfExperience' && {
          count: candidate?.vacancy?.['yearsOfExperienceRequired'],
        }),
      }),
      ...(qualKey !== 'yearsOfExperience' &&
        !(
          qualKey === 'educationLevel' &&
          candidate?.type === ApplicationType.NON_ACADEMIC
        ) && {
          candidateHave: t(
            `QualificationDescription.candidateHave.${qualKey}`,
            {
              [qualKey]: QualificationsService.getCandidateValue(
                qualKey as keyof typeof rest,
                candidate,
              ),
            },
          ),
        }),
      ...(qualKey === 'languages' && {
        additionalDescription: languagesDontMatch.length
          ? languagesDontMatchText
          : '',
      }),
      ...(qualKey === 'curriculumExperience' && {
        additionalDescription: curriculumExperienceAdditionalDescription,
      }),
    }));
  }, [candidate, t]);

  const fetchCandidate = useCallback(async () => {
    const actionResult = await getCanidate(candidateId);

    if (actionResult.isError) {
      if ((actionResult.error as any).status === 403) {
        navigate('/forbidden');
      }

      if ((actionResult.error as any).status === 400) {
        navigate('/not-found');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCanidate, candidateId]);

  const confirmAccess = useCallback(async () => {
    if (candidateId) {
      setIsLoading(true);
      const queryParams = new URLSearchParams(window.location.search);

      const token = queryParams.get('authToken');

      if (token && role === Role.Reviewer) {
        const actionResult = await confirmMagicLink({
          candidateId,
          token,
        });

        if (actionResult.isSuccess) {
          dispatch(updateUser(actionResult.data));
          await fetchCandidate();
        }
        if (actionResult.isError) {
          if ((actionResult.error as any).status === 403) {
            navigate('/forbidden');
          }

          if ((actionResult.error as any).status === 400) {
            navigate('/not-found');
          }
        }
        setIsLoading(false);
        return;
      }

      await fetchCandidate();
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmMagicLink, dispatch, fetchCandidate, role, candidateId]);

  useEffect(() => {
    confirmAccess();
  }, [confirmAccess]);

  if (isLoading) {
    return <PageLoader />;
  }

  const { appliedAt, statusInSchool, isArchived } = candidate || {};

  const baseClass = 'candidate-profile';

  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      {candidate && (
        <>
          <div className={`${baseClass}__left`}>
            <GoBackButton />
            <ProfileMetadata
              schools={candidate?.schools}
              appliedAt={appliedAt}
              statusInSchool={statusInSchool}
              positionTitle={candidate?.vacancy?.positionTitle}
              schoolGroupInfo={`${
                candidate?.type === ApplicationType.NON_ACADEMIC
                  ? candidate?.vacancy?.category
                  : candidate?.vacancy?.subjectGradeLevel
              } - ${candidate?.schoolGroup?.postcode ?? ''} ${
                candidate?.schoolGroup?.state ?? candidate?.schools?.[0]?.state
              }`}
            />
            {[
              ApplicationStatus.INFORMATION_REQUESTED,
              ApplicationStatus.INFORMATION_SUBMITTED,
              ApplicationStatus.IN_PROCESS,
            ].includes(candidate?.status) && (
              <CandidateProfileDocuments
                requestedDocuments={candidate?.requestedDocuments?.documents}
                requestedInformation={candidate?.requestedAdditionalInformation}
              />
            )}
            <CandidateProfileQualifications
              requirementsData={requirementsData}
            />
            <ProfileInvite
              vacancyId={candidate?.vacancyId}
              candidateId={candidate?._id}
            />
            <ProfileComments candidateId={candidate?._id} />
            <ProfileContact
              phoneNumber={candidate?.phoneNumber}
              email={candidate?.email}
            />
          </div>
          <div className={`${baseClass}__right`}>
            <ProfileActions
              statusInSchool={statusInSchool}
              candidateId={candidate?._id}
              isFavourite={candidate?.isFavourite}
              isArchived={isArchived}
            />
            <Profile cardInfo={extractProfileInfoFromCandidate(candidate)} />
          </div>
        </>
      )}
    </div>
  );
};

export default CandidateProfile;
