import { useGetAssociationInformationQuery } from 'api/associations.api';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  const { data } = useGetAssociationInformationQuery();

  if (!data) {
    return [];
  }

  return [
    { label: t('associationName'), value: data?.name },
    { label: t('associationWebsite'), value: data?.website },
  ];
};
