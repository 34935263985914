import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Form } from 'react-final-form';
import { Vacancy, VacancyFormValues } from 'models/Vacancy';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import CloseModal from './components/CloseModal';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import { ReactComponent as CloseIcon } from 'icons/Close.icon.svg';
import { ReactComponent as ReturnIcon } from 'assets/images/Return.icon.svg';
import { scrollToFirstValidationError, scrollToRef } from 'utils';
import useAppSelector from 'hooks/useAppSelector';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popError,
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import {
  updateVacancyWizardLastGroupStep,
  nextVacancyStep,
  previuosVacancyStep,
  initializeVacancyWizard,
} from 'store/slices/createVacancyWizard.slice';
import CloseEditModal from './components/CloseEditModal';
import {
  useAttachBoardingPositionDescriptionMutation,
  useAttachPositionDescriptionMutation,
  useDeleteBoardingPositionDescriptionMutation,
  useDeletePositionDescriptionMutation,
  useDraftVacancyMutation,
} from 'api/vacancies.api';
import useStandaloneSchoolId from './hooks/useStandaloneSchoolId';
import { RouteElementMapping, VacancyWizardTitleMapping } from './constants';
import { salaryInfoFormValidation } from 'validations';
import VacancyWizardService from '../../services/VacancyWizard.service';
import { vacancyBuilderActions } from 'store/slices/vacancyBuilder.slice';
import { PaymentStatus, SchoolGroupStructureType } from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';
import ShareVacancyModal from '../../components/ShareVacancyModal';
import { ShareVacancyModalRef } from '../../components/ShareVacancyModal/ShareVacancyModal.component';
import { checkIfSomeSchoolPayedMatching } from '../../utils';
import { useDisableVacanciesBannerMutation } from 'api/matching.api';
import { updateUser } from 'store/slices/auth.slice';

import './VacancyWizard.styles.scss';

type VacancyWizardProps = {
  onSubmit: (values: VacancyFormValues) => void;
  initialValues: VacancyFormValues;
};
const VacancyWizard: React.FC<VacancyWizardProps> = ({
  initialValues,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { vacancyId } = useParams();

  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );

  const [disableVacanciesBanner] = useDisableVacanciesBannerMutation();
  const [draftVacancy, { isLoading: isDraftReqLoading }] =
    useDraftVacancyMutation();
  const [
    uploadPositionDescription,
    { isLoading: isUploadPositionDescLoading },
  ] = useAttachPositionDescriptionMutation();
  const [
    deletePositionDescription,
    { isLoading: isDeletePositionDescLoading },
  ] = useDeletePositionDescriptionMutation();
  const [
    uploadBoardingPositionDescription,
    { isLoading: isUploadBoardingDescLoading },
  ] = useAttachBoardingPositionDescriptionMutation();
  const [
    deleteBoardingPositionDescription,
    { isLoading: isDeleteBoardingDescLoading },
  ] = useDeleteBoardingPositionDescriptionMutation();

  const standaloneSchoolId = useStandaloneSchoolId();

  const isSubmitting =
    isDraftReqLoading ||
    isUploadPositionDescLoading ||
    isDeletePositionDescLoading ||
    isUploadBoardingDescLoading ||
    isDeleteBoardingDescLoading;

  const isEditMode = pathname.includes('edit') && vacancyId;

  const {
    schoolGroup: { structureType, schools },
    marketingInformation,
  } = useAppSelector(authSelectors.selectUser);

  const isStandalone = structureType === SchoolGroupStructureType.STANDALONE;

  const { isMatchingAdDisabled } = marketingInformation || {};

  const modalRef = useRef<ModalRef>();

  const shareVacancyModalRef = useRef<ShareVacancyModalRef>();

  const divRef = useRef(null);

  const { t } = useTranslation();

  const { steps, currentStepIndex, nextStep } = useAppSelector(
    ({ createVacancyWizard }) => {
      const { steps, currentStepIndex } = createVacancyWizard;
      return {
        steps,
        currentStepIndex,
        nextStep: steps[currentStepIndex + 1],
      };
    },
  );

  const isFinalStep = useMemo(
    () => currentStepIndex === steps.length - 1,
    [currentStepIndex, steps.length],
  );

  const goToNextStep = useCallback(async () => {
    await dispatch(nextVacancyStep());
    scrollToRef(divRef);
    if (isEditMode) {
      navigate(`../${nextStep}`);
      return;
    }

    navigate(`../${nextStep}`);
  }, [dispatch, isEditMode, navigate, nextStep]);

  const handleGoBack = useCallback(async () => {
    if (currentStepIndex === 0) {
      modalRef.current.open();
      return;
    }

    await dispatch(previuosVacancyStep());
    if (isEditMode) {
      navigate(`../${steps[currentStepIndex - 1]}`);
      return;
    }
    navigate(`../${steps[currentStepIndex - 1]}`);
  }, [currentStepIndex, dispatch, isEditMode, navigate, steps]);

  const handleOnSubmit = useCallback(
    async (values: VacancyFormValues) =>
      isFinalStep ? onSubmit(values) : goToNextStep(),
    [isFinalStep, onSubmit, goToNextStep],
  );

  const baseClass = 'vacancy-wizard-page';

  useEffect(() => {
    dispatch(
      initializeVacancyWizard({
        structureType,
        isAcademic: !pathname.includes('non-academic'),
        ...(isStandalone && {
          isMatchingPaidForStandaloneSchool:
            schools?.[0]?.matchingInformation?.paymentStatus ===
            PaymentStatus.PAID,
          isMatchingAddDisabled: marketingInformation?.isMatchingAdDisabled,
        }),
      }),
    );
    return () => {
      dispatch(
        initializeVacancyWizard({
          structureType,
        }),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validate={(values) =>
        pathname.includes('teacher-offers')
          ? salaryInfoFormValidation(values?.salaryInfo)
          : {}
      }
      render={({ handleSubmit, values: vacancyValues, form }) => {
        const { getState, submit, change } = form;

        const handleContinue = async () => {
          const { invalid } = getState();

          if (invalid) {
            submit();
            scrollToFirstValidationError();
          } else {
            if (
              pathname.includes('school-information') &&
              !pathname.includes('non-academic')
            ) {
              const {
                isMultipleSchools,
                schoolId,
                schoolIds,
                matchingInformation,
              } = vacancyValues;

              const isMatchingPaidForSelectedSchools =
                checkIfSomeSchoolPayedMatching(
                  isMultipleSchools,
                  schoolIds,
                  schoolId,
                  schools,
                );

              if (!isMatchingPaidForSelectedSchools && matchingInformation) {
                change('matchingInformation', undefined);
              }

              dispatch(
                updateVacancyWizardLastGroupStep({
                  isMatchingPaidForSelectedSchools,
                  isMatchingAdDisabled,
                }),
              );
            }
            const { disableAdd } = vacancyValues;

            if (pathname.includes('matching-advertisement') && disableAdd) {
              await disableVacanciesBanner(true)
                .unwrap()
                .then((res) => {
                  dispatch(updateUser(res));
                });
            }

            submit();
          }
        };

        return (
          <div className={baseClass} ref={divRef}>
            <Button
              onClick={handleGoBack}
              className="text-button"
              icon={<ReturnIcon />}
            >
              {t('goBack')}
            </Button>
            <form onSubmit={handleSubmit}>
              <div className={`${baseClass}__title-container`}>
                <span>{`${currentStepIndex + 1}/${steps.length}`}</span>
                <h1>
                  {t(
                    VacancyWizardTitleMapping[
                      steps[
                        currentStepIndex
                      ] as keyof typeof VacancyWizardTitleMapping
                    ],
                  )}
                </h1>
              </div>
              {RouteElementMapping[steps[currentStepIndex] as string]}
              <div className="buttons-container">
                <Button onClick={handleContinue}>{t('continue')}</Button>
                {!isEditMode && (
                  <>
                    <Button
                      variant="outline"
                      disabled={
                        (structureType === SchoolGroupStructureType.GROUP &&
                          !vacancyValues?.schoolId &&
                          !vacancyValues?.schoolIds) ||
                        isSubmitting
                      }
                      onClick={async () => {
                        const {
                          status,
                          positionDescriptionUrl,
                          boardingPositionRequirementsUrl,
                          ...vacancyObject
                        } = VacancyWizardService.convertFormIntoVacancy(
                          vacancyValues as VacancyFormValues,
                          type,
                          standaloneSchoolId,
                        );

                        if (JSON.stringify(vacancyObject) === '{}') {
                          dispatch(popError(t('cantSaveAsDraft')));
                          return;
                        }
                        draftVacancy(vacancyObject as Vacancy)
                          .unwrap()
                          .then(
                            async ({
                              _id,
                              positionDescriptionUrl,
                              boardingPositionRequirementsUrl,
                            }) => {
                              if (vacancyValues?.positionDescription?.length) {
                                await uploadPositionDescription({
                                  vacancyId: _id,
                                  positionDescriptionFile:
                                    vacancyValues.positionDescription[0],
                                });
                              } else if (
                                !vacancyValues?.positionDescription?.length &&
                                positionDescriptionUrl
                              ) {
                                await deletePositionDescription({
                                  vacancyId: _id,
                                });
                              }
                              if (
                                vacancyValues?.boardingRoleDescription?.length
                              ) {
                                await uploadBoardingPositionDescription({
                                  vacancyId: _id,
                                  boardingPositionDescriptionFile:
                                    vacancyValues.boardingRoleDescription[0],
                                });
                              } else if (
                                !vacancyValues?.boardingRoleDescription
                                  ?.length &&
                                boardingPositionRequirementsUrl
                              ) {
                                await deleteBoardingPositionDescription({
                                  vacancyId: _id,
                                });
                              }
                              dispatch(
                                popSuccess(t('successSaveAsDraftVacancy')),
                              );
                              shareVacancyModalRef?.current?.openShareVacancy(
                                _id,
                              );
                            },
                          )
                          .catch((err) => dispatch(popServerError(err)));
                      }}
                    >
                      {t('saveDraft')}
                    </Button>
                    <ShareVacancyModal
                      ref={shareVacancyModalRef}
                      title={t('ShareVacancyModal.draftTitle')}
                      description={t('ShareVacancyModal.draftDescription')}
                      onClose={() => {
                        navigate('/');
                      }}
                    />
                  </>
                )}
              </div>
            </form>
            <Button
              className={classNames('text-button', 'color-teal')}
              icon={<CloseIcon />}
              onClick={() => modalRef.current.open()}
            >
              {t('close')}
            </Button>
            {!isEditMode ? (
              <CloseModal ref={modalRef} />
            ) : (
              <CloseEditModal
                ref={modalRef}
                onDiscard={() => {
                  modalRef.current.close();
                  navigate('/vacancies');
                  dispatch(vacancyBuilderActions.clearValues());
                }}
              />
            )}
          </div>
        );
      }}
    />
  );
};

export default VacancyWizard;
