import React, { useCallback } from 'react';
import classNames from 'classnames';
import MainLayout from 'components/MainLayout';
import AuthSection from '../../components/AuthSection';
import { useTranslation } from 'react-i18next';
import useQParamsActions from 'hooks/useQueryParams';
import { Field, Form } from 'react-final-form';
import PasswordField from 'components/PasswordField';
import { Button } from 'ncoded-component-library';
import { passwordValidation, required, composeValidators } from 'validations';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { useNavigate } from 'react-router-dom';
import InputField from 'components/InputField';
import searchalityLogo from 'assets/images/searchality-logo.webp';
import { HOMEPAGE_LINK } from 'constants/links';
import credentialsService from 'services/credentialsService';
import { loadCurrentUser } from 'store/slices/auth.slice';
import api from 'api';

import './AssociationSetupPassword.styles.scss';
import './AssociationSetupPassword.styles.responsive.scss';

const AssociationSetupPassword: React.FC = (props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    params: { token, email },
  } = useQParamsActions();

  const handleSubmit = useCallback(
    async ({ password }: { password: string; confirmPassword: string }) => {
      try {
        const { data } = await api.auth.confirmAssociationAccount({
          password,
          token,
        });
        credentialsService.saveAuthBody(data);
        await dispatch(loadCurrentUser());
        dispatch(popSuccess(t('ConfirmAssociationAccount.success')));
        navigate('/schools');
      } catch (e) {
        dispatch(popServerError(e));
      }
    },
    [dispatch, navigate, t, token],
  );

  const classes = classNames('auth', 'association-signup');

  return (
    <MainLayout className={classes} dontShowHeader>
      <a href={HOMEPAGE_LINK} target="_blank" rel="noreferrer">
        <img src={searchalityLogo} alt="Logo" />
      </a>
      <h1>{t('ConfirmAssociationAccount.setUpNewPass')}</h1>
      <p>{t('ConfirmAssociationAccount.description')}</p>
      <AuthSection className="auth-form">
        {!token ? (
          <h1 style={{ textAlign: 'center' }}>{t('noTokenPresent')}</h1>
        ) : (
          <Form
            onSubmit={handleSubmit}
            validate={({ confirmPassword, password }) =>
              confirmPassword !== password
                ? { confirmPassword: t('passwordMustMatch') }
                : {}
            }
            render={({ handleSubmit, invalid, submitting }) => (
              <form onSubmit={handleSubmit} className="auth-section__form">
                <Field
                  name="email"
                  component={InputField}
                  label={t('email')}
                  required
                  initialValue={email}
                  disabled
                />
                <Field
                  name="password"
                  component={PasswordField}
                  label={t('password')}
                  required
                  validate={composeValidators(required(), passwordValidation())}
                />
                <Field
                  name="confirmPassword"
                  component={PasswordField}
                  label={t('password')}
                  hint={t('passwordHint')}
                  required
                />
                <Button
                  type="submit"
                  disabled={invalid || submitting}
                  className="forgot-password__submit"
                >
                  {t('savePassword')}
                </Button>
              </form>
            )}
          />
        )}
      </AuthSection>
    </MainLayout>
  );
};

export default AssociationSetupPassword;
