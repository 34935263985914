import React, { useCallback, useMemo, useState } from 'react';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { Field, useForm, useFormState } from 'react-final-form';
import InputField from 'components/InputField';
import { composeValidators, required, websiteValidation } from 'validations';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError, popServerError } from 'store/slices/popNotifications.slice';
import api from 'api';
import MapboxAutocompleteField from 'components/MapboxAutocomplete';
import usePublicSchoolType from 'hooks/usePublicSchoolType';
import useReverseGeocodingFeature from 'hooks/useReverseGeocodingFeature';
import GoBackButton from 'components/GoBackButton';
import { useNavigate } from 'react-router-dom';
import { School as SchoolType } from 'models/School';

import './School.styles.scss';

const School: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    schoolGroup: { type },
  } = useAppSelector(authSelectors.selectUser);

  const {
    values: { schools, school },
  } = useFormState();

  const publicSchoolType = usePublicSchoolType();

  const { change, getState, submit } = useForm();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { getAddressObject } = useReverseGeocodingFeature();

  const goBackRoute = useMemo(() => {
    if (schools.length > 0) return '../preview';

    return '/my-account/manage-subscription';
  }, [schools.length]);

  const checkIfSchoolExists = useCallback(async () => {
    if (getState().invalid) {
      return submit();
    }

    if (schools?.some(({ name }: SchoolType) => name === school.name)) {
      return dispatch(popError(t('District.Schools.sameNameError')));
    }

    try {
      setIsLoading(true);
      const { name, postcode, website } = school;

      const {
        data: { exists },
      } = await api.schools.checkIfSchoolExists({
        name,
        postcode,
        website,
      });

      if (!exists) {
        navigate('../school-logo');
      } else {
        dispatch(popError(t('schoolAlreadyExists')));
      }
    } catch (error) {
      dispatch(popServerError(error));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, getState, navigate, school, schools, submit, t]);

  return (
    <form className="school">
      <GoBackButton to={goBackRoute} />
      <div>
        <h1>
          {t('AddNewSchool.School.title', {
            type: publicSchoolType,
          })}
        </h1>
        <p className="description">{t('District.Schools.description')}</p>
      </div>

      <Field
        name="school.name"
        component={InputField}
        label={t('District.Schools.namePlaceholder')}
        required
        validate={required()}
      />
      <Field
        name="school.city"
        label={t('District.Schools.cityPlaceholder')}
        validate={required()}
        required
        component={MapboxAutocompleteField}
        callback={async (selectedOption: any, feature: any) => {
          const address = await getAddressObject(feature);
          if (address?.state) {
            change('school.state', address.state);
          }
        }}
        mapFeaturesToOptions={(feature: any) => {
          const { place_name } = feature;
          return {
            label: place_name,
            value: place_name,
          };
        }}
        mapboxParams={{
          types: ['place'],
        }}
      />
      <div className="state-postcode">
        <Field
          name="school.state"
          searchable
          component={MapboxAutocompleteField}
          label={t('District.Schools.statePlaceholder')}
          required
          validate={required()}
          mapboxParams={{
            types: ['district', 'region'],
          }}
          key={school?.state}
          mapFeaturesToOptions={(feature: any) => {
            const { place_name } = feature;
            return {
              label: place_name,
              value: place_name,
            };
          }}
        />
        <Field
          name="school.postcode"
          component={InputField}
          label={t('District.Schools.postcodePlaceholder')}
          required
          validate={required()}
        />
      </div>
      <Field
        name="school.website"
        component={InputField}
        label={t('District.Schools.websitePlaceholder', {
          type: type === 'public school' ? t('district') : t('Group'),
        })}
        validate={composeValidators(required(), websiteValidation())}
        required
      />

      <Button disabled={isLoading} onClick={checkIfSchoolExists}>
        {t('District.Schools.addSchool')}
      </Button>
    </form>
  );
};

export default School;
