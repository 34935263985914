import { createApi } from '@reduxjs/toolkit/query/react';
import {
  Application,
  ApplicationRequestedAdditionalInformation,
} from 'models/Candidate';
import { PublicationFormValue } from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/models/TeacherFormValues';
import { PaginatedResponse, Params } from 'types';
import axiosBaseQuery from './axiosBaseQuery';

type getApplicationsParams = {
  id: string;
  params: Params;
};

export const teacherApplicationsApi = createApi({
  reducerPath: 'teacherApplicationsApi',
  tagTypes: ['AllApplications', 'Application'],
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getApplications: build.query<
      PaginatedResponse<Application>,
      getApplicationsParams
    >({
      providesTags: ['AllApplications'],
      query: (requestInfo) => {
        const { id, params } = requestInfo;

        return {
          url: `teacher-users/${id}/applications`,
          method: 'get',
          params,
        };
      },
      keepUnusedDataFor: 10,
    }),
    getApplication: build.query<
      Application,
      { teacherId: string; applicationId: string }
    >({
      query: (requestInfo) => {
        const { teacherId, applicationId } = requestInfo;
        return {
          url: `teacher-users/${teacherId}/applications/${applicationId}`,
          method: 'get',
          params: {
            $populate: ['vacancy', 'schools', 'schoolGroup'],
          },
        };
      },
      providesTags: ['Application'],
    }),
    patchTeacherApplication: build.mutation<
      Application,
      { data: Partial<Application>; teacherId: string; applicationId: string }
    >({
      invalidatesTags: ['AllApplications'],
      query: (requestInfo) => {
        const { teacherId, applicationId, data } = requestInfo;
        return {
          url: `teacher-users/${teacherId}/applications/${applicationId}`,
          method: 'patch',
          data,
          params: {
            $populate: ['vacancy', 'vacancy.schools', 'vacancy.schoolGroup'],
          },
        };
      },
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { teacherId, applicationId } = requestInfo;

          dispatch(
            teacherApplicationsApi.util.updateQueryData(
              'getApplication',
              { teacherId, applicationId },
              (draft) => {
                return data;
              },
            ),
          );
        });
      },
    }),
    submitTeacherApplication: build.mutation<
      Application,
      { teacherId: string; applicationId: string }
    >({
      invalidatesTags: ['AllApplications'],
      query: (requestInfo) => {
        const { teacherId, applicationId } = requestInfo;
        return {
          url: `teacher-users/${teacherId}/applications/${applicationId}/submit`,
          method: 'post',
          params: {
            $populate: ['vacancy', 'vacancy.schools', 'vacancy.schoolGroup'],
          },
        };
      },
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { teacherId, applicationId } = requestInfo;

          dispatch(
            teacherApplicationsApi.util.updateQueryData(
              'getApplication',
              { teacherId, applicationId },
              (draft) => {
                return data;
              },
            ),
          );
        });
      },
    }),
    createTeacherApplication: build.query<
      Application,
      { teacherId: string; data: Partial<Application> }
    >({
      query: (requestInfo) => {
        const { teacherId, data } = requestInfo;

        return {
          url: `teacher-users/${teacherId}/applications/`,
          method: 'post',
          data,
          params: {
            $populate: ['vacancy', 'vacancy.schools', 'vacancy.schoolGroup'],
          },
        };
      },
    }),
    attachTeacherCoverLetter: build.mutation<
      Application,
      { teacherId: string; applicationId: string; coverLetterFile: File }
    >({
      query: (requestInfo) => {
        const { teacherId, applicationId, coverLetterFile } = requestInfo;
        const formData = new FormData();
        formData.append('file', coverLetterFile);

        return {
          url: `teacher-users/${teacherId}/applications/${applicationId}/cover-letter`,
          method: 'post',
          data: formData,
          params: {
            $populate: ['vacancy', 'vacancy.schools', 'vacancy.schoolGroup'],
          },
        };
      },
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { teacherId, applicationId } = requestInfo;

          dispatch(
            teacherApplicationsApi.util.updateQueryData(
              'getApplication',
              { teacherId, applicationId },
              () => {
                return data;
              },
            ),
          );
        });
      },
    }),
    attachTeacherResume: build.mutation<
      Application,
      { teacherId: string; applicationId: string; resumeFile: File }
    >({
      query: (requestInfo) => {
        const { applicationId, resumeFile } = requestInfo;
        const formData = new FormData();
        formData.append('file', resumeFile);

        return {
          url: `teacher-users/applications/${applicationId}/resume`,
          method: 'post',
          data: formData,
          params: {
            $populate: ['vacancy', 'vacancy.schools', 'vacancy.schoolGroup'],
          },
        };
      },
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { teacherId, applicationId } = requestInfo;

          dispatch(
            teacherApplicationsApi.util.updateQueryData(
              'getApplication',
              { teacherId, applicationId },
              () => {
                return data;
              },
            ),
          );
        });
      },
    }),
    attachEducationPhilosophy: build.mutation<
      Application,
      {
        teacherId: string;
        applicationId: string;
        educationPhilosophyFile: File;
      }
    >({
      query: (requestInfo) => {
        const { teacherId, applicationId, educationPhilosophyFile } =
          requestInfo;
        const formData = new FormData();
        formData.append('file', educationPhilosophyFile);

        return {
          url: `teacher-users/${teacherId}/applications/${applicationId}/education-philosophy`,
          method: 'post',
          data: formData,
          params: {
            $populate: ['vacancy', 'vacancy.schools', 'vacancy.schoolGroup'],
          },
        };
      },
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { teacherId, applicationId } = requestInfo;

          dispatch(
            teacherApplicationsApi.util.updateQueryData(
              'getApplication',
              { teacherId, applicationId },
              () => {
                return data;
              },
            ),
          );
        });
      },
    }),
    attachPublications: build.mutation<
      Application,
      {
        teacherId: string;
        applicationId: string;
        publications: PublicationFormValue[];
      }
    >({
      query: (requestInfo) => {
        const { applicationId, publications } = requestInfo;
        const formData = new FormData();
        publications.forEach(({ url, file, name }) => {
          if (file?.[0]) {
            formData.append('files', file[0]);
            formData.append('fileNames', name);
            return;
          }
          formData.append('urls', url);
          formData.append('urlNames', name);
        });

        return {
          url: `teacher-users/applications/${applicationId}/publications`,
          method: 'post',
          data: formData,
          params: {
            $populate: ['vacancy', 'vacancy.schools', 'vacancy.schoolGroup'],
          },
        };
      },
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { teacherId, applicationId } = requestInfo;

          dispatch(
            teacherApplicationsApi.util.updateQueryData(
              'getApplication',
              { teacherId, applicationId },
              () => {
                return data;
              },
            ),
          );
        });
      },
    }),
    attachTeacherPhoto: build.mutation<
      Application,
      { teacherId: string; applicationId: string; imageFile: File }
    >({
      query: (requestInfo) => {
        const { teacherId, applicationId, imageFile } = requestInfo;
        const formData = new FormData();
        formData.append('file', imageFile);

        return {
          url: `teacher-users/${teacherId}/applications/${applicationId}/photo`,
          method: 'post',
          params: {
            $populate: ['vacancy', 'vacancy.schools', 'vacancy.schoolGroup'],
          },
          data: formData,
        };
      },
      async onQueryStarted(requestInfo, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          const { teacherId, applicationId } = requestInfo;

          dispatch(
            teacherApplicationsApi.util.updateQueryData(
              'getApplication',
              { teacherId, applicationId },
              (draft) => {
                return data;
              },
            ),
          );
        });
      },
    }),
    uploadTeacherRequestedFiles: build.mutation<
      Application,
      { applicationId: string; data: FormData }
    >({
      query: (requestInfo) => {
        const { applicationId, data } = requestInfo;

        return {
          url: `teacher-users/applications/${applicationId}/requested-documents`,
          method: 'PATCH',
          data,
        };
      },
    }),
    uploadAdditionalInformation: build.mutation<
      void,
      { applicationId: string; data: ApplicationRequestedAdditionalInformation }
    >({
      query: (requestInfo) => {
        const { applicationId, data } = requestInfo;

        return {
          url: `teacher-users/applications/${applicationId}/requested-additional-information`,
          method: 'PATCH',
          data,
        };
      },
    }),
  }),
});

export const {
  useGetApplicationsQuery,
  useGetApplicationQuery,
  usePatchTeacherApplicationMutation,
  useSubmitTeacherApplicationMutation,
  useLazyCreateTeacherApplicationQuery,
  useAttachTeacherCoverLetterMutation,
  useAttachTeacherPhotoMutation,
  useAttachEducationPhilosophyMutation,
  useAttachPublicationsMutation,
  useUploadTeacherRequestedFilesMutation,
  useAttachTeacherResumeMutation,
  useUploadAdditionalInformationMutation,
} = teacherApplicationsApi;
