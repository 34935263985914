import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RadioGroup } from 'ncoded-component-library';
import { FieldRenderProps } from 'react-final-form';
import Anim from 'components/animations';
import classNames from 'classnames';
import Input from 'components/Input';
import { useTranslation } from 'react-i18next';
import RadioButtonLabel from 'components/RadioButtonLabel';

import './RadioGroupWithOtherField.styles.scss';

type RadioOption = {
  label: React.ReactNode;
  value: string;
};

type RadioGroupWithOtherFieldProps = {
  options: RadioOption[];
} & FieldRenderProps<string, HTMLElement>;

const RadioGroupWithOtherField: React.FC<RadioGroupWithOtherFieldProps> = (
  props,
) => {
  const {
    options,
    input,
    wrapperClassName,
    meta: { error, touched },
    ...rest
  } = props;

  const [radioGroupValue, setRadioGroupValue] = useState<string>();

  const { t } = useTranslation();

  const optionsMapped = useMemo(
    () =>
      options?.concat({
        label: <RadioButtonLabel label={t('other')} />,
        value: 'Other',
      }),
    [options, t],
  );

  const handleRadioGroupChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;

      setRadioGroupValue(e.target.value);

      if (value !== 'Other') {
        return input?.onChange(value);
      }

      input?.onChange(undefined);
    },
    [input],
  );

  const classes = classNames('radio-group-with-other-field', wrapperClassName);

  useEffect(() => {
    if (input?.value) {
      if (options?.some(({ value }) => value === input?.value)) {
        return setRadioGroupValue(input?.value);
      }
      return setRadioGroupValue('Other');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes}>
      <RadioGroup
        name={input?.name}
        options={optionsMapped}
        value={radioGroupValue}
        onChange={handleRadioGroupChange}
      />
      {radioGroupValue === 'Other' && <Input {...rest} {...input} />}
      <Anim.Collapse active={!!error && touched}>
        <p className="s-input__error">{error}</p>
      </Anim.Collapse>
    </div>
  );
};

export default RadioGroupWithOtherField;
