import React, { useContext, useMemo } from 'react';
import Pagination from 'components/Pagination';
import PageLoader from 'components/PageLoader';
import PaginationInfo from 'components/PaginationInfo';
import { useParams as userRequestParams } from 'hooks/useParams';
import CandidatesFilters from 'router/subrouters/Dashboard/subrouters/Candidates/components/CandidatesFilters';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetVacancyCandidatesQuery } from 'api/canidates.api';
import { ApplicationSchoolStatus } from 'searchality-data';
import ValueContext from 'providers/ValueContext/Value.context';
import CandidateCardWrapper from 'router/subrouters/Dashboard/subrouters/Candidates/components/CandidateCard/components/CandidateCardWrapper';
import CandidateQualification from 'components/CandidateQualification';
import QualificationsService from 'services/Qualifications.service';
import SortSelect from 'components/SortSelect';
import useSortByOptions from 'router/subrouters/Dashboard/subrouters/Candidates/hooks/useSortByOptions';
import { Checkbox } from 'ncoded-component-library';

import './VacancyFillFactory.styles.scss';

const initialParams = {
  $limit: 6,
  $populate: ['schools', 'vacancy'],
  $where: {
    statusInSchool: {
      $in: [ApplicationSchoolStatus.IN_PROCESS],
    },
  },
};

const VacancyFillFactory: React.FC = (props) => {
  const { t } = useTranslation();

  const { vacancyId } = useParams();

  const { selectedIds, manageId } = useContext(ValueContext);

  const sortByOptions = useSortByOptions();

  const { params, sortBy, filterBy, changeCurrentPage, resetFilters } =
    userRequestParams(initialParams);

  const { isLoading, data, isFetching } = useGetVacancyCandidatesQuery(
    { id: vacancyId, params },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const filterParams = useMemo(
    () => ({
      $where: {
        statusInSchool: {
          $in: [ApplicationSchoolStatus.IN_PROCESS],
        },
        vacancyId: {
          $in: [vacancyId],
        },
      },
    }),
    [vacancyId],
  );

  if (isLoading || isFetching) {
    return <PageLoader />;
  }

  const { items: candidates, ...pagination } = data;

  const { $where, $sort } = params;

  return (
    <div className="vacancies-fill-factory">
      <p className="vacancies-fill-factory__description">
        {t('vacancyFillCandidateModal.description')}
      </p>

      <CandidatesFilters
        onFilter={filterBy}
        filteredBy={$where}
        onReset={resetFilters}
        filterParams={filterParams}
      />
      <div className="vacancies-fill-factory__pagination-info-wrapper">
        <PaginationInfo
          name={t('candidatesSmall')}
          {...pagination}
          limit={params?.$limit}
        />
        <SortSelect
          options={sortByOptions}
          value={$sort}
          placeholder={t('sortBy')}
          multiple={false}
          onChange={({ value }) => {
            sortBy(value);
          }}
        />
      </div>

      <ul>
        {candidates.map(({ _id, ...restCandidate }) => {
          const { qualifications } = restCandidate;
          const { numberOfMatchingFields = 0, numberOfTotalFields = 0 } =
            qualifications || {};

          const [variant, message] =
            QualificationsService.getCandidatesQualificationVariantAndMessage(
              numberOfMatchingFields,
              numberOfTotalFields,
            );

          return (
            <CandidateCardWrapper
              _id={_id}
              key={_id}
              {...restCandidate}
              isActive={_id === selectedIds[0]}
              onClick={() => manageId(_id)}
              additionalContent={
                <Checkbox
                  onChange={() => manageId(_id)}
                  checked={selectedIds.includes(_id)}
                  style={{ marginRight: '16px' }}
                />
              }
              suffixNode={
                <CandidateQualification
                  qualificationsNumber={numberOfTotalFields}
                  candidateQualifications={numberOfMatchingFields}
                  variant={variant}
                  message={message}
                />
              }
            />
          );
        })}
      </ul>
      {pagination?.totalPages > 1 && (
        <Pagination {...pagination} onChange={changeCurrentPage} />
      )}
    </div>
  );
};

export default VacancyFillFactory;
