import React, { useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import InputField from 'components/InputField';
import {
  required,
  composeValidators,
  fiveDigits,
  websiteValidation,
} from 'validations';
import GoBackButton from 'components/GoBackButton';
import { Navigate, useNavigate } from 'react-router-dom';
import api from 'api';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';
import { CREATE_SCHOOL_WIZARD } from 'enums';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError, popServerError } from 'store/slices/popNotifications.slice';
import { SchoolGroupType } from 'searchality-data';
import MapboxAutocompleteField from 'components/MapboxAutocomplete';
import useReverseGeocodingFeature from 'hooks/useReverseGeocodingFeature';

import './SchoolInformationStandAlone.styles.scss';
import utils from 'utils';

type SchoolInformationStandAloneProps = {
  className?: string;
};

const SchoolInformationStandAlone: React.FC<
  SchoolInformationStandAloneProps
> = (props) => {
  const { className } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { completeStep } = useContext(CreateSchoolWizardContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { submit, change, getState } = useForm();

  const {
    values: { schools, school, type, state, religion, residentialType },
  } = useFormState();

  const classes = classNames('school-information-stand-alone', className);

  const prefillFields = useCallback(
    (school: any) => {
      change('name', school.name);
      change('state', school.state);
      change('website', school.website);
      change('schoolCount', '1');
      change('county', school.county);
    },
    [change],
  );

  const checkIfSchoolExists = useCallback(async () => {
    setIsLoading(true);
    try {
      const { name, postcode, website } = school;

      const {
        data: { exists },
      } = await api.schools.checkIfSchoolExists({
        name,
        postcode,
        website,
      });

      if (!exists) {
        prefillFields(school);
        completeStep(CREATE_SCHOOL_WIZARD.SCHOOL_INFORMATION);
        navigate(`school-logo`);
      } else {
        dispatch(popError(t('schoolAlreadyExists')));
      }
    } catch (error) {
      dispatch(popServerError(error));
    } finally {
      setIsLoading(false);
    }
  }, [school, prefillFields, navigate, completeStep, dispatch, t]);

  const { getAddressObject } = useReverseGeocodingFeature();

  useEffect(() => {
    if (schools?.length > 0) {
      change('school', schools[0]);
    }
  }, [change, schools]);

  if (type === SchoolGroupType.RELIGIOUS && !religion) {
    return (
      <Navigate
        to={`/create-school/school-type/${type}/residential-type/${utils.mapResidentialTypeToRoute(
          residentialType,
        )}/religion-type`}
      />
    );
  }

  return (
    <form
      className={classes}
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid } = getState();
        if (!valid) return submit();
        checkIfSchoolExists();
      }}
    >
      <GoBackButton
        to={
          type === SchoolGroupType.RELIGIOUS
            ? `/create-school/school-type/${type}/residential-type/${utils.mapResidentialTypeToRoute(
                residentialType,
              )}/religion-type`
            : `/create-school/school-type/${type}/residential-type/${utils.mapResidentialTypeToRoute(
                residentialType,
              )}`
        }
      />
      <h1>{t('schoolInforamtion.standAlone.title')}</h1>
      <Field
        name="school.name"
        component={InputField}
        label={t('schoolName')}
        validate={required()}
        required
      />
      <Field
        name="school.city"
        component={MapboxAutocompleteField}
        label={t('city')}
        validate={required()}
        required
        callback={async (selectedOption: any, feature: any) => {
          const address = await getAddressObject(feature);
          if (address?.state) {
            change('school.state', address.state);
          }
        }}
        mapFeaturesToOptions={(feature: any) => {
          const { place_name } = feature;
          return {
            label: place_name,
            value: place_name,
          };
        }}
        mapboxParams={{
          types: ['place'],
        }}
      />
      <Field
        name="school.county"
        component={MapboxAutocompleteField}
        mapFeaturesToOptions={(feature: any, alo: any) => {
          const { place_name } = feature;
          return {
            label: place_name,
            value: place_name,
          };
        }}
        label={t('county')}
        key={state}
        mapboxParams={{
          types: ['district', 'region'],
        }}
      />
      <div className="fields-row">
        <Field
          name="school.state"
          component={MapboxAutocompleteField}
          searchable
          label={t('state')}
          validate={required()}
          required
          key={school?.state}
          mapFeaturesToOptions={(feature: any) => {
            const { place_name } = feature;
            return {
              label: place_name,
              value: place_name,
            };
          }}
        />
        <Field
          name="school.postcode"
          component={InputField}
          label={t('zipcode')}
          validate={composeValidators(required(), fiveDigits())}
          onlyNumbers
          maxLength={10}
          required
        />
      </div>
      <Field
        name="school.website"
        component={InputField}
        label={t('schoolWebsite')}
        validate={composeValidators(required(), websiteValidation())}
        required
      />
      <Button type="submit" disabled={isLoading}>
        {t('continue')}
      </Button>
    </form>
  );
};

export default SchoolInformationStandAlone;
