import React, { forwardRef, useRef } from 'react';
import { Button, Modal } from 'ncoded-component-library';
import { OverlayProps } from 'ncoded-component-library/build/components/atoms/Overlay/Overlay.component';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import classNames from 'classnames';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  popError,
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import useStandaloneSchoolId from '../../hooks/useStandaloneSchoolId';
import { Vacancy, VacancyFormValues } from 'models/Vacancy';
import {
  useAttachBoardingPositionDescriptionMutation,
  useAttachPositionDescriptionMutation,
  useDeleteBoardingPositionDescriptionMutation,
  useDeletePositionDescriptionMutation,
  useDraftVacancyMutation,
} from 'api/vacancies.api';
import VacancyWizardService from '../../../../services/VacancyWizard.service';
import { vacancyBuilderActions } from 'store/slices/vacancyBuilder.slice';
import useAppSelector from 'hooks/useAppSelector';
import { ShareVacancyModalRef } from '../../../../components/ShareVacancyModal/ShareVacancyModal.component';
import ShareVacancyModal from '../../../../components/ShareVacancyModal';

import './CloseModal.styles.scss';

type CloseModalProps = {
  className?: string;
} & OverlayProps;

const CloseModal: React.ForwardRefRenderFunction<ModalRef, CloseModalProps> = (
  props,
  ref,
) => {
  const { className, ...rest } = props;

  const { t } = useTranslation();

  const standaloneSchoolId = useStandaloneSchoolId();
  const type = useAppSelector(
    ({ createVacancyWizard }) => createVacancyWizard.type,
  );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { values } = useFormState();

  const baseClass = 'close-modal';

  const classes = classNames(baseClass, className);

  const shareVacancyModalRef = useRef<ShareVacancyModalRef>();

  const [draftVacancy, { isLoading: isDraftReqLoading }] =
    useDraftVacancyMutation();
  const [
    uploadPositionDescription,
    { isLoading: isUploadPositionDescLoading },
  ] = useAttachPositionDescriptionMutation();
  const [
    deletePositionDescription,
    { isLoading: isDeletePositionDescLoading },
  ] = useDeletePositionDescriptionMutation();
  const [
    uploadBoardingPositionDescription,
    { isLoading: isUploadBoardingDescLoading },
  ] = useAttachBoardingPositionDescriptionMutation();
  const [
    deleteBoardingPositionDescription,
    { isLoading: isDeleteBoardingDescLoading },
  ] = useDeleteBoardingPositionDescriptionMutation();

  const isSubmitting =
    isDraftReqLoading ||
    isUploadPositionDescLoading ||
    isDeletePositionDescLoading ||
    isUploadBoardingDescLoading ||
    isDeleteBoardingDescLoading;

  const onCloseConfirm = async () => {
    const vacancyObject = VacancyWizardService.convertFormIntoVacancy(
      values as VacancyFormValues,
      type,
      standaloneSchoolId,
    );
    if (JSON.stringify(vacancyObject) === '{}') {
      dispatch(popError(t('cantSaveAsDraft')));
      return;
    }
    const {
      positionDescriptionUrl,
      boardingPositionRequirementsUrl,
      ...vacancyObjectRest
    } = vacancyObject;
    draftVacancy(vacancyObjectRest as Vacancy)
      .unwrap()
      .then(
        async ({
          _id,
          positionDescriptionUrl,
          boardingPositionRequirementsUrl,
        }) => {
          if (values?.positionDescription?.length) {
            await uploadPositionDescription({
              vacancyId: _id,
              positionDescriptionFile: values.positionDescription[0],
            });
          } else if (
            !values?.positionDescription?.length &&
            positionDescriptionUrl
          ) {
            await deletePositionDescription({
              vacancyId: _id,
            });
          }
          if (values?.boardingRoleDescription?.length) {
            await uploadBoardingPositionDescription({
              vacancyId: _id,
              boardingPositionDescriptionFile:
                values.boardingRoleDescription[0],
            });
          } else if (
            !values?.boardingRoleDescription?.length &&
            boardingPositionRequirementsUrl
          ) {
            await deleteBoardingPositionDescription({
              vacancyId: _id,
            });
          }
          dispatch(popSuccess(t('successSaveAsDraftVacancy')));
          shareVacancyModalRef?.current?.openShareVacancy(_id);
        },
      )
      .catch((e) => {
        dispatch(popServerError(e));
      });
  };

  return (
    <>
      <Modal
        ref={ref}
        title={t('vacancyBuilderCreateModal.title')}
        className={classes}
        {...rest}
        footer={
          <>
            <Button
              onClick={() => {
                navigate('../../../');
                dispatch(vacancyBuilderActions.clearValues());
              }}
              variant="outline"
              className={`${baseClass}__exit-process`}
            >
              {t('cancelDelete')}
            </Button>
            <Button
              onClick={onCloseConfirm}
              className={`${baseClass}__save-draft`}
              disabled={isSubmitting}
            >
              {t('saveDraft')}
            </Button>
          </>
        }
      />
      <ShareVacancyModal
        ref={shareVacancyModalRef}
        title={t('ShareVacancyModal.draftTitle')}
        description={t('ShareVacancyModal.draftDescription')}
        onClose={() => {
          dispatch(vacancyBuilderActions.clearValues());
          navigate('../../../');
        }}
      />
    </>
  );
};

export default forwardRef(CloseModal);
