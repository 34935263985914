import React, { useCallback } from 'react';
import { Field, FormSpy, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TPBPageContent from '../../components/TPBPageContent';
import TPBPageHeading from '../../components/TPBPageHeading';
import CheckboxField from 'components/CheckboxField';
import InputField from 'components/InputField';
import RadioGroupField from 'components/RadioGroupField';
import TPBTopActions from '../../components/TPBTopActions';
import TPBBottomActions from '../../components/TPBBottomActions';
import useWorkRightOptions from 'router/subrouters/JobApplication/components/JobApplicationForm/components/PositionRequirements/hooks/useWorkRightOptions/useWorkRightOptions';
import DatepickerField from 'components/DatepickerField';
import useAppSelector from 'hooks/useAppSelector';
import teacherProfileBuilderSelectors from 'store/selectors/teacherProfileBuilder.selectors';
import ToggleField from 'components/ToggleField';
import FormFieldLabel from 'components/FormFieldLabel';
import bus from 'modules/bus';
import { required } from 'validations';
import { RightToWorkType } from 'searchality-data';

import './WorkAvailability.styles.scss';

const WorkAvailabilityPage: React.FC = () => {
  const { t } = useTranslation();
  const baseClass = 'tpb-work-availability';

  const workRightOptions = useWorkRightOptions();

  const finishedRoutes = useAppSelector(
    teacherProfileBuilderSelectors.selectFinishedRoutes,
  );
  const currentRoute = useAppSelector(
    teacherProfileBuilderSelectors.selectCurrentRoute,
  );

  const startDateValidation = useCallback(
    (value: any, allValues: any) => {
      if (!allValues?.immediately) {
        if (typeof value === 'undefined' || value === null) {
          return t('requiredField');
        }
      }
    },
    [t],
  );

  const {
    values: { immediately },
  } = useFormState();

  const { submit } = useForm();
  const { dirtyFields, submitting, valid } = useFormState();

  const isSubmitable = [
    'rightToWork',
    'startDate',
    'hasRightToWork',
    'dateUntilVisaIsValid',
    'rightToWorkDescription',
    'immediately',
  ].some((fieldName) => dirtyFields[fieldName]);

  const onSubmit = () => {
    if (isSubmitable || !valid || !finishedRoutes.includes(currentRoute)) {
      submit();
    } else {
      bus.broadcastEvent('JUMP_TO_ROUTE', 'Education Statements');
    }
  };

  return (
    <div className={baseClass}>
      <TPBTopActions disabledSaveButton={submitting} />
      <TPBPageContent centered>
        <TPBPageHeading
          text={t('teacherProfileBuilder.workAvailability.title')}
        />
        <FormFieldLabel text={t('startDate')} />

        <div className={`${baseClass}__start-date__inputs`}>
          <Field
            name="startDate"
            placeholder={t('startDate')}
            component={DatepickerField}
            validate={startDateValidation}
            onlyFuture
            required
            disabled={immediately}
          />
          <Field
            name="immediately"
            label={t('immediately')}
            component={CheckboxField}
            type="checkbox"
          />
        </div>

        <div className={`${baseClass}__work-rights`}>
          <FormFieldLabel
            text={t('jobApplicationPositionRequirements.workRightTitle')}
          />
          <Field component={ToggleField} name="hasRightToWork" reverse />
          <FormSpy subscription={{ values: true }}>
            {({ values: { hasRightToWork, rightToWork } }) => {
              return (
                <>
                  {hasRightToWork === true ? (
                    <>
                      <FormFieldLabel
                        text={t(
                          'teacherProfileBuilder.workAvailability.rightToWork',
                        )}
                      />
                      <Field
                        name="rightToWork"
                        component={RadioGroupField}
                        options={workRightOptions}
                        direction="row"
                        className={`${baseClass}__work-rights__radio-group`}
                      />
                      {rightToWork === RightToWorkType.VISA && (
                        <>
                          <Field
                            name="visaType"
                            component={InputField}
                            label={t(
                              'jobApplicationPositionRequirements.visaTypeLabel',
                            )}
                            className={`${baseClass}__select`}
                          />
                          <Field
                            name="dateUntilVisaIsValid"
                            component={DatepickerField}
                            placeholder={t(
                              'jobApplicationPositionRequirements.visaDateLabel',
                            )}
                            onlyFuture
                            required
                          />
                        </>
                      )}
                    </>
                  ) : undefined}
                  {hasRightToWork === false ? (
                    <Field
                      name="rightToWorkDescription"
                      component={InputField}
                      label={t(
                        'jobApplicationPositionRequirements.rightToWorkExplanation',
                      )}
                      className={`${baseClass}__select`}
                      validate={required()}
                    />
                  ) : undefined}
                </>
              );
            }}
          </FormSpy>
        </div>
        <TPBBottomActions onSubmit={onSubmit} submitBtnDisabled={submitting} />
      </TPBPageContent>
    </div>
  );
};

export default WorkAvailabilityPage;
