import {
  ExperienceType,
  FormExperienceType,
  LanguageRequirement,
} from 'models/Candidate';
import { FormLanguageRequirement } from 'router/subrouters/JobApplication/types/JobApplicationFormValues';

function castEmployementType(isFullTime: boolean, isPermanent: boolean) {
  if (isFullTime && isPermanent) {
    return 'fullTime';
  }
  if (isFullTime && !isPermanent) {
    return 'partTime';
  }
  return 'temporaryContract';
}

/**
 * Function that maps experience type from one used in forms into Applicant/Candidate etc.
 * @param experience:ExperienceType
 * @returns
 */

function parseExperience(experience: FormExperienceType) {
  const { employmentType, endDate, ...rest } = experience;

  return {
    ...rest,
    isFullTimeEmployment: employmentType !== 'partTime',
    isPermanentContract: employmentType === 'fullTime',
    endDate: rest.isCurrentlyWorking ? undefined : endDate,
    employmentType,
  };
}
/**
 * Function that maps experience type from one used in forms
 * @param experience:ExperienceType
 * @returns
 */
function formatExperience(experience: ExperienceType) {
  const { isFullTimeEmployment, isPermanentContract, ...rest } = experience;
  return {
    ...rest,
    ...(isFullTimeEmployment !== undefined && {
      employmentType: castEmployementType(
        isFullTimeEmployment,
        isPermanentContract,
      ),
    }),
  } as FormExperienceType;
}

function parseLanguageReuirementType(
  languageRequirement: LanguageRequirement[],
  requiredLanguages?: {
    language: string;
    level: string;
  }[],
): FormLanguageRequirement {
  return languageRequirement
    ?.filter((language) =>
      requiredLanguages?.some(
        (reqLanguage) => reqLanguage.language === language.language,
      ),
    )
    .reduce(
      (prev, { language, ...rest }) =>
        ({
          ...prev,
          [language]: { ...rest, doesSpeak: true },
        } as LanguageRequirement),
      {},
    );
}

function formatLanguageReuirementType(
  languageReuirement: FormLanguageRequirement,
): LanguageRequirement[] {
  return languageReuirement
    ? Object.entries(languageReuirement).reduce(
        (acc: LanguageRequirement[], { 0: key, 1: val }: any) => {
          return [...acc, { ...val, language: key }];
        },
        [],
      )
    : undefined;
}

export default {
  formatExperience,
  parseExperience,
  formatLanguageReuirementType,
  parseLanguageReuirementType,
};
