import { ComplexRoute } from 'router/components/Routes/Routes';
import DistrictInfo from './pages/DistrictInfo';
import DisctrictSchools from './pages/DisctrictSchools';
import DisctrictSchoolsPreview from './pages/DisctrictSchoolsPreview';
import SchoolPartOfAssociation from '../../pages/SchoolPartOfAssociation';
import SchoolAssociations from '../../pages/SchoolAssociations';
import AssociationContactDetails from '../../pages/AssociationContactDetails';
import SchoolLogoGroupWrapper from 'router/subrouters/Dashboard/components/SchoolLogoGroupWrapper';

const routes: Readonly<Array<ComplexRoute>> = [
  {
    path: 'schools-preview',
    element: DisctrictSchoolsPreview,
  },
  {
    path: 'schools',
    element: DisctrictSchools,
  },
  {
    path: 'part-of-association',
    element: SchoolPartOfAssociation,
  },
  {
    path: 'school-logo',
    element: SchoolLogoGroupWrapper,
  },
  {
    path: 'choose-associations',
    element: SchoolAssociations,
  },
  {
    path: 'association-contact-details',
    element: AssociationContactDetails,
  },
  {
    index: true,
    element: DistrictInfo,
  },
] as const;

export default routes;
