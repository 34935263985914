import FormFieldLabel from 'components/FormFieldLabel';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { NonAcademicContractType } from 'searchality-data';
import useContractDaysOptions from '../../../../hooks/useContractDaysOptions';

type NonAcademicContractDaysFieldsProps = {
  baseClass: string;
  isDisabled: boolean;
};

const NonAcademicContractDaysFields: React.FC<
  NonAcademicContractDaysFieldsProps
> = (props) => {
  const { baseClass, isDisabled } = props;

  const { t } = useTranslation();

  const {
    values: { contractDays },
  } = useFormState();

  const contractDaysOptions = useContractDaysOptions();

  return (
    <div className={`${baseClass}__contact-tearms`}>
      <FormFieldLabel
        text={t('contractTerms')}
        hint={t('vacancyWizard.step4.contractTippyContentNonAcademic')}
      />
      <p className="descriptive-paragraph">
        {t('vacancyWizard.step4.contractTermsParagraphNonAcademic')}
      </p>
      <Field
        name="contractDays"
        component={SelectField}
        label={t('vacancyWizard.step4.contractTermsInputLabel')}
        options={contractDaysOptions}
        disabled={isDisabled}
      />
      {contractDays === NonAcademicContractType.OTHER && (
        <Field
          name="contractDaysDetails"
          component={InputField}
          label={t('contractDaysDetailsLabel')}
          disabled={isDisabled}
        />
      )}
    </div>
  );
};

export default NonAcademicContractDaysFields;
