import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import { Field, useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import InputField from 'components/InputField';
import {
  composeValidators,
  positiveNumber,
  required,
  websiteValidation,
} from 'validations';
import { Button } from 'ncoded-component-library';
import { Navigate, useNavigate } from 'react-router-dom';
import api from 'api';
import CreateSchoolWizardContext from 'providers/CreateSchoolWizard/CreateSchoolWizard.context';
import { CREATE_SCHOOL_WIZARD } from 'enums';
import GoBackButton from 'components/GoBackButton';
import useAppDispatch from 'hooks/useAppDispatch';
import { popError, popServerError } from 'store/slices/popNotifications.slice';
import { SchoolGroupType } from 'searchality-data';

import './IndependentCharterSchoolGroup.styles.scss';
import utils from 'utils';

type IndependentCharterSchoolGroupProps = {
  className?: string;
};

const IndependentCharterSchoolGroup: React.FC<
  IndependentCharterSchoolGroupProps
> = (props) => {
  const { className } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { completeStep } = useContext(CreateSchoolWizardContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { submit, getState } = useForm();

  const {
    values: { name, website, type, schools, religion, residentialType },
  } = useFormState();

  const checkIfSchoolGroupExists = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        data: { exists },
      } = await api.schools.checkIfSchoolGroupExists({
        name,
        website,
      });

      if (!exists) {
        completeStep(CREATE_SCHOOL_WIZARD.SCHOOL_INFORMATION);
        if (schools?.length) {
          return navigate('schools-preview');
        }
        return navigate('schools');
      }
      dispatch(popError(t('IndependentCharterSchoolGroup.alreadyExistsError')));
    } catch (error) {
      dispatch(popServerError(error));
    } finally {
      setIsLoading(false);
    }
  }, [name, website, dispatch, t, completeStep, schools?.length, navigate]);

  const classes = classNames('independent-charter-school-group', className);

  if (type === SchoolGroupType.RELIGIOUS && !religion) {
    return (
      <Navigate
        to={`/create-school/school-type/${type}/residential-type/${utils.mapResidentialTypeToRoute(
          residentialType,
        )}/religion-type`}
      />
    );
  }

  return (
    <form
      className={classes}
      onSubmit={async (ev) => {
        ev.preventDefault();
        const { valid } = getState();
        if (!valid) return submit();
        checkIfSchoolGroupExists();
      }}
    >
      <GoBackButton
        to={
          type === SchoolGroupType.RELIGIOUS
            ? `/create-school/school-type/${type}/residential-type/${utils.mapResidentialTypeToRoute(
                residentialType,
              )}/religion-type`
            : `/create-school/school-type/${type}/residential-type/${utils.mapResidentialTypeToRoute(
                residentialType,
              )}`
        }
      />
      <h1>{t('IndependentCharterSchoolGroup.title')}</h1>
      <Field
        name="name"
        component={InputField}
        label={t('IndependentCharterSchoolGroup.namePlaceholder')}
        required
        validate={required()}
      />
      <Field
        name="website"
        component={InputField}
        label={t('IndependentCharterSchoolGroup.websitePlaceholder')}
        required
        validate={composeValidators(required(), websiteValidation())}
      />
      <Field
        onlyNumbers
        maxLength={4}
        name="schoolCount"
        component={InputField}
        hint={t('District.Info.schoolCountHint')}
        label={t('IndependentCharterSchoolGroup.schoolCountPlaceholder')}
        required
        validate={composeValidators(required(), positiveNumber())}
      />
      <Button
        type="submit"
        disabled={isLoading}
        className={'independent-charter-school-group__submit'}
      >
        {t('continue')}
      </Button>
    </form>
  );
};

export default IndependentCharterSchoolGroup;
