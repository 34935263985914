import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import pdfImage from 'assets/images/pdf-image.webp';
import { numberPriceToCurrencyString } from 'utils';
import { ApplicantStage } from 'searchality-data';

import './JobPreviewBoardingSection.styles.scss';

type JobPreviewBoardingSectionProps = {
  className?: string;
  roleDescription?: string;
  roleDescriptionUrl?: string;
  isRequired?: boolean;
  salary?: number;
  additionalBenefits?: string;
  noSubTitles?: boolean;
  applicantStage?: string;
};

const JobPreviewBoardingSection: React.FC<JobPreviewBoardingSectionProps> = (
  props,
) => {
  const {
    className,
    roleDescription,
    roleDescriptionUrl,
    isRequired = false,
    noSubTitles = false,
    salary,
    additionalBenefits,
    applicantStage,
  } = props;

  const { t } = useTranslation();

  const baseClass = 'job-preview-boarding-section';
  const classes = classNames(baseClass, className);

  const salaryInfo = useMemo(() => {
    if (applicantStage !== ApplicantStage.IN_THE_JOB_POSTING) {
      return t(applicantStage);
    }

    return `${numberPriceToCurrencyString(salary)}`;
  }, [applicantStage, salary, t]);

  return (
    <div className={classes}>
      {!noSubTitles && (
        <p>
          <b>{t('jobPreview.JobPreviewBoardingSection.supervision')}</b>
        </p>
      )}
      <ul>
        <li>
          {t(
            `jobPreview.JobPreviewBoardingSection.${
              isRequired ? 'required' : 'optional'
            }`,
          )}
        </li>
        {roleDescription && <li>{roleDescription}</li>}
      </ul>
      {roleDescriptionUrl && (
        <div className={`${baseClass}__detailed-container`}>
          <p>{t('jobPreview.JobPreviewBoardingSection.view')}</p>
          <a href={roleDescriptionUrl} target="_blank" rel="noreferrer">
            <img src={pdfImage} alt="pdf" />
            {t('jobPreview.JobPreviewBoardingSection.parent')}
          </a>
        </div>
      )}
      {!noSubTitles && (
        <p>
          <b>
            {t('jobPreview.JobPreviewBoardingSection.additionalSupervision')}
          </b>
        </p>
      )}
      <ul>
        {salary && (
          <li>
            {t('jobPreview.salary')}
            {applicantStage !== ApplicantStage.IN_THE_JOB_POSTING
              ? t('jobPreview.salaryDescription', { salaryInfo })
              : salaryInfo}
          </li>
        )}
        {additionalBenefits && (
          <li>
            {t('jobPreview.JobPreviewBoardingSection.benefits')}
            {additionalBenefits}
          </li>
        )}
      </ul>
    </div>
  );
};

export default JobPreviewBoardingSection;
