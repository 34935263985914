import React from 'react';
import classNames from 'classnames';
import ReviewCardWrapper from '../../../CreateSchool/pages/Review/components/ReviewCardWrapper';
import { useNavigate } from 'react-router-dom';

import './AssociationInformation.styles.scss';
import { useTranslation } from 'react-i18next';
import useAssociationInformationList from '../../hooks/useAssociationInformationList';
import useAssociationProfileList from '../../hooks/useAssociationProfileList';
import { useGetAssociationInformationQuery } from 'api/associations.api';
import PageLoader from 'components/PageLoader';

type AssociationInformationProps = {
  className?: string;
};

const AssociationInformation: React.FC<AssociationInformationProps> = (
  props,
) => {
  const { className } = props;

  const navigate = useNavigate();

  const { isLoading, isFetching } = useGetAssociationInformationQuery();

  const informationList = useAssociationInformationList();

  const profileList = useAssociationProfileList();

  const { t } = useTranslation();

  const classes = classNames('association-information', className);

  if (isLoading || isFetching) {
    return <PageLoader />;
  }

  return (
    <div className={classes}>
      <ReviewCardWrapper
        list={informationList}
        title={t('associationInformation')}
        onEdit={() => navigate('../edit-association-information')}
      />
      <ReviewCardWrapper list={profileList} title={t('associationProfile')} />
    </div>
  );
};

export default AssociationInformation;
