import React from 'react';
import { Navigate } from 'react-router-dom';
import { ComplexRoute } from 'router/components/Routes/Routes';
import SchoolGroupResidentialType from '../../pages/SchoolGroupResidentialType';
import IndependentCharterSubtypeForm from '../../pages/IndependentCharterSubtypeForm';

export default [
  {
    path: 'residential-type/:residentialType',
    element: IndependentCharterSubtypeForm,
  },
  {
    path: 'residential-type',
    element: SchoolGroupResidentialType,
  },
  {
    index: true,
    element: <Navigate to="residential-type" replace />,
  },
] as Array<ComplexRoute>;
