import React from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSchoolInformationRoute from 'router/subrouters/Dashboard/subrouters/CreateSchool/hooks/useSchoolInformationRoute';
import useSchoolInformation from '../../hooks/useSchoolInformation';
import ReviewCardWrapper from '../ReviewCardWrapper';
import SliderButtons from 'components/SliderButtons';

import './SchoolInformationPreview.styles.scss';

const SchoolInformationPreview: React.FC = () => {
  const { list, nextStep, previousStep } = useSchoolInformation();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const navigateRoute = useSchoolInformationRoute();

  const {
    values: { schools },
  } = useFormState();

  return (
    <div className="school-information-preview">
      <ReviewCardWrapper
        title={t('schoolInformation')}
        onEdit={() => navigate(`../${navigateRoute}`)}
        list={list}
      />
      {schools?.length > 1 && (
        <SliderButtons onNext={nextStep} onPrevious={previousStep} />
      )}
    </div>
  );
};

export default SchoolInformationPreview;
