import api from 'api';
import React from 'react';
import EditPersonalInformation from 'router/subrouters/Dashboard/components/EditPersonalInformation';

const AssociationPersonalDetails: React.FC = () => {
  return (
    <EditPersonalInformation apiRequest={api.user.updateAssociationUser} />
  );
};

export default AssociationPersonalDetails;
