import { ComplexRoute } from 'router/components/Routes/Routes';
import React from 'react';
import { Navigate } from 'react-router-dom';
import Checkout from './pages/Checkout';
import { SchoolGroupStructureType } from 'searchality-data';
import BasicMatchingSub from './pages/BasicMatchingSub';
import MatchingSelectSchools from '../../pages/MatchingSelectSchools';

export default [
  {
    path: 'matching-plan',
    element: BasicMatchingSub,
  },
  {
    path: 'checkout',
    element: Checkout,
  },
  {
    path: 'select-schools',
    element: MatchingSelectSchools,
    validate: ({ auth: { user } }) =>
      user?.schoolGroup?.structureType === SchoolGroupStructureType.STANDALONE
        ? '/my-account/manage-subscription/matching-plan'
        : '',
  },
  {
    index: true,
    element: <Navigate to="matching-plan" />,
  },
] as Array<ComplexRoute>;
