import React from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { numberPriceToCurrencyString } from 'utils';
import usePricingInformation from '../../../hooks/usePricingInformation';
import ReviewCardWrapper from '../../ReviewCardWrapper';
import { ReactComponent as InformationIcon } from 'icons/Information.icon.svg';
import classNames from 'classnames';
import { SchoolGroupStructureType } from 'searchality-data';
import useSearchalityPricing from 'hooks/useSearchalityPricing';

import './PricingPreview.styles.scss';

const PicingPreview: React.FC<{ payLater?: boolean }> = ({
  payLater = true,
}) => {
  const { GROUP_ADDITIONAL_SCHOOL_PRICE } = useSearchalityPricing(false);
  const list = usePricingInformation();

  const { t } = useTranslation();

  const {
    values: { structureType },
  } = useFormState();

  const classes = classNames('pricing-preview', {
    'pricing-preview--pay-later': payLater,
  });

  return (
    <div className={classes}>
      <ReviewCardWrapper title={t('pricingPlan')} list={list}>
        {structureType === SchoolGroupStructureType.GROUP && (
          <>
            <p className={'pricing-preview__note'}>
              {t('pricingPlanReviewNote1', {
                price: numberPriceToCurrencyString(
                  GROUP_ADDITIONAL_SCHOOL_PRICE,
                ),
              })}
            </p>
            <p className={'pricing-preview__note'}>
              {t('pricingPlanReviewNote2')}
            </p>
          </>
        )}
      </ReviewCardWrapper>
      {payLater && (
        <div className="pricing-preview__pay-later">
          <InformationIcon />
          <p>{t('pricingPlanReviewPayLater')}</p>
        </div>
      )}
    </div>
  );
};

export default PicingPreview;
