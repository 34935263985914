import React, { useCallback, useEffect } from 'react';
import PersonalInformation from 'router/subrouters/Dashboard/components/PersonalInformation';
import confirm from 'modules/confirm';
import useAppDispatch from 'hooks/useAppDispatch';
import api from 'api';
import {
  popServerError,
  popSuccess,
} from 'store/slices/popNotifications.slice';
import { logout } from 'store/slices/auth.slice';
import { useTranslation } from 'react-i18next';
import { Role, UserStatus } from 'searchality-data';
import { useLazyGetAssociationMembersQuery } from 'api/associations.api';
import useAppSelector from 'hooks/useAppSelector';
import authSelectors from 'store/selectors/auth.selectors';
import { useNavigate } from 'react-router-dom';

const AssociationPersonalInformation: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [getMembersQuery, { data }] = useLazyGetAssociationMembersQuery();
  const { role } = useAppSelector(authSelectors.selectUser);

  const { items: members } = data || {};

  const handleDeleteRequest = useCallback(async () => {
    try {
      await api.user.deleteAssociationAccount();
      dispatch(popSuccess(t('succesfulyDeletedAccount')));
      dispatch(logout());
    } catch (e) {
      dispatch(popServerError(e));
    }
  }, [dispatch, t]);

  const handleEmptyAssociation = useCallback(async () => {
    try {
      await api.user.requestAccountRemoval();
      dispatch(popSuccess(t('deleteAccountModal.associationSuccess')));
      dispatch(logout());
    } catch (e) {
      dispatch(popServerError(e));
    }
  }, [dispatch, t]);

  const handleDelete = useCallback(async () => {
    if (role === Role.AssociationOwner && members?.length === 1) {
      await confirm({
        title: t('deleteAccountModal.associationTitle'),
        content: <p>{t('deleteAccountModal.associationDescription')}</p>,
        confirmBtnText: t('deleteAccountModal.associationButton'),
        onSubmit: () => {
          handleEmptyAssociation();
        },
      });
    } else if (role === Role.AssociationOwner) {
      await confirm({
        title: t('deleteAccountModal.title'),
        content: (
          <p>
            {t('deleteAccountModal.description', {
              name: t('deleteAccountModal.association'),
            })}
          </p>
        ),
        showCancel: false,
        confirmBtnText: t('deleteAccountModal.button'),
        onSubmit: () => {
          navigate('/association-members');
        },
      });
    } else {
      await confirm({
        title: t('deleteAccountConfirmationModal.title'),
        content: t('deleteAccountConfirmationModal.content'),
        confirmBtnText: t('deleteAccountConfirmationModal.delete'),
        onSubmit: () => {
          handleDeleteRequest();
        },
      });
    }
  }, [
    handleDeleteRequest,
    handleEmptyAssociation,
    members?.length,
    navigate,
    role,
    t,
  ]);

  useEffect(() => {
    (async () => {
      getMembersQuery({
        $page: 1,
        $limit: 2,
        $where: {
          status: UserStatus.ACTIVE,
        },
      });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PersonalInformation handleDelete={handleDelete} />;
};

export default AssociationPersonalInformation;
