import React, { useEffect } from 'react';
import EmptyScreen from 'components/EmptyScreen';
import { Button } from 'ncoded-component-library';
import { useNavigate } from 'react-router-dom';
import emptyScreen from 'assets/images/add-school-empty-state.webp';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import { resetState } from 'store/slices/addNewSchool.slice';
import useAppDispatch from 'hooks/useAppDispatch';

import './EmptyStateAddSchools.styles.scss';

const EmptyStateAddSchools: React.FC = () => {
  const {
    values: { schools },
  } = useFormState();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  return (
    <form className="empty-state-add-schools">
      <EmptyScreen
        title={t('AddNewSchool.EmptyStateAddSchools.title', {
          count: schools.length,
        })}
        description={t('AddNewSchool.EmptyStateAddSchools.description')}
        img={<img src={emptyScreen} alt="Empty screen" />}
        headingLevel={1}
      />
      <Button
        onClick={() =>
          navigate(
            '/my-account/manage-subscription/basic-subscription/matching-plan',
          )
        }
      >
        {t('AddNewSchool.EmptyStateAddSchools.finish')}
      </Button>
    </form>
  );
};

export default EmptyStateAddSchools;
