import { useGetAssociationInformationQuery } from 'api/associations.api';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  const { data } = useGetAssociationInformationQuery();

  if (!data) {
    return [];
  }

  return [
    { label: t('associationType'), value: data?.type },
    {
      label: t('amountOfSchoolsInAssociation'),
      value: data?.schools?.length?.toString(),
    },
  ];
};
