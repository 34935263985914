import React from 'react';
import classNames from 'classnames';
import { Field, useForm, useFormState } from 'react-final-form';
import InputField from 'components/InputField';
import { useTranslation } from 'react-i18next';
import {
  composeValidators,
  emailValidation,
  required,
  youtubeLink,
} from 'validations';
import PhoneNumberField from 'components/PhoneNumberField';
import { ReactComponent as InformationIcon } from 'icons/Information.icon.svg';
import Tippy from '@tippyjs/react';
import DropzoneProfilePhoto from 'components/DropzoneProfileImage';
import MapboxAutocompleteField from 'components/MapboxAutocomplete';
import { useGetOpenJobQuery } from 'api/jobs.api';
import { useParams } from 'react-router-dom';
import { VacancyType } from 'searchality-data';
import useReverseGeocodingFeature from 'hooks/useReverseGeocodingFeature';

import './PersonalInfo.styles.scss';
import './PersonalInfo.styles.responsive.scss';

type JobApplicationPersonalInfoProps = {
  className?: string;
};

const PersonalInfo: React.FC<JobApplicationPersonalInfoProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation();

  const { jobId } = useParams();

  const { data: job } = useGetOpenJobQuery(jobId);

  const { batch, change } = useForm();
  const { values } = useFormState();

  const { getAddressObject } = useReverseGeocodingFeature();

  const baseClass = 'personal-info';
  const classes = classNames(baseClass, className);

  return (
    <div className={classes}>
      <div className={`${baseClass}__basic`}>
        <Field
          name="personalInformation.firstName"
          component={InputField}
          label={t('firstName')}
          required
          validate={required()}
        />
        <Field
          name="personalInformation.lastName"
          component={InputField}
          label={t('lastName')}
          required
          validate={required()}
        />
        <Field
          name="personalInformation.email"
          component={InputField}
          label={t('email')}
          required
          validate={composeValidators(required(), emailValidation())}
        />
        <Field
          name="personalInformation.address"
          key={values?.personalInformation?.address}
          component={MapboxAutocompleteField}
          label={t('address')}
          validate={required()}
          required
          mapFeaturesToOptions={(feature: any) => {
            const { place_name } = feature;
            return {
              label: place_name,
              value: place_name,
            };
          }}
          callback={async (_: string, feature: any) => {
            const address = await getAddressObject(feature);

            batch(() => {
              if (address?.city)
                change('personalInformation.city', address.city);
              if (address?.state)
                change('personalInformation.state', address.state);
              if (address?.postcode)
                change('personalInformation.zipcode', address.postcode);
            });
          }}
          mapboxParams={{
            types: ['address'],
          }}
        />
        <div className={`${baseClass}__basic__phone-number`}>
          <PhoneNumberField
            name="personalInformation.phoneNumber"
            label={t('phoneNumberOptional')}
          />
          <p className={`${baseClass}__basic__phone-number__hint`}>
            {t('phoneNumberHint')}
          </p>
        </div>
      </div>
      <div className={`${baseClass}__photo`}>
        <p className={`${baseClass}__photo__title`}>
          {t('photo')}
          <small>{t('optionalWithMaxSize')}</small>
          <Tippy
            className="tooltip-information"
            content={t('PortraitPhotoModal.tooltip')}
            placement="bottom"
          >
            <InformationIcon />
          </Tippy>
        </p>

        <Field
          name="personalInformation.applicantPhoto"
          render={(props) => {
            const {
              input: { value, onChange },
            } = props;

            return (
              <DropzoneProfilePhoto
                value={value?.length ? value[0] : null}
                setFileCallback={(file: File) => onChange(file ? [file] : [])}
              />
            );
          }}
        />
      </div>
      {job?.type === VacancyType.ACADEMIC && (
        <div className={`${baseClass}__video`}>
          <p className={`${baseClass}__video__title`}>
            {t('video')} <small>{t('optional')}</small>
            <Tippy
              className="tooltip-information video-tippy"
              content={
                <>
                  <p>{t('videoTippy.title')}</p>
                  <ol>
                    <li>{t('videoTippy.li1')}</li>
                    <li>{t('videoTippy.li2')}</li>
                    <li>{t('videoTippy.li3')}</li>
                    <li>{t('videoTippy.li4')}</li>
                    <li>{t('videoTippy.li5')}</li>
                  </ol>
                </>
              }
              placement="bottom"
            >
              <InformationIcon />
            </Tippy>
          </p>
          <Field
            name="personalInformation.videoUrl"
            component={InputField}
            label={t('url')}
            validate={youtubeLink()}
          />
        </div>
      )}
    </div>
  );
};

export default PersonalInfo;
