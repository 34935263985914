import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from 'icons/Calendar.icon.svg';
import { RightToWorkType } from 'searchality-data';
import { formatDate } from 'utils';

import './ProfileWorkAvailabilitySection.styles.scss';

type ProfileWorkAvailabilitySectionProps = {
  className?: string;
  startDate: Date | string;
  hasRightToWork: boolean;
  rightToWork: RightToWorkType[];
  rightToWorkDescription: string;
};

const ProfileWorkAvailabilitySection: React.FC<
  ProfileWorkAvailabilitySectionProps
> = (props) => {
  const {
    className,
    startDate,
    hasRightToWork,
    rightToWork,
    rightToWorkDescription,
  } = props;

  const { t } = useTranslation();

  const baseClass = 'profile-work-availability-section';
  const classes = classNames(baseClass, 'profile-section', className);

  return (
    <div className={classes}>
      <div className="profile-section__title">
        <CalendarIcon />
        <p>{t('Profile.WorkProfile.workAvailability')}</p>
      </div>
      <div className={`${baseClass}__section`}>
        <p className="title">{t('Profile.WorkProfile.startingDate')}</p>
        <p className="value">
          {startDate
            ? formatDate(startDate)
            : startDate === null
            ? t('immediately')
            : t('noData')}
        </p>
      </div>
      <div className={`${baseClass}__section`}>
        <p className="title">{t('Profile.WorkProfile.rightToWorkInUS')}</p>
        {hasRightToWork && rightToWork?.length ? (
          rightToWork.map((el: string) => (
            <p key={el} className="value">
              {el}
            </p>
          ))
        ) : !hasRightToWork && rightToWorkDescription ? (
          <p className="value">{rightToWorkDescription}</p>
        ) : (
          <p className="value">{t('noData')}</p>
        )}
      </div>
    </div>
  );
};

export default ProfileWorkAvailabilitySection;
